import React, {useEffect, useState, useRef} from 'react';
import HRLineDashed from "../assets/hrline-dashed.svg";
import TryonLabsLogo from "../assets/TryonLabsLogo.png";
import closeIcon from "../assets/close_icon1.png";
import MailIcon from "../assets/mail_icon.svg";
import {useNavigate} from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import {selectUserData} from "../selectors";
import { useSelector } from "react-redux";

const Sidebar = React.forwardRef((props, ref) => {
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate();
    const [options, setOptions] = React.useState({
        "/myimages": "My Images",
        "/tryonai": "Virtual Try-On",
        "/aimodel": "Model Generation AI",
        "/modelswapai": "Model Swap AI",
        "/outfitai": "Outfit Generation AI",
        "/outfitcaptionai": "Outfit Caption AI",
        "/credits": "Credits",
        "/profile": "Profile",
        "/docs": "Documentation",
        "/admin/dashboard": "Dashboard",
        "/admin/useronboardingdetails": "User Onboarding Details",
        "/admin/users": "Users",
        "/admin/experiments": "Experiments"
    });

    let adminOptions = [
        "/admin/dashboard",
        "/admin/useronboardingdetails",
        "/admin/users",
        "/admin/experiments"
    ]
    const location = useLocation();
    const sidebarRef = useRef(null);
    const isMobile1 = useMediaQuery({ query: `(max-width: 760px)` })
    const userData = JSON.parse(useSelector(selectUserData));

    useEffect(() => {
        console.log("props.options", props.options.isAdmin, userData)

        const handleResize = () => {
            console.log("innerWidth", window.innerWidth)
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                console.log("is mobile:", isMobile1)

                if(isMobile1){
                    props.closeSideBar();
                }
            }
          };

        window.addEventListener('mousedown', handleClickOutside);
        
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('mousedown', handleClickOutside)
        };
    }, []);

    function handleSelectOption(option) {
        console.log("handleSelectOption inside sidebar: ", option)
        if (props.isSidebarOpen && isMobile) {
            props.closeSideBar();
        }
        let options1 = props.options;
        console.log("options1 before", options1)
        Object.keys(options1).forEach((key) => {
            if (key === option) {
                options1[key] = true;
            } else {
                options1[key] = false;
            }
        })
        console.log("options1 after", options1)
        props.handleOptionSelect(option, options1);
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <div ref={ref}>
            {
                props.isSidebarOpen &&
                (<div className='bg-opac-sidebar relative lg:translate-x-0 lg:static lg:w-auto'>

                    <aside id="sidebar-multi-level-sidebar"
                           className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform translate-x-0 bg-white/opacity-70 shadow backdrop-blur-[30px]"
                           aria-label="Sidebar">

                        <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
                            <div
                                className='flex flex-col items-center justify-center w-full cursor-pointer pointer-events-auto'
                                onClick={() => handleSelectOption("/")}>

                                <div className="flex justify-center gap-4 items-center ">
                                    <img src={TryonLabsLogo} alt="tryonlabs logo" className="w-12 h-auto"/>
                                    <div className="flex flex-col items-end text-right">
                                        <div
                                            className="text-neutral-400 text-sm font-normal font-proxima">
                                            Tryon AI Beta
                                        </div>
                                        <div
                                            className="text-gray-900 text-xl font-medium font-Readex_pro">Playground
                                        </div>
                                        <div
                                            className="block text-gray-900 text-sm font-medium font-Readex_pro">By
                                            TryOn Labs
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {props.options ? (
                                <div className="space-y-2 font-medium mt-4">

                                    {Object.keys(props.options).map((option, key) => (
                                        <div>
                                            {option.includes("/admin")? userData?.is_staff ? (
                                                <div>   
                                                    <li className={`w-full py-3 px-4 justify-start items-center gap-2.5 inline-flex cursor-pointer ${props.options[option] ? "bg-gradient-to-r from-red-500 to-rose-600 rounded-lg" : ""}`}
                                                    onClick={() => {
                                                        handleSelectOption(option)
                                                    }}>
                                                    <a href="#"
                                                    className={`${props.options[option] ? "text-rose-100" : "text-neutral-800 leading-normal"}`}><span
                                                        className="ms-3">{options[option]}</span></a>
                                                    </li>
                                                    <img src={HRLineDashed} className="mx-auto" alt="hr line"/>
                                                </div>):(<></>):(<div>   
                                                <li className={`w-full py-3 px-4 justify-start items-center gap-2.5 inline-flex cursor-pointer ${props.options[option] ? "bg-gradient-to-r from-red-500 to-rose-600 rounded-lg" : ""}`}
                                                onClick={() => {
                                                    handleSelectOption(option)
                                                }}>
                                                <a href="#"
                                                className={`${props.options[option] ? "text-rose-100" : "text-neutral-800 leading-normal"}`}><span
                                                    className="ms-3">{options[option]}</span></a>
                                                </li>
                                                <img src={HRLineDashed} className="mx-auto" alt="hr line"/>
                                            </div>)}
                                        </div>
                                    ))}
                                </div>) : (<></>)}
                        </div>

                        <div className="text-black absolute bottom-5 left-5 text-sm cursor-pointer">
                            <div className="flex justify-start items-center gap-2">
                                <img src={MailIcon} alt="mail icon" className="w-6 h-auto"/>
                                <span className="underline">Contact Us</span>
                            </div>
                            <a className="text-gray-500" href="mailto:contact@tryonlabs.ai">contact@tryonlabs.ai</a>
                        </div>
                    </aside>

                    <div onClick={() => props.closeSideBar()} className='w-8 h-8 fixed left-56 top-4 z-40 lg:hidden m-2 cursor-pointer border rounded-full flex justify-center items-center bg-white'>
                        <img src={closeIcon}/>
                    </div>
                </div>)
            }
        </div>
    )
})

export default Sidebar;

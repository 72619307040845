import React from "react";


import CardGradient from "../assets/CardGradient.svg";
import CardGradient2 from "../assets/CardGradient2.svg";

export default function InfoCard({ title, description, onClick, icon }) {
    return (
        <div
            className="group bg-white/70 border border-gray-200 rounded-xl shadow-sm hover:shadow-md transition-all duration-200 hover:border-gray-300">
            <div className="p-6 h-full">
                <div className="flex flex-col h-full">
                    {/* Icon and Title Section */}
                    <div className="flex items-center gap-3 mb-4">
                        <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-gray-50 group-hover:bg-gray-100 transition-colors duration-200">
                            <div className="text-gray-600 group-hover:text-gray-700 transition-colors duration-200">
                                {icon}
                            </div>
                        </div>
                        <h5 className="text-xl font-semibold tracking-tight text-gray-800 font-OpenSans">{title}</h5>
                    </div>

                    {/* Description */}
                    <p className="text-gray-600 font-OpenSans mb-6 flex-grow">{description}</p>

                    {/* Button */}
                    <button
                        className="inline-flex font-semibold items-center justify-center gap-2 py-2 px-4 text-sm font-medium text-white bg-gradient-to-r from-red-500 to-red-600 rounded-lg hover:from-red-600 hover:to-red-700 transition-all duration-200 hover:shadow-md w-fit"
                        onClick={onClick}
                    >
                        Open
                        <svg 
                            className="w-4 h-4 transition-transform duration-200" 
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" 
                            fill="none" 
                            viewBox="0 0 14 10"
                        >
                            <path 
                                stroke="currentColor" 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth="2"
                                d="M1 5h12m0 0L9 1m4 4L9 9" 
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    )
}    
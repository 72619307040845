import React from 'react';
import NoModels from "../assets/NoModels.svg";
import GradientFont from "../components/GradientFont";

export default function NoContent({title, message}) {
    return (
        <div className='flex flex-col items-center justify-center h-full w-full p-6 my-4 bg-opacity-70 backdrop-blur-lg rounded-xl bg-white/60 border-2 border-rose-100'>
            <div className="flex flex-col items-center justify-center w-full mb-4">
                <img src={NoModels} alt="error" className='object-contain h-16 w-16 mb-4' />
                <div className="text-xl font-semibold text-center font-OpenSans">
                    <GradientFont>{title}</GradientFont>
                </div>
            </div>
            <span className='text-neutral-800 text-center font-OpenSans text-sm font-normal leading-normal px-4'>
                {message}
            </span>
        </div>
    )
}
import React from "react";
import { TRYON_SERVER_URL } from "../config";
import PictureCard from "./PictureCard";
import errorIcon from "../assets/errorIcon.svg";
import GradientFont from "./GradientFont";


export default function PictureGrid(props) {
    const experiment = props.experiment;

    return (
        <div className={`flex flex-col w-full items-center justify-center rounded-lg border-2 border-rose-100/50 bg-white/30 shadow-lg ${
            experiment?.status === "failed" ? 'border-selected' : 'border-lite-red'
        }`}>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full h-full p-4">

                {experiment?.model !== null ?(
                <PictureCard
                    key={experiment?.id}
                    experiment={experiment}
                    imageUrl={experiment?.model?.image_url}
                    label={"Original"}
                    index={0}
                    TRYON_SERVER_URL={TRYON_SERVER_URL}
                    loading={false}
                /> ):(<></>)}
                {experiment?.status === "completed" && (
                    <>
                        <PictureCard
                            key={experiment.result?.id || experiment.model?.id} 
                            experiment={experiment.result || experiment.model} 
                            imageUrl={experiment.result?.image_url || experiment.model?.image_url} 
                            label="Image 1"
                            index={1} 
                            TRYON_SERVER_URL={TRYON_SERVER_URL}
                            loading={false}
                        />
                      
                        {Array.isArray(experiment.results) && experiment.results.length > 0 &&
                            experiment.results.map((image, index) => {
                                const imageUrl = image?.image_url || experiment?.model?.image_url;
                                const label = `Image ${index + 2}`;

                                return (
                                    <PictureCard
                                        key={image.id}
                                        experiment={image}
                                        imageUrl={imageUrl}
                                        label={label}
                                        index={index + 2} 
                                        TRYON_SERVER_URL={TRYON_SERVER_URL}
                                        loading={false}
                                    />
                                );
                            })
                        }
                    </>
                )}

                {(experiment?.status === "pending" || experiment?.status === "running") &&
                    <>{experiment?.params && 
                        Array.from({ 
                            length: experiment.action === "model_swap" ? JSON.parse(experiment.params).num_images_per_prompt : JSON.parse(experiment.params).num_images_per_prompt 
                        }).map((_, i) => (
                            <PictureCard
                                key={`${experiment.id}-${i}`}
                                experiment={experiment}
                                imageUrl={experiment?.model?.image_url}
                                label="Loading..."
                                index={i}
                                TRYON_SERVER_URL={TRYON_SERVER_URL}
                                loading={true}
                            />
                        ))
                    }

                        {/* <PictureCard
                            key={experiment?.id}
                            experiment={experiment}
                            imageUrl={experiment?.model?.image_url}
                            label={"Original"}
                            index={0}
                            TRYON_SERVER_URL={TRYON_SERVER_URL}
                            loading={true}
                        />

                        <PictureCard
                            key={experiment.id +1}
                            experiment={experiment}
                            imageUrl={experiment.model?.image_url}
                            label={"Original"}
                            index={0}
                            TRYON_SERVER_URL={TRYON_SERVER_URL}
                            loading={true}
                        />

                        <PictureCard
                            key={experiment.id + 2}
                            experiment={experiment}
                            imageUrl={experiment.model?.image_url}
                            label={"Original"}
                            index={0}
                            TRYON_SERVER_URL={TRYON_SERVER_URL}
                            loading={true}
                        /> */}
                    </>
                }

                {experiment?.status === "failed" && (
                    <div className="inset-0 backdrop-blur-sm lg:col-span-4 rounded-2xl w-full h-full">
                        <div className="w-full h-full flex items-center justify-center">
                            <div className="bg-white/90 rounded-xl w-full p-6 shadow-sm">
                                <div className="flex flex-col items-center space-y-4 text-center">
                                    <div className="flex items-center justify-center">
                                        <img 
                                            src={errorIcon} 
                                            alt="error" 
                                            className='w-6 h-6 md:w-8 md:h-8 mr-2' 
                                        />
                                        <GradientFont>FAILED</GradientFont>
                                    </div>
                                    <p className="text-sm md:text-base text-gray-700">
                                        Generation failed. Try again or contact us at{' '}
                                        <a 
                                            href="mailto:contact@tryonlabs.ai" 
                                            className="underline text-[#BE3144] hover:text-[#BE3144]/80 transition-colors"
                                        >
                                            contact@tryonlabs.ai
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="px-4 py-3 bg-gray-50 border-t border-gray-200 text-left w-full rounded-b-lg">
                <div className="flex flex-wrap gap-2">
                    {experiment?.params &&
                        Object.entries(JSON.parse(experiment.params))
                            .filter(([key]) => key !== 'negative_prompt')
                            .map(([key, value]) => {
                                const formattedKey = key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1);
                                return (
                                    key === "prompt" ? (
                                        <div key={key} className="w-full">
                                            <span className="text-sm font-semibold text-gray-700">{formattedKey}:</span>
                                            <span className="ml-2 text-sm text-gray-500">{value.toString()}</span>
                                        </div>
                                    ) : (
                                        <div 
                                            key={key} 
                                            className="flex items-center justify-start bg-gradient-to-r from-gray-50 to-gray-100 
                                            shadow-sm border border-red-200 rounded-full px-4 py-1.5 hover:shadow-md 
                                            transition-all duration-200 ease-in-out"
                                        >
                                            <span className="text-sm font-semibold text-gray-600 my-auto">
                                                {formattedKey.split(' ').map(word => 
                                                    word.charAt(0).toUpperCase() + word.slice(1)
                                                ).join(' ')}:
                                            </span>
                                            <span className="ml-1.5 text-sm font-medium text-gray-500 my-auto">
                                                {value.toString().split(' ').map(word => 
                                                    word.charAt(0).toUpperCase() + word.slice(1)
                                                ).join(' ')}
                                            </span>
                                        </div>
                                    )
                                )
                            })
                    }
                </div>
            </div>
        </div>
    )
}   
import UploadIcon from "../assets/upload_icon.svg";
import ExpandIcon from "../assets/expandicon.svg";
// import UploadIntructImg1 from "../assets/upload_instructions_1.png"
// import UploadIntructImg2 from "../assets/upload_instructions_2.png"
// import UploadIntructImg3 from "../assets/upload_instructions_3.png"
// import UploadIntructImg4 from "../assets/upload_instructions_4.png"
// import UploadIntructImg5 from "../assets/upload_instructions_5.png"
// import UploadIntructImg6 from "../assets/upload_instructions_6.png"

import Carousel from "../components/Carousel";
import { TRYON_SERVER_URL } from "../config";
import { ADMIN_SHOP_URL } from "../config";
import DummyModel from "../assets/dummy-img.svg";
import ArrowDown from "../assets/arrow-down.svg";
import DownloadIcon from "../assets/download.svg";
import ShareIcon from "../assets/shareIcon.svg";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectAccessToken, selectAuthenticated } from "../selectors";
import GradientFont from "../components/GradientFont";
import axios from "axios";
import ErrorMessage from "../components/ErrorMessage";
import StatusModal from "../components/statusModal";
import deleteIcon from "../assets/deleteIcon.svg";
import ThumbsUpIcon from "../assets/like_smiley.svg";
import ThumbsDownIcon from "../assets/dislike_smiley.svg";
import { AsyncImage } from "loadable-image";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
// import { Carousel } from "flowbite-react";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import LazyLoad from "react-lazyload";
import Joyride, { STATUS } from "react-joyride";
import { close } from "@sentry/react";
// import {Carousel} from "flowbite-react";
import WebcamOverlay from "../components/WebCamOverlay";
import { useMediaQuery } from "react-responsive";

toast.configure();

const steps = [
    {
        target: ".select_garment",
        content: "Choose A Garment",
        disableBeacon: true,
    },
    {
        target: ".select_model",
        content: "Choose One Of The Uploaded Pictures Or..."
    },
    {
        target: ".upload_photo",
        content: "...Upload Your Picture",
    },
    {
        target: ".upload_instructions",
        content: "Please Refer To This Guide Before Uploading the Photo",
    },
    {
        target: ".perform_tryon",
        content: "Start Try-on. You Can Try-on Multiple Garments At The Same Time!",
    },
    {
        target: ".tryon_results",
        content: "View All Your Results Here. Have Fun Trying-on Our Garments :)",
    }
]

export default function TryonAI() {
    const [garments, setGarments] = useState([]);
    const [models, setModels] = useState([]);
    const [globalModels, setGlobalModels] = useState([]);
    const [garmentsFetched, setGarmentsFetched] = useState(false);
    const [userModelsFetched, setUserModelsFetched] = useState(false);
    const [globalModelsFetched, setGlobalModelsFetched] = useState(false);
    const [selectedGarment, setSelectedGarment] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedResult, setSelectedResult] = useState(null);
    const [showImagePreview, setShowImagePreview] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const accessToken = useSelector(selectAccessToken) || null;
    const [tryonLimit, setTryonLimit] = useState(null);
    const [results, setResults] = useState([]);
    const [resultsFetched, setResultsFetched] = useState(false);
    const hiddenUserModelFileInput = useRef(null);
    const [isMobile, setIsMobile] = useState(false);
    const [previewArea, setPreviewArea] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorStatus, setErrorStatus] = useState('');
    const [errorCode, setErrorCode] = useState(null);
    const [processingModels, setProcessingModels] = useState([]);
    const [triggerFetch, setTriggerFetch] = useState(false);
    const [warning, setWarning] = useState(false);
    const [experimentWarning, setExperimentWarning] = useState(false);
    const [showUploadInstructions, setShowUploadInstructions] = useState(false);
    const previewAreaRef = useRef(null);
    const controller = new AbortController();
    const signal = controller.signal;
    const [showUploadGuideModal, setShowUploadGuideModal] = useState(true);
    const authenticated = useSelector(selectAuthenticated);
    const [waitingQueue, setWaitingQueue] = useState(null);
    const [carouselVisible, setCarouselVisible] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    // const [cameraInput, setCameraInput] = useState(false);
    const carouselRef = useRef(null);
    const uploadRef = useRef(null);
    const navigate = useNavigate();
    const [genderFilter, setGenderFilter] = useState('all');
    const [unfilteredGarments, setUnfilteredGarments] = useState([]);
    const [garmentsByBrand, setGarmentsByBrand] = useState(null);
    const isMobile1 = useMediaQuery({ query: `(max-width: 760px)` })

    // Trigger to stop ongoing api calls 
    const abortFetch = () => {
        controller.abort();
    };

    const notify_feedback = () => {
        toast("Thanks for the feedback!", { autoClose: 3000 },);
    };

    const wrong_feedback_error = () => {
        toast("Feedback for Public Images not Possible!", { autoClose: 3000 },);
    };

    // Bug fix for iOS double touch issue 
    useEffect(() => {
        const handleTouchStart = (event) => {
            const clickableDiv = event.target.closest('[data-clickable-id]');
            // console.log('Event Target:', event.target);
            // console.log('Closest Clickable Div:', clickableDiv);
            if (clickableDiv) {
                event.preventDefault();
                clickableDiv.click();
            }
        };

        document.addEventListener('touchstart', handleTouchStart, { passive: false });

        return () => {
            document.removeEventListener('touchstart', handleTouchStart);
        };
    }, []);

    // get and update corousel settings in localStorage 
    useEffect(() => {
        let carousel = localStorage.getItem('carousel') || null;
        console.log("carousel from local storage", carousel)
        if (carousel === null) {
            localStorage.setItem('carousel', true);
            carousel = true;
        }
        setCarouselVisible(carousel);
    }, [showUploadInstructions,])


    //Close carousel
    const closeCarousel = () => {
        setCarouselVisible(false);
        localStorage.setItem('carousel', false);
        setShowUploadInstructions(false);
    }

    // Open carousel
    const triggerCarousel = () => {
        console.log("carousel trigger", carouselVisible)
        if (carouselVisible === "true") {
            console.log("carousel here", carouselVisible)
            setShowUploadInstructions(carouselVisible);
            // handleUserModelClick();
        } else {
            setShowUploadModal(true);
            // handleUserModelClick();
        }
    }

    // useEffect(,[carouselVisible, ])

    // Closes Upload modal upon clickng outside of the modal
    const handleClickOutsideUploadModal = (event) => {
        console.log("Clicked event target:", event.target);
        if (uploadRef.current && !uploadRef.current.contains(event.target)) {
            console.log("Clicked outside modal, current modal state:", showUploadModal);
            setShowUploadModal(false);
        }
    };

    useEffect(() => {
        console.log("Upload modal visibility changed:", showUploadModal);

        if (showUploadModal) {
            document.addEventListener('mousedown', handleClickOutsideUploadModal);
        } else {
            document.removeEventListener('mousedown', handleClickOutsideUploadModal);
        }

        // Cleanup function to remove the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideUploadModal);
        };
    }, [showUploadModal]);

    // gets the image file from camera component, and outputs it as array and pass it to handleFilesAdded
    const handleCameraInput = async (imgSrc) => {
        console.log('Uploaded image:', imgSrc);
        if (imgSrc) {
            await handleFilesAdded([imgSrc]);
        }
        // setCameraInput(false);
        setShowUploadModal(false);
    };

    //Check if the response is JSON
    function isJSON(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }


    //set and update tryonlimit in localStorage

    // useEffect(() => {
    //     const tryonLimit = JSON.parse(localStorage.getItem('tryonLimit'));
    //     if (!tryonLimit) {
    //         localStorage.setItem('tryonLimit', 0);
    //     }
    // }, []);

    // const isLimitExceeded = () => {
    //     const tryonLimit = localStorage.getItem('tryonLimit');
    //     if (tryonLimit >= 3) {
    //         setErrorMessage("Tryon Limit Exceeded. Please Sign Up or Login to Continue!");
    //         setErrorStatus("tryon Limit Exceeded");
    //         setErrorCode(401);
    //         setError(true);
    //         return true;
    //     }
    //     return false;
    // };

    // const updateLimit = () => {
    //     const tryonLimit = localStorage.getItem('tryonLimit');
    //     localStorage.setItem('tryonLimit', parseInt(tryonLimit) + 1);
    // };


    //function to check the viewport dimensions dynamically and will set IsMobile to true if window width is less than 768px
    useEffect(() => {
        console.log("Joyride status:", STATUS.FINISHED)

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handleUserModelClick = event => {
        hiddenUserModelFileInput.current.click();
    };

    const closeImagePreview = () => {
        setShowImagePreview(false);
        setPreviewImage(null);
        setSelectedType(null);
    }

    // collect the files from event and pass it to handleFilesAdded 
    const handleFileSelected = async (event) => {
        const files = event.target.files;
        console.log("file types in fm", files)

        if (files.length > 0) {
            await handleFilesAdded(files);
        }
        // event.target.value = null;
    };

    // resize and upload files to the server 
    const handleFilesAdded = async (files) => {
        console.log("files dropped", files);

        for (let i = 0; i < files.length; i++) {
            console.log('Uploading file', i + 1);


            try {
                const resizedImageUrl = await resizeUploadedFile(files[i]);
                const resizedFile = dataURLToBlob(resizedImageUrl);
                console.log("after resize", files[i]?.name, resizedFile);


                const formData = new FormData();
                formData.append('type', 'model');
                formData.append('access', 'private');
                formData.append('image', resizedFile, files[i]?.name);
                console.log('FormData for file', i + 1, formData);

                let headers = {};
                if (accessToken) {
                    headers = {
                        'Authorization': `Bearer ${accessToken}`
                    };
                }
                const response = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment_image/`, {
                    method: 'POST',
                    headers: headers,
                    body: formData,
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        throw new Error("User not logged in. Please Log in and try Again");
                    } else {
                        throw new Error(JSON.stringify(await response.json()));
                    }
                }

                const data = await response.json();
                console.log("uploaded image", data);
                setModels((prevModels) => [data, ...prevModels]);
                await checkAndUpdateStatus(data?.id);
                // setSelectedModel(data?.id);
            } catch (error) {
                console.error("Error during try on:", error.message);
                if (isJSON(error?.message)) {
                    let errorData = JSON.parse(error?.message);
                    if (errorData?.image && Array.isArray(errorData?.image) && errorData?.image?.length > 0) {
                        setErrorMessage(errorData.image[0]);
                    } else if (errorData?.message && Array.isArray(errorData?.message) && errorData?.message?.length > 0) {
                        setErrorMessage(errorData?.message[0]);
                    } else {
                        setErrorMessage('Something went wrong! Please try again later');
                    }
                    // setErrorMessage(errorData[0]);
                    setErrorStatus(errorData?.status);
                    setError(true);
                } else {
                    setErrorStatus("Failure!")
                    setErrorMessage(error?.message);
                    setError(true);
                }
            }
        }
        // setUpload(false);
        // Update state or perform other actions after all files are uploaded
    };

    // Resizes the image and returns the resized image dataURL 
    const resizeUploadedFile = (file) => {
        return new Promise((resolve, reject) => {
            if (file.type.match(/image.*/)) {
                // Load the image
                var reader = new FileReader();
                reader.onload = function (readerEvent) {
                    var image = new Image();
                    image.onload = function (imageEvent) {
                        // Resize the image
                        var canvas = document.createElement('canvas'),
                            max_size = 1024, // TODO: pull max size from a site config
                            width = image.width,
                            height = image.height;
                        if (width > height) {
                            if (width > max_size) {
                                height *= max_size / width;
                                width = max_size;
                            }
                        } else {
                            if (height > max_size) {
                                width *= max_size / height;
                                height = max_size;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                        var dataUrl = canvas.toDataURL('image/jpeg');
                        resolve(dataUrl); // Resolve with the resized image URL
                    };
                    image.src = readerEvent.target.result;
                };
                reader.readAsDataURL(file);
            } else {
                reject(new Error('Uploaded file is not an image.'));
            }
        });
    };

    // Convert the resized image dataURL back to a Blob
    function dataURLToBlob(dataURL) {
        var parts = dataURL.split(';base64,');
        var contentType = parts[0].split(':')[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;
        var uInt8Array = new Uint8Array(rawLength);

        for (var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], { type: contentType });
    }

    // Download the generated Tryon Experiment
    const handleDownload = async (imageUrl) => {
        let headers = {};
        if (accessToken) {
            headers = {
                'Authorization': `Bearer ${accessToken}`
            };
        }
        const response = await fetch(imageUrl, {
            headers: headers
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'Result.png'; // You can dynamically set the filename based on image data
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.remove();
        window.URL.revokeObjectURL(url);
    };

    const handleShare = async (result) => {
        console.log("handle share result",result);
        const shareData = {
            title: 'Checkout this Tryon',
            text: 'Visit TryOn AI Playground by TryOn Labs, a Virtual Trial Room for Apparels.',
            url: `${window.location.origin}/preview/${encodeURIComponent(result?.garment?.image_url)}/${encodeURIComponent(result?.model?.image_url)}/${encodeURIComponent(result?.result?.image_url)}`,
        };

        if (navigator.share) {
            try {
                await navigator.share(shareData);
                console.log('Shared successfully');
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            // Fallback: Copy to clipboard
            try {
                await navigator.clipboard.writeText(`${shareData.text} ${shareData.url}`);
                alert('Link copied to clipboard. Share it with your friends!');
            } catch (error) {
                console.error('Error copying to clipboard:', error);
            }
        }
    };

    // const handleUserModelChange = event => {
    //     const fileUploaded = event.target.files[0];
    //     console.log("File uploaded:", fileUploaded)

    //     let filename = fileUploaded.name;

    //     let data = new FormData();
    //     data.append("image", fileUploaded, filename)
    //     data.append("type", "model")
    //     data.append("gender", "male")

    //     axios.post(`${TRYON_SERVER_URL}/api/v1/experiment_image/`, data, {
    //         headers: {
    //             Authorization: `Bearer ${accessToken}`,
    //             'Content-Type': 'multipart/form-data'
    //         }
    //     })
    //         .then((response) => {
    //             //handle success
    //             console.log(response)
    //             if (response.status === 201) {
    //                 console.log(response.data)
    //                 // let userModels = fetchedUserModels
    //                 // console.log(userModels.length)
    //                 // userModels.unshift(response.data)
    //                 // console.log(userModels.length)
    //                 // // fetch user models again
    //                 // setFetchedUserModels(userModels)

    //                 fetchUserModels().then(() => {
    //                 })
    //             } else {
    //                 console.log(response.data.message)
    //             }
    //         }).catch((error) => {
    //             //handle error
    //             console.log(error)
    //         });

    //     // fetch(fileUploaded).then(res => res.blob())
    //     //     .then(blob => {
    //     //         console.log("Blob:", blob)
    //     //
    //     //     });
    // };

    useEffect(()=>{
        filterGarments(unfilteredGarments)
    },[genderFilter,unfilteredGarments ])


    // fetch all the garments from the server and filter out the failed ones
    async function fetchShopGarments() {
        let res = await axios.get(`${TRYON_SERVER_URL}/api/v1/experiment_image/?shop=${ADMIN_SHOP_URL}&access=public`)
        console.log("Fetched garments:", res.data)

        if (res.status === 200) {
            const filteredSet = res.data.filter(item => item.type === 'garment' && !(item.preprocessing === "failed" || item.preprocessing === "failure"))
            setUnfilteredGarments(filteredSet);
            // filterGarments(filteredSet);
            // setGarments(res.data)
            setGarmentsFetched(true)
        } else {
            setGarmentsFetched(true)
        }
    }

    // filter the garments on the basis of gender and seggragate the garments based on brand, outputs a dictionary, with key as the brand name and value as the array
    const filterGarments =(garments) => {
        setGarmentsFetched(false);
        console.log("garments before", garments)
        const garmentsByGender = genderFilter === 'all'
        ? garments
        : garments.filter(garment => {
            if (genderFilter === 'unisex') {
                return garment.gender === 'male';
            }
            return garment.gender === genderFilter;
        });
    
        console.log("garmentsBygender", garmentsByGender)

        const garmentsByBrand = garmentsByGender.reduce((acc, garment) => {
            if (!acc[garment.garment_brand]) {
                acc[garment.garment_brand] = [];
            }
            acc[garment.garment_brand].push(garment);
            return acc;
        }, {});

        // Sort brands alphabetically
        const sortedBrands = Object.keys(garmentsByBrand).sort();

        // Combine sorted brands into a single array
        const sortedGarments = sortedBrands.flatMap(brand => garmentsByBrand[brand]);

        const sortedGarmentsByBrand = sortedBrands.reduce((acc, brand) => {
            acc[brand] = garmentsByBrand[brand];
            return acc;
        }, {});

        setGarments(sortedGarments);
        console.log("sorted Garments", sortedGarments)
        console.log("sorted garments by brand", sortedGarmentsByBrand)
        setGarmentsByBrand(sortedGarmentsByBrand);
        setGarmentsFetched(true);

    }

    // async function fetchUserModels() {
    //     setUserModelsFetched(false);
    //     try {

    //         const userModelsResponse = fetch(`${TRYON_SERVER_URL}/api/v1/experiment_image/?access=private&type=model`, {
    //             method: 'GET',
    //             headers: {
    //                 Authorization: `Bearer ${accessToken}`,
    //                 'Content-Type': 'application/json',
    //             },
    //         });

    //         const globalModelsResponse = fetch(`${TRYON_SERVER_URL}/api/v1/experiment_image/?shop=${ADMIN_SHOP_URL}&access=public&type=model`, {
    //             method: 'GET',
    //             headers: {
    //                 // Authorization: `Bearer ${accessToken}`,
    //                 'Content-Type': 'application/json',
    //             },
    //         });
    //         const [userResponse, globalResponse] = await Promise.all([userModelsResponse, globalModelsResponse]);
    //         console.log("trying to fetch global models",globalResponse);

    //         if (userResponse.ok && globalResponse.ok) {
    //             const userModelsData = await userResponse.json();
    //             const globalModelsData = await globalResponse.json();

    //             console.log("Fetched user models:", userModelsData);
    //             console.log("fetched global models:", globalModelsData);

    //             const mergedData = [...userModelsData, ...globalModelsData];

    //             setModels(mergedData.filter(item => item.type === 'model' && !(item.preprocessing === "failed" || item.preprocessing === "failure")));
    //             // setModels(userModelsData.filter(item => item.type === 'model' && !(item.preprocessing === "failed" || item.preprocessing === "failure")));
    //         }else {
    //             console.error('Failed to fetch user results or public experiments');
    //         }
    //         setUserModelsFetched(true);
    //     } catch (error) {
    //         console.error('Error fetching user models:', error);
    //         setUserModelsFetched(true);
    //     }
    // }

    async function fetchUserModels() {
        setUserModelsFetched(false);
        try {
            let userModelsData = [];
            let globalModelsData = [];

            const globalModelsResponse = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment_image/?shop=${ADMIN_SHOP_URL}&access=public&type=model`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (globalModelsResponse.ok) {
                globalModelsData = await globalModelsResponse.json();
                console.log("Fetched global models:", globalModelsData);
            } else {
                console.error('Failed to fetch public experiments');
            }

            // Fetch user models if authenticated or accessToken is available
            if (authenticated === true || accessToken) {
                const userModelsResponse = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment_image/?access=private&type=model`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (userModelsResponse.ok) {
                    userModelsData = await userModelsResponse.json();
                    console.log("Fetched user models:", userModelsData);
                } else {
                    console.error('Failed to fetch user results');
                }
            }

            // Merge data and set models
            const mergedData = [...userModelsData, ...globalModelsData];
            // setModels(mergedData)
            setModels(mergedData.filter(item => item.type === 'model' && !(item.preprocessing === "failed" || item.preprocessing === "failure")));

            setUserModelsFetched(true);
        } catch (error) {
            console.error('Error fetching models:', error);
            setUserModelsFetched(true);
        }
    }


    async function fetchGlobalResults() {
        setResultsFetched(false);
        let userData = [];
        let publicData = [];

        try {
            // Fetch public experiments
            const publicExperimentsResponse = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment/?shop=${ADMIN_SHOP_URL}&access=public`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (publicExperimentsResponse.ok) {
                publicData = await publicExperimentsResponse.json();
            } else {
                console.error('Failed to fetch public experiments');
            }

            // Fetch user results if authenticated or accessToken is available
            if (authenticated || accessToken) {
                const userResultsResponse = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment/?aimodel=virtual_tryon`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (userResultsResponse.ok) {
                    userData = await userResultsResponse.json();
                    console.log("Fetched user results:", userData);
                } else {
                    console.error('Failed to fetch user results');
                }
            }

            // Merge data and set results
            const mergedData = [...userData, ...publicData];
            console.log("Fetched user results and public experiments:", mergedData);
            setResults(mergedData);
            setResultsFetched(true);
        } catch (error) {
            console.error('Error fetching results:', error);
            setResultsFetched(true);
        }
    }


    // useEffect(() => {
    //     if(triggerFetch){
    //         fetchShopGarments();
    //         fetchUserModels();
    //         fetchResults();
    //         setTriggerFetch(false)
    //     }
    // }, [triggerFetch,])

    useEffect(() => {
        if (previewArea && previewAreaRef.current) {
            previewAreaRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [previewArea]);

    function handleGarmentSelect(garment) {
        console.log(garment);
        if (garment?.preprocessing === "completed" || garment?.preprocessing === "failed") {
            setSelectedGarment(garment);
            setSelectedResult(null)
            setPreviewArea(true);
            // console.log("preview ref got clicked",previewAreaRef.current)
            if (previewAreaRef.current) {
                console.log("preview ref got clicked 2", previewAreaRef.current)
                previewAreaRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            setError(false);
        } else {
            setErrorMessage("Please wait while image is getting uploaded to our servers. It's a one time process!");
            setErrorStatus("Status!");
            setError(true);
        }
    }

    function handleModelSelect(model) {
        console.log(model);
        if (model?.preprocessing === "completed" || model?.preprocessing === "failed") {
            setSelectedModel(model);
            setSelectedResult(null)
            setPreviewArea(true);
            if (previewAreaRef.current) {
                previewAreaRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            setError(false);
        } else {
            setErrorMessage("Please wait while image is getting uploaded to our servers. It's a one time process!");
            setErrorStatus("Status!");
            setError(true);
        }

    }

    function handleResultSelect(experiment) {
        console.log(experiment);
        setSelectedResult(experiment);
        setSelectedGarment(experiment?.garment);
        setSelectedModel(experiment?.model);
        setPreviewArea(false);
        setError(false);
    }

    useEffect(() => {
        if (!garmentsFetched) {
            console.log("fetching shopgarments");
            fetchShopGarments().then(() => {
            })
        }
        if (!userModelsFetched) {
            console.log("fetching userModels");
            fetchUserModels().then(() => {
            })
        }
        if (!resultsFetched) {
            console.log("fetching Tryon results");
            fetchGlobalResults().then(() => {
            })
        }
    }, [garmentsFetched, userModelsFetched, resultsFetched]);

    const checkAndUpdateStatus = async (image) => {
        if (processingModels.includes(image)) {
            return;
        }

        setProcessingModels((prevModels) => [...prevModels, image]);

        if (image === null || image <= 0) {
            return;
        }

        let statusUrl = `${TRYON_SERVER_URL}/api/v1/experiment_image/${image}/`;
        let headers = {};
        if (accessToken) {
            headers = {
                Authorization: `Bearer ${accessToken}`,
            }
        }

        let intervalId;
        let status;
        let preprocessingStatus;

        const fetchStatus = async () => {
            try {
                const response = await fetch(statusUrl, { headers });
                if (!response.ok) {
                    if (response.status >= 400 && response.status < 500) {
                        throw new Error("Upload failed please try again, or contact us at: contact@tryonlabs.ai");
                    } else {
                        throw new Error(`Server error: ${response.status} ${response.statusText}`);
                    }
                }

                status = await response.json();
                preprocessingStatus = status?.preprocessing;

                if (preprocessingStatus === 'completed' || preprocessingStatus === 'failed' || preprocessingStatus === 'failure') {
                    clearInterval(intervalId);
                    if (preprocessingStatus === 'failed' || preprocessingStatus === 'failure') {
                        throw new Error("Upload failed please try again, or contact us at: contact@tryonlabs.ai");
                    } else {
                        if (status?.type === "model") {
                            setModels((prevModels) =>
                                prevModels.map((model) =>
                                    model?.id === status?.id ? { ...model, preprocessing: preprocessingStatus } : model
                                )
                            );
                        } else if (status?.type === "garment") {
                            setGarments((prevGarments) =>
                                prevGarments.map((garment) =>
                                    garment?.id === status?.id ? {
                                        ...garment,
                                        preprocessing: preprocessingStatus
                                    } : garment
                                )
                            );
                        }
                    }
                    setProcessingModels((prevModels) => prevModels.filter((id) => id !== image));
                }
            } catch (error) {
                if (status?.type === "model") {
                    setModels((prevModels) => {
                        const filteredModels = prevModels.filter((model) => model?.id !== status?.id);
                        if (filteredModels.length > 0) {
                            setSelectedModel(null);
                        }
                        return filteredModels;
                    });
                } else if (status?.type === "garment") {
                    setGarments((prevGarments) => {
                        const filteredGarments = prevGarments.filter((garment) => garment?.id !== status?.id);
                        if (filteredGarments.length > 0) {
                            setSelectedGarment(null);
                        }
                        return filteredGarments;
                    });
                }
                setErrorMessage(error.message);
                setError(true);
                clearInterval(intervalId);
                console.error("Error fetching status:", error);
            }
        };

        // Ensure the initial call is made before waiting for the interval
        await fetchStatus();

        intervalId = setInterval(fetchStatus, 2000);
    };


    useEffect(() => {
        if (userModelsFetched && garmentsFetched) {
            const pendingOrRunningModels = models.filter(model =>
                model.preprocessing !== 'completed' || model.preprocessing !== 'failed'
            );

            pendingOrRunningModels.forEach(model => {
                checkAndUpdateStatus(model.id);
            });

            const pendingOrRunningGarments = garments.filter(garment =>
                garment.preprocessing !== 'completed' || garment.preprocessing !== 'failed'
            );

            pendingOrRunningGarments.forEach(garment => {
                checkAndUpdateStatus(garment.id);
            });
        }
    }, [userModelsFetched, garmentsFetched]);

    useEffect(() => {
        if (resultsFetched) {
            checkPendingResults(results);
        }
    }, [resultsFetched,])

    const checkPendingResults = async (results) => {
        const pendingResults = results.filter(
            result => (result?.status === 'pending' || result?.status === 'running')
        );

        try {
            await Promise.all(pendingResults.map(async result => {
                console.log("checking pending result status", result);

                try {
                    const image = await checkResultStatus(result);

                    console.log("image is received", image);
                    console.log("image status", image?.status);

                    if (!image || image?.status === "failed" || image?.status === "failure") {
                        console.error("Virtual Try-on failed please try again, or contact us at: contact@tryonlabs.ai");
                        setResults(prevResults =>
                            prevResults.filter(prevResult => prevResult.id !== result?.id)
                        );
                        setSelectedResult(null);
                        throw new Error("Virtual Try-on failed please try again, or contact us at: contact@tryonlabs.ai");
                    }

                    setResults(prevResults =>
                        prevResults.map(prevResult =>
                            prevResult?.id === image?.id ? image : prevResult
                        )
                    );
                } catch (error) {
                    console.error("Error during checkResultStatus:", error.message);
                    setSelectedResult(null);
                    setErrorMessage(error?.message);
                    setError(true);
                }
            }));
        } catch (error) {
            console.error("Error checking result status:", error);
            setErrorMessage(error?.message);
            setErrorStatus("Failure!");
            setError(true);
        }
    };



    // function runExperiment() {
    //     if (selectedModel || selectedGarment) {
    //         alert("Select a garment to try on")
    //     }

    //     let data = new FormData()
    //     data.append('person_id', selectedGarment.id)
    //     data.append('garment_id', selectedModel.id)
    //     axios.post(`${TRYON_SERVER_URL}/api/v1/experiment/`, data, {
    //         headers: {
    //             Authorization: `Bearer ${accessToken}`,
    //             'Content-Type': 'multipart/form-data'
    //         }
    //     })
    //         .then((response) => {
    //             //handle success
    //             console.log(response)
    //             if (response.status === 200) {
    //                 console.log(response.data)
    //                 fetchResults().then(() => {
    //                 })
    //             } else {
    //                 console.log(response.data.message)
    //             }
    //         }).catch((error) => {
    //             //handle error
    //             console.log(error)
    //         });
    // }

    useEffect(() => {
        console.log("queue number is", waitingQueue);
    }, [waitingQueue])

    useEffect(() => {
        let intervalId;

        const checkSelectedResultStatus = async () => {
            try {
                let headers = {
                    // 'Content-Type': 'application/json',
                    // Add any other headers needed
                };
                if (accessToken) {
                    headers = {
                        // 'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    };
                }

                const response = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment/${selectedResult.id}`, {
                    method: 'GET',
                    headers: headers,
                });

                if (response.ok) {
                    const data = await response.json();
                    const status = data?.status;

                    if (status === 'running') {
                        const queueResponse = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment_length/?experiment_id=${selectedResult.id}`, {
                            method: 'GET',
                            headers: headers,
                        });

                        if (queueResponse.ok) {
                            const queueData = await queueResponse.json();
                            console.log('Queue position fetched:', queueData.current_position, !isNaN(Number(queueData.current_position)));
                            if (queueData.current_position && !isNaN(Number(queueData.current_position))) {
                                setWaitingQueue(Number(queueData.current_position));
                            }
                        } else {
                            console.error('Failed to fetch queue position');
                        }
                        // Do not set intervalId here to avoid infinite loop
                    } else if (status === "pending") {
                        console.log("Experiment is pending")
                    } else {
                        clearInterval(intervalId);
                    }
                } else {
                    console.error('Failed to fetch experiment status');
                }
            } catch (error) {
                console.error('Error fetching experiment status:', error);
            }
        };

        if (selectedResult) {
            // Clear the existing interval if it exists
            clearInterval(intervalId);
            checkSelectedResultStatus();
            // Set intervalId here to start a new interval after the first check
            intervalId = setInterval(checkSelectedResultStatus, 2000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [selectedResult, accessToken]); // Include accessToken in the dependencies array if it's used inside the effect



    const checkResultStatus = async (result) => {
        let status = result?.status;
        console.log("Initial status inside checkResult:", status);
        let id = result?.experiment ? result.experiment?.id : result?.id;
        let image;

        while (status === "pending" || status === "running") {
            await new Promise((resolve) => setTimeout(resolve, 2000));
            let headers = {};
            if (accessToken) {
                headers = {
                    'Authorization': `Bearer ${accessToken}`
                };
            }

            try {
                const response = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment/${id}/`, {
                    headers: headers,
                    signal: signal
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                console.log("data inside checkResultStatus Function", data);
                status = data?.status;
                image = data;

                console.log("Updated status inside checkResult:", status);
                console.log("Updated image inside checkResult:", image);
            } catch (error) {
                console.error("Fetch error inside checkResult:", error);
                // Optionally handle error state here
                status = 'failed';
            }
        }

        if (status === 'failure' || status === "failed") {
            console.log('Preprocessing failed. Please try again later.');
            setResults((prevResults) =>
                prevResults.filter((prevResult) => prevResult.id !== id)
            );
            return;
        }

        if (status === 'completed' || status === 'success') {
            console.log('Preprocessing completed. Here is your image:', image);
            return image.experiment;
        }
    };

    const handleDeleteImage = async () => {
        const headers = {
            'Content-Type': 'application/json',
            ...(accessToken && { 'Authorization': `Bearer ${accessToken}` }),
        };

        const deleteImageUrl = `${TRYON_SERVER_URL}/api/v1/experiment_image/${previewImage.id}/`;
        console.log("delete image URL", deleteImageUrl);

        try {
            const response = await fetch(deleteImageUrl, {
                method: 'DELETE',
                headers: headers
            });
            const data = await response.json();
            if (response.ok) {
                console.log(data.message);
                closeImagePreview();
            } else {
                throw new Error(JSON.stringify(await response.json()));
            }
        } catch (error) {
            console.error("Error deleting image:", error.message);
            if (isJSON(error.message)) {
                let errorData = JSON.parse(error.message);
                setErrorMessage(errorData?.message);
                setErrorStatus(errorData?.status);
                setError(true);
            } else {
                setErrorStatus("Failure!")
                setErrorMessage(error.message);
            }
        }
        closeImagePreview();
        setWarning(false);
        if (previewImage === selectedModel) {
            setSelectedModel(null);
        }
        setUserModelsFetched(false);
        setResultsFetched(false);
        // setTriggerFetch(!triggerFetch);
    };


    const handleDeleteExperiment = async () => {
        const headers = {
            'Content-Type': 'application/json',
            ...(accessToken && { 'Authorization': `Bearer ${accessToken}` }),
        };

        const deleteImageUrl = `${TRYON_SERVER_URL}/api/v1/experiment/${selectedResult.id}/`;
        console.log("delete image URL", deleteImageUrl);

        try {
            const response = await fetch(deleteImageUrl, {
                method: 'DELETE',
                headers: headers
            });
            const data = await response.json();
            if (response.ok) {
                console.log(data.message);
                setSelectedResult(null);
            } else {
                throw new Error(JSON.stringify(await response.json()));
            }
        } catch (error) {
            console.error("Error deletting experiment:", error.message);
            if (isJSON(error.message)) {
                let errorData = JSON.parse(error.message);
                setErrorMessage(errorData?.message);
                setErrorStatus(errorData?.status);
                setError(true);
            } else {
                setErrorStatus("Failure!")
                setErrorMessage(error.message);
            }
        }
        setExperimentWarning(false);
        setResultsFetched(false);
        setSelectedResult(null);
        // setGarmentsFetched(false);
        // setUserModelsFetched(false);
        // setTriggerFetch(!triggerFetch);
    };

    const performTryOn = async () => {
        try {
            const resultPromise = new Promise(async (resolve, reject) => {
                if (selectedGarment.id < 0 || selectedModel.id < 0) {
                    console.log("Something went wrong");
                    return reject(new Error("Selected garment or model is invalid."));
                }
                const filteredResults = results.filter(result =>
                    result?.model?.id === selectedModel?.id && result?.garment?.id === selectedGarment?.id && result?.model?.access === "public"
                );
                console.log("filtered with model and garment", filteredResults)

                const finalResults = filteredResults.filter(result =>
                    result && (result.status === 'pending' || result.status === 'running' || result.status === 'completed')
                );
                console.log("final results", finalResults)
                if (finalResults.length > 0) {
                    console.log("loop inside")
                    setSelectedResult(finalResults[0]);
                    return;
                }

                const garment = garments.find(garment => garment?.id === selectedGarment.id);
                const model = models.find(model => model?.id === selectedModel.id);

                const modelStatus = model?.preprocessing;
                console.log("garment and model status", modelStatus, garment?.preprocessing);

                if (!modelStatus || modelStatus === 'failed' || modelStatus === 'failure' || !garment?.preprocessing || garment?.preprocessing === 'failed' || garment?.preprocessing === 'failure') {
                    return reject(new Error("Model status check failed."));
                }



                // setProcessed(true);

                // if (!small) {
                //   setSmall(true);
                // }

                console.log("performing Try on ...");
                const formData = new FormData();
                formData.append('person_id', model?.id);
                formData.append('garment_id', garment?.id);
                console.log('FormData for file', formData);

                let headers = {};

                if (accessToken) {
                    headers['Authorization'] = `Bearer ${accessToken}`;
                }
                let EXPERIMENT_URL = `${TRYON_SERVER_URL}/api/v1/experiment/`

                if (garment.access === "public" && model.access === "public") {
                    EXPERIMENT_URL = `${TRYON_SERVER_URL}/api/v1/public/experiment/`
                    const params = new URLSearchParams({ shop: ADMIN_SHOP_URL, access: "public" }); // Replace with actual parameters
                    EXPERIMENT_URL += `?${params.toString()}`;
                }

                // if (!accessToken) {
                //     if ( isLimitExceeded() ){
                //         console.log("is LImit Exceeded",isLimitExceeded())
                //         return ;
                //     }else{
                //         updateLimit();
                //     }
                // }

                const response = await fetch(EXPERIMENT_URL, {
                    method: 'POST',
                    headers: headers,
                    body: formData,
                });

                if (!response.ok) {
                    return reject(new Error(JSON.stringify(await response.json())));
                }

                const result = await response.json();
                if (result.status === "pending" || result.status === "failure" || result.status === "failed") {
                    setErrorMessage("Image preprocessing is pending. Please try again after sometime!");
                    setErrorStatus("Failure!");
                    setError(true);
                    return reject(new Error("Image preprocessing is pending."));
                }

                console.log("response is recieved", result?.experiment);
                const data = result?.experiment;
                setResults((prevResults) => [data, ...prevResults]);
                setSelectedResult(data);

                // Handle timeout for checkResultStatus
                // const timeout = setTimeout(async () => {
                //     console.error("checkResultStatus timed out after 5 minutes");
                //     setResults((prevResults) =>
                //         prevResults.filter((prevResult) => prevResult?.id !== data?.id)
                //     );

                //     abortFetch();
                //     reject(new Error("Image processing timed out."));
                // }, 5 * 60 * 1000); // 5 minutes in milliseconds

                try {
                    console.log("Result =======", result)
                    let image
                    if (result?.status !== "completed") {
                        image = await checkResultStatus(result);
                    } else {
                        image = result?.experiment;
                    }

                    // clearTimeout(timeout); // Clear timeout if successful
                    // clearTimeout(timeout); // Clear timeout if successful

                    console.log("image is recieved", image);
                    console.log("image status", image?.status); // debug: check image status
                    if (!image || image?.status === "failed" || image?.status === "failure") {
                        console.error("Virtual Try-on failed please try again, or contact us at: contact@tryonlabs.ai"); // debug: log error message
                        setResults((prevResults) =>
                            prevResults.filter((prevResult) => prevResult?.id !== image?.id)
                        );
                        setSelectedResult(null);
                        throw new Error("Virtual Try-on failed please try again, or contact us at: contact@tryonlabs.ai");
                        return;
                    }
                    if (image) {
                        setResults((prevResults) =>
                            prevResults.map((prevResult) =>
                                prevResult.id === image.id ? image : prevResult
                            )
                        );
                    }

                    setSelectedResult(image);
                    setPreviewArea(false);
                    //   if (isMobile) {
                    //     setMobileImageGrid(false);
                    //   }
                } catch (error) {
                    // clearTimeout(timeout); // Clear timeout on error
                    // clearTimeout(timeout); // Clear timeout on error
                    console.error("Error during checkResultStatus:", error?.message);
                    setSelectedResult(null)
                    setErrorMessage(error?.message);
                    setError(true);
                    reject(error);
                }
                // } finally {
                //     clearTimeout(timeout); // Ensure timeout is always cleared
                // }

                resolve();
            });

            await resultPromise;
        } catch (error) {
            console.error("Error during try on:", error?.message);
            if (isJSON(error?.message)) {
                let errorData = JSON.parse(error?.message);
                setErrorMessage(errorData?.message);
                setErrorStatus(errorData?.status);
                setError(true);
            } else {

                if (error?.message === "Server busy, try again later after sometime") {
                    setErrorStatus("Server Busy")
                } else if (error?.message === "Image preprocessing is pending.") {
                    setErrorStatus("Preprocessing is pending")
                } else if (error?.message === "Model status check failed.") {
                    setErrorStatus("Model preporcessing is failed")
                } else (setErrorStatus("Failure!"))
                console.error(error);
                setErrorMessage(error?.message);
                // setErrorStatus(errorData?.status);
                setError(true);
            }
        }
    };

    useEffect(() => {
        console.log("selecedGarment and selectedModel", selectedGarment, selectedModel);
    }, [selectedGarment, selectedModel])

    const handleLikeTryonResult = async () => {
        const selectedResultId = selectedResult.id; // Assuming selectedResult is defined elsewhere
        const apiUrl = `${TRYON_SERVER_URL}/api/v1/experiment/${selectedResultId}/`;
        if (selectedResult.garment.access === "public" && selectedResult.model.access === "public") {
            // setErrorMessage("User Feedback is disabled for Public Images");
            // setErrorStatus("Public Image");
            // setError(true);
            wrong_feedback_error();
            return;
        }

        try {
            const response = await fetch(apiUrl, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_feedback: 'positive' }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            } else {
                const updatedResults = results.map((result) =>
                    result.id === selectedResultId ? { ...result, user_feedback: 'positive' } : result
                );
                setResults(updatedResults);
                setSelectedResult({ ...selectedResult, user_feedback: 'positive' });
                notify_feedback();
            }

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setErrorMessage(error?.message);
            setErrorStatus(error?.status);
            setError(true);
        }
    }

    const handleDislikeTryonResult = async () => {
        const selectedResultId = selectedResult.id; // Assuming selectedResult is defined elsewhere
        const apiUrl = `${TRYON_SERVER_URL}/api/v1/experiment/${selectedResultId}/`;

        if (selectedResult.garment.access === "public" && selectedResult.model.access === "public") {
            setErrorMessage("User Feedback is disabled for Public Images");
            setErrorStatus("Public Image");
            setError(true);
            return;
        }

        try {
            const response = await fetch(apiUrl, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_feedback: 'negative' }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            } else {

                const updatedResults = results.map((result) =>
                    result.id === selectedResultId ? { ...result, user_feedback: 'negative' } : result
                );
                setResults(updatedResults);
                setSelectedResult({ ...selectedResult, user_feedback: 'negative' });
                notify_feedback();
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setErrorMessage(error?.message);
            setErrorStatus(error?.status);
            setError(true);
        }
    }

    const handleRedirectCount = async () => {
        const selectedResultId = selectedResult.id; // Assuming selectedResult is defined elsewhere
        const apiUrl = `${TRYON_SERVER_URL}/api/v1/experiment/${selectedResultId}/`;

        if (selectedResult.garment.access === "public" && selectedResult.model.access === "public") {
            // setErrorMessage("User Feedback is disabled for Public Images");
            // setErrorStatus("Public Image");
            // setError(true);
            console.log("User Feedback is disabled for Public Images");
            return;
        }

        try {
            const response = await fetch(apiUrl, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ redirect_count : 'true' }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }else{
                const responseJson =await response.json();
                console.log("redirect_count updated",responseJson);
            }
            //  else {

            //     const updatedResults = results.map((result) =>
            //         result.id === selectedResultId ? { ...result, user_feedback: 'negative' } : result
            //     );
            //     setResults(updatedResults);
            //     setSelectedResult({ ...selectedResult, user_feedback: 'negative' });
            //     notify_feedback();
            // }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            // setErrorMessage(error?.message);
            // setErrorStatus(error?.status);
            // setError(true);
        }
    }

    const handleJoyrideCallback = (data) => {
        const { step, status } = data;
        console.log(step.target, garments.length, models.length)
        if (step.target === ".select_garment") {
            if (garments.length > 0) {
                setSelectedGarment(garments[0])
                setPreviewArea(true)
            }
        } else if (step.target === ".select_model") {
            if (models.length > 0) {
                setSelectedModel(models[0])
                setPreviewArea(true)
            }
        }

        if ([STATUS.FINISHED].includes(status)) {
            localStorage.setItem("JOYRIDE_STATUS", "finished")
        }
    };

    return (
        <>
            {localStorage.getItem("JOYRIDE_STATUS") !== "finished" ? (
                <Joyride callback={handleJoyrideCallback} steps={steps} showProgress={true} showSkipButton={true}
                    continuous={true} />) : (<></>)}
            <div className="lg:grid lg:grid-cols-3 lg:gap-4 relative h-[93svh] w-full">
                {warning &&
                    <StatusModal payload={{
                        title: "Warning",
                        message: "Do you want to delete this file?",
                        detail: "All the associated try-on images realted to this image will be deleted. Do you still want to continue?"
                    }}
                        onCancel={() => setWarning(false)}
                        onDelete={async () => {
                            await handleDeleteImage()
                        }} />}
                {experimentWarning &&
                    <StatusModal payload={{
                        title: "Warning",
                        message: "Do you want to delete this file?",
                        detail: "This action cannot be undone. Do you still want to continue?"
                    }}
                        onCancel={() => setExperimentWarning(false)}
                        onDelete={async () => {
                            await handleDeleteExperiment();
                        }} />
                }



                {showUploadInstructions && (
                    <div ref={carouselRef} className="!z-50 fixed inset-0 flex items-center justify-center bg-opacity-50 backdrop-blur-lg">
                        <Carousel finalSlideRedirect={() => {
                            setShowUploadModal(true);
                            closeCarousel();
                        }}
                            closeCarousel={closeCarousel} />
                    </div>
                )}

                {showUploadModal && (
                    <div className="!z-50 fixed inset-0 flex items-center justify-center bg-opacity-50 backdrop-blur-lg">
                        <div tabIndex="-1" aria-hidden="true" className="!z-50 fixed inset-0 flex items-center justify-center bg-opacity-50 backdrop-blur-lg">
                            <div className="relative flex flex-col items-center justify-center p-2 max-w-3xl md:w-[85%] h-full">
                                <div ref={uploadRef} className="flex flex-row w-[90%] md:w-[80%] lg:w-[65%] xl:w-[55%] 2xl:w-[50%] min-h-[50%] h-fit max-h-full items-center justify-center rounded-lg  ">
                                    {/* substitute  */}
                                    <WebcamOverlay onUpload={handleCameraInput} onClose={() => { setShowUploadModal(false) }} handleUserModelClick={handleUserModelClick} />

                                    {/* substitutute  */}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="col-span-1 rounded-lg shadow backdrop-blur-[30px] m-1 h-[92vh] ">

                    <div className="flex flex-col relative h-full w-full ">
                    {isMobile1?(
                <p className="my-2 text-center text-lg font-Mulish">TryOn AI</p>):(<></>)}
                
                        <div
                            className="flex-1 h-[50%] bg-opac-gallery border-t border-red-300 py-6 px-6 select_garment">
                            <div
                                className=" flex w-full itmes-center justify-between font-OpenSans text-neutral-800 text-sm capitalize leading-normal h-fit max-h-[10%]">
                                <span className="">Select garment</span>
                                <div className="flex mr-4">
                                    <select
                                        id="genderFilter"
                                        value={genderFilter}
                                        onChange={(e) => setGenderFilter(e.target.value)}
                                        defaultValue={"all"}
                                        className= "block py-2.5 text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-200 appearance-nonefocus:outline-none h-fit w-min transition duration-150 ease-in-out focus:ring-0 focus:border-gray-200 peer"
                                        // className="border border-[#F05941] text-gray-900 text-sm rounded-full focus:ring-2 focus:ring-[#BE3144] focus:border-transparent h-fit w-fit transition duration-150 ease-in-out"
                                    >
                                        <option className="flex items-center gap-2" value="female">
                                            
                                            Women</option>
                                        <option className="flex items-center gap-2" value="male">
                                            
                                            Men</option>
                                        <option className="flex items-center gap-2" value="unisex">
                                            Unisex</option>
                                        <option className="flex items-center gap-2" value="all">All</option>
                                    </select>
                                </div>

                            </div>
                            <div className="flex-1 h-[90%] overflow-y-auto">
                                {garmentsFetched ?
                                    <div className=" w-full h-full">
                                        {Object.keys(garmentsByBrand).map((brand) => (
                                            <div key={brand} className="mb-4">
                                                <h2 className="text-md font-bold mb-2">{brand}</h2>
                                                <div className="grid grid-cols-3 md:grid-cols-4 gap-2 py-4 ">
                                                    {garmentsByBrand[brand].map((garment) => {
                                                        return (
                                                            <div key={garment.id}
                                                                className={`group cursor-pointer relative flex items-center justify-center border-2 border-solid rounded-lg hover:border-[#BE3144] aspect-auto overflow-hidden ${selectedGarment?.id === garment.id ? 'border-[#BE3144]' : 'border-[#F8EEEE]'} `}>
                                                                <div onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleGarmentSelect(garment)
                                                                }} onTouchStart={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    handleGarmentSelect(garment);
                                                                }}
                                                                    className={`relative flex w-full min-h-[16vh] h-full bg-gray-100 items-center justify-center cursor-pointer`}>
                                                                    <AsyncImage
                                                                        loader={<div
                                                                            className=" flex w-full h-full bg-gray-200 items-center justify-center animate-pulse" />}
                                                                        error={<div style={{ background: '#222' }} />}
                                                                        // className={`flex h-48 w-auto object-cover object-top cursor-pointer`}
                                                                        style={{
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            display: 'flex',
                                                                            objectFit: 'contain',
                                                                            objectPosition: 'top',
                                                                            position: 'relative'
                                                                        }}
                                                                        alt="garment image"
                                                                        src={`${TRYON_SERVER_URL}/${garment?.image_url}`}
                                                                    />
                                                                </div>

                                                                <div onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setShowImagePreview(true)
                                                                    setPreviewImage(garment)
                                                                    setSelectedType("garment")
                                                                }}
                                                                    className=" hidden  group-hover:flex absolute cursor-pointer right-1 top-1 h-fit ">
                                                                    <img className="w-4 h-4" src={ExpandIcon} alt="Selected" />
                                                                </div>
                                                                <div
                                                                    className={`absolute inset-0 backdrop-blur-sm w-full h-full p-1 ${(garment?.preprocessing === "completed" || garment?.preprocessing === "failed") ? 'hidden' : 'flex items-center justify-center'}`}>
                                                                    <div
                                                                        className={`absolute loader ${(garment?.preprocessing === "completed" || garment?.preprocessing === "failed") ? 'hidden' : 'flex'}`}></div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    <div className="flex h-full w-full items-center justify-center"><Spinner /></div>
                                }


                                {/* </div> */}
                            </div>
                        </div>

                        <div className="h-[50%] flex flex-col bg-opac-gallery border-t border-red-300 p-6 select_model">
                            <div className="flex justify-between items-center">
                                <div
                                    className="text-neutral-800 text-sm capitalize leading-normal">
                                    Select/Upload your photo
                                </div>
                                <div>
                                    <button
                                        className="upload_instructions py-1 px-2 w-full mx-4 flex rounded-[50px] bg-white z-20 border border-[#F05941] backdrop-blur-[100px] text-center text-sm gap-1.5 items-center justify-center"
                                        onClick={() => setShowUploadInstructions(true)}>
                                        <GradientFont>Guide To Capturing The Photo</GradientFont>
                                    </button>
                                </div>
                            </div>

                            <div className="flex-1 h-[90%] overflow-y-scroll">
                                {userModelsFetched ?
                                    <div className="grid grid-cols-3 md:grid-cols-4 gap-2 py-4 w-full">
                                        {models.map((userModel) => {
                                            return (
                                                <div key={userModel.id}
                                                    className={`group cursor-pointer bg-gray-100 relative flex w-full items-center justify-center border-2 border-solid rounded-lg hover:border-[#BE3144] aspect-auto overflow-hidden ${selectedModel?.id === userModel.id ? 'border-[#BE3144]' : 'border-[#F8EEEE]'} `}>

                                                    <div onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleModelSelect(userModel);
                                                    }}
                                                        onTouchStart={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            handleModelSelect(userModel);
                                                        }}
                                                        className={`relative flex w-full min-h-[16vh] h-full items-center justify-center cursor-pointer`}>
                                                        <AsyncImage
                                                            loader={<div
                                                                className=" flex w-full h-full bg-gray-200 items-center justify-center animate-pulse" />}
                                                            error={<div style={{ background: '#222' }} />}
                                                            // className={`relative flex min-h-[100px] h-auto w-full object-contain object-top cursor-pointer`}
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                                display: 'flex',
                                                                objectFit: 'contain',
                                                                objectPosition: 'top',
                                                                position: 'relative'
                                                            }}
                                                            alt="garment image"
                                                            src={`${TRYON_SERVER_URL}/${userModel?.image_url}`}
                                                        />
                                                    </div>
                                                    {/* <img onClick={(e) => {
                                            e.stopPropagation();
                                            handleModelSelect(userModel)
                                        }}
                                            className={`flex h-[95%] max-w-full object-cover object-top cursor-pointer`}
                                            alt="user model image"
                                            src={`${TRYON_SERVER_URL}/${userModel?.image_url}`} /> */}

                                                    <div onClick={(e) => {
                                                        e.stopPropagation();
                                                        setShowImagePreview(true)
                                                        setPreviewImage(userModel)
                                                        setSelectedType("model")
                                                    }}
                                                        className=" hidden  group-hover:flex absolute cursor-pointer right-1 top-1 h-fit ">
                                                        <img className="w-4 h-4" src={ExpandIcon} alt="Selected" />
                                                    </div>

                                                    <div
                                                        className={`absolute inset-0 backdrop-blur-sm w-full h-full p-1 ${(userModel?.preprocessing === "completed" || userModel?.preprocessing === "failed") ? 'hidden' : 'flex items-center justify-center'}`}>
                                                        <div
                                                            className={`absolute loader ${(userModel?.preprocessing === "completed" || userModel?.preprocessing === "failed") ? 'hidden' : 'flex'}`}></div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    :
                                    <div className="flex h-full w-full items-center justify-center"><Spinner /></div>
                                }

                            </div>

                            <div
                                className="flex items-center justify-center bottom-2 left-0 right-0 cursor-pointer pt-6 ">
                                <button
                                    className="upload_photo py-1 px-4 w-full mx-4 flex rounded-[50px] bg-white z-20 border border-[#F05941] backdrop-blur-[100px] text-center gap-1.5 items-center justify-center"
                                    onClick={() => {
                                        if (!authenticated) {
                                            navigate('/signin'); // Note: use the navigate function, not Navigate
                                        } else {
                                            triggerCarousel();
                                        }
                                    }}>
                                    <img src={UploadIcon} alt="upload" className="w-4 h-auto" />
                                    <GradientFont>Upload your photo</GradientFont>
                                </button>
                                <input
                                    type="file"
                                    multiple
                                    onChange={handleFileSelected}
                                    ref={hiddenUserModelFileInput}
                                    style={{ display: 'none' }}
                                />
                            </div>
                        </div>

                        {showImagePreview ? (<div
                            className="absolute md:top-10 md:left-10 bg-white rounded-2xl shadow z-30 border border-zinc-300 p-4">
                            <div className="bg-red-50 rounded-lg border border-[#F05941] max-h-[80vh]">
                                <img src={`${TRYON_SERVER_URL}/${previewImage?.image_url}`} alt="preview image"
                                    className="rounded-lg max-w-[300px]" />
                            </div>
                            <div className="flex justify-center items-center gap-4 mt-4">

                                {selectedType === "model" ? (
                                    <button
                                        onClick={() => {
                                            setWarning(true);
                                        }}
                                        className="cursor-pointer px-4 py-2 bg-rose-50 rounded-[50px] border border-red-200 backdrop-blur-[100px] justify-center items-center gap-[9px] inline-flex">
                                        <div
                                            className="text-[#F05941] text-sm leading-normal">Delete
                                        </div>
                                    </button>
                                ) : (
                                    <button onClick={() => {
                                        console.log("Product URL: ", previewImage?.shopify_product_handle);
                                        const url = new URL(previewImage.shopify_product_handle, 'https://prod.server.tryonlabs.ai');
                                        window.open(url.href, '_blank');
                                    }}
                                        className="cursor-pointer px-4 py-2 bg-sky-50 rounded-[50px] border border-blue-100 backdrop-blur-[100px] justify-center items-center gap-[9px] inline-flex">
                                        <div
                                            className="text-blue-500 text-sm leading-normal">Visit Garment Page
                                        </div>
                                    </button>
                                )}

                                <button
                                    onClick={() => {
                                        setShowImagePreview(false)
                                    }}
                                    className="cursor-pointer px-4 py-2 bg-neutral-100 rounded-[50px] border border-zinc-100 backdrop-blur-[100px] justify-center items-center gap-[9px] inline-flex">
                                    <div
                                        className="text-gray-900 text-sm leading-normal">Close
                                    </div>
                                </button>
                            </div>
                        </div>
                        ) : (<div></div>)}
                    </div>

                </div>
                <div className="col-span-2 text-2xl w-full h-[92vh]">

                    <div className='flex flex-col relative items-center justify-center w-full md:px-4 md:pt-4 h-[73%]'>
                        {error ? (
                            <ErrorMessage errorMessage={errorMessage} errorStatus={errorStatus} errorCode={errorCode} onClose={() => {
                                setError(false)
                                setErrorMessage('');
                                setErrorCode(null);
                            }} />
                        )
                            :
                            (selectedResult ?
                                <div
                                    className='flex flex-col relative items-center justify-end w-[90%] h-[90%] border border-solid border-[#BE3144] bg-opac-preview rounded-2xl overflow-hidden'>
                                    <div
                                        className='flex flex-col items-center justify-center p-4 md:p-2 rounded-lg h-full '>
                                        <div
                                            className={`absolute inset-0 ${selectedResult ? selectedResult?.result ? '' : 'pointer-events-none flex backdrop-blur-sm' : ''} w-full h-full`} />

                                        <div
                                            className={`absolute ${selectedResult ? selectedResult?.result ? 'hidden' : 'flex' : 'hidden'}`}>
                                            <iframe
                                                src="https://lottie.host/embed/df0a0e6c-91f9-4149-b4e0-6d6f7f98512c/bUA4UZbR5g.json"></iframe>
                                        </div>

                                        <img
                                            src={selectedResult
                                                ? (TRYON_SERVER_URL + "/" + (selectedResult?.result?.image_url || selectedResult?.model?.image_url))
                                                : DummyModel}
                                            alt="preview-result"
                                            className={`object-contain bg-inherit object-center max-w-full h-auto max-h-full ${selectedResult ? selectedResult?.result ? '' : 'pointer-events-none' : ''}`}
                                        />

                                        {/* <div className={`absolute backdrop-blur-md loader ${!(results.find(result => result.id === selectedResult).result) ? 'flex' : 'hidden'}`}></div> */}
                                        {/* {selectedResult && selectedResult.status === "completed" &&
                                            <div
                                                className='flex absolute right=-4 top-4 w-6 h-8 cursor-pointer'>
                                                <img
                                                    src={DownloadIcon}
                                                    onClick={() => {
                                                        handleDownload(`${TRYON_SERVER_URL}/${selectedResult?.result?.image_url}`)
                                                    }}
                                                    alt="DownloadIcon"
                                                    className='object-contain w-6 h-6'></img>
                                            </div>
                                        }


                                        {selectedResult && selectedResult.status === "completed" &&
                                            <div
                                                className='flex absolute left-4 top-4 w-6 h-8 cursor-pointer'>
                                                <img
                                                    src={ShareIcon}
                                                    onClick={() => {
                                                        handleShare(`${TRYON_SERVER_URL}/${selectedResult?.result?.image_url}`)
                                                    }}
                                                    alt="DownloadIcon"
                                                    className='object-contain w-6 h-6'></img>
                                            </div>
                                        } */}

                                        {selectedResult && selectedResult.status === "completed" &&
                                            <div
                                                className='flex flex-row items-start justify-between absolute px-4 top-4 w-full cursor-pointer'>
                                                <img
                                                    src={DownloadIcon}
                                                    onClick={() => {
                                                        handleDownload(`${TRYON_SERVER_URL}/${selectedResult?.result?.image_url}`)
                                                    }}
                                                    alt="DownloadIcon"
                                                    className='object-contain w-6 h-full mb-4'></img>

                                                <img
                                                    src={ShareIcon}
                                                    onClick={() => {
                                                        handleShare(selectedResult)
                                                    }}
                                                    alt="DownloadIcon"
                                                    className='object-contain w-5 h-full'></img>
                                            </div>
                                        }
                                        {selectedResult && selectedResult.status !== "completed" && typeof waitingQueue === "number" &&
                                            <div className='flex absolute left-4 top-2 items-start pointer-events-none border-1 border-solid rounded-lg opacity-60 bg-white p-2' >
                                                <GradientFont><span className="flex pointer-events-none text-black text-xs font-normal font-OpenSans">Your Try-on is #{waitingQueue} in GPU Queue. It will take Approximately {waitingQueue * 30} seconds...</span></GradientFont>
                                            </div>
                                        }
                                        {selectedResult && selectedResult.status === "completed" && <div
                                            className='flex flex-col items-center absolute right-4 bottom-2 gap-4'>
                                            {selectedResult.user_feedback === null &&
                                                <div className='flex flex-col w-full items-end justify-end gap-4'>
                                                    <img src={ThumbsUpIcon} alt="thumbs up"
                                                        className="w-8 h-auto cursor-pointer"
                                                        onClick={() => {
                                                            handleLikeTryonResult();
                                                            //  notify_feedback()
                                                        }} />
                                                    <img src={ThumbsDownIcon} alt="thumbs down"
                                                        className="w-8 h-auto cursor-pointer"
                                                        onClick={() => {
                                                            handleDislikeTryonResult();
                                                            //  notify_feedback()
                                                        }} />

                                                </div>
                                            }
                                            <div className='flex flex-row items-center justify-between'>
                                                <button onClick={async() => {
                                                    await handleRedirectCount();
                                                    console.log("Product URL: ", selectedResult?.garment?.shopify_product_handle);
                                                    const url = new URL(selectedResult?.garment?.shopify_product_handle, 'https://prod.server.tryonlabs.ai');
                                                    window.open(url.href, '_blank');
                                                }}
                                                    className="cursor-pointer mr-2 px-4 py-2 rounded-[50px] border border-blue-300 backdrop-blur-sm justify-center items-center gap-[9px] inline-flex">
                                                    <div
                                                        className="text-blue-500 text-sm leading-normal">Visit Garment
                                                    </div>
                                                </button>

                                                <img onClick={() => {
                                                    setExperimentWarning(true);
                                                }} src={deleteIcon} alt="deleteIcon"
                                                    className='object-contain object-center w-8 h-auto cursor-pointer' />

                                            </div>
                                        </div>
                                        }
                                        <div
                                            className='absolute flex-1 flex-col w-[20%] md:w-[10%] left-[1rem] bottom-[1rem] items-start justify-between max-h-[50%] -pb-2'>
                                            <div
                                                className='flex items-center w-full h-[45%] rounded border-2 border-white mb-[10%]'>
                                                <img
                                                    src={selectedResult ? TRYON_SERVER_URL + "/" + selectedResult?.garment?.image_url : DummyModel}
                                                    alt="preview-result"
                                                    className='object-cover bg-white object-center w-auto max-h-full'
                                                />
                                            </div>
                                            <div
                                                className='flex items-center w-full h-[45%] rounded border-2 border-white'>
                                                <img
                                                    src={selectedResult ? TRYON_SERVER_URL + "/" + selectedResult?.model?.image_url : DummyModel}
                                                    alt="preview-result"
                                                    className='object-cover bg-white object-center w-auto max-h-full'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div
                                    className='flex flex-col relative items-center justify-end w-[90%] h-[90%] border border-solid border-[#BE3144] bg-opac-preview rounded-2xl overflow-hidden'>
                                    <img
                                        src={DummyModel}
                                        alt="preview"
                                        className='object-cover md:object-contain h-full w-full object-center flex-shrink relative'
                                    />
                                    {previewArea &&
                                        (
                                            <div ref={previewAreaRef}
                                                className='!md:flex absolute top-0 left-0 right-0 bottom-0 w-full h-full backdrop-blur-[5px] rounded-2xl items-center justify-center '>
                                                <div
                                                    className='flex flex-col items-center justify-center h-full w-full perform_tryon'>
                                                    <div
                                                        className='flex flex-col items-center justify-center h-full  w-[30%] md:w-[15%] '>
                                                        <div
                                                            className='flex items-center justify-center h-[30%] w-full bg-[#F8EEEE] border-2 border-solid border-[#FFF] rounded-lg'>
                                                            {selectedGarment !== null ? (
                                                                <img
                                                                    src={TRYON_SERVER_URL + "/" + selectedGarment?.image_url}
                                                                    alt="preview"
                                                                    className=" object-center object-cover max-w-full h-auto max-h-[95%] " />
                                                            ) :
                                                                <span
                                                                    className=" !text-center text-base font-normal font-OpenSans leading-none w-[80%]">
                                                                    <GradientFont>Select A Garment</GradientFont>
                                                                </span>
                                                            }
                                                        </div>
                                                        <div className='flex items-center justify-center w-full h-[8%]'>
                                                            <img
                                                                src={ArrowDown}
                                                                alt="Arrow"
                                                                className=" object-center object-contain w-full h-full " />
                                                        </div>
                                                        <div
                                                            className='flex items-center justify-center w-full h-[30%] bg-[#F8EEEE] border-2 border-solid border-[#FFF] rounded-lg backdrop-blur-lg'>
                                                            {selectedModel !== null ? (
                                                                <img
                                                                    src={TRYON_SERVER_URL + "/" + selectedModel?.image_url}
                                                                    alt="preview"
                                                                    className=" object-top object-cover max-w-full h-auto max-h-[95%] " />
                                                            ) :
                                                                <span
                                                                    className=" !text-center text-base font-normal font-OpenSans leading-none w-[80%]">
                                                                    <GradientFont>Select Your Photo</GradientFont>
                                                                </span>
                                                            }
                                                        </div>

                                                        <div
                                                            className='flex flex-col items-center justify-center w-full h-[12%] mt-[1rem] '>

                                                            <button onClick={() => {
                                                                // setProcessed(false);
                                                                performTryOn();
                                                            }}
                                                                disabled={selectedGarment === null || selectedModel === null || selectedModel?.preprocessing !== "completed" || selectedModel?.preprocessing === "failed"}
                                                                className='flex flex-row items-center justify-center bg-gradient-to-r cursor-pointer w-full from-[#F05941] to-[#BE3144] rounded-[50px] border-1 border-solid border-[#EFCBCB] backdrop-blur-[50px] py-3 px-4'>
                                                                <div
                                                                    className={`cursor-pointer flex flex-row items-center justify-center w-full h-full `}>
                                                                    <span
                                                                        className={`${(selectedGarment && selectedModel) ? '!text-[#F9E0E0]' : '!text-[#F9E0E080] text-opacity-50'} text-base font-normal font-OpenSans leading-normal`}>Try-on</span>
                                                                </div>
                                                            </button>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        )
                                    }
                                </div>)
                        }


                        <span
                            className='!text-center text-[#8E8E8E] pointer-events-none text-sm md:text-base mt-4 flex w-3/4 h-fit items-center justify-center font-normal font-OpenSans leading-normal pb-4 '>
                            Choose/Upload Your Photo to Try On Different Garments
                        </span>
                    </div>

                    <div className="h-[27%] overflow-hidden px-6 tryon_results">
                        <div
                            className="flex text-neutral-800 text-sm capitalize leading-normal items-center justify-start h-[8%] w-fit text-start">
                            Garment Try-On Results
                        </div>

                        <div className="flex justify-start items-center w-full overflow-auto h-[92%]">

                            <div
                                className='!flex flex-col items-start justify-start h-full w-full font-OpenSans'>
                                {resultsFetched ?
                                    <div
                                        className="!grid grid-flow-col auto-cols-max gap-3 items-start justify-center h-full overflow-x-auto py-2">
                                        {results
                                            .filter((image) => {
                                                if (!image.result) {
                                                    return (image?.status === 'completed' || image?.status === 'running' || image?.status === "pending") && (image?.model?.preprocessing === "completed" && image?.garment?.preprocessing === "completed");
                                                }
                                                return true;
                                            })
                                            .map((image, index) => (
                                                <div
                                                    onClick={() => {
                                                        handleResultSelect(image)
                                                    }}
                                                    key={image?.id}
                                                    className={`relative flex h-full cursor-pointer max-w-full items-center justify-center border-2 border-solid rounded-lg hover:border-[#BE3144] aspect-auto overflow-hidden ${selectedResult?.id === image.id ? 'border-[#BE3144]' : 'border-[#F8EEEE]'} `}
                                                >
                                                    <div
                                                        className={`absolute inset-0 backdrop-blur-sm w-full h-full p-1 ${!(image?.id && image?.result) ? 'flex items-center justify-center' : 'hidden'}`}>
                                                        <div
                                                            className={` absolute loader ${!(image?.id && image?.result) ? 'flex ' : 'hidden'} `}></div>
                                                    </div>
                                                    {image?.result ? (
                                                        <div
                                                            className="flex w-[20vw] md:w-[10vw] h-full items-center bg-gray-200 justify-center cursor-pointer">
                                                            <AsyncImage
                                                                loader={<div
                                                                    className="relative flex w-full h-full bg-gray-100 items-center justify-center animate-pulse" />}
                                                                error={<div style={{ background: '#222' }} />}
                                                                style={{
                                                                    minwidth: '100px',
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    display: 'flex',
                                                                    objectFit: 'contain',
                                                                    objectPosition: 'top',
                                                                    position: 'relative'
                                                                }}
                                                                alt="preview"
                                                                src={`${TRYON_SERVER_URL}/${image.result?.image_url}`}
                                                            // className="flex h-full max-w-full object-cover object-top"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <img
                                                            key={image?.id}
                                                            src={`${TRYON_SERVER_URL}/${image.model?.image_url}`}
                                                            alt="loading screen"
                                                            className="flex h-full max-w-full object-cover object-top"
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                        {/*
                                {results.length > 7 && (
                                    <div className="hidden cursor-pointer md:flex flex-col items-center justify-center h-full max-w-full bg-[#F8EEEE] border-2 border-solid rounded-lg hover:border-[#BE3144] border-[#F8EEEE] px-4">
                                        <span className="!text-sm font-semibold mb-2 font-OpenSans "> <GradientFont>See All</GradientFont></span>
                                        <img className="object-contain object-center w-6 h-6" src={GradientArrow} />
                                    </div>
                                )} */}
                                    </div>
                                    :
                                    <div className="flex h-full w-full items-center justify-center"><Spinner /></div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
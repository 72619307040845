import React, { useEffect } from "react";

export default function Docs(){
    useEffect(() => {
        document.title = "Docs - Tryon AI";
        window.open("https://docs.tryonlabs.ai", "_blank");
    }, []);

    return (
            <>
                <div className="flex flex-col justify-start p-4">
                    
                    <div className="flex items-center mb-4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 text-rose-500 mr-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 3h18v18H3V3z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 3v18l-6-6h12l-6 6V3z" />
                        </svg>
                        <h1 className="text-2xl font-semibold">Developer Documentation</h1>
                    </div>

                    <div className="flex items-center justify-start">
                        <button 
                            onClick={() => window.open("https://docs.tryonlabs.ai", "_blank")} 
                            className="flex items-center justify-center bg-gradient-to-r from-[#F05941] to-[#BE3144] rounded-full border border-[#EFCBCB] backdrop-blur-[50px] py-2 px-6 shadow-md hover:shadow-lg transition-shadow duration-200"
                        >
                            <span className="text-white text-sm font-semibold font-OpenSans leading-normal">Visit Documentation</span>
                        </button>
                    </div>
                </div>
            </>
    )
}
import React, { useEffect, useState } from "react";
import FashionModelCustomizer from "../components/Tuning";
import GenerateModelModal from "../components/GenerateModelModal";

export default function HumanModelGeneration(props) {

    const [modelGender, setModelGender] = useState("female");
    const [modelEthnicity, setModelEthnicity] = useState("caucasian");
    const [modelAge, setModelAge] = useState(30);
    const [modelSize, setModelSize] = useState("M");
    const [modelHeight, setModelHeight] = useState(160);

    const [experiments, setExperiments] = useState([
        {
            id: 1,
            src: 'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg',
        },
        {
            id: 2,
            src: 'https://images.unsplash.com/photo-1599566150163-29194dcaad36',
        },
        {
            id: 3,
            src: 'https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg',
        },
        {
            id: 4,
            src: 'https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e',
        },
        {
            id: 5,
            src: 'https://cdn.pixabay.com/photo/2016/03/09/09/30/beautiful-1246844_960_720.jpg',
        },
        {
            id: 6,
            src: 'https://images.unsplash.com/photo-1517841905240-472988babdf9',
        },
        {
            id: 7,
            src: 'https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083390_960_720.jpg',
        },
        {
            id: 8,
            src: 'https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg',
        },
    ]);

    return (
        <div>
            <div className="max-w-7xl h-screen place-content-stretch gap-4 m-4">
                <div className="flex-row flex w-full h-full">
                    <div className="flex w-1/4">
                        <FashionModelCustomizer
                            gender={modelGender}
                            setGender={setModelGender}
                            ethnicity={modelEthnicity}
                            setEthnicity={setModelEthnicity}
                            age={modelAge}
                            setAge={setModelAge}
                            size={modelSize}
                            setSize={setModelSize}
                            height={modelHeight}
                            setHeight={setModelHeight}
                        />
                    </div>
                    <div className="flex w-3/4 m-4 rounded-lg">
                        <GenerateModelModal
                            models={experiments}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
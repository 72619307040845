
import { handleDownload } from "../Utils/DownloadUtils";

export default function ImageViewer2(props) {
    return (
        <div className="fixed inset-0 p-4 rounded-lg lg:p-0 flex items-center justify-center bg-black bg-opacity-75 z-50" onClick={props.onClose}>
            <div className="max-w-full max-h-full rounded-lg relative bg-gray-50">
                <img 
                    src={props.image_url}
                    className="max-w-full max-h-full rounded-lg object-contain" 
                    alt="Preview" 
                    style={{ maxHeight: '80vh', maxWidth: '80vw' }}
                />

                <button 
                    className="absolute top-2 left-2 bg-white rounded-full p-2 shadow-md hover:shadow-lg transition-shadow duration-300"
                    onClick={(e) => {
                        e.stopPropagation()
                        handleDownload(props.image_url)
                    }}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 text-blue-600">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5V21h18v-4.5M12 3v12m0 0l-3-3m3 3l3-3" />
                    </svg>
                </button>   

                <button
                    className="absolute top-2 right-2 bg-white rounded-full p-2 shadow-md hover:shadow-lg transition-shadow duration-300"
                    onClick={props.onClose}
                    aria-label="Close Modal"
                    >
                    <svg
                        className="h-4 w-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
            </div>
        </div>
    );
}
import React from "react";
import errorIcon from "../assets/errorIcon.svg";
import GradientFont from "./GradientFont";
import closeIconBlack from "../assets/close.svg";
import { useNavigate } from "react-router-dom";

export default function ErrorMessage({ errorMessage, errorStatus,errorCode, onClose }) {
    const navigate = useNavigate();
    const handleLoginRedirect = () => {
        navigate("/signin");
    }
    return (
        <div className='animate-fadeIn hidden md:flex flex-col w-full h-full items-center justify-center px-4 pt-4 pb-4'>
            <div className='flex relative items-center justify-center left-0 right-0 bg-opacity-70 backdrop-blur-lg w-full h-full bg-white rounded-2xl border-2 border-[#22092C] border-dashed'>

                <div onClick={onClose} className="absolute top-4 right-4 w-6 h-6 cursor-pointer">
                    <img className="object-contain object-center w-4 h-4 mb-2 " src={closeIconBlack} alt="close icon" />
                </div>

                <div className='relative flex flex-row items-center justify-center h-full w-fit'>

                    <div className='flex flex-col items-center justify-center h-full w-full p-4 mb-2'>
                        <div className="flex flex-row w-full items-center justify-center">
                            <img src={errorIcon} alt="error" className='object-contain h-8 object-center w-8 mr-4' />
                            <div className="!text-xl font-semibold text-center font-OpenSans ">
                                <GradientFont>{errorStatus ? errorStatus : "Failure!"}</GradientFont>
                            </div>
                        </div>
                        <span className='ml-4 my-2 !text-neutral-800 text-center font-OpenSans text-sm font-normal leading-normal'>{errorMessage ? errorMessage : "Try again After sometime!"}</span>
                        {errorCode === 401 &&
                            <div className='flex flex-row mt-2 cursor-pointer items-center justify-center bg-gradient-to-r from-[#F05941] to-[#BE3144] rounded-[50px] border-1 border-solid border-[#EFCBCB] backdrop-blur-[50px] py-3 px-4'>
                                <div onClick={handleLoginRedirect} className={` flex flex-row items-center justify-center w-fit h-full `}>
                                    <span className={`!text-[#F9E0E0] pointer-events-none text-sm font-semibold font-OpenSans leading-normal`}>Log In</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}
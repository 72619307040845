import React from 'react';
import downloadIcon from "../assets/download.svg";
import { TRYON_SERVER_URL } from '../config';
import deleteIcon from "../assets/deleteIcon.svg"

const Modal = ({ image, showExperimentImageDeleteWarning, handleRedirectCount, onClose }) => {
    const handleDownload = async (imageUrl) => {
        let headers = {};
        const response = await fetch(imageUrl, {
            headers: headers
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'Result.png';
        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(url);
    };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75">
      <div className="relative bg-white rounded-lg overflow-hidden shadow-lg max-w-full max-h-full">
        <img
          src={`${TRYON_SERVER_URL}/${image?.image_url}`}
          alt="Preview"
          className="w-full h-auto max-h-[80vh] object-contain"
        />
        <button
          className="absolute top-4 right-4 p-2 bg-white rounded-full shadow-lg text-gray-500 hover:text-gray-700"
          onClick={onClose}
          aria-label="Close Modal"
        >
          <svg
            className="h-4 w-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <button
          className="absolute top-4 left-4 p-2 bg-white rounded-full shadow-lg text-gray-500 hover:text-gray-700"
          onClick={(e)=>{e.stopPropagation();
            handleDownload(`${TRYON_SERVER_URL}/${image?.image_url}`);}}
          aria-label="Download Modal"
        >
          <img className='w-4 h-4' src={downloadIcon}></img>
        </button>

        <div className="absolute bottom-4 right-4 flex gap-2 justify-start items-center">
          <button
            className="flex gap-2 justify-center items-center bg-red-500 rounded-full p-2 shadow-md hover:shadow-lg transition-shadow duration-300"
            onClick={(e) => {e.stopPropagation(); showExperimentImageDeleteWarning(true)}}
          >
            <img src={deleteIcon} alt="delete" className="w-4 h-4 text-white" />
          </button>
        </div>

        {image?.shopify_product_handle &&
          <div className="absolute bottom-4 left-4 flex gap-2 justify-start items-center">
            <button onClick={async(e) => {
              e.stopPropagation();
              await handleRedirectCount();
              const url = new URL(image?.shopify_product_handle, 'https://prod.server.tryonlabs.ai');
              window.open(url.href, '_blank');
          }}
              className="cursor-pointer px-4 py-1.5 rounded-[50px] border border-blue-300 bg-gradient-to-r from-blue-500 to-blue-600 backdrop-blur-sm justify-center items-center gap-[9px] inline-flex">
              <div
                  className="text-white text-sm leading-normal">Visit {image?.type === "garment" ? "Garment" : "Model"} Page
              </div>
          </button>
          </div>
        }
      </div>
    </div>
  );
};

const ImageViewer = ({ image, onClose, showExperimentImageDeleteWarning, handleRedirectCount }) => {
  return (
    <div
      onClick={onClose}
      className="fixed inset-0 z-50 cursor-pointer"
      role="presentation"
    >
      <Modal image={image} onClose={onClose} showExperimentImageDeleteWarning={showExperimentImageDeleteWarning} handleRedirectCount={handleRedirectCount} />
    </div>
  );
};

export default ImageViewer;

import React, { useEffect, useState } from "react";
import { useAuthentication } from "../Authentication";
import { useSelector } from "react-redux";
import { selectAccessToken } from "../selectors";
import { TRYON_SERVER_URL } from "../config";
import ThreeDotSpinner from "../components/ThreeDotSpinner";
import deleteIcon from "../assets/deleteIcon.svg"
import { Select } from "flowbite-react";
import NoModels from "../assets/NoModels.svg";
import GradientFont from "../components/GradientFont";
import { selectAuthenticated } from "../selectors";
import AuthenticationRequired from "../components/AuthenticationRequired";
import { useNavigate } from "react-router-dom";
import UploadImage from "../components/UploadImage";
import { fetchAllImages, deleteExperimentImage } from "../Utils/ExperimentImageUtils";

export default function MyImages(){
    const {isAuthenticated} = useAuthentication();
    const accessToken = useSelector(selectAccessToken)
    const [myImages, setMyImages] = useState([])
    const [fetchingMyImages, setFetchingMyImages] = useState(false)
    const [myImagesFetched, setMyImagesFetched] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [totalImages, setTotalImages] = useState(0)
    const [nextUrl, setNextUrl] = useState(null)
    const [previousUrl, setPreviousUrl] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [imageToDelete, setImageToDelete] = useState(null);
    const [genderFilter, setGenderFilter] = useState("all");
    const [typeFilter, setTypeFilter] = useState("all");
    const authenticated = useSelector(selectAuthenticated)
    const navigate = useNavigate()
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [uploadImage, setUploadImage] = useState(null);
    const baseUrl = `${TRYON_SERVER_URL}/api/v1/experiment_image/?`
    const limit = 20
    const offset = 0

    useEffect(() => {
        if(authenticated){
            if(!myImagesFetched){
                fetchMyImages(baseUrl, {type: typeFilter, gender: genderFilter, limit: limit, offset: offset})
            }
        }
    }, [authenticated])

    useEffect(() => {
        if(authenticated){
            fetchMyImages(baseUrl, {type: typeFilter, gender: genderFilter, limit: limit, offset: offset})
        }
    }, [typeFilter, genderFilter])
    
    const fetchMyImages = async (url, params) => {
        setFetchingMyImages(true)
        const response = await fetchAllImages(url, accessToken, true, false, params)
        console.log("response:", response)
        if(response.status === "success"){
            console.log("response.data:", response.data)
            setMyImages(response.data)
            setTotalImages(response.count)
            setFetchingMyImages(false)
            setMyImagesFetched(true)
            setNextUrl(response.next)
            setPreviousUrl(response.previous)
        }else{
            setError(true)
            setErrorMessage(response.message)
            setFetchingMyImages(false)
            setMyImagesFetched(true) 
        }
    }

    const downloadImage = async (imageId, imageUrl) => {
        try {
            console.log("imageUrl:", TRYON_SERVER_URL + '/' + imageUrl)
            const response = await fetch(TRYON_SERVER_URL + '/' + imageUrl, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            })

            if (response.ok) {
                const blob = await response.blob()
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = 'image_' + imageId + '.png'
                link.click()
                URL.revokeObjectURL(link.href)
            } else {
                console.log(response)
                setError(true)
                setErrorMessage("Error downloading image!")
            }
        } catch (error) {
            console.log(error)
            setError(true)
            setErrorMessage("Error downloading image!")
        }
    }

    const handleDeleteImage = async (imageId) => {
        const response = await deleteExperimentImage(imageId, accessToken)
        if(response.status === "success"){
            setMyImages(prevImages => prevImages.filter(image => image.id !== imageId));
            setTotalImages(totalImages - 1)
            console.log("Image deleted successfully");
        }else{
            setError(true)
            setErrorMessage(response.message)
        }
    };

    const confirmDelete = () => {
        handleDeleteImage(imageToDelete);
        setIsDeleteModalOpen(false);
        setImageToDelete(null);
    };

    const openDeleteModal = (imageId) => {
        setImageToDelete(imageId);
        setIsDeleteModalOpen(true);
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setUploadImage(file);
        setIsUploadModalOpen(true);
    };

    return (
        <div className="p-4 relative h-[calc(100vh-50px)] w-full">
            <div className="lg:flex lg:justify-between lg:items-center">
                <div className="dark:text-white flex justify-start items-center text-lg md:text-xl text-gray-800">
                    <div className="flex justify-start items-center mr-4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-6 h-6 mr-2 text-gray-800 dark:text-white">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                        </svg>
                        <p>
                            My Images {totalImages > 0 && `(${totalImages})`}
                        </p>
                    </div>
                    {authenticated && (
                    <button 
                        onClick={() => setIsUploadModalOpen(true)}
                        className="flex justify-center items-center text-sm bg-rose-500 text-white px-2 py-1 rounded-lg hover:bg-rose-600 transition-all duration-200 ease-in-out">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-3 h-3 mr-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                            Upload
                        </button>
                    )}
                </div>

                {authenticated && (
                <div className="flex space-x-4 flex-wrap items-center mt-4 lg:mt-0">
                    <p className="text-sm text-gray-500">Filters:</p>

                    <select
                        className="text-sm border border-rose-200 bg-white text-gray-500 rounded-lg p-2 focus:ring-rose-300 focus:border-rose-300"
                        value={typeFilter}
                        onChange={(e) => {
                            setTypeFilter(e.target.value)
                        }}
                    >
                        <option className="text-gray-700" value="all">All Types</option>
                        <option className="text-gray-700" value="model">Model</option>
                        <option className="text-gray-700" value="garment">Garment</option>
                        <option className="text-gray-700" value="result">Generated</option>
                    </select>
                    <select 
                        className="text-sm border border-rose-200 bg-white text-gray-500 rounded-lg p-2 focus:ring-rose-300 focus:border-rose-300"
                        value={genderFilter}
                        onChange={(e) => {
                            setGenderFilter(e.target.value)
                        }}
                    >
                        <option className="text-gray-700" value="all">All Genders</option>
                        <option className="text-gray-700" value="male">Male</option>
                        <option className="text-gray-700" value="female">Female</option>
                    </select>
                    </div>
                )}
            </div>

            <hr className="border-rose-200/50 mb-4 mt-2" />

            {fetchingMyImages && 
                <div className="flex justify-center items-center h-full w-full absolute top-0 left-0">
                    <div className="flex justify-center items-center">
                        <ThreeDotSpinner />
                    </div>
                </div>
            }

            {myImages.length > 0 && (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 max-h-[calc(100vh-200px)] overflow-y-auto">
                    {myImages.map((image) => (
                        <div key={image.id} className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
                            <div className="aspect-square overflow-hidden relative">
                                <img 
                                    src={TRYON_SERVER_URL + '/' + image.image_url} 
                                    className="w-full h-full object-cover hover:scale-105 transition-transform duration-300 cursor-pointer"
                                    alt="my image" 
                                    onClick={() => setSelectedImage(image)}
                                />
                                <button 
                                    className="absolute top-2 right-2 bg-white rounded-full p-2 shadow-md hover:shadow-lg transition-shadow duration-300"
                                    onClick={() => downloadImage(image.id, image.image_url)}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4 text-blue-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5V21h18v-4.5M12 3v12m0 0l-3-3m3 3l3-3" />
                                    </svg>
                                </button>
                                <button className="text-sm absolute top-0 left-0 bg-rose-500 hover:bg-rose-600 px-2 py-1 text-white rounded-br-lg shadow-md hover:shadow-lg transition duration-200 ease-in-out">
                                    {image.access.charAt(0).toUpperCase() + image.access.slice(1)}
                                </button>
                                {image.type !== null && (
                                <button className="text-sm absolute bottom-0 left-0 bg-rose-500 hover:bg-rose-600 px-2 py-1 text-white rounded-tr-lg shadow-md hover:shadow-lg transition duration-200 ease-in-out">
                                    {image.type === "result" ? "Generated" : image.type?.charAt(0).toUpperCase() + image.type?.slice(1)}
                                </button>
                                )}
                            </div>
                            <div className="p-4 space-y-2 bg-gradient-to-b from-gray-50 to-white">
                                <div className="flex flex-col mb-3">
                                    <div className="flex flex-start flex-wrap gap-2">
                                    {image.id && (
                                        <div className="flex items-center justify-center lg:justify-start bg-gradient-to-r from-gray-50 to-gray-100  shadow-sm border border-red-200 rounded-lg px-2 py-1 hover:shadow-md  transition-all duration-200 ease-in-out">
                                            <p className="text-sm" style={{fontSize: '0.75rem'}}>ID:&nbsp;{image.id}</p>
                                        </div>
                                        )}
                                        {image.gender !== null && (
                                        <div className="flex items-center justify-center lg:justify-start bg-gradient-to-r from-gray-50 to-gray-100  shadow-sm border border-red-200 rounded-lg px-2 py-1 hover:shadow-md  transition-all duration-200 ease-in-out">
                                            <p className="text-sm" style={{fontSize: '0.75rem'}}>Gender:&nbsp;{image.gender?.charAt(0).toUpperCase() + image.gender?.slice(1)}</p>
                                        </div>
                                        )}
                                    </div>
                                </div>
                                <div className="grid grid-cols-4 items-center gap-4">
                                    <div className="col-span-1 flex gap-2 justify-start items-center">
                                    <button 
                                        className="flex gap-2 justify-center items-center bg-red-500 rounded-full p-2 shadow-md hover:shadow-lg transition-shadow duration-300"
                                        onClick={() => openDeleteModal(image.id)}
                                    >
                                        <img src={deleteIcon} alt="delete" className="w-4 h-4 text-white" />
                                            </button>
                                    </div>
                                    <p className="col-span-3 text-base lg:text-sm text-gray-600 flex justify-end items-center">
                                        {new Date(image.created_on).toLocaleString('en-GB', {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true
                                        }).replace(',', '')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {myImagesFetched && myImages.length === 0 && (
                <div className='flex flex-col items-center justify-center h-74 w-full p-4 mb-2 bg-opacity-70 backdrop-blur-lg rounded-lg bg-white/60 border-2 border-rose-100'>
                    <div className="flex flex-col w-full items-center justify-center ">
                        <img src={NoModels} alt="error" className='object-contain h-16 object-center w-16 mb-4' />
                        <div className="!text-xl font-semibold text-center font-OpenSans ">
                            <GradientFont>{"No Images Found!"}</GradientFont>
                        </div>
                    </div>
                    <span className='mx-4 mt-4 !text-neutral-800 text-center font-OpenSans text-sm font-normal leading-normal'>Uploaded images will be displayed here!</span>
                </div>
            )}

            {authenticated==false && (
                <AuthenticationRequired message="Please sign in to see your images." handleLoginRedirect={() => navigate("/signin")} />
            )}

            {!fetchingMyImages && (
                <div className="flex justify-center items-center mt-4 gap-4 absolute bottom-4 left-0 right-0 mx-auto">
                    {previousUrl && myImages.length !== 0 && (
                        <button className="w-32 text-white px-4 py-2 rounded-lg bg-gradient-to-r from-rose-500 to-rose-600 hover:from-rose-600 hover:to-rose-700" onClick={() => fetchMyImages(previousUrl, {})}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4 inline-block">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                            </svg>
                            {" Previous"}
                        </button>
                    )}
                    {nextUrl && myImages.length !== 0 ? (
                        <button className="w-32 text-white px-4 py-2 rounded-lg bg-gradient-to-r from-rose-500 to-rose-600 hover:from-rose-600 hover:to-rose-700" onClick={() => fetchMyImages(nextUrl, {})}>
                            {"Next "}
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4 inline-block">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5 15.75 12 8.25 19.5" />
                            </svg>
                        </button>
                    ):(
                        <></>
                    )}
                </div>  
            )}
            
            {error && <div className="text-red-500">{errorMessage}</div>}

            {selectedImage && (
                <div className="fixed inset-0 p-4 rounded-lg lg:p-0 flex items-center justify-center bg-black bg-opacity-75 z-50" onClick={() => setSelectedImage(null)}>
                    <div className="max-w-full max-h-full rounded-lg relative">
                        <img 
                            src={TRYON_SERVER_URL + '/' + selectedImage.image_url} 
                            className="max-w-full max-h-full rounded-lg object-contain" 
                            alt="Preview" 
                            style={{ maxHeight: '80vh', maxWidth: '80vw' }}
                        />
                        <button 
                            className="absolute top-2 right-2 bg-white rounded-full p-2 shadow-md hover:shadow-lg transition-shadow duration-300"
                            onClick={(e) => {
                                e.stopPropagation()
                                downloadImage(selectedImage.id, selectedImage.image_url)
                            }}
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 text-blue-600">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5V21h18v-4.5M12 3v12m0 0l-3-3m3 3l3-3" />
                            </svg>
                        </button>   
                    </div>
                </div>
            )}

            {isDeleteModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg p-6 mx-4 lg:mx-0 w-full lg:w-1/3">
                        <h2 className="text-lg font-semibold mb-4">Confirm Deletion</h2>
                        <p>Are you sure you want to delete this image?</p>
                        <p className="text-sm text-gray-500 mt-2">Deleting this image will delete the experiment that has been generated using this image.</p>
                        <div className="flex justify-end mt-4">
                            <button 
                                className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                                onClick={() => setIsDeleteModalOpen(false)} // Close modal
                            >
                                Cancel
                            </button>
                            <button 
                                className="bg-red-500 text-white px-4 py-2 rounded"
                                onClick={confirmDelete} // Confirm deletion
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isUploadModalOpen && (
                <UploadImage imageType="all" allImages={myImages} setAllImages={setMyImages} closeModal={() => setIsUploadModalOpen(false)} />
            )}

        </div>
    )
}
import React, { useEffect, useState, useRef } from "react";

import UploadIntructImg1 from "../assets/upload_instructions_1.png";
import UploadIntructImg2 from "../assets/upload_instructions_2.png";
import UploadIntructImg3 from "../assets/upload_instructions_3.png";
import UploadIntructImg4 from "../assets/upload_instructions_4.png";
import UploadIntructImg5 from "../assets/upload_instructions_5.png";
import UploadIntructImg6 from "../assets/upload_instructions_6.png";
import ArrowModal from "../assets/arrow_right_modal.svg"

export default function Carousel({ finalSlideRedirect, closeCarousel }) {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const containerRef = useRef(null);

    const slides = [UploadIntructImg1, UploadIntructImg2, UploadIntructImg3, UploadIntructImg4, UploadIntructImg5, UploadIntructImg6];

    const handleSlideClick = (index) => {
        setCurrentSlide(index);
    };

    const handleNext = () => {
        if (currentSlide === totalSlides - 1) {
            console.log("This is the last slide!");
            finalSlideRedirect();
        }
        setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides)
    };

    const handlePrev = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
    };

    const handleCloseCarousel = () =>{
        if (currentSlide === totalSlides - 1) {
            console.log("This is the last slide!");
            finalSlideRedirect();
        }
        closeCarousel();
    }

    const slideWidth = containerRef.current?.clientWidth || 0;
    const totalSlides = slides.length;
    const transformValue = `translateX(-${currentSlide * slideWidth}px)`;

    useEffect(() => {
        if (isPaused) return;

        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
        }, 3000); // Change slide every 3 seconds

        return () => clearInterval(interval);
    }, [isPaused, currentSlide]);

    const handleMouseEnter = () => {
        setIsPaused(true);
    };

    const handleMouseLeave = () => {
        setIsPaused(false);
    };

    return (
        <div id="default-modal" tabIndex="-1" aria-hidden="true" className="!z-50 fixed inset-0 flex items-center justify-center bg-opacity-50 backdrop-blur-lg">
            <div className="relative flex items-center justify-center p-4 max-w-3xl md:max-w-[75%] max-h-full">
                <div className="relative flex flex-col w-full bg-white rounded-lg shadow-lg">
                    <div className="flex items-center justify-between p-4 border-b rounded-t">
                        <h2 className="text-lg font-semibold text-gray-900">
                            Guide to Capturing Perfect Image For Virtual Try-on
                        </h2>
                        <button type="button" onClick={() => { handleCloseCarousel();  }} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center" data-modal-hide="default-modal">
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div
                        id="default-carousel"
                        className="relative w-full h-full overflow-hidden"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div
                            ref={containerRef}
                            className="flex w-full h-full transition-transform duration-700 ease-in-out "
                            style={{ transform: transformValue }}
                        >
                            {slides.map((slide, index) => (
                                <img
                                    key={index}
                                    src={slide}
                                    className="w-full h-full object-contain flex-shrink-0 rounded-b-lg"
                                    alt={`Slide ${index + 1}`}
                                    style={{ minWidth: "100%" }}
                                />
                            ))}
                        </div>

                        {/* Navigation Buttons */}
                        <button
                            className="absolute top-1/2 left-0 transform -translate-y-1/2"
                            onClick={handlePrev}
                            aria-label="Previous slide"
                        >
                            <img className="flex w-10 h-10 scale-x-[-1]" src={ArrowModal}></img>
                        </button>
                        <button
                            className="absolute top-1/2 right-0 transform -translate-y-1/2"
                            onClick={handleNext}
                            aria-label="Next slide"
                        >
                            <img className="flex w-10 h-10" src={ArrowModal}></img>
                        </button>

                        {/* Slide Indicators */}
                        {/* <div className="absolute flex justify-center w-full bottom-5">
{slides.map((_, index) => (
<button
    key={index}
    type="button"
    className={`w-3 h-3 rounded-full mx-1 ${index === currentSlide ? "bg-black" : "bg-gray-300"}`}
    aria-current={index === currentSlide ? "true" : "false"}
    aria-label={`Slide ${index + 1}`}
    onClick={() => handleSlideClick(index)}
></button>
))}
</div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

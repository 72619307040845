import React, { useState, useEffect } from 'react';
import { TRYON_SERVER_URL } from "../../config";
import { AsyncImage } from "loadable-image";
import Spinner from "../../components/Spinner";
import { useSelector } from "react-redux";
import { selectAccessToken } from '../../selectors';

export default function Experiments() {
    const [experiments, setExperiments] = useState([]);
    const [experimentsFetched, setExperimentsFetched] = useState(false);
    const [selectedResult, setSelectedResult] = useState(null);
    const accessToken = useSelector(selectAccessToken);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);
    const [count, setCount] = useState(0);
    const [isNextDisabled, setIsNextDisabled] = useState(false);
    const [isPrevDisabled, setIsPrevDisabled] = useState(false);

    const handleResultSelect = (experiment) => {
        setSelectedResult(experiment);
    }

    const fetchExperiments = async () => {
        setExperimentsFetched(false);
        let headers = {
            'Content-Type': 'application/json',
        }
        if (accessToken) {
            headers = {
                ...headers,
                Authorization: `Bearer ${accessToken}`
            }
        }
        try {
            const experimentsResponse = await fetch(`${TRYON_SERVER_URL}/api/v1/admin/experiments/?is_admin=True&offset=${offset}&limit=${limit}`, {
                method: 'GET',
                headers: headers,
            });

            if (experimentsResponse.ok) {
                const experimentsData = await experimentsResponse.json();
                setExperiments(experimentsData.results);
                setCount(experimentsData.count);
                setIsNextDisabled(experimentsData.next?false :true);
                setIsPrevDisabled(experimentsData.previous?false : true);
                console.log("experiments is fetched", experimentsData);
            } else {
                console.error('Failed to fetch public experiments');
            }
            setExperimentsFetched(true)
        } catch (error) {
            console.error('Error fetching results:', error);
            setExperimentsFetched(true);
        }
    }

    const handleNext = () => {
        if(offset>= count){
            return;
        }
        setOffset(offset + limit);
        setExperimentsFetched(false);
    }

    const handlePrev = () => {
        if(offset <= 0){
            return;
        }
        setOffset(offset - limit);
        setExperimentsFetched(false);
    }

    useEffect(() => {
        if (!experimentsFetched) {
            fetchExperiments().then(() => {
            });
        }
    }, [experimentsFetched, offset, limit]);
    

    const Pagination = ({ currentPage, count, offset }) => {
        const totalPages = Math.ceil(count / offset);
        let startPage = 1;
        let endPage = totalPages;

        if (totalPages > 10) {
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }
    }


    return (
        <div
            className='!flex flex-col items-center justify-center h-[92vh] w-full font-OpenSans'>
            {experimentsFetched ?

                <div className='relative flex flex-col w-full h-full items-center justify-center overflow-y-auto mt-4'>
                    <div
                        className="!relative grid grid-cols-4 gap-3 items-center justify-center h-max overflow-y-auto w-[80%]">
                        {experiments
                            .filter((image) => image?.status === 'completed')
                            .map((image, index) => (
                                <div
                                    onClick={() => {
                                        handleResultSelect(image);
                                    }}
                                    key={image?.id}
                                    className={`relative flex h-full cursor-pointer max-w-full items-center justify-center border-2 border-solid rounded-lg hover:border-[#BE3144] aspect-auto overflow-hidden ${selectedResult?.id === image.id ? 'border-[#BE3144]' : 'border-[#F8EEEE]'} `}
                                >
                                    <div
                                        className={`absolute inset-0 backdrop-blur-sm w-full h-full p-1 ${!(image?.id && image?.result) ? 'flex items-center justify-center' : 'hidden'}`}>
                                        <div
                                            className={` absolute loader ${!(image?.id && image?.result) ? 'flex ' : 'hidden'} `}></div>
                                    </div>
                                    {image?.result ? (
                                        <div
                                            className="flex w-[20vw] md:w-[10vw] h-full items-center bg-gray-200 justify-center cursor-pointer">
                                            <AsyncImage
                                                loader={<div
                                                    className="relative flex w-full h-full bg-gray-100 items-center justify-center animate-pulse" />}
                                                error={<div style={{ background: '#222' }} />}
                                                style={{
                                                    minwidth: '100px',
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    objectFit: 'contain',
                                                    objectPosition: 'top',
                                                    position: 'relative'
                                                }}
                                                alt="preview"
                                                src={`${TRYON_SERVER_URL}/${image.result?.image_url}`}
                                            // className="flex h-full max-w-full object-cover object-top"
                                            />
                                        </div>
                                    ) : (
                                        <img
                                            key={image?.id}
                                            src={`${TRYON_SERVER_URL}/${image.model?.image_url}`}
                                            alt="loading screen"
                                            className="flex h-full max-w-full object-cover object-top"
                                        />
                                    )}
                                    
                                </div>
                            ))}
                    </div>
                    <nav aria-label="Page navigation example" className='m-4 p-4'>
                        <ul className="inline-flex -space-x-px text-sm">
                            <li >
                                <button disabled={isPrevDisabled} onClick={handlePrev} className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 disabled:opacity-35 disabled:bg-gray:400">Previous</button>
                            </li>
                            {/* {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((page) => (
                                                <li key={page}>
                                                    <a href="#" className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 ${currentPage === page ? 'bg-blue-50 text-blue-600' : 'hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}`}>
                                                        {page}
                                                    </a>
                                                </li>
                                            ))} */}
                            <li>
                                <button disabled={isNextDisabled} onClick={handleNext} className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 disabled:opacity-35 disabled:bg-gray:400">Next</button>
                            </li>
                        </ul>
                    </nav>
                </div>
                :
                <div className="flex h-full w-full items-center justify-center"><Spinner /></div>
            }

        </div>
    )
}
import React, { useEffect } from "react";

export default function Docs(){
    return (
            <>
                <div className="max-w-4xl mx-auto px-4 sm:px-6 py-6 sm:py-8 h-screen overflow-y-auto">
                    {/* Header Section */}
                    <div className="text-center mb-8 sm:mb-12">
                        <div className="flex flex-col lg:flex-row items-center justify-center gap-4 lg:gap-2 mb-4 sm:mb-6">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 sm:w-8 sm:h-8 text-primary-600">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 3h18v18H3V3z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 3v18l-6-6h12l-6 6V3z" />
                            </svg>
                            <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">TryOn AI API Documentation</h1>
                        </div>
                        <p className="text-base sm:text-lg text-gray-600 max-w-2xl mx-auto leading-relaxed px-4">
                            Explore our APIs and easily integrate them into your applications. 
                            You can also visit our UI to test and experience our AI models quickly.
                        </p>
                    </div>

                    {/* Credits Card */}
                    <div className="bg-white rounded-lg border border-gray-100 shadow-lg p-6 sm:p-8 mb-8 sm:mb-12">
                        <div className="flex items-center gap-2 sm:gap-3 mb-4 sm:mb-6">
                            <span className="text-2xl sm:text-3xl">🎁</span>
                            <h2 className="text-xl sm:text-2xl font-semibold text-gray-900">Free Credits Available</h2>
                        </div>
                        <p className="text-base sm:text-lg text-gray-700 mb-4 sm:mb-6">
                            We offer you <span className="font-bold text-primary-600">5 free credits</span>. Each credit allows you to:
                        </p>
                        <ul className="space-y-3 sm:space-y-4 mb-6 sm:mb-8">
                            <li className="flex items-start gap-3 sm:gap-4">
                                <span className="text-primary-600 text-lg sm:text-xl">•</span>
                                <span className="text-gray-700 text-sm sm:text-base">Do one virtual try-on</span>
                            </li>
                            <li className="flex items-start gap-3 sm:gap-4">
                                <span className="text-primary-600 text-lg sm:text-xl">•</span>
                                <span className="text-gray-700 text-sm sm:text-base">Reimagine a single image</span>
                            </li>
                            <li className="flex items-start gap-3 sm:gap-4">
                                <span className="text-primary-600 text-lg sm:text-xl">•</span>
                                <span className="text-gray-700 text-sm sm:text-base">Generate one outfit</span>
                            </li>
                            <li className="flex items-start gap-3 sm:gap-4">
                                <span className="text-primary-600 text-lg sm:text-xl">•</span>
                                <span className="text-gray-700 text-sm sm:text-base">Generate a caption for a single outfit</span>
                            </li>
                        </ul>
                        <div className="mt-6 sm:mt-8 pt-4 sm:pt-6 border-t border-gray-100">
                            <p className="text-sm sm:text-base text-gray-600">
                                Need more credits? <a href="mailto:contact@tryonlabs.ai" className="text-primary-600 hover:text-primary-700 font-semibold">Contact us</a>
                            </p>
                        </div>
                    </div>

                    {/* Documentation Button */}
                    <div className="flex justify-center mb-8 sm:mb-12 px-4">
                        <button 
                            onClick={() => window.open("https://docs.tryonlabs.ai", "_blank")} 
                            className="w-full sm:w-auto flex items-center justify-center bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700 rounded-full border border-primary-200 backdrop-blur-[50px] py-2.5 sm:py-3 px-6 sm:px-8 shadow-lg hover:shadow-xl transition-all duration-200 hover:scale-[1.02] active:scale-[0.98]"
                        >
                            <span className="text-white text-sm sm:text-base font-semibold">Visit Documentation</span>
                        </button>
                    </div>
                </div>
            </>
    )
}
import CloseIcon from "../assets/close.svg";
import { useState, useEffect } from "react";
import axios from "axios";
import { fetchAllImages } from "../Utils/ExperimentImageUtils";
import { selectAccessToken } from "../selectors";
import { useSelector } from "react-redux";
import { TRYON_SERVER_URL } from "../config";
import ThreeDotSpinner from "./ThreeDotSpinner";
import { AsyncImage } from "loadable-image";

export default function GalleryModal({setImage, setSelectedObj, setShowGalleryModal}) {
    const accessToken = useSelector(selectAccessToken);
    const [galleryImages, setGalleryImages] = useState([]);
    const [fetchingGalleryImages, setFetchingGalleryImages] = useState(false);
    const [galleryImagesFetched, setGalleryImagesFetched] = useState(false);
    const [totalImages, setTotalImages] = useState(0);
    const [nextUrl, setNextUrl] = useState(null);
    const [previousUrl, setPreviousUrl] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const baseUrl = `${TRYON_SERVER_URL}/api/v1/experiment_image/?`
    const limit = 20
    const offset = 0

    useEffect(() => {
        if(!galleryImagesFetched){
            fetchGalleryImages(baseUrl, {type: "all", gender: "all", limit: limit, offset: offset})
        }
    }, [galleryImagesFetched]);

    const fetchGalleryImages = async (url, params) => {
        setFetchingGalleryImages(true)
        const response = await fetchAllImages(url, accessToken, true, false, params)
        console.log("response:", response)
        if(response.status === "success"){
            console.log("response.data:", response.data, response.data.next, response.data.previous)
            setGalleryImages(response.data)
            setTotalImages(response.count)
            setFetchingGalleryImages(false)
            setGalleryImagesFetched(true)
            setNextUrl(response.next)
            setPreviousUrl(response.previous)
        }else{
            setError(true)
            setErrorMessage(response.message)
            setFetchingGalleryImages(false)
            setGalleryImagesFetched(true) 
        }
    }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 touch-none overflow-y-auto py-4"
            onClick={(e) => {
                e.stopPropagation();
                setShowGalleryModal(false);
            }}
        >
            <div className="relative bg-white w-[90vw] lg:w-[75vw] mx-4 h-[90vh] rounded-lg shadow-lg"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="flex flex-col h-full p-4">
                    <div className="flex-none flex flex-row items-center justify-between w-full mb-4">
                        <div className="text-start flex items-center font-semibold text-xl font-Mulish">Gallery</div>
                        <button className="w-6 h-6 bg-white shadow-lg rounded-full p-1 cursor-pointer" 
                            onClick={() => {
                                setShowGalleryModal(false);
                            }}>
                            <img src={CloseIcon} alt="close icon" />
                        </button>
                    </div>

                    <div className="flex-grow overflow-y-auto min-h-0">
                        {fetchingGalleryImages ? (
                            <div className="flex h-full w-full justify-center items-center animate-pulse">
                                <ThreeDotSpinner />
                            </div>
                        ) : galleryImagesFetched && galleryImages.length > 0 ? (
                            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 space-y-2 pb-16 px-4">
                                {galleryImages.map((image) => (
                                    <div 
                                        key={image.id}
                                        className="break-inside-avoid relative group overflow-hidden rounded-lg bg-gradient-to-br from-rose-100/30 to-slate-100/30 p-2 cursor-pointer"
                                    >
                                        <div className="relative overflow-hidden rounded-lg object-cover object-top w-full h-[300px]">
                                            <AsyncImage
                                                src={TRYON_SERVER_URL + '/' + image.image_url}  
                                                alt={`gallery image ${image.id}`} 
                                                style={{
                                                    width: 'auto',
                                                    height: '100%',
                                                    minHeight: '200px',
                                                    maxHeight: '500px',
                                                    objectFit: 'cover',
                                                    objectPosition: 'top',
                                                    display: 'block',
                                                }}
                                                className="transition-all object-cover object-top duration-300 ease-in-out group-hover:scale-105"
                                                loading="lazy"
                                                error={
                                                    <div className="flex h-[300px] w-full bg-gradient-to-br from-rose-50 to-slate-50 items-center justify-center text-gray-500 rounded-lg">
                                                        <div className="text-center">
                                                            <svg className="w-12 h-12 mx-auto mb-2 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                            </svg>
                                                            <span>Failed to load</span>
                                                        </div>
                                                    </div>
                                                }
                                                onClick={() => {
                                                    setImage(TRYON_SERVER_URL + '/' + image.image_url)
                                                    setSelectedObj(image)
                                                    setShowGalleryModal(false)
                                                }}
                                            />
                                            <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-10 transition-all duration-300 ease-in-out pointer-events-none" />
                                            <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-white text-sm">
                                                Click to select
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="flex flex-col justify-center items-center h-full">
                                <p className="text-gray text-xl font-semibold text-center">No images found</p>
                                <span className="text-gray-500 text-center mt-2">You need to upload some images to your gallery first</span>
                            </div>
                        )}
                    </div>

                    <div className="flex-none h-16 flex items-center justify-center">
                        {!fetchingGalleryImages && (
                            <div className="flex justify-center items-center gap-4">
                                {previousUrl && galleryImages.length !== 0 && (
                                    <button className="w-32 text-white px-4 py-2 rounded-lg bg-gradient-to-r from-rose-500 to-rose-600 hover:from-rose-600 hover:to-rose-700" 
                                        onClick={() => fetchGalleryImages(previousUrl, {})}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4 inline-block">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                        </svg>
                                        {" Previous"}
                                    </button>
                                )}
                                {nextUrl && galleryImages.length !== 0 ? (
                                    <button className="w-32 text-white px-4 py-2 rounded-lg bg-gradient-to-r from-rose-500 to-rose-600 hover:from-rose-600 hover:to-rose-700" 
                                        onClick={() => fetchGalleryImages(nextUrl, {})}>
                                        {"Next "}
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4 inline-block">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5 15.75 12 8.25 19.5" />
                                        </svg>
                                    </button>
                                ) : null}
                            </div>  
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
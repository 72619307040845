import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../assets/deleteIcon.svg";
import errorIcon from "../assets/errorIcon.svg";
import ErrorMessage from "../components/ErrorMessage";
import GradientFont from "../components/GradientFont";
import PictureGrid from "../components/PictureGrid";
import NoModels from "../assets/NoModels.svg";
import { selectAccessToken, selectAuthenticated } from "../selectors";
import { useMediaQuery } from "react-responsive";
import FileUpload from "../components/FileUpload";
import MaskEditor from "../components/MaskEditor";
import ImageViewer2 from "../components/ImageViewer2";
import { resizeUploadedFile, dataURLToBlob, isJSON, convertBinaryMaskToFile } from "../CommonUtils";
import { uploadExperimentImage } from "../Utils/ExperimentImageUtils";
import { createExperiment, fetchExperiments, fetchExperiment } from "../Utils/ExperimentUtils";
import ToastErrorMessage from "../components/ToastErrorMessage";
import { updateAllResults } from "../Utils/ExperimentUtils";
import { SlInfo } from "react-icons/sl";
import { Tooltip } from "flowbite-react";
import GalleryModal from "../components/GalleryModal";
import Joyride, { STATUS } from "react-joyride";
import { TRYON_SERVER_URL } from "../config";
import { fetchExperimentImage } from "../Utils/ExperimentImageUtils";

export default function ModelSwapAI({selectedPrompt, setShowPromptGalleryModal}) {
    const [modelImage, setModelImage] = useState(null)
    const [selectedModelFile, setSelectedModelFile] = useState(null)
    const [selectedModelObj, setSelectedModelObj] = useState(null)
    const [selectedMaskFile, setSelectedMaskFile] = useState(null)
    const [selectedMaskObj, setSelectedMaskObj] = useState(null)
    const [outputImage, setOutputImage] = useState(null)
    const [prompt, setPrompt] = useState(selectedPrompt ? selectedPrompt : "")
    const accessToken = useSelector(selectAccessToken) || null;
    const [errorMessage, setErrorMessage] = useState('');
    const [errorStatus, setErrorStatus] = useState('');
    const authenticated = useSelector(selectAuthenticated);
    const [experiments, setExperiments] = useState([]);
    const [experimentsFetched, setExperimentsFetched] = useState(false);
    const [fetchingExperiments, setFetchingExperiments] = useState({});
    const [advancedOptions, setAdvancedOptions] = useState(false);
    const navigate = useNavigate();
    const [fetchingExperimentsFromServer, setFetchingExperimentsFromServer] = useState({});

    const [seed, setSeed] = useState(-1);
    const [guidanceScale, setGuidanceScale] = useState(7.5);
    const [strength, setStrength] = useState(0.99);
    const [inferenceSteps, setInferenceSteps] = useState(20);
    const [numResults, setNumResults] = useState(2);

    const handleSeedChange = (e) => setSeed(Number(e.target.value));
    const handleGuidanceScaleChange = (e) => setGuidanceScale(Number(e.target.value));
    const handleStrengthChange = (e) => setStrength(Number(e.target.value));
    const handleInferenceStepsChange = (e) => setInferenceSteps(Number(e.target.value));
    const handleNumResultsChange = (e) => setNumResults(Number(e.target.value));
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` })
    const [showMaskEditor, setShowMaskEditor] = useState(false);
    const [binaryMaskImage, setBinaryMaskImage] = useState(null);
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [imageViewerImage, setImageViewerImage] = useState(null);
    const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
    const [pendingOrRunningExperimentIds, setPendingOrRunningExperimentIds] = useState([]);
    const ongoingRequestsForExperimentStatus = new Set();
    const [showGalleryModal, setShowGalleryModal] = useState(false);
    const [selectingType, setSelectingType] = useState("image");

    useEffect(() => {
        console.log("accessToken:", accessToken)
        console.log("authenticated:", authenticated)

        if (!experimentsFetched) {
            console.log("fetching experiments");
            if(accessToken && authenticated){
                fetchExperiments1().then(() => {
                    console.log("experiments fetched");
                })
            }
        }

        // experiments.map((experiment) => {
        //     if (experiment.status === "pending" || experiment.status === "running") {
        //         if (!fetchingExperiments[experiment.id]) {
        //             console.log("setTimeout", experiment.id)
        //             setTimeout(fetchExperiment1, 5000, experiment);
        //         }
        //     }
        // })
    }, [experiments, accessToken, authenticated]);

    const fetchExperimentStatus = async (experimentId) => {
        if(!ongoingRequestsForExperimentStatus.has(experimentId)){
            ongoingRequestsForExperimentStatus.add(experimentId);

            try{
                console.log("fetching experiment status for", experimentId);
                const response = await fetchExperiment(experimentId, accessToken);
                console.log("experiment status response", response);
                if(response.status === "success"){
                    console.log("experiment status fetched for", experimentId);
                    
                    // update experiment results
                    const experiment = response.data.experiment;
                    console.log("experiment status:", experiment.status);
                    if(experiment.status === "completed" || experiment.status === "failed"){
                        
                        if(experiment.status === "failed"){
                            setShowToastErrorMessage(true);
                            setErrorMessage("Experiment Failed. Please Try Again!");
                            setErrorStatus("experiment failed");
                        }

                        setPendingOrRunningExperimentIds((prevExperimentIds) => prevExperimentIds.filter((id) => id !== experimentId));
                        setExperiments((prevExperiments) => updateAllResults(experiment, prevExperiments));
                    }
                }
            }catch(error){
                console.error("Error fetching experiment status:", error);
            }finally{
                ongoingRequestsForExperimentStatus.delete(experimentId);
            }
        }
    }

    useEffect(() => {
        console.log("pending or running experiment ids", pendingOrRunningExperimentIds);
        const fetchExperimentStatuses = async () => {
            console.log("fetching experiment statuses:", pendingOrRunningExperimentIds);
            pendingOrRunningExperimentIds.forEach((experimentId) => {
                fetchExperimentStatus(experimentId);
            });
        }

        fetchExperimentStatuses();

        let interval = null;
        if(pendingOrRunningExperimentIds.length > 0){
            interval = setInterval(() => {
                fetchExperimentStatuses();
            }, 5000);
        }

        return () => {
            if(interval !== null){
                clearInterval(interval);
            }
            ongoingRequestsForExperimentStatus.clear();
        }
    }, [pendingOrRunningExperimentIds]);

    useEffect(() => {
        const id = new URLSearchParams(window.location.search).get('id');
        console.log("id found in url", id);
        if(id){
            console.log("id found in url", id);
            fetchExperimentImage(id, accessToken).then(async (response) => {
                if(response.status === "success"){
                    console.log("experiment image fetched", response.data);
                    let experiment_image = response.data;
                    setSelectedModelObj(experiment_image);
                    // convert image_url to file
                    const imageUrl = `${TRYON_SERVER_URL}/${experiment_image.image_url}`;
                    console.log("image url", imageUrl);
                    const imageBlob = await fetch(imageUrl).then(res => res.blob());
                    const imageFile = new File([imageBlob], "image.png", { type: "image/png" });
                    setSelectedModelFile(imageFile);
                    setModelImage(URL.createObjectURL(imageFile));
                    console.log("model image", experiment_image, imageFile);
                }else{
                    console.error("Failed to fetch experiment image");
                }
            });
        }
    }, []);

    const handleErrorClose = () => {
        setShowToastErrorMessage(false);
        setErrorMessage("");
        setErrorStatus("");
    }

    const handleModelImageSelected = async (event) => {
        console.log("model image selected", event)
        const files = event.target.files;
        console.log("file types in fm", files)

        if (files.length > 0) {
            console.log(files[0])
            setModelImage(URL.createObjectURL(files[0]))
            setSelectedModelFile(files[0])
        }
    };

    const handleMaskImageSelected = async (event) => {
        console.log("mask image selected", event)
        const files = event.target.files;
        console.log("file types in fm", files)

        if (files.length > 0) {
            console.log(files[0])
            setBinaryMaskImage(URL.createObjectURL(files[0])) 
            setSelectedMaskFile(files[0])
        }
    };

    const handleLoginRedirect = () => {
        navigate("/signin");
    }

    const fetchExperiments1 = async () => {
        setFetchingExperimentsFromServer(true);
        const response = await fetchExperiments({aimodel: "reimagine_ai", access: "private"}, accessToken)
        if(response.status === "success"){
            setExperiments(response.data);
            setExperimentsFetched(true);
            setFetchingExperimentsFromServer(false);
            // add ids of pending or running experiments to the pendingOrRunningExperimentIds array
            let pendingOrRunningExperimentIds = response.data.filter((result) => result.status === "pending" || result.status === "running").map((result) => result.id); 
            console.log("pending or running experiment ids", pendingOrRunningExperimentIds);
            setPendingOrRunningExperimentIds(pendingOrRunningExperimentIds);
        }else{
            setFetchingExperimentsFromServer(false);
            console.error("Failed to fetch experiments");
        }
    }

    async function handleGenerateClick(e) {
        console.log("Generate button clicked")
        console.log("selectedModelFile", selectedModelFile)
        console.log("selectedModelObj", selectedModelObj)
        console.log("binaryMaskImage", binaryMaskImage)
        console.log("selectedMaskFile", selectedMaskFile)
        console.log("selectedMaskObj", selectedMaskObj)
        console.log("prompt", prompt)
        
        if (!authenticated){
            setErrorStatus("Login Required");
            setErrorMessage("User not logged in. Please Log in and try Again");
            setShowToastErrorMessage(true);
            return;
        }

        if ((selectedModelFile !== null || selectedModelObj !== null) && (binaryMaskImage !== null || selectedMaskFile !== null || selectedMaskObj !== null) && prompt !== "") {
            try {
                let modelId = null;
                if(selectedModelObj !== null){
                    modelId = selectedModelObj.id;
                }else if(selectedModelFile !== null){
                    // upload model image
                    let params = {type: "model", access: "private", preprocess: "false"};
                    let modelResponse = await uploadExperimentImage(selectedModelFile, accessToken, params)
                    if (modelResponse.status === "success"){
                        modelId = modelResponse.data.id;
                    }else{
                        setErrorStatus(modelResponse.statusCode);
                        setErrorMessage(modelResponse.message);
                        setShowToastErrorMessage(true);
                        return;
                    }
                }
    
                let maskId = null;
                // upload mask image
                let params1 = {type: "mask", access: "private", preprocess: "false"}
                if (selectedMaskObj !== null){
                    maskId = selectedMaskObj.id;
                }else if (selectedMaskFile !== null){
                    console.log("selected mask file", selectedMaskFile)
                    let maskResponse = await uploadExperimentImage(selectedMaskFile, accessToken, params1)
                    if (maskResponse.status === "success"){
                        maskId = maskResponse.data.id;
                    }else{
                        setErrorStatus(maskResponse.statusCode);
                        setErrorMessage(maskResponse.message);
                        setShowToastErrorMessage(true);
                        return;
                    }
                }
                else if(binaryMaskImage !== null){
                    console.log("binary mask image", binaryMaskImage)
                    // convert the base64 binarymaskimage to File format
                    const binaryMaskFile = convertBinaryMaskToFile(binaryMaskImage);
                    console.log("binary mask file", binaryMaskFile)
                    let maskResponse = await uploadExperimentImage(binaryMaskFile, accessToken, params1)
                    if (maskResponse.status === "success"){
                        maskId = maskResponse.data.id;
                    }else{
                        setErrorStatus(maskResponse.statusCode);
                        setErrorMessage(maskResponse.message);
                        setShowToastErrorMessage(true);
                        return;
                    }
                }
    
                let params2 = {
                    prompt: prompt, guidance_scale: guidanceScale,
                    strength: strength, num_inference_steps: inferenceSteps, num_images_per_prompt: numResults, seed: seed,
                }
    
                // create experiment
                const response = await createExperiment({model_id: modelId, mask_id: maskId, action: "reimagine", params: params2}, accessToken)
                if (response.status === "success"){
                    console.log("Experiment created successfully", response.data);
                    let experiments1 = [...[response.data], ...experiments]
                    setExperiments(experiments1)
                    setModelImage(null)
                    setBinaryMaskImage(null)
                    setSelectedModelFile(null)
                    setSelectedMaskFile(null)
                    setSelectedModelObj(null)
                    setSelectedMaskObj(null)
                    setPrompt("")
                    setNumResults(2)
                    setPendingOrRunningExperimentIds((prevExperimentIds) => [...prevExperimentIds, response.data.id]);
                }else{
                    setErrorStatus(response.statusCode);
                    setErrorMessage(response.message);
                    setShowToastErrorMessage(true);
                    return;
                }
            } catch (error) {
                console.error("Error during try on:", error.message);
                if (isJSON(error?.message)) {
                    let errorData = JSON.parse(error?.message);
                    if (errorData?.image && Array.isArray(errorData?.image) && errorData?.image?.length > 0) {
                        setShowToastErrorMessage(true);
                        setErrorStatus("Error!");
                        setErrorMessage(errorData.image[0]);
                    } else if (errorData?.message && Array.isArray(errorData?.message) && errorData?.message?.length > 0) {
                        setShowToastErrorMessage(true);
                        setErrorStatus("Error!");
                        setErrorMessage(errorData?.message[0]);
                    } else {
                        setShowToastErrorMessage(true);
                        setErrorStatus("Error!");
                        setErrorMessage('Something went wrong! Please try again later');
                    }
                } else {
                    setShowToastErrorMessage(true);
                    setErrorStatus("Error!");
                    setErrorMessage(error?.message);
                }
            }
        } else {
            setShowToastErrorMessage(true);
            setErrorMessage("Please upload an image, create a mask and provide a prompt.");
            setErrorStatus("Error!");
        }
        return false;
    }

    function handlePromptChange(e) {
        setPrompt(e.target.value)
    }

    const toggleAdvancedOptions = () => {
        setAdvancedOptions((prev) => !prev);
    }

    const fetchExperiment1 = async (experiment) => {
        if (!fetchingExperiments[experiment.id]) {
            let fetchingExperiments1 = { ...fetchingExperiments }; // Clone the state object to avoid direct mutation
            fetchingExperiments1[experiment.id] = true;
            setFetchingExperiments(fetchingExperiments1);

            const response = await fetchExperiment(experiment.id, accessToken)
            if(response.status === "success"){
                let fetchedExperiment = response.data;
                console.log("Fetched experiment:", fetchedExperiment);

                if (fetchedExperiment.status === 'completed' || fetchedExperiment.status === 'failed') {
                    console.log("Final status reached:", fetchedExperiment.status);

                    setExperiments((prevExperiments) =>
                        prevExperiments.map((experiment1) =>
                            experiment1.id === fetchedExperiment.id ? { ...experiment1, ...fetchedExperiment } : experiment1
                        )
                    );
                } else if (fetchedExperiment.status === 'pending' || fetchedExperiment.status === 'running') {
                    console.log("Experiment is still running, polling again...");
                    setTimeout(() => fetchExperiment1(experiment), 5000);
                }
            }else{
                console.error("Failed to fetch experiment");
            }

            fetchingExperiments1[experiment.id] = false;
            setFetchingExperiments(fetchingExperiments1);
        }
    };

    const handleImageClick = (image) => {
        setShowImageViewer(true);
        setImageViewerImage(image);
    }

    const hideToastErrorMessage = () => {
        setShowToastErrorMessage(false);
        setErrorMessage("");
        setErrorStatus("");
    }

    const handleCreateMaskManually = () => {
        //hide error message if it is shown
        hideToastErrorMessage();
        if(selectedModelFile !== null || selectedModelObj !== null){
            setShowMaskEditor(true);
            setSelectedMaskFile(null);
            setBinaryMaskImage(null);
        }else{
            setShowToastErrorMessage(true);
            setErrorMessage("Please upload an image first.");
            setErrorStatus("Error!");
            setShowMaskEditor(false);
        }
    }

    const handleSelectFromLibrary = (type) => {
        setSelectingType(type);
        setShowGalleryModal(true);
    }

    const steps = [
        {
            target: ".select_image",
            content: "Upload an image or select from library",
            disableBeacon: true,
        },
        {
            target: ".select_mask",
            content: "Upload a mask or create a mask manually to specify the area you want to regenerate",
            disableBeacon: true,
        },
        {
            target: ".prompt",
            content: "Enter the prompt for the masked area you want to regenerate",
            disableBeacon: true,
        },
        {
            target: ".browse_prompt_gallery",
            content: "Click here to browse the prompt gallery",
            disableBeacon: true,
        },
        {
            target: ".num_results",
            content: "Enter the number of images you want to generate",
            disableBeacon: true,
        },
        {
            target: ".advanced_options",
            content: "Click here to select advanced options like seed, guidance scale, strength and inference steps",
            disableBeacon: true,
        },
        {
            target: ".generate",
            content: "Click the generate button to generate the images",
            disableBeacon: true,
        },
        {
            target: ".output",
            content: "The generated images will be displayed here",
            disableBeacon: true,
        }
    ]

    const handleJoyrideCallback = (data) => {
        const { step, status } = data;
        console.log(step, status)

        if ([STATUS.FINISHED].includes(status)) {
            localStorage.setItem("REIMAGINEAI_JOYRIDE_STATUS", "finished")
        }
    };

    useEffect(() => {
        console.log("selectedPrompt:", selectedPrompt)
        if(selectedPrompt){
            setPrompt(selectedPrompt)
        }
    }, [selectedPrompt])

    return (
        <>
        {localStorage.getItem("REIMAGINEAI_JOYRIDE_STATUS") !== "finished" ? (
                <Joyride callback={handleJoyrideCallback} steps={steps} showProgress={true} showSkipButton={true}
                    continuous={true} />) : (<></>)}
        <div className="flex flex-col lg:grid lg:grid-cols-10 gap-4 p-4 h-[calc(100vh-5rem)] overflow-y-auto lg:overflow-hidden">
            <div className="overflow-y-auto flex-shrink-0 lg:col-span-4 bg-rose-50/30 shadow-lg lg:bg-white/30 rounded-lg px-4 py-2 border border-rose-100/50 lg:border-0">
                {isMobile?(
                <p className="mb-2 text-center text-xl">Reimagine AI</p>):(<></>)}
                
                <div className="flex flex-col">
                    <div className="lg:flex lg:flex-row flex flex-col items-center justify-center w-full gap-4">
                        <div className="flex flex-col items-center justify-center w-full select_image">
                            <div className="mb-3 text-center text-lg font-Mulish text-gray-700 flex flex-row w-full items-center justify-between gap-2">
                                <span>Image</span>
                                <Tooltip content={
                                    <div className="whitespace-pre-line">
                                        Upload a image or select from library
                                    </div>
                                } className="text-xs max-w-[200px]">
                                    <SlInfo className="w-4 h-4 cursor-pointer" />
                                </Tooltip>
                            </div>

                            {modelImage ? (
                                <div className="relative bg-white flex justify-center w-full mx-auto border-2 border-gray-300/50 shadow-lg items-center bg-opacity-20 rounded-lg">
                                    <img src={modelImage} className="h-80 w-full object-cover object-top rounded-lg cursor-pointer" alt="model image" style={{ zIndex: 0 }} onClick={() => handleImageClick(modelImage)} />
                                    <img src={DeleteIcon} alt="close icon" onClick={() => {
                                        setModelImage(null);
                                        setSelectedModelFile(null);
                                        setSelectedModelObj(null);
                                    }}
                                        className="absolute top-2 right-2 w-6 h-auto cursor-pointer bg-red-500 rounded-full p-1 shadow" />
                                    <span className="absolute bottom-2 left-2 text-xs text-white bg-black/30 rounded-lg py-1 px-2">
                                        {selectedModelObj ? "Selected from gallery" : "Selected from files"}
                                    </span>

                                    <button className="absolute bottom-2 right-2 text-xs text-white bg-gradient-to-r from-red-500 to-rose-600 hover:from-rose-600 hover:to-rose-700 rounded-lg py-1 px-2" onClick={handleCreateMaskManually}>
                                        Draw Mask
                                    </button>
                                </div>
                            ) : (
                                <FileUpload key1="image" handleFileSelected={handleModelImageSelected} buttonText="Select From Gallery" handleClick={() => handleSelectFromLibrary("image")}/>
                            )}
                        </div>

                        <div className="flex flex-col items-center justify-center w-full border-t border-stone-300/50 lg:border-t-0 lg:pt-0 pt-4 select_mask">
                            <div className="mb-3 text-center text-lg font-Mulish text-gray-700 flex flex-row w-full items-center justify-between gap-2">
                                <span>Mask</span>
                                <Tooltip content={
                                    <div className="whitespace-pre-line">
                                        Upload a mask image or create a mask manually
                                    </div>
                                } className="text-xs max-w-[200px]">
                                    <SlInfo className="w-4 h-4 cursor-pointer" />
                                </Tooltip>
                            </div>

                            {binaryMaskImage ? (  
                                <div
                                className="relative flex justify-center w-full bg-white mx-auto border-2 border-solid border-stone-300/50 shadow-lg items-center bg-opac-preview rounded-lg">
                                <img src={binaryMaskImage} className="h-80 w-full object-cover object-top rounded-lg cursor-pointer" alt="mask image" onClick={() => handleImageClick(binaryMaskImage)} />
                                <img src={DeleteIcon} alt="close icon" onClick={() => {
                                    setBinaryMaskImage(null)
                                    setSelectedMaskFile(null)
                                }}
                                    className="absolute top-2 right-2 w-6 h-auto cursor-pointer bg-red-500 rounded-full p-1 shadow" />
                                <span className="absolute bottom-2 left-2 text-xs text-white bg-black/30 rounded-lg py-1 px-2">
                                    {selectedMaskObj ? "Selected from gallery" : "Selected from files"}
                                </span>
                                </div>
                            ) : (
                                <FileUpload key1="mask" handleFileSelected={handleMaskImageSelected} buttonText="Select From Gallery" handleClick={() => handleSelectFromLibrary("mask")}/>
                            )}
                        </div>
                    </div>
                    
                    <div className="text-center text-sm text-gray-700 flex flex-row w-full items-center justify-between gap-2 mt-4">
                        <span>What is a mask?</span>
                        <Tooltip content={
                            <div className="whitespace-pre-line">
                                A mask is a binary image that highlights the area you want to use for generation. 
                                The white area is used for generation, while the black area is not.
                            </div>
                        } className="text-xs max-w-[200px]">
                            <SlInfo className="w-4 h-4 cursor-pointer" />
                        </Tooltip>
                    </div>

                    <div className="prompt">
                        <label htmlFor="numResults" className="mt-4 text-sm flex flex-row items-center justify-between font-Mulish">
                            Prompt
                            <Tooltip content={
                                <div className="whitespace-pre-line">
                                    Enter the prompt for the image you want to generate.
                                </div>
                            } className="text-xs max-w-[200px]">
                                <SlInfo className="w-4 h-4 cursor-pointer" />
                            </Tooltip>
                        </label>

                        <textarea
                            name="prompt" rows="3"
                            className="font-Mulish rounded-lg bg-white w-full shadow border border-stone-400 focus:border-rose-600 mt-2
                        backdrop-blur-[30px] py-2 px-4"
                            placeholder="Enter prompt here! e.g. 'Fashion model close-up, flawless skin, perfectly styled blonde hair, striking green eyes, high-fashion makeup, professional studio lighting, ultra-detailed' " value={prompt} onChange={handlePromptChange} />
                    </div>

                    <button 
                        onClick={() => setShowPromptGalleryModal(true)}
                        className="browse_prompt_gallery inline-flex items-center gap-2 px-4 py-3 text-sm font-medium text-primary-600 bg-primary-50 hover:bg-primary-100 border border-primary-200 rounded-lg shadow-sm hover:shadow-md transition-all duration-200 mt-2 mb-2"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
                        </svg>
                        Browse Prompt Gallery
                    </button>

                    <div className="flex flex-col mb-4 mt-2 num_results">
                        <label htmlFor="numResults" className="text-sm flex flex-row items-center justify-between">
                            Number of images (1 to 5)
                            <Tooltip content={
                                <div className="whitespace-pre-line">
                                    Enter the number of images you want to generate.
                                    Choose between 1 to 5 images.
                                </div>
                            } className="text-xs max-w-[200px]">
                                <SlInfo className="w-4 h-4 cursor-pointer" />
                            </Tooltip>
                        </label>
                        <input
                            id="numResults"
                            name="numberResults"
                            type="number"
                            className="rounded-lg bg-white w-full shadow border border-stone-400 focus:border-rose-600 mt-2 px-3 py-2"
                            value={numResults}
                            onChange={handleNumResultsChange}
                            min={1}
                            max={5}
                        />
                        <span className="text-sm mt-2 text-gray-500">Enter the number of images you want to generate (1 to 5)</span>
                    </div>

                    <div onClick={() => toggleAdvancedOptions()} className="flex my-4 mx-1 justify-between items-center cursor-pointer advanced_options">
                        <span className="text-sm">Advanced Options</span>
                        <div className={`transform transition-transform duration-300 ${advancedOptions ? 'rotate-180' : 'rotate-0'}`}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path fillRule="evenodd" d="M5.293 9.707a1 1 0 011.414 0L10 13l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </div>

                    </div>

                    {advancedOptions && (
                        <div className="flex flex-col">
                            <div className="flex flex-col mb-4">
                                <label htmlFor="seed" className="text-sm">
                                    Seed
                                </label>
                                <input
                                    name="seed"
                                    type="number"
                                    className="rounded-lg bg-white w-full shadow border border-stone-400 focus:border-rose-600 mt-2 px-3 py-2"
                                    value={seed}
                                    onChange={handleSeedChange}
                                />
                            </div>

                            <div className="flex flex-col mb-4">
                                <label htmlFor="guidanceScale" className="text-sm">
                                    Guidance Scale
                                </label>
                                <input
                                    name="guidanceScale"
                                    type="number"
                                    step="0.1"
                                    max="20.0"
                                    min="0.0"
                                    className="rounded-lg bg-white w-full shadow border border-stone-400 focus:border-rose-600 mt-2 px-3 py-2"
                                    value={guidanceScale}
                                    onChange={handleGuidanceScaleChange}
                                />
                            </div>

                            <div className="flex flex-col mb-4">
                                <label htmlFor="strength" className="text-sm mb-2">
                                    Strength (0.1 to 1.0)
                                </label>
                                <input
                                    name="strength"
                                    type="range"
                                    min="0.0"
                                    max="1.0"
                                    step="0.01"
                                    className="appearance-none h-2 w-full rounded-lg bg-gradient-to-r from-gray-200 to-black focus:outline-none"
                                    value={strength}
                                    onChange={handleStrengthChange}
                                />
                                <span className="text-sm mt-2">Strength: {strength}</span>
                            </div>

                            <div className="flex flex-col mb-4">
                                <label htmlFor="inferenceSteps" className="text-sm">
                                    Inference Steps
                                </label>
                                <input
                                    name="inferenceSteps"
                                    type="number"
                                    className="rounded-lg bg-white w-full shadow border border-stone-400 focus:border-rose-600 mt-2 px-3 py-2"
                                    value={inferenceSteps}
                                    onChange={handleInferenceStepsChange}
                                />
                            </div>
                        </div>
                    )}

                    <button
                        className="py-2 px-4 w-full mt-2 mx-auto text-bg bg-gradient-to-r from-red-500 to-rose-600 rounded-lg
                    text-white cursor-pointer generate" onClick={handleGenerateClick}>
                        Generate
                    </button>
                </div>
            </div>

            <div className="output flex-shrink-0 lg:col-span-6 lg:h-full bg-rose-50/30 shadow-lg lg:bg-white/30 rounded-lg px-2 py-2 border border-rose-100/50 lg:border-0 lg:overflow-y-auto">
                <div className="flex flex-col min-h-full">

                    {authenticated === null ?
                        <div className="flex flex-wrap gap-2 rounded-lg p-2 w-full">
                            <div className="w-full shadow rounded-lg bg-white animate-pulse">
                                <div className="bg-slate-200 rounded-lg h-72 col-span-2 m-4"></div>
                                <div className="bg-slate-200 rounded-lg h-72 col-span-2 m-4"></div>
                                <div className="bg-slate-200 rounded-lg h-72 col-span-2 m-4"></div>
                            </div>
                        </div>
                        :
                        authenticated ?
                            <div className="flex flex-wrap gap-2 rounded-lg w-full">
                                {
                                    experimentsFetched && experiments.length > 0
                                        ?
                                        experiments.map((experiment) => (
                                            <PictureGrid experiment={experiment} />
                                        ))
                                        :
                                        fetchingExperimentsFromServer ?
                                        <div className="flex flex-wrap gap-2 rounded-lg w-full">
                                            <div className="w-full shadow rounded-lg bg-white animate-pulse">
                                                <div className="bg-slate-200 rounded-lg h-72 col-span-2 m-4"></div>
                                                <div className="bg-slate-200 rounded-lg h-72 col-span-2 m-4"></div>
                                                <div className="bg-slate-200 rounded-lg h-72 col-span-2 m-4"></div>
                                            </div>
                                        </div>
                                        :
                                        (<div className='flex flex-col items-center justify-center h-full w-full p-4 mb-2 bg-opacity-70 backdrop-blur-lg rounded-xl bg-white/60 border-2 border-rose-100'>
                                            <div className="flex flex-col w-full items-center justify-center ">
                                                <img src={NoModels} alt="error" className='object-contain h-16 object-center w-16 mb-4' />
                                                <div className="!text-xl font-semibold text-center font-OpenSans ">
                                                    <GradientFont>{"No Images Found!"}</GradientFont>
                                                </div>
                                            </div>
                                            <span className='ml-4 my-4 !text-neutral-800 text-center font-OpenSans text-sm font-normal leading-normal'> 
                                                Reimagined images will be displayed here.
                                            </span>
                                        </div>)
                                }

                            </div>
                            :
                            <div className='flex flex-col w-full h-full items-center justify-center px-4 pt-4 pb-4'>
                                <div className='flex relative items-center justify-center left-0 right-0 bg-opacity-70 backdrop-blur-lg w-full h-full rounded-2xl bg-white/70 border-2 border-rose-100'>

                                    <div className='relative flex flex-row items-center justify-center h-full w-fit'>

                                        <div className='flex flex-col items-center justify-center h-full w-full p-4 mb-2'>

                                            <div className="flex flex-row w-full items-center justify-center">
                                                <img src={errorIcon} alt="error" className='object-contain h-8 object-center w-8 mr-4' />
                                                <div className="!text-xl font-semibold text-center font-OpenSans ">
                                                    <GradientFont>{"Authentication Required"}</GradientFont>
                                                </div>
                                            </div>
                                            <span className='ml-4 my-2 !text-neutral-800 text-center font-OpenSans text-sm font-normal leading-normal'> Please Sign in/Sign up to use Reimagine AI</span>

                                            <div onClick={handleLoginRedirect} className='flex flex-row mt-2 cursor-pointer items-center justify-center bg-gradient-to-r from-[#F05941] to-[#BE3144] rounded-[50px] border-1 border-solid border-[#EFCBCB] backdrop-blur-[50px] py-3 px-8'>
                                                <div className={` flex flex-row items-center justify-center w-fit h-full `}>
                                                    <span className={`!text-white text-sm font-semibold font-OpenSans leading-normal`}>Sign in</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>

            {showMaskEditor && modelImage && (
                <MaskEditor image={modelImage} setShowMaskEditor={setShowMaskEditor} setBinaryMaskImage={setBinaryMaskImage} />
            )}

            {showImageViewer && imageViewerImage && (
                <ImageViewer2 image_url={imageViewerImage} onClose={() => setShowImageViewer(false)} />
            )}

            {showToastErrorMessage && (
                <ToastErrorMessage
                    errorStatus={errorStatus}
                    errorMessage={errorMessage}
                    handleErrorClose={() => setShowToastErrorMessage(false)}
                />
            )}

            {showGalleryModal && (
                selectingType === "image" ?
                    <GalleryModal setImage={setModelImage} setSelectedObj={setSelectedModelObj} setShowGalleryModal={setShowGalleryModal} />
                :
                    <GalleryModal setImage={setBinaryMaskImage} setSelectedObj={setSelectedMaskObj} setShowGalleryModal={setShowGalleryModal} />
            )}
        </div>
        </>
    );
}

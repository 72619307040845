import React, {useCallback, useEffect, useState} from 'react';
import BGGradient from "../assets/bgGradient2.svg"
import {TRYON_SERVER_URL} from "../config";
import {setUserData, setUserID, logoutUser} from "../store/AuthActions";
import {useDispatch, useSelector} from "react-redux";
import {selectAccessToken, selectAuthenticated} from "../selectors";
import {useNavigate} from "react-router-dom";
import {useAuthentication} from "../Authentication";
import ToastErrorMessage from "../components/ToastErrorMessage";
import ToastSuccessMessage from "../components/ToastSuccessMessage";

export default function Profile(props) {
    const accessToken = useSelector(selectAccessToken)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [username, setUsername] = useState("")
    const [userFullname, setUserFullname] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [userDetailsFetched, setUserDetailsFetched] = useState(false)
    const authenticated = useSelector(selectAuthenticated)
    const {isAuthenticated} = useAuthentication();
    const [isEditingUsername, setIsEditingUsername] = useState(false);
    const [isEditingName, setIsEditingName] = useState(false);
    const [newUsername, setNewUsername] = useState(username);
    const [newName, setNewName] = useState(userFullname);
    const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [currentFirstName, setCurrentFirstName] = useState("");
    const [currentLastName, setCurrentLastName] = useState("");
    const [isEditingPassword, setIsEditingPassword] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showToastSuccessMessage, setShowToastSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
    const [isUpdatingUsername, setIsUpdatingUsername] = useState(false);
    const [isUpdatingName, setIsUpdatingName] = useState(false);

    const logout = () => {
        dispatch(logoutUser()).then(() => {
            // Redirect or perform any other actions after successful logout
            console.log("logged out succesfully!")
            navigate("/");
        })
        .catch((error) => {
            // Handle any errors that occur during logout
            console.error('Logout error:', error);
            setShowToastErrorMessage(true);
            setErrorMessage("Failed to logout");
            setErrorStatus("error");
        });
    }

    const handleErrorClose = () => {
        setShowToastErrorMessage(false);
        setErrorMessage("");
        setErrorStatus("");
    }

    const handleSuccessClose = () => {
        setShowToastSuccessMessage(false);
        setSuccessMessage("");
    }

    async function fetchUserDetails() {
        hideToastMessages();

        try {
            console.log("access in fetch data", accessToken)
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/user/get/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                const userid = data.id
                dispatch(setUserID(userid));
                const dataWithoutPassword = {...data, password: undefined}; // Omit the password field
                dispatch(setUserData(JSON.stringify(dataWithoutPassword)));
                setUsername(dataWithoutPassword.username)
                setUserFullname(`${dataWithoutPassword.first_name !== undefined ? dataWithoutPassword.first_name : ""} ${dataWithoutPassword.last_name !== undefined ? dataWithoutPassword.last_name : ""}`)
                setUserEmail(dataWithoutPassword.email)
                setUserDetailsFetched(true)
                setNewUsername(dataWithoutPassword.username)
                setNewName(`${dataWithoutPassword.first_name !== undefined ? dataWithoutPassword.first_name : ""} ${dataWithoutPassword.last_name !== undefined ? dataWithoutPassword.last_name : ""}`)
                setFirstName(dataWithoutPassword.first_name || "");
                setLastName(dataWithoutPassword.last_name || "");
                setCurrentFirstName(dataWithoutPassword.first_name || "");
                setCurrentLastName(dataWithoutPassword.last_name || "");
            } else {
                console.error('Failed to fetch user data');
                setUserDetailsFetched(true)
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            setUserDetailsFetched(true)
        }
    }

    const checkAuthentication = useCallback(async () => {
        try {
            const userAuthenticated = await isAuthenticated();
            if (!userAuthenticated) {
                navigate("/signin");
            }
        } catch (error) {
            console.error('Error checking authentication:', error);

            if (error.message && error.message.toLowerCase().includes('network')) {
                console.error('Network error, redirecting to login page');
                navigate("/signin");
            }
        }
    }, [dispatch, isAuthenticated, navigate]);

    useEffect(() => {
        if (!authenticated) {
            checkAuthentication().then(() => {
            });
        }

        if (!userDetailsFetched) {
            fetchUserDetails().then(() => {
            })
        }
    }, []);

    const handleUpdateProfile = async (field, value) => {
        hideToastMessages();

        try {
            if (field === 'name') {
                setIsUpdatingName(true);
            } else if (field === 'username') {
                setIsUpdatingUsername(true);
            }
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/user/update/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    field === 'name' 
                        ? { first_name: value.first_name, last_name: value.last_name }
                        : { [field]: value }
                )
            });

            if (response.ok) {
                if (field === 'name') {
                    setFirstName(value.first_name);
                    setLastName(value.last_name);
                    setUserFullname(`${value.first_name} ${value.last_name}`);
                    setIsEditingName(false);
                    setShowToastSuccessMessage(true);
                    setSuccessMessage("Profile updated successfully");
                    setIsUpdatingName(false);
                } else if (field === 'username') {
                    setUsername(value);
                    setIsEditingUsername(false);
                    setShowToastSuccessMessage(true);
                    setSuccessMessage("Profile updated successfully");
                    setIsUpdatingUsername(false);
                }
            } else {
                const errorData = await response.json();
                console.error('Failed to update profile');  
                setShowToastErrorMessage(true);
                setErrorMessage(errorData.message);
                setErrorStatus("Error");
                if (field === 'name') {
                    setIsUpdatingName(false);
                } else if (field === 'username') {
                    setIsUpdatingUsername(false);
                }
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            setShowToastErrorMessage(true);
            setErrorMessage("Failed to update profile");
            setErrorStatus("Error");
            if (field === 'name') {
                setIsUpdatingName(false);
            } else if (field === 'username') {
                setIsUpdatingUsername(false);
            }
        }
    };

    const hideToastMessages = () => {
        setShowToastErrorMessage(false);
        setErrorMessage("");
        setErrorStatus("");
        setShowToastSuccessMessage(false);
        setSuccessMessage("");
    }

    const handleUpdatePassword = async () => {
        // hide toast messages
        hideToastMessages();

        if (newPassword !== confirmPassword) {
            setShowToastErrorMessage(true);
            setErrorMessage("Passwords do not match");
            setErrorStatus("error");
            return;
        }

        try {
            setIsUpdatingPassword(true);
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/user/password/update/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    current_password: currentPassword,
                    new_password: newPassword
                })
            });

            if (response.ok) {
                setIsEditingPassword(false);
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setShowToastSuccessMessage(true);
                setSuccessMessage("Password updated successfully");
                setIsUpdatingPassword(false);
            } else {
                const errorData = await response.json();
                console.error('Failed to update password:', errorData);
                setShowToastErrorMessage(true);
                setErrorMessage(errorData.message);
                setErrorStatus("Error");
                setIsUpdatingPassword(false);
            }
        } catch (error) {
            console.error('Error updating password:', error);
            setShowToastErrorMessage(true);
            setErrorMessage("There was an error updating your password. Please try again.");
            setErrorStatus("Error");
            setIsUpdatingPassword(false);
        }
    };

    return <>{authenticated ? (
        <div className="min-h-screen lg:min-h-[calc(100vh-60px)] p-6 bg-no-repeat bg-cover w-full" style={{backgroundImage: `url(${BGGradient})`}}>
            <div className="max-w-3xl mx-auto bg-white/40 rounded-2xl p-8 backdrop-blur-sm">
                <h1 className="text-gray-800 text-2xl font-semibold mb-6">Your Profile</h1>

                <div className="space-y-3">
                    {/* Username Section */}
                    <div className="rounded-xl bg-white shadow-sm backdrop-blur-[30px] p-5">
                        <div className="flex justify-between items-center mb-2">
                            <div className="text-gray-500 text-sm font-medium">Username</div>
                            {!isEditingUsername && (
                                <button 
                                    onClick={() => setIsEditingUsername(true)}
                                    className="p-1.5 rounded-full bg-rose-50 hover:bg-rose-100 text-rose-500 hover:text-rose-600 transition-all duration-200 hover:scale-110 hover:shadow-sm group"
                                >
                                    <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    viewBox="0 0 24 24" 
                                    fill="currentColor" 
                                    className="w-4 h-4 group-hover:animate-pulse"
                                >
                                    <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                                </svg>
                            </button>
                            )}
                        </div>
                        {isEditingUsername ? (
                            <div className="flex flex-col lg:flex-row lg:items-center gap-3">
                                <input
                                    type="text"
                                    value={newUsername}
                                    onChange={(e) => setNewUsername(e.target.value)}
                                    className="flex-1 px-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-rose-300 focus:ring-1 focus:ring-rose-300 text-gray-700"
                                />
                                <button 
                                    onClick={() => handleUpdateProfile('username', newUsername)}
                                    className="px-4 py-2 bg-gradient-to-r from-rose-400 to-rose-500 hover:from-rose-500 hover:to-rose-600 text-white rounded-lg text-sm font-medium transition-all duration-200 shadow-sm hover:shadow"
                                >
                                    {isUpdatingUsername ? (<p className="flex items-center gap-2">Saving... <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white"></div></p>) : "Save"}
                                </button>
                                <button 
                                    onClick={() => {
                                        setIsEditingUsername(false);
                                        setNewUsername(username);
                                    }}
                                    className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg text-gray-600 text-sm font-medium transition-colors"
                                >
                                    Cancel
                                </button>
                            </div>
                        ) : (
                            <div className="text-gray-700 text-base font-medium">{username}</div>
                        )}
                    </div>

                    {/* Name Section */}
                    <div className="rounded-xl bg-white shadow-sm backdrop-blur-[30px] p-5">
                        <div className="flex justify-between items-center mb-2">
                            <div className="text-gray-500 text-sm font-medium">Name</div>
                            {!isEditingName && (
                                <button 
                                    onClick={() => setIsEditingName(true)}
                                    className="p-1.5 rounded-full bg-rose-50 hover:bg-rose-100 text-rose-500 hover:text-rose-600 transition-all duration-200 hover:scale-110 hover:shadow-sm group"
                                >
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        viewBox="0 0 24 24" 
                                        fill="currentColor" 
                                        className="w-4 h-4 group-hover:animate-pulse"
                                    >
                                        <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                                    </svg>
                                </button>
                            )}
                        </div>
                        {isEditingName ? (
                            <div className="flex flex-col lg:flex-row lg:items-center gap-3">
                                <input
                                    type="text"
                                    value={currentFirstName}
                                    onChange={(e) => setCurrentFirstName(e.target.value)}
                                    className="flex-1 px-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-rose-300 focus:ring-1 focus:ring-rose-300 text-gray-700"
                                    placeholder="First Name"
                                />
                                <input
                                    type="text"
                                    value={currentLastName}
                                    onChange={(e) => setCurrentLastName(e.target.value)}
                                    className="flex-1 px-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-rose-300 focus:ring-1 focus:ring-rose-300 text-gray-700"
                                    placeholder="Last Name"
                                />
                                <button 
                                    onClick={() => handleUpdateProfile('name', { first_name: currentFirstName, last_name: currentLastName })}
                                    className="px-4 py-2 bg-gradient-to-r from-rose-400 to-rose-500 hover:from-rose-500 hover:to-rose-600 text-white rounded-lg text-sm font-medium transition-all duration-200 shadow-sm hover:shadow"
                                >
                                    {isUpdatingName ? (<p className="flex items-center gap-2">Saving... <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white"></div></p>) : "Save"}
                                </button>
                                <button 
                                    onClick={() => {
                                        setIsEditingName(false);
                                        setCurrentFirstName(firstName);
                                        setCurrentLastName(lastName);
                                    }}
                                    className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg text-gray-600 text-sm font-medium transition-colors"
                                >
                                    Cancel
                                </button>
                            </div>
                        ) : (
                            <div className="text-gray-700 text-base font-medium">{userFullname}</div>
                        )}
                    </div>

                    {/* Email Section */}
                    <div className="rounded-xl bg-white shadow-sm backdrop-blur-[30px] p-5">
                        <div className="text-gray-500 text-sm font-medium mb-2">Email</div>
                        <div className="text-gray-700 text-base font-medium">{userEmail}</div>
                    </div>

                    <div className="rounded-xl bg-white shadow-sm backdrop-blur-[30px] p-5">
                        <div className="flex justify-between items-center mb-2">
                            <div className="text-gray-500 text-sm font-medium">Password</div>
                            {!isEditingPassword && (
                                <button 
                                    onClick={() => setIsEditingPassword(true)}
                                    className="p-1.5 rounded-full bg-rose-50 hover:bg-rose-100 text-rose-500 hover:text-rose-600 transition-all duration-200 hover:scale-110 hover:shadow-sm group"
                                >
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        viewBox="0 0 24 24" 
                                        fill="currentColor" 
                                        className="w-4 h-4"
                                    >
                                        <path fillRule="evenodd" d="M15.75 1.5a6.75 6.75 0 00-6.651 7.906c.067.39-.032.717-.221.906l-6.5 6.499a3 3 0 00-.878 2.121v2.818c0 .414.336.75.75.75H6a.75.75 0 00.75-.75v-1.5h1.5A.75.75 0 009 19.5V18h1.5a.75.75 0 00.53-.22l2.658-2.658c.19-.189.517-.288.906-.22A6.75 6.75 0 1015.75 1.5zm0 3a.75.75 0 000 1.5A2.25 2.25 0 0118 8.25a.75.75 0 001.5 0 3.75 3.75 0 00-3.75-3.75z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            )}
                        </div>
                        {isEditingPassword ? (
                            <div className="space-y-4">
                                <div>
                                    <input
                                        type="password"
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                        placeholder="Current Password"
                                        className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-rose-300 focus:ring-1 focus:ring-rose-300 text-gray-700 text-sm"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        placeholder="New Password"
                                        className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-rose-300 focus:ring-1 focus:ring-rose-300 text-gray-700 text-sm"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="Confirm New Password"
                                        className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-rose-300 focus:ring-1 focus:ring-rose-300 text-gray-700 text-sm"
                                    />
                                </div>
                                <div className="flex gap-2 justify-end pt-2">
                                    <button 
                                        onClick={() => handleUpdatePassword()}
                                        className="px-4 py-2 bg-gradient-to-r from-rose-400 to-rose-500 hover:from-rose-500 hover:to-rose-600 text-white rounded-lg text-sm font-medium transition-all duration-200 shadow-sm hover:shadow"
                                    >
                                        {isUpdatingPassword ? (<p className="flex items-center gap-2">Updating... <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white"></div></p>) : "Update"}
                                    </button>
                                    <button 
                                        onClick={() => {
                                            setIsEditingPassword(false);
                                            setCurrentPassword('');
                                            setNewPassword('');
                                            setConfirmPassword('');
                                        }}
                                        className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg text-gray-600 text-sm font-medium transition-colors"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="text-gray-700 text-base font-medium">********</div>
                        )}
                    </div>
                </div>

                <button 
                    className="mt-8 px-6 py-2.5 bg-gradient-to-r from-rose-400 to-rose-500 hover:from-rose-500 hover:to-rose-600 rounded-lg text-white text-sm font-medium shadow-sm hover:shadow transition-all duration-200" 
                    onClick={logout}
                >
                    Sign Out
                </button>
            </div>

            {showToastErrorMessage && (
                <ToastErrorMessage errorMessage={errorMessage} errorStatus={errorStatus} handleErrorClose={handleErrorClose} />
            )}

            {showToastSuccessMessage && (
                <ToastSuccessMessage message={successMessage} handleClose={handleSuccessClose} />
            )}
        </div>) : (//show a loading screen
            <div className="min-h-screen flex items-center justify-center animate-pulse">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-rose-500"></div>
            </div>
        )}
    </>
}
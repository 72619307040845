import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Signin from "./pages/Signin"
import Signup from "./pages/Signup"
import Playground from "./pages/Playground"
import Home from "./pages/admin/Home";
import Preview from "./pages/Preview";
import ModelSwapAI from "./pages/ModelSwapAI";
import Navbar2 from "./components/Navbar2";
import React, {useEffect, useRef, useState} from "react";
import Sidebar from "./components/Sidebar";
import TryonAI from "./pages/TryonAI";
import Credits from "./pages/Credits";
import Profile from "./pages/Profile";
import Cataloging from "./pages/Cataloging"
import HumanModelGeneration from "./pages/HumanModelGeneration"

const App = () => {
    const location = useLocation();
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const [currentOption, setCurrentOption] = useState("/");
    const [options, setOptions] = React.useState({
        "/tryonai": false,
        // "/aimodel": false,
        "/modelswapai": false,
        "/credits": false,
        "/profile": false,
    });
    const sidebarRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        let option = location.pathname;

        console.log("Pathname:", option)
        setCurrentOption(option)

        Object.keys(options).forEach((key) => {
            if (key === option) {
                options[key] = true;
            } else {
                options[key] = false;
            }
        })
        console.log("Updated options:", options)
        setOptions(options)

    }, []);

    const toggleSidebar = () => {
        setSidebarOpen(choice => !choice);
    };

    function handleOptionSelect(option, options) {
        console.log("Option selected:", option)
        setOptions(options)
        navigateToPage(option)
    }

    const navigateToPage = (selectedOption) => {
        if (currentOption === selectedOption) {
            console.log("You clicked on the same option:", currentOption)
        } else {
            setCurrentOption(selectedOption)
            navigate(selectedOption)
        }
    }

    const closeSideBar = () => {
        setSidebarOpen(false);
    }

    return (
        <>
            {location.pathname === "/signin" || location.pathname === "/signup" ? (<></>):(
            <Sidebar ref={sidebarRef} handleOptionSelect={handleOptionSelect} isOpen={isSidebarOpen}
                     closeSideBar={closeSideBar} options={options}/>)}

            <div className={location.pathname==="/signin" || location.pathname === "/signup"?"md:ml-0":"md:ml-64"}>
                <Navbar2 toggleSidebar={toggleSidebar} handleOptionSelect={handleOptionSelect} options={options}/>

                <Routes>
                    <Route path="/signup" element={<Signup/>}/>
                    <Route path="/signin" element={<Signin/>}/>
                    <Route path="/" element={<Playground/>}/>
                    <Route path="/tryonai" element={<TryonAI/>}/>
                    {/*<Route path="/aimodel" element={<HumanModelGeneration/>}/>*/}
                    <Route path="/modelswapai" element={<ModelSwapAI/>}/>
                    <Route path="/credits" element={<Credits/>}/>
                    <Route path="/profile" element={<Profile/>}/>
                    <Route path="/admin" element={<Home/>}/>
                    <Route path="/preview/:garment_url/:model_url/:tryon_url" element={<Preview/>}/>
                </Routes>
            </div>
        </>
    );
};

export default App;

import axios from "axios";
import { TRYON_SERVER_URL } from "../config";

export const fetchPrompts = async (accessToken) => {
    try {
        const res = await axios.get(`${TRYON_SERVER_URL}/api/v1/flux/prompts/`, {
                headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        if(res.status === 200) {
            console.log("Prompts fetched successfully", res.data)
            return {data: res.data.prompts, status: "success", statusCode: res.status, message: "Prompts fetched successfully"}
        }else{
            console.log("Failed to fetch prompts", res.data)
            return {data: null, status: "error", statusCode: res.status, message: "Failed to fetch prompts"}
        }
    } catch (err) {
        console.log(err);
        return {data: null, status: "error", statusCode: 400, message: "Error fetching prompts: " + err}
    }
};

import React, {useCallback, useEffect, useState} from 'react';
import BGGradient from "../assets/bgGradient2.svg"
import {TRYON_SERVER_URL} from "../config";
import {setUserData, setUserID} from "../store/AuthActions";
import {useDispatch, useSelector} from "react-redux";
import {selectAccessToken, selectAuthenticated} from "../selectors";
import {useNavigate} from "react-router-dom";
import {useAuthentication} from "../Authentication";

export default function Profile(props) {
    const accessToken = useSelector(selectAccessToken)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [username, setUsername] = useState("")
    const [userFullname, setUserFullname] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [userDetailsFetched, setUserDetailsFetched] = useState(false)
    const authenticated = useSelector(selectAuthenticated)
    const {isAuthenticated} = useAuthentication();

    async function fetchUserDetails() {
        try {
            console.log("access in fetch data", accessToken)
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/user/get/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                const userid = data.id
                dispatch(setUserID(userid));
                const dataWithoutPassword = {...data, password: undefined}; // Omit the password field
                dispatch(setUserData(JSON.stringify(dataWithoutPassword)));
                setUsername(dataWithoutPassword.username)
                setUserFullname(`${dataWithoutPassword.first_name !== undefined ? dataWithoutPassword.first_name : ""} ${dataWithoutPassword.last_name !== undefined ? dataWithoutPassword.last_name : ""}`)
                setUserEmail(dataWithoutPassword.email)
                setUserDetailsFetched(true)
            } else {
                console.error('Failed to fetch user data');
                setUserDetailsFetched(true)
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            setUserDetailsFetched(true)

        }
    }

    const checkAuthentication = useCallback(async () => {
        try {
            const userAuthenticated = await isAuthenticated();
            if (!userAuthenticated) {
                navigate("/signin");
            }
        } catch (error) {
            console.error('Error checking authentication:', error);

            if (error.message && error.message.toLowerCase().includes('network')) {
                console.error('Network error, redirecting to login page');
                navigate("/signin");
            }
        }
    }, [dispatch, isAuthenticated, navigate]);

    useEffect(() => {
        if (!authenticated) {
            checkAuthentication().then(() => {
            });
        }

        if (!userDetailsFetched) {
            fetchUserDetails().then(() => {
            })
        }
    }, []);

    return <>{authenticated ? (
        <div className="p-4 bg-no-repeat bg-cover w-full h-full" style={{backgroundImage: `url(${BGGradient})`}}>

            <div className="max-w-4xl bg-white/30 rounded-2xl p-8">
                <div className="text-gray-900 text-2xl">Your Profile</div>

                <div className="rounded-lg bg-white shadow backdrop-blur-[30px] mt-8 py-4 px-6">
                    <div className="text-neutral-400 text-sm mb-4">User name</div>
                    <div className="text-neutral-800 text-base">{username}
                    </div>
                </div>

                <div className="rounded-lg bg-white shadow backdrop-blur-[30px] mt-2 py-4 px-6">
                    <div className="text-neutral-400 text-sm mb-4">Your name</div>
                    <div className="text-neutral-800 text-base">{userFullname}
                    </div>
                </div>

                <div className="rounded-lg bg-white shadow backdrop-blur-[30px] mt-2 py-4 px-6">
                    <div className="text-neutral-400 text-sm mb-4">User email</div>
                    <div className="text-neutral-800 text-base">{userEmail}
                    </div>
                </div>
            </div>
        </div>) : (<></>)}
    </>
}
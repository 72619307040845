import React from "react";


import CardGradient from "../assets/CardGradient.svg";
import CardGradient2 from "../assets/CardGradient2.svg";

export default function InfoCard({ title, description, onClick}) {
    return (
        <div
            className="max-w-md bg-white/70 border border-gray-200 rounded-lg shadow lg:mb-0 mb-4">
            <div className=" flex items-center justify-center p-4 h-full ">
               <div className="flex flex-col p-4 ">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 font-OpenSans">{title}</h5>
                <p className="mb-3 font-normal text-gray-700 font-OpenSans">{description}</p>
                {/* <button onClick= {onClick}
                    className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Read more
                    
                </ button> */}

                <button
                    className="py-2 px-4 inline-flex items-center text-sm font-medium text-center bg-gradient-to-r from-red-500 to-rose-600 rounded-lg text-white cursor-pointer w-fit"
                    onClick={onClick}>
                    Open
                    <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M1 5h12m0 0L9 1m4 4L9 9" />
                    </svg>
                </button>
                </div>
            </div>
        </div>

    )
}    
import React, { useEffect, useCallback, useState } from "react";
import { useAuthentication } from "../../Authentication";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAccessToken, selectAuthenticated } from "../../selectors";
import { TRYON_SERVER_URL } from "../../config";
import InfoCard from "../../components/InfoCard";
import ThreeDotSpinner from "../../components/ThreeDotSpinner";

export default function Dashboard(props){
    const {isAuthenticated} = useAuthentication();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const authenticated = useSelector(selectAuthenticated)
    const accessToken = useSelector(selectAccessToken)
    const [userDetailsFetched, setUserDetailsFetched] = useState(false)
    const [isStaff, setIsStaff] = useState(false)
    const [fetchingUserDetails, setFetchingUserDetails] = useState(false)

    const checkAuthentication = useCallback(async () => {
        try {
            const userAuthenticated = await isAuthenticated();
            if (!userAuthenticated) {
                navigate("/signin");
            }
        } catch (error) {
            console.error('Error checking authentication:', error);

            if (error.message && error.message.toLowerCase().includes('network')) {
                console.error('Network error, redirecting to login page');
                navigate("/signin");
            }
        }
    }, [dispatch, isAuthenticated, navigate]);

    useEffect(() => {
        if (!authenticated) {
            checkAuthentication().then(() => {
            });
        }
    }, []);

     async function fetchUserDetails() {
        try {
            setFetchingUserDetails(true)
            console.log("access in fetch data", accessToken)
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/user/get/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data)
                if(data.is_staff){
                    setIsStaff(true)
                }else{
                    setIsStaff(false)
                }
                setUserDetailsFetched(true)
                setFetchingUserDetails(false)
            } else {
                console.error('Failed to fetch user data');
                setUserDetailsFetched(true)
                setFetchingUserDetails(false)
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            setUserDetailsFetched(true)
            setFetchingUserDetails(false)
        }
    }

    useEffect(() => {
        if(authenticated && !userDetailsFetched){
            fetchUserDetails()
        }
    }, [authenticated, userDetailsFetched])

    return (<div>
        {authenticated? 
        fetchingUserDetails ? <div className="text-center text-xl h-72 bg-gray-100 m-4 rounded-lg animate-pulse flex items-center justify-center"><ThreeDotSpinner/></div> :
        isStaff && userDetailsFetched ? <div className="m-4 flex justify-start gap-4">

            <InfoCard title="Users" description="View all users and their details." onClick= {() => {navigate("/admin/users")}}/>

            <InfoCard title="Experiments" description="View all experiments and their details." onClick= {() => {navigate("/admin/experiments")}}/>

            <InfoCard title="User Onboarding Details" description="View all user onboarding details and their details." onClick= {() => {navigate("/admin/useronboardingdetails")}}/>
            
        </div>
         : 
        <div className="text-center text-xl mt-4">You are not authorized to access this page</div>
        :
        (<div></div>)}
    </div>)
}
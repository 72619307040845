import React, {useEffect, useState, useRef} from "react";

import Sidebar from "../components/Sidebar";
import Navbar2 from "../components/Navbar2";
import TryonAI from "./TryonAI";
import Credits from "./Credits";
import Profile from "./Profile";
import landingbg2 from "../assets/bgGradient2.svg";
import {useNavigate} from "react-router-dom";
import {useAuthentication} from "../Authentication";
import {useSelector} from "react-redux";
import {selectAuthenticated} from "../selectors";
import Spinner from "../components/Spinner";
import ModelSwapAI from "./ModelSwapAI";
import InfoCard from "../components/InfoCard";


export default function Playground(props) {
    const [selectedOption, setSelectedOption] = useState("Virtual Try-On");
    const [options, setOptions] = React.useState({
        "Virtual Try-On": true,
        "Model Swap": false,
        "Credits": false,
        "Profile": false,
    });
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const navigate = useNavigate();
    const sidebarRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const authenticated = useSelector(selectAuthenticated);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            if (window.innerWidth >= 768) {
                setSidebarOpen(true);
            }else{
                setSidebarOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const closeSideBar = () => {
        setSidebarOpen(false);
    }

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            if (isMobile) {
                toggleSidebar(false);
            }
        }
    };

    useEffect(() => {
        if (isSidebarOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSidebarOpen]);

    const toggleSidebar = () => {
        setSidebarOpen(choice => !choice);
    };

    function handleOptionSelect(option, options) {
        console.log("Option selected:", option)
        setSelectedOption(option)
        setOptions(options)
    }

    function renderPlayground() {
        console.log("Selected option:", selectedOption)
        if (selectedOption === "Virtual Try-On") {
            return (<TryonAI/>)
        }if (selectedOption === "Model Swap") {
            return (<ModelSwapAI/>)
        } else if (selectedOption === "Credits") {
            return (<Credits/>)
        } else if (selectedOption === "Profile") {
            return (<Profile/>)
        }
    }

    // useEffect(() => {
    //     if (!authenticated) {
    //         navigate("/signin");
    //     }else{
    //         setLoading(false);
    //     }
    // }, [authenticated,]);

    return (
        <div className="p-4 flex justify-start gap-4">

            <InfoCard title="TryOn AI" description="Experience Virtual Try-On let you try a garment in different poses. It helps buyers make a conscious decision before making a purpose." onClick= {() => {navigate("/tryonai")}}/>

            <InfoCard title="Model Swap AI" description="Replace a human model with an AI-generated model, ensuring the garment stays intact and unchanged." onClick= {() => {navigate("/modelswapai")}}/>

        </div>
    )
}
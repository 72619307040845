import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { clearData, setAuthenticated, setUserData, setUserID } from "../store/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken, selectAuthenticated, selectUserData } from "../selectors";
import { TRYON_SERVER_URL } from "../config";
import ArrowUp from "../assets/arrow_up.png"
import LogoutIcon from "../assets/logout_icon.svg"
import UserAccountIcon from "../assets/user_account.svg"
import { useAuthentication } from "../Authentication";
import { logoutUser } from "../store/AuthActions";

export default function Navbar2(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const authenticated = useSelector(selectAuthenticated)
    const accessToken = useSelector(selectAccessToken);
    const [userDetailsFetched, setUserDetailsFetched] = useState(false)
    const [username, setUsername] = useState(undefined)
    const [showDropdown, setShowDropdown] = useState(false);
    const [authenticated,setAuthenticated] = useState(false);
    const [userData,setUserData] = useState(JSON.parse(localStorage.getItem('user_data')));
    const user = useSelector(selectUserData);

    const { isAuthenticated } = useAuthentication();

    useEffect(() => {
        const checkAuth = async () => {
            const authStatus = await isAuthenticated();
            setAuthenticated(authStatus);
            console.log("from auth Status", authStatus)
        };

        checkAuth();
    }, [isAuthenticated, navigate]);
    

    useEffect(() => {
        if (authenticated) {
            console.log("user before",user);
            if (user) {
                try {
                    const parsedUser = JSON.parse(user);
                    console.log("user details here", parsedUser);
                    setUserData(parsedUser);
                    setUserDetailsFetched(true);
                } catch (error) {
                    console.error("Failed to parse user data from localStorage:", error);
                }
            }
        }
    }, [authenticated, user, ]);


    // async function fetchUserDetails() {
    //     try {
    //         if (!accessToken) return;

    //         console.log("Access token in fetch data:", accessToken);
    //         const response = await fetch(`${TRYON_SERVER_URL}/api/v1/user/get/`, {
    //             method: 'GET',
    //             headers: {
    //                 Authorization: `Bearer ${accessToken}`,
    //                 'Content-Type': 'application/json',
    //             },
    //         });

    //         if (response.ok) {
    //             const data = await response.json();
    //             const userid = data.id;
    //             dispatch(setUserID(userid));
    //             const dataWithoutPassword = { ...data, password: undefined }; // Omit the password field
    //             dispatch(setUserData(JSON.stringify(dataWithoutPassword)));
    //             setUsername(dataWithoutPassword.username);
    //             setUserDetailsFetched(true);
    //         } else {
    //             console.error('Failed to fetch user data');
    //             setUserDetailsFetched(true);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching user data:', error);
    //         setUserDetailsFetched(true);
    //     }
    // }

    // useEffect(() => {
    //     if (authenticated && !userDetailsFetched) {
    //         console.log("auth", authenticated)
    //         fetchUserDetails();
    //     }
    // }, [authenticated, userDetailsFetched, accessToken]);

    const logout = () => {
        dispatch(logoutUser())
          .then(() => {
            // Redirect or perform any other actions after successful logout
            console.log("logged out succesfully!")
            // navigate('/signin');
            window.location.reload();
          })
          .catch((error) => {
            // Handle any errors that occur during logout
            console.error('Logout error:', error);
          });
      };
    function handleSelectOption(option) {
        let options1 = props.options;
        Object.keys(options1).forEach((key) => {
            if (key === option) {
                options1[key] = true;
            } else {
                options1[key] = false;
            }
        })
        console.log(options1)
        props.handleOptionSelect(option, options1);
    }

    return (
        <div className="relative z-10">
            <div className="flex justify-between md:justify-end items-center bg-white backdrop-blur-[30px] border-solid
                border-b border-stone-300 p-4 h-fit min-h-[5vh] max-h-[7vh]">
                <button onClick={props.toggleSidebar} data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar"
                    aria-controls="sidebar-multi-level-sidebar" type="button"
                    className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 ">
                    <span className="sr-only">Open sidebar</span>
                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path clipRule="evenodd" fillRule="evenodd"
                            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                    </svg>
                </button>
                <div>
                    { (authenticated) ? (
                            <div className="flex items-center justify-start gap-4">
                                <button
                                    className="py-2 px-4 text-sm bg-gradient-to-r from-red-500 to-rose-600 rounded-lg text-white cursor-pointer"
                                    onClick={() => handleSelectOption("credits")}>
                                    Credits: {userData?.credits}
                                </button>

                                <div className="relative">
                                    <button type="button"
                                            onClick={() => {
                                                setShowDropdown(!showDropdown)
                                            }}
                                            className="flex justify-end items-center gap-2 cursor-pointer"
                                            data-dropdown-toggle="dropdown">
                                        <div className="text-gray-900 text-base font-semibold">
                                            {userData?.username}
                                        </div>
                                        <img src={ArrowUp} alt="arrow up"/>
                                    </button>

                                    {showDropdown && (
                                        <div
                                            className="absolute right-0 z-10 mt-2 origin-top-right w-36 rounded-bl-2xl rounded-br-2xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-6 px-4" role="none">
                                                <div
                                                    className="cursor-pointer text-gray-700 text-sm flex justify-start items-center gap-2 mb-4"
                                                    onClick={() => {
                                                        handleSelectOption("profile")
                                                    }}><img src={UserAccountIcon} alt="Logout"/>
                                                    <div
                                                        className="text-neutral-800 text-base">Profile
                                                    </div>
                                                </div>

                                                <div onClick={logout}
                                                     className="cursor-pointer text-gray-700 w-full text-left text-sm flex justify-start items-center gap-2">
                                                    <img src={LogoutIcon} alt="Logout"/>
                                                    <div
                                                        className="text-neutral-800 text-base">Sign out
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>
                        )
                        :
                        (
                            <div>
                                <button type="button" onClick={() => {
                                    navigate("/signin")
                                }}
                                    className="cursor-pointer px-4 py-2 bg-rose-700 rounded-2xl border border-red-200 backdrop-blur-[100px] justify-center items-center gap-2.5 inline-flex">
                                    <div className="text-white text-xs">Sign in
                                    </div>
                                </button>
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
}
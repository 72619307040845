// import { LEVITON_SERVER_URL } from "./config";
// import axios from "axios";
// import { setAccessToken } from "./store/AuthActions";
// import {useDispatch} from "react-redux";

// const dispatch = useDispatch()
// // export async function isAuthenticated() {
// //     try {
// //         console.log("token", localStorage.getItem("accessToken"))
// //         const response = await axios.post(`${LEVITON_SERVER_URL}/api/token/verify/`, {
// //             'token': `${localStorage.getItem("accessToken")}`
// //         });

// //         console.log('Token is valid',response);
// //         return true;
// //     } catch (error) {
// //         console.log('Token is invalid', error);
// //         return false;
// //     }
// // }
//  checking auto save 
// // import axios from 'axios';

// // Function to refresh the access token
// const refreshAccessToken = async () => {
    
//   try {
//     const response = await axios.post(`${LEVITON_SERVER_URL}/api/token/refresh/`, {
//       refresh : localStorage.getItem('refreshToken'),
//     });

//     const newAccessToken = response.data.access;
//     dispatch(setAccessToken(newAccessToken))

//     return newAccessToken;
//   } catch (error) {
//     console.error('Error refreshing access token:', error);
//     throw error;
//   }
// };

// export async function isAuthenticated(){
//   try {
//     console.log('token', localStorage.getItem('accessToken'));
//     const response = await axios.post(`${LEVITON_SERVER_URL}/api/token/verify/`, {
//       token: localStorage.getItem('accessToken'),
//     });

//     console.log('Token is valid', response);
//     return true;
//   } catch (error) {
//     console.log('Token is invalid', error);

//     if (error.response && error.response.status === 401) {
//       try {
//         const newAccessToken = await refreshAccessToken();
//         const verificationResponse = await axios.post(`${LEVITON_SERVER_URL}/api/token/verify/`, {
//           token: newAccessToken,
//         });

//         console.log('Token is valid after refresh', verificationResponse);
//         return true;
//       } catch (refreshError) {
//         console.error('Error refreshing token:', refreshError);
//         return false;
//       }
//     }

//     return false;
//   }
// }

    //Check if the response is JSON
    export function isJSON(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    // Resizes the image and returns the resized image dataURL
    export const resizeUploadedFile = (file, max_size = 1024) => {
        console.log("file:", file)
        return new Promise((resolve, reject) => {
            if (file.type.match(/image.*/)) {
                // Load the image
                var reader = new FileReader();
                reader.onload = function (readerEvent) {
                    var image = new Image();
                    image.onload = function (imageEvent) {
                        // Resize the image
                        var canvas = document.createElement('canvas'),
                            width = image.width,
                            height = image.height;
                        if (width > height) {
                            if (width > max_size) {
                                height *= max_size / width;
                                width = max_size;
                            }
                        } else {
                            if (height > max_size) {
                                width *= max_size / height;
                                height = max_size;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                        var dataUrl = canvas.toDataURL('image/jpeg');
                        resolve(dataUrl); // Resolve with the resized image URL
                    };
                    image.src = readerEvent.target.result;
                };
                reader.readAsDataURL(file);
            } else {
                reject(new Error('Uploaded file is not an image.'));
            }
        });
    };

    // Convert the resized image dataURL back to a Blob
    export function dataURLToBlob(dataURL) {
        var parts = dataURL.split(';base64,');
        var contentType = parts[0].split(':')[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;
        var uInt8Array = new Uint8Array(rawLength);

        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], { type: contentType });
    }

    export const urlToFile = async (url, fileName) => {
        const response = await fetch(url);
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: blob.type });
        return file;
    };

    export const fileToImageData = async (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
                // Create a canvas to draw the image
                const canvas = document.createElement('canvas');
                canvas.width = img.width; // Set canvas width
                canvas.height = img.height; // Set canvas height

                const context = canvas.getContext('2d');
                context.drawImage(img, 0, 0); // Draw the image on the canvas

                // Get the ImageData from the canvas
                const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                console.log("imageData from fileToImageData", imageData.data, canvas.width, canvas.height);
                resolve({ imageData: imageData.data, width: canvas.width, height: canvas.height });
            };

            img.onerror = (error) => {
                console.error("Error loading image:", error);
                reject(new Error("Error loading image"));
            };
        });
    }
import React, {useCallback, useEffect, useState} from 'react';
import CreditsBG from "../assets/credit-bg.png"
import CreditsBG2 from "../assets/credits-bg2.svg"
import CopyIcon from "../assets/copy_icon.svg"
import ArrowLineUpRight from "../assets/ArrowLineUpRight.svg"
import {useDispatch, useSelector} from "react-redux";
import {selectAccessToken, selectAuthenticated} from "../selectors";
import BuyCreditsBG1 from "../assets/buy_credits_bg1.png"
import CloseIcon from "../assets/close-icon.svg"
import {useNavigate} from "react-router-dom";
import {useAuthentication} from "../Authentication";
import {TRYON_SERVER_URL} from "../config";
import {setUserData, setUserID} from "../store/AuthActions";

export default function Credits(props) {
    const accessToken = useSelector(selectAccessToken)
    const [showToast, setShowToast] = React.useState(false);
    const [toastMessage, setToastMessage] = React.useState("");
    const [showBuyCreditsModal, setShowBuyCreditsModal] = React.useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [userDetailsFetched, setUserDetailsFetched] = useState(false)
    const [userCredits, setUserCredits] = useState(0)
    const authenticated = useSelector(selectAuthenticated)
    const {isAuthenticated} = useAuthentication();

    function handleCopyAccessToken() {
        navigator.clipboard.writeText(accessToken)
        setShowToast(true);
        setToastMessage("Access Token Copied!");

        setTimeout(() => {
            setShowToast(false)
            setToastMessage("")
        }, 2500);
    }

    async function fetchUserDetails() {
        try {
            console.log("access in fetch data", accessToken)
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/user/get/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                const userid = data.id
                dispatch(setUserID(userid));
                const dataWithoutPassword = {...data, password: undefined}; // Omit the password field
                dispatch(setUserData(JSON.stringify(dataWithoutPassword)));
                setUserCredits(dataWithoutPassword.credits);
                setUserDetailsFetched(true)
            } else {
                console.error('Failed to fetch user data');
                setUserDetailsFetched(true)
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            setUserDetailsFetched(true)

        }
    }

    const checkAuthentication = useCallback(async () => {
        try {
            const userAuthenticated = await isAuthenticated();
            if (!userAuthenticated) {
                navigate("/signin");
            }
        } catch (error) {
            console.error('Error checking authentication:', error);

            if (error.message && error.message.toLowerCase().includes('network')) {
                console.error('Network error, redirecting to login page');
                navigate("/signin");
            }
        }
    }, [dispatch, isAuthenticated, navigate]);

    useEffect(() => {
        if (!authenticated) {
            checkAuthentication().then(() => {
            });
        }

        if (!userDetailsFetched) {
            fetchUserDetails().then(() => {
            })
        }
    }, []);

    return (
        <div className="p-4 relative">
            {authenticated ? (<>
                    <div className="bg-gradient-to-b from-stone-100 to-emerald-100 rounded-2xl">
                        <div className="w-full rounded-2xl bg-right p-8 bg-no-repeat"
                             style={{backgroundImage: `url(${CreditsBG2})`}}>
                            <div className="text-stone-800 text-2xl font-normal font-Mulish">Credits</div>

                            <div className="text-center inline-block mt-6">
                                <div style={{backgroundImage: `url(${CreditsBG})`}}
                                     className="bg-no-repeat bg-contain bg-center px-10 py-6">
                                    <div className="text-stone-800 text-2xl font-semibold">{userCredits}</div>
                                </div>

                                <div className="text-green-600 text-xs font-normal font-Mulish">Total Credit Available
                                </div>
                            </div>

                            <div className="flex justify-end items-center">
                                <button type="button" onClick={() => {
                                    setShowBuyCreditsModal(true)
                                }}
                                        className="cursor-pointer px-6 py-2 bg-gray-900 rounded-[50px] border backdrop-blur-[100px] justify-center items-center gap-[9px] inline-flex">
                                    <div className="text-red-100 text-base leading-normal">
                                        Buy Credits
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="bg-gradient-to-b from-stone-100 to-rose-200 rounded-2xl p-8 mt-4">
                        <div className="text-stone-800 text-2xl font-Mulish">Access Token</div>
                        <div
                            className="bg-rose-100 rounded-[56px] border-dashed border border-gray-600 px-4 py-2 mt-10 flex overflow-x-auto justify-between items-center">
                            <div
                                className="text-stone-800 text-base font-semibold">{accessToken.slice(0, 20)}...{accessToken.slice(-20)}
                            </div>

                            <button type="button"
                                    className="px-6 py-2 bg-gray-900 rounded-[48px] border backdrop-blur-[100px] inline-flex justify-center items-center gap-2"
                                    onClick={handleCopyAccessToken}>
                                <div className="text-red-100 text-base leading-normal">Copy</div>
                                <img src={CopyIcon} alt="copy" className=""/>
                            </button>
                        </div>

                        <div className="mt-10 text-center">
                            <div className="text-[#424242] text-base leading-normal">See
                                documentation to know how to use APIs
                            </div>

                            <a type="button"
                               href="https://documenter.getpostman.com/view/31553983/2sA2xpTpoF" target='_blank'
                               className="cursor-pointer px-4 py-2 rounded-[50px] border border-rose-700 backdrop-blur-[100px] justify-center items-center gap-2.5 inline-flex mt-6">
                                <div className="text-rose-700 text-sm leading-normal">See
                                    Documentation
                                </div>
                                <img src={ArrowLineUpRight} alt="arrow up right"/>
                            </a>
                        </div>
                    </div>

                    {showToast ? (
                        <div id="toast-bottom-right"
                             className="fixed flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 bg-white divide-x rtl:divide-x-reverse divide-gray-200 rounded-lg shadow right-5 bottom-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800"
                             role="alert">
                            <div className="text-sm font-normal">{toastMessage}</div>
                        </div>) : (<></>)}

                    {showBuyCreditsModal ? (
                        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div
                                    className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

                                    <div
                                        className="relative transform overflow-hidden rounded-2xl bg-gradient-to-b from-white to-rose-50 text-left shadow transition-all sm:my-8 sm:w-full sm:max-w-lg border border-stone-300">
                                        <div
                                            className="bg-gradient-to-b from-white to-rose-50 px-4 pb-4 pt-5 sm:p-6 sm:pb-4 bg-no-repeat bg-right"
                                            style={{backgroundImage: `url(${BuyCreditsBG1})`}}>
                                            <div className="sm:flex sm:items-start">
                                                <div className="mt-3 mb-10 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                    <div
                                                        className="text-neutral-800 text-2xl font-normal font-Mulish">Buy
                                                        Credits
                                                    </div>
                                                    <div
                                                        className="text-neutral-800 text-2xl mt-12">Lets
                                                        level up your Brand, together
                                                    </div>
                                                    <div className="mt-2"><span className="text-stone-800 text-base">contact us to buy credits to use our APIs</span>
                                                    </div>

                                                    <div className="mt-8">
                                                        <a href="mailto:contact@tryonlabs.ai"
                                                           className="text-rose-700 text-base">contact@tryonlabs.ai
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                            <button type="button"
                                                    onClick={() => {
                                                        setShowBuyCreditsModal(false)
                                                    }}
                                                    className="cursor-pointer w-8 h-8 right-2 top-2 absolute bg-white rounded-full border border-stone-300">
                                                <img src={CloseIcon} alt="close"/>
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (<></>)}</>
            ) : (<></>)}
        </div>
    )
}
import React from 'react';

export default function GradientFont(props){
    return(
        <div style={{
            background: 'linear-gradient(90deg, #ED5642 -17.63%, #C23444 114.16%)',
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textFillColor: 'transparent', // This is not universally supported and might not work in all browsers
          }}> {props.children}</div>
    );
}
import React, {useCallback, useEffect, useRef, useState} from "react";
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {TRYON_SERVER_URL} from "../config";
import {selectAuthenticated} from "../selectors";
import {useAuthentication} from "../Authentication";
import Spinner from "../components/Spinner";
import TryonLabsLogo from '../assets/TryonLabsLogo.png'
import GoogleIcon from "../assets/GoogleIcon.svg"
import HRLine from "../assets/HRLine.svg"
import HideIcon from "../assets/HideIcon.svg";
import showIcon from "../assets/showIcon.svg";
import landingbg1 from "../assets/bgGradient.svg";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import {setAccessToken, setRefreshToken, setUserData, setUserID} from "../store/AuthActions";


export default function Signup() {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [first_name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {isAuthenticated} = useAuthentication();
    const authenticated = useSelector(selectAuthenticated)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("This is an error")
    const [policyChecked, setPolicyChecked] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [showVerify, setShowVerify] = useState(false);
    const [ user, setUser ] = useState([]);
    const [ profile, setProfile ] = useState([]);
    const [ spinner, setSpinner ] = useState(false);


    const fetchUserData = async (accessToken) => {
        try {
            console.log("access in fetch data", accessToken)
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/user/get/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                const userid = data?.id
                console.log("userid", userid)
                dispatch(setUserID(userid));
                const dataWithoutPassword = {...data, password: undefined}; // Omit the password field
                dispatch(setUserData(JSON.stringify(dataWithoutPassword)));
            } else {
                console.error('Failed to fetch user data');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);

        }
    };
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const submitForm = async () => {
        setShowError(false)
        setErrorMessage("")
    
        if(username === "" || first_name === "" || last_name === "" || email === "" || password === ""){
            setShowError(true)
            setSpinner(false)
            setErrorMessage("Please enter all credentials")
            return
        }
    
        if(!policyChecked){
            setShowError(true)
            setSpinner(false)
            setErrorMessage("Accept our privacy policy and terms of use")
            return
        }
    
        try {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('first_name', first_name);
            formData.append('last_name', last_name);
            formData.append('email', email);
            formData.append('password', password);
            console.log("registering user", formData)
    
            await registerUser(formData);

            setSpinner(false)
            navigate("/verify", {
                state: {
                    username,
                    first_name,
                    last_name,
                    email,
                    password,
                },
            });

            // const loginFormData1 = new FormData();
            // loginFormData1.append('username', email);
            // loginFormData1.append('password', password);
            // console.log("login user", loginFormData1)

            // await loginUser(loginFormData1);
            // navigate("/signin");
        } catch (error) {
            console.error('Error while registering user:', error?.response?.data?.message);
            setShowError(true)
            setSpinner(false)
            setErrorMessage(error?.response?.data?.message)
        }
    };
    
    const registerUser = async (formData) => {
        try {
            const response = await axios.post(`${TRYON_SERVER_URL}/api/v1/user/create/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.status === 201) {
                // navigate("/signin");
                console.log('User registered successfully:', response.data);
            } else {
                throw new Error('Unable to create an account.');
                // setShowError(true)
                // setErrorMessage("Unable to create an account.")
            }
        } catch (error) {
            console.error('Error while registering user:', error.response.data.message);
            setShowError(true)
            setSpinner(false)
            setErrorMessage(error.response.data.message)
            throw error
        }
    };

    const loginUser = async (formData) => {
        try {
            const response = await axios.post(`${TRYON_SERVER_URL}/api/v1/user/token/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            console.log("status:", response)
            const {access, refresh} = response.data;
            console.log('Access token received:', access);
            localStorage.clear();
            sessionStorage.clear();
            dispatch(setAccessToken(access));
            dispatch(setRefreshToken(refresh));
            isAuthenticated().then(r => {
            });
            fetchUserData(access).then(r => {
            })
            // navigate to playground
            navigate("/");
        } catch (error) {
            // setErrorMessage(error?.response?.message)
            // setShowError(true)
            // console.log(error)
            if(error?.response?.data?.is_active === false){
                setShowVerify(true);
            }
            throw error;
        }
    };
    
    const handleSignup = (e) => {
        e.preventDefault();
        setSpinner(true);
        submitForm();
    };

    const regenerateOtp = async (email) => {
        try {
            // Simulating an API call for OTP regeneration
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/user/otp/regenerate`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: email }),
            });

            if (!response.ok) {
                throw new Error("Failed to regenerate OTP. Please try again.");
            }

            // setOtpRegenerated(true);
            setErrorMessage(""); // Clear any previous errors
        } catch (error) {
            setShowError(true);
            setErrorMessage(error.message);
            // setOtpRegenerated(false);
        }
    };

    const navigateVerify = async() => {
        console.log("profile", profile);
        
        if (profile && Object.keys(profile).length > 0) {
            console.log("navigated thru profile");
            await regenerateOtp(profile.email);
            navigate("/verify", {
                state: {
                    email: profile.email,
                    password: profile.id,
                },
            });
        } else {
            console.log("navigated thru username/password");
            await regenerateOtp(username);
            navigate("/verify", {
                state: {
                    email: username,
                    password: password,
                },
            });
        }
    };



    const checkAuthentication = useCallback(async () => {
        try {
            const userAuthenticated = await isAuthenticated();
            if (userAuthenticated) {
                navigate("/");
            }
        } catch (error) {
            console.error('Error checking authentication:', error);

            if (error.message && error.message.toLowerCase().includes('network')) {
                console.error('Network error, redirecting to login page');
                navigate("/login");
            }
        }
    }, [dispatch, isAuthenticated, navigate]);

    useEffect(() => {
        if (!authenticated) {
            checkAuthentication();
        }
    }, []);

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(
        () => {
            if (user) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        setProfile(res.data);
                        console.log("data from gooogelogin", res.data)
                    })
                    .catch((err) => console.log(err));
            }
        },
        [ user ]
    );

    useEffect(() => {
        const registerOrLogin = async () => {
            if (profile) {
                try {
                    const loginFormData = new FormData();
                    loginFormData.append('email', profile.email);
                    loginFormData.append('password', profile.id);
    
                    await loginUser(loginFormData);

                } catch (loginError) {
                    if (loginError.response && loginError.response.status === 401) {
                        // User does not exist, attempt to register
                        try {
                            const registerFormData = new FormData();
                            const baseUsername = profile.given_name.replace(/[^a-zA-Z0-9@/./+/-/_]/g, '');
                            const uniqueUsername = `${baseUsername}${Date.now()}`;
                            registerFormData.append('username', uniqueUsername);
                            registerFormData.append('first_name', profile.given_name);
                            registerFormData.append('last_name', profile.family_name);
                            registerFormData.append('email', profile.email);
                            registerFormData.append('password', profile.id);
    
                            await registerUser(registerFormData);

                            navigate("/verify", {
                                state: {
                                    username: uniqueUsername,
                                    first_name: profile.given_name,
                                    last_name: profile.family_name,
                                    email: profile.email,
                                    password: profile.id,
                                },
                            });
    
                            // Now try to login again
                            // const loginFormData2 = new FormData();
                            // loginFormData2.append('username', profile.email);
                            // loginFormData2.append('password', profile.id);
                            // await loginUser(loginFormData2);
                        } catch (registerError) {
                            console.error('Error while registering user:', registerError?.response?.data?.message);
                            setShowError(true)
                            setErrorMessage(registerError?.response?.data?.message)
                        }
                    } else {
                        console.error('Error while logging in user:', loginError?.response?.data?.message);
                        setShowError(true)
                        setErrorMessage(loginError?.response?.data?.message)
                    }
                }
            }
        };
    
        registerOrLogin();
    }, [profile]);
    
    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        googleLogout();
        setProfile(null);
    };


    return (
        <div className="flex flex-col h-screen overflow-y-hidden relative" style={{ backgroundImage: `url(${landingbg1})`, backgroundSize: 'cover'}}>

            <div className="flex w-full h-full items-center justify-center max-h-screen overflow-hidden py-[2rem]">
                {!authenticated ?
                    (
                        <div className=" flex m-4 w-full h-full items-center justify-center  ">
                            <div className="max-w-lg bg-white/opacity-30 rounded-2xl shadow-auth-box-shadow backdrop-blur-[30px]">
                                <div className=" w-full rounded-tl-2xl rounded-tr-2xl bg-opac-auth shadow-navbarShadow backdrop-blur-[30px]">
                                    <div className="flex justify-center gap-4 items-center py-10 px-24">
                                        <img src={TryonLabsLogo} alt="tryonlabs logo" className="w-12 h-auto"/>
                                        <div
                                            className="text-gray-900 text-xl font-medium font-Readex_pro">Tryon Labs
                                        </div>
                                    </div>
                                </div>

                                <div className="p-6">
                                    <div onClick={login}
                                        className="flex justify-center items-center bg-opac-auth gap-2 py-2 rounded-[118px] border border-rose-200 hover:border-rose-600 text-center cursor-pointer">
                                        <img src={GoogleIcon} alt="google icon"/> Sign in with Google
                                    </div>

                                    <div
                                        className="flex justify-center items-center bg-repeat-x bg-center text-center gap-2 py-6">
                                        <img src={HRLine} alt="hrline"/>
                                        <div
                                            className="text-[#424242] text-sm leading-normal">or
                                            create an account
                                        </div>
                                        <img src={HRLine} alt="hrline"/>
                                    </div>

                                    <form action="#">
                                        <div className="mb-4">
                                            <label htmlFor="username"
                                                   className="text-neutral-800 text-sm leading-normal mb-4 mx-4">Username
                                            </label>
                                            <input type="text" name="username" id="username" placeholder="eg. tryonlabs"
                                                   className="w-full h-10 rounded-[118px] border border-stone-400 focus:outline-none focus:border-1 focus:border-rose-600 mt-2 bg-opac-input px-4 "
                                                   value={username}
                                                   onChange={(e) => {
                                                       setUsername(e.target.value)
                                                   }}
                                                   required/>
                                        </div>

                                        <div className="mb-4 grid grid-cols-2 gap-4">
                                            <div>
                                                <label for="firstName"
                                                       className="text-neutral-800 text-sm leading-normal mb-4 mx-4  ">First
                                                    Name
                                                </label>
                                                <input type="text" name="firstName" id="firstName"
                                                       placeholder="eg. Tryon"
                                                       value={first_name}
                                                       onChange={(e) => {
                                                           setFirstName(e.target.value)
                                                       }}
                                                       className="w-full h-10 rounded-[118px] border border-stone-400 focus:border-rose-600 mt-2 bg-opac-input px-4"
                                                       required/>
                                            </div>

                                            <div>
                                                <label htmlFor="lastName"
                                                       className="text-neutral-800 text-sm leading-normal mb-4 mx-4">Last
                                                    Name
                                                </label>
                                                <input type="text" name="lastName" id="lastName"
                                                       placeholder="eg. Labs"
                                                       value={last_name}
                                                       onChange={(e) => {
                                                           setLastName(e.target.value)
                                                       }}
                                                       className="w-full h-10 rounded-[118px] border border-stone-400 focus:border-rose-600 mt-2 bg-opac-input px-4"
                                                       required/>
                                            </div>
                                        </div>

                                        <div className="my-4">
                                            <label for="email"
                                                   className="text-neutral-800 text-sm leading-normal mb-4 mx-4 ">Email</label>
                                            <input type="email" name="email" id="email"
                                                   placeholder="eg. tryon@tryonlabs.ai"
                                                   value={email}
                                                   onChange={(e) => {
                                                       setEmail(e.target.value)
                                                   }}
                                                   className="w-full h-10 rounded-[118px] border border-stone-400 focus:border-rose-600 mt-2 bg-opac-input px-4"
                                                   required/>
                                        </div>
                                        <div className="my-4">
                                            <label for="password"
                                                   className="text-neutral-800 text-sm leading-normal mb-4 mx-4 ">Password</label>
                                            <div className="relative flex justify-between items-center">
                                                <input type={ passwordVisible ? "text" : "password"} name="password" id="password"
                                                       placeholder="*********"
                                                       value={password}
                                                       className="w-full h-10 rounded-[118px] border border-stone-400 focus:border-rose-600 mt-2 bg-opac-input px-4"
                                                       onChange={(e) => {
                                                           setPassword(e.target.value)
                                                       }}
                                                       required/>
                                                <img onClick={togglePasswordVisibility} src={passwordVisible ? showIcon : HideIcon} alt="hide icon" className="absolute top-5 right-4"/>
                                            </div>
                                        </div>

                                        <div className="flex justify-start items-center gap-4 mx-2">
                                            <input type="checkbox" checked={policyChecked} required onChange={(e) => {
                                                console.log(e.target.checked)
                                                setPolicyChecked(e.target.checked)
                                            }}/>

                                            <div className="ml-2 flex h-fit">
                                                <span className="text-[#424242] text-xs leading-[18px]">I Agree To TryonLabs'&nbsp;
                                                <a href="https://prod.server.tryonlabs.ai/terms_and_conditions/" target="_blank" className="text-[#BE3144] underline cursor-pointer">Privacy Policy</a>.
                                                </span>
                                            </div>
                                        </div>

                                        <button type="submit"
                                                onClick={handleSignup}
                                                className="w-full h-12 px-[113px] cursor-pointer py-4 bg-gradient-to-r from-[#F05941] to-[#BE3144] rounded-[50px] border border-red-200 hover:border-red-600 backdrop-blur-[100px] justify-center items-center gap-2.5 inline-flex my-6">
                                            {spinner ?
                                                <Spinner />
                                                :
                                                <div
                                                    className="text-[#F9E0E0] text-base font-semibold leading-normal">Sign up
                                                </div>
                                            }
                                        </button>

                                        <div className="text-center">
                                            <span
                                                className="text-[#424242] text-sm leading-normal">Already a member? </span>
                                            <a href="#" onClick={()=>{navigate("/signin")}}
                                               className="text-[#BE3144] text-sm underline leading-normal cursor-pointer">Sign
                                                in</a>
                                        </div>

                                        {showError ? (
                                            <p className="mt-4 text-sm text-red-600 dark:text-red-400 text-center">
                                                {errorMessage}
                                                {showVerify && (
                                                    <>
                                                        {" "}
                                                        <div
                                                            onClick= {(e) => {
                                                                e.preventDefault()
                                                                navigateVerify()
                                                            }}
                                                            // target="_blank"
                                                            // rel="noopener noreferrer"
                                                            className="text-blue-600 underline cursor-pointer"
                                                        >
                                                            Click here to verify.
                                                        </div>
                                                    </>
                                                )}
                                            </p>
                                        ) : (
                                            <div></div>
                                        )}


                                    </form>
                                </div>
                            </div>

                            {/*    <div*/}
                            {/*        className="hidden w-full max-w-lg p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700 overflow-y-auto">*/}
                            {/*        <form className="space-y-6" action="#">*/}
                            {/*            <h5 className="text-xl font-medium text-gray-900 dark:text-white">Sign Up</h5>*/}
                            {/*            <div className="flex items-center">*/}
                            {/*                {*/}
                            {/*                    logoPreview ? (*/}
                            {/*                        <div className="bg-[#00C1ED] p-0.5 w-20 h-20 rounded-full mr-6" style={{*/}
                            {/*                            backgroundImage: `url(${logoPreview})`,*/}
                            {/*                            backgroundSize: 'cover',*/}
                            {/*                            backgroundPosition: 'center',*/}
                            {/*                        }}></div>*/}
                            {/*                    ) : (*/}
                            {/*                        <div*/}
                            {/*                            className=" bg-white p-7 rounded-full mr-6 border border-[#7279CC] border-dashed">*/}
                            {/*                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"*/}
                            {/*                                 xmlns="http://www.w3.org/2000/svg">*/}
                            {/*                                <path*/}
                            {/*                                    d="M7.5 7.5C8.19036 7.5 8.75 6.94036 8.75 6.25C8.75 5.55964 8.19036 5 7.5 5C6.80964 5 6.25 5.55964 6.25 6.25C6.25 6.94036 6.80964 7.5 7.5 7.5Z"*/}
                            {/*                                    fill="#3B3F6A"/>*/}
                            {/*                                <path*/}
                            {/*                                    d="M20 15H17.5V12.5H15V15H12.5V17.5H15V20H17.5V17.5H20V15Z"*/}
                            {/*                                    fill="#3B3F6A"/>*/}
                            {/*                                <path*/}
                            {/*                                    d="M10 17.5H2.5V2.5H17.5V10H20V1.25C20 0.918479 19.8683 0.600537 19.6339 0.366117C19.3995 0.131696 19.0815 0 18.75 0L1.25 0C0.918479 0 0.600537 0.131696 0.366117 0.366117C0.131696 0.600537 0 0.918479 0 1.25L0 18.75C0 19.0815 0.131696 19.3995 0.366117 19.6339C0.600537 19.8683 0.918479 20 1.25 20H10V17.5Z"*/}
                            {/*                                    fill="#3B3F6A"/>*/}
                            {/*                                <path*/}
                            {/*                                    d="M14.535 11.5712L12.5 7.5L8.75 12.5L6.25 10L3.75 15H11.4262C11.6285 14.2197 12.017 13.5001 12.5585 12.9029C13.0999 12.3057 13.7782 11.8487 14.535 11.5712Z"*/}
                            {/*                                    fill="#3B3F6A"/>*/}
                            {/*                            </svg>*/}
                            {/*                        </div>*/}
                            {/*                    )*/}
                            {/*                }*/}
                            {/*                <div className=" flex flex-col">*/}
                            {/*                    <div className=" flex items-center mb-4 cursor-pointer">*/}
                            {/*                        <p className=" text-bright-blue mr-4"*/}
                            {/*                           onClick={handleProjectLogoFileUpload}>*/}
                            {/*                            {*/}
                            {/*                                logoPreview ? "Change Logo" : "Choose File"*/}
                            {/*                            }</p>*/}
                            {/*                        {*/}
                            {/*                            logoPreview ? (*/}
                            {/*                                <div onClick={handleLogoDelete}>*/}
                            {/*                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none"*/}
                            {/*                                         xmlns="http://www.w3.org/2000/svg">*/}
                            {/*                                        <path*/}
                            {/*                                            d="M13.7 0.3C13.3 -0.1 12.7 -0.1 12.3 0.3L7 5.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L7 8.4L12.3 13.7C12.5 13.9 12.8 14 13 14C13.2 14 13.5 13.9 13.7 13.7C14.1 13.3 14.1 12.7 13.7 12.3L8.4 7L13.7 1.7C14.1 1.3 14.1 0.7 13.7 0.3Z"*/}
                            {/*                                            fill="#7279CC"/>*/}
                            {/*                                    </svg>*/}
                            {/*                                </div>*/}
                            {/*                            ) : (*/}
                            {/*                                <></>*/}
                            {/*                            )*/}
                            {/*                        } </div>*/}
                            {/*                    <input type="file"*/}
                            {/*                           ref={ProjectLogoRef}*/}
                            {/*                           id="file_input"*/}
                            {/*                           accept="image/*"*/}
                            {/*                           onChange={handleProjectLogoFileChange}*/}
                            {/*                           style={*/}
                            {/*                               {display: 'none'}*/}
                            {/*                           }/>*/}
                            {/*                    <p className=" font-mulish text-sm">.JPG, PNG, SVG*/}
                            {/*                        up to 8MB, Recommended size is 256x256px.</p>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <div>*/}
                            {/*                <label htmlFor="text"*/}
                            {/*                       className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First*/}
                            {/*                    Name</label>*/}
                            {/*                <input type="text"*/}
                            {/*                       value={first_name}*/}
                            {/*                       name="firstname"*/}
                            {/*                       id="firstname"*/}
                            {/*                       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"*/}
                            {/*                       placeholder="First Name"*/}
                            {/*                       onChange={(e) => {*/}
                            {/*                           setFirstName(e.target.value)*/}
                            {/*                       }}*/}
                            {/*                       required/>*/}
                            {/*            </div>*/}
                            {/*            <div>*/}
                            {/*                <label htmlFor="text"*/}
                            {/*                       className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last*/}
                            {/*                    Name</label>*/}
                            {/*                <input type="text"*/}
                            {/*                       value={last_name}*/}
                            {/*                       name="lastname"*/}
                            {/*                       id="lastname"*/}
                            {/*                       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"*/}
                            {/*                       placeholder="Last Name"*/}
                            {/*                       onChange={(e) => {*/}
                            {/*                           setLastName(e.target.value)*/}
                            {/*                       }}*/}
                            {/*                       required/>*/}
                            {/*            </div>*/}
                            {/*            <div>*/}
                            {/*                <label htmlFor="text"*/}
                            {/*                       className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>*/}
                            {/*                <input type="text"*/}
                            {/*                       value={username}*/}
                            {/*                       name="username"*/}
                            {/*                       id="username"*/}
                            {/*                       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"*/}
                            {/*                       placeholder="Username"*/}
                            {/*                       onChange={(e) => {*/}
                            {/*                           setUsername(e.target.value)*/}
                            {/*                       }}*/}
                            {/*                       required/>*/}
                            {/*            </div>*/}

                            {/*            <div>*/}
                            {/*                <label htmlFor="email"*/}
                            {/*                       className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>*/}
                            {/*                <input*/}
                            {/*                    type="email"*/}
                            {/*                    value={email}*/}
                            {/*                    name="email"*/}
                            {/*                    id="email"*/}
                            {/*                    placeholder="email"*/}
                            {/*                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"*/}
                            {/*                    required*/}
                            {/*                    onBlur={() => {*/}
                            {/*                        const newErrors = {...errors, email: ''};*/}
                            {/*                        if (!email) {*/}
                            {/*                            newErrors.email = 'Email is required.';*/}
                            {/*                        } else if (!/\S+@\S+\.\S+/.test(email)) {*/}
                            {/*                            newErrors.email = 'Email is not correct.';*/}
                            {/*                        }*/}
                            {/*                        setErrors(newErrors);*/}
                            {/*                    }}*/}
                            {/*                    onChange={(e) => {*/}
                            {/*                        setEmail(e.target.value)*/}
                            {/*                    }}*/}
                            {/*                />*/}
                            {/*                {errors.email &&*/}
                            {/*                    <p className="pointer-events-none animate-wiggle text-sm font-normal text-[#F05941] mt-2">{errors.email}</p>}*/}
                            {/*            </div>*/}
                            {/*            <div>*/}
                            {/*                <label htmlFor="text"*/}
                            {/*                       className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mobile </label>*/}
                            {/*                <input*/}
                            {/*                    type="text"*/}
                            {/*                    value={mobileNo}*/}
                            {/*                    name="mobile"*/}
                            {/*                    id="mobile"*/}
                            {/*                    placeholder="mobile"*/}
                            {/*                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"*/}
                            {/*                    required*/}
                            {/*                    onChange={(e) => {*/}
                            {/*                        setMobileNo(e.target.value)*/}
                            {/*                    }}*/}
                            {/*                />*/}
                            {/*            </div>*/}
                            {/*            <div>*/}
                            {/*                <label htmlFor="password"*/}
                            {/*                       className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>*/}
                            {/*                <input*/}
                            {/*                    type="password"*/}
                            {/*                    value={password}*/}
                            {/*                    name="password"*/}
                            {/*                    id="password"*/}
                            {/*                    placeholder="Password"*/}
                            {/*                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"*/}
                            {/*                    required*/}
                            {/*                    onChange={(e) => {*/}
                            {/*                        setPassword(e.target.value)*/}
                            {/*                    }}*/}
                            {/*                />*/}
                            {/*            </div>*/}

                            {/*            /!* <div className="flex items-start">*/}
                            {/*    <div className="flex items-start">*/}
                            {/*        <div className="flex items-center h-5">*/}
                            {/*            <input id="remember" type="checkbox" value="" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" required />*/}
                            {/*        </div>*/}
                            {/*        <label htmlFor="remember" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Remember me</label>*/}
                            {/*    </div>*/}
                            {/*    <a href="#" className="ms-auto text-sm text-blue-700 hover:underline dark:text-blue-500">Lost Password?</a>*/}
                            {/*</div> *!/*/}
                            {/*            <button type="submit" onClick={handleSignUp}*/}
                            {/*                    className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Sign*/}
                            {/*                Up*/}
                            {/*            </button>*/}
                            {/*            <div className="text-sm font-medium text-gray-500 dark:text-gray-300">*/}
                            {/*                Already registered? <a href="#"*/}
                            {/*                                       className="text-blue-700 hover:underline dark:text-blue-500"*/}
                            {/*                                       onClick={() => {*/}
                            {/*                                           navigate("/login")*/}
                            {/*                                       }}>Login</a>*/}
                            {/*            </div>*/}
                            {/*        </form>*/}
                            {/*    </div>*/}

                        </div>
                    )
                    :
                    (<div className="flex h-screen items-center justify-center">
                        <Spinner/>
                    </div>)
                }
            </div>
        </div>
    )
}
import React, {useCallback, useEffect, useState} from "react";
import axios from 'axios';
import {useLocation, useNavigate} from "react-router-dom";
import {TRYON_SERVER_URL} from "../config";
import {useDispatch, useSelector} from 'react-redux';
import {setAccessToken, setRefreshToken, setUserData, setUserID} from "../store/AuthActions";
import {useAuthentication} from "../Authentication";
import {selectAccessToken, selectAuthenticated} from "../selectors";
import Spinner from "../components/Spinner";
import TryonLabsLogo from "../assets/TryonLabsLogo.png";
import GoogleIcon from "../assets/GoogleIcon.svg";
import HRLine from "../assets/HRLine.svg";
import HideIcon from "../assets/HideIcon.svg"; 
import showIcon from "../assets/showIcon.svg";
import landingbg1 from "../assets/bgGradient.svg";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';


export default function Signin() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {isAuthenticated} = useAuthentication();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const authenticated = useSelector(selectAuthenticated)
    const [showError, setShowError] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState("This is an error");
    const location = useLocation();
    const [showVerify, setShowVerify] = useState(false);
    const [ user, setUser ] = useState([]);
    const [ profile, setProfile ] = useState([]);
    const [ spinner, setSpinner ] = useState(false);

    console.log("Redirected from:", location)

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
      };

    const fetchUserData = async (accessToken) => {
        try {
            console.log("access in fetch data", accessToken)
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/user/get/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                const userid = data?.id
                console.log("userid", userid)
                dispatch(setUserID(userid));
                const dataWithoutPassword = {...data, password: undefined}; // Omit the password field
                dispatch(setUserData(JSON.stringify(dataWithoutPassword)));
            } else {
                console.error('Failed to fetch user data');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);

        }
    };

    const submitForm = async () => {
        // set error message to null
        setErrorMessage("");
        setShowError(false);

        // check username and password
        if (username === "" || password === "") {
            setErrorMessage("Enter valid username and password");
            setShowError(true);
            setSpinner(false);
            return;
        }

        try {
            const formData = new FormData();
            formData.append('email', username);
            formData.append('password', password);

            await loginUser(formData);
        } catch (error) {
            console.log(error)
            setSpinner(false)
            setErrorMessage(error?.response?.data?.message)
            setShowError(true)
        }
    };

    const registerUser = async (formData) => {
        try {
            const response = await axios.post(`${TRYON_SERVER_URL}/api/v1/user/create/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 201) {
                navigate("/signin");
                console.log('User registered successfully:', response.data);
            } else {
                setShowError(true)
                setErrorMessage("Unable to create an account.")
            }
        } catch (error) {
            console.error('Error while registering user:', error?.response?.data?.message);
            setShowError(true)
            setErrorMessage(error?.response?.data?.message)
            throw error;
        }
    };

    const loginUser = async (formData) => {
        try {
            // api/v1/user/verify/
            const response = await axios.post(`${TRYON_SERVER_URL}/api/v1/user/token/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log("status:", response)
            const {access, refresh} = response.data;
            console.log('Access token received:', access);
            localStorage.clear();
            sessionStorage.clear();
            dispatch(setAccessToken(access));
            dispatch(setRefreshToken(refresh));
            isAuthenticated().then(r => {
            });
            fetchUserData(access).then(r => {
            })
            // navigate to playground
            if (location.state && location.state.path) {
                navigate(location.state.path);
            } else {
                navigate("/");
            }
            setSpinner(false);
        } catch (error) {
            // setErrorMessage(error?.response?.data?.message)
            // setShowError(true)
            // console.log(error)
            if(error?.response?.data?.is_active === false){
                setShowVerify(true);
            }
            throw error;
        }
    };

    const handleSignin = (e) => {
        e.preventDefault();
        setSpinner(true);
        submitForm();
    };

    const regenerateOtp = async (email) => {
        try {
            // Simulating an API call for OTP regeneration
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/user/otp/regenerate`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: email }),
            });

            if (!response.ok) {
                throw new Error("Failed to regenerate OTP. Please try again.");
            }

            // setOtpRegenerated(true);
            setErrorMessage(""); // Clear any previous errors
        } catch (error) {
            setShowError(true);
            setErrorMessage(error.message);
            // setOtpRegenerated(false);
        }
    };

    const navigateVerify = async() => {
        console.log("profile", profile);
        
        if (profile && Object.keys(profile).length > 0) {
            console.log("navigated thru profile");
            await regenerateOtp(profile.email);
            navigate("/verify", {
                state: {
                    email: profile.email,
                    password: profile.id,
                },
            });
        } else {
            console.log("navigated thru username/password");
            await regenerateOtp(username);
            navigate("/verify", {
                state: {
                    email: username,
                    password: password,
                },
            });
        }
    };
        
    const accessToken = useSelector(selectAccessToken)

    const checkAuthentication = useCallback(async () => {
        try {
            const userAuthenticated = await isAuthenticated();
            if (userAuthenticated) {
                if(location.state !== null) {
                    navigate(location.state.path)
                }else {
                    navigate("/");
                }
            }
        } catch (error) {
            console.error('Error checking authentication:', error);

            if (error.message && error.message.toLowerCase().includes('network')) {
                console.error('Network error, redirecting to login page');
                navigate("/login");
            }
        }
    }, [dispatch, isAuthenticated, navigate]);

    useEffect(() => {
        console.log("Authenticated:", authenticated)
        if (!authenticated) {
            checkAuthentication();
        }
    }, []);

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(
        () => {
            if (user) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        setProfile(res.data);
                        console.log("data from gooogelogin", res.data)
                    })
                    .catch((err) => console.log(err));
            }
        },
        [ user ]
    );

    useEffect(() => {
        const registerOrLogin = async () => {
            if (profile) {
                try {
                    const loginFormData = new FormData();
                    loginFormData.append('email', profile.email);
                    loginFormData.append('password', profile.id);

                    await loginUser(loginFormData);

                } catch (loginError) {
                    console.log(loginError.response)
                    if (loginError.response && loginError.response.status === 401) {
                        // User does not exist, attempt to register
                        try {
                            const registerFormData = new FormData();
                            const baseUsername = profile.given_name.replace(/[^a-zA-Z0-9@/./+/-/_]/g, '');
                            const uniqueUsername = `${baseUsername}${Date.now()}`;
                            registerFormData.append('username', uniqueUsername);
                            registerFormData.append('first_name', profile.given_name);
                            registerFormData.append('last_name', profile.family_name);
                            registerFormData.append('email', profile.email);
                            registerFormData.append('password', profile.id);

                            await registerUser(registerFormData);

                            navigate("/verify", {
                                state: {
                                    username: uniqueUsername,
                                    first_name: profile.given_name,
                                    last_name: profile.family_name,
                                    email: profile.email,
                                    password: profile.id,
                                },
                            });

                            // Now try to login again
                            // const loginFormData2 = new FormData();
                            // loginFormData2.append('username', profile.email);
                            // loginFormData2.append('password', profile.id);
                            // await loginUser(loginFormData2);
                        } catch (registerError) {
                            console.error('Error while registering user:', registerError?.response?.data?.message);
                            setShowError(true)
                            setErrorMessage(registerError?.response?.data?.message)
                        }
                    } else {
                        console.error('Error while logging in user:', loginError?.response?.data?.message);
                        setShowError(true)
                        setErrorMessage(loginError?.response?.data?.message)
                    }
                }
            }
        };

        registerOrLogin();
    }, [profile]);

    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        googleLogout();
        setProfile(null);
    };


    return (
        <div>
            <div className="flex w-full h-screen items-center justify-center overflow-hidden" style={{ backgroundImage: `url(${landingbg1})`, backgroundSize: 'cover'}} >
                {!authenticated ?
                    (
                        <div className=" flex m-4 w-full items-center justify-center">

                            <div className="max-w-lg bg-inherit rounded-2xl shadow-auth-box-shadow backdrop-blur-[15px]">
                                <div className=" w-full rounded-tl-2xl rounded-tr-2xl bg-opac-auth shadow-navbarShadow backdrop-blur-[15px]">
                                    <div className="flex justify-center gap-4 items-center py-10 px-24">
                                        <img src={TryonLabsLogo} alt="tryonlabs logo" className="w-12 h-auto"/>
                                        <div
                                            className="text-gray-900 text-xl font-medium font-Readex_pro ">Tryon Labs
                                        </div>
                                    </div>
                                </div>

                                <div className="p-6">
                                    <div onClick={login}
                                        className="flex justify-center items-center gap-2 py-2 rounded-[118px] bg-opac-auth border border-rose-200 hover:border-rose-600 text-center cursor-pointer">

                                        <img src={GoogleIcon} alt="google icon"/> Sign in with Google
                                    </div>

                                    <div
                                        className="flex justify-center items-center bg-repeat-x bg-center text-center gap-2 py-6">
                                        <img src={HRLine} alt="hrline"/>
                                        <div
                                            className="text-[#424242] text-sm leading-normal">or
                                            use email to sign in
                                        </div>
                                        <img src={HRLine} alt="hrline"/>
                                    </div>

                                    <form action="#">
                                        <div className="mb-4">
                                            <label htmlFor="email"
                                                   className="text-neutral-800 text-sm leading-normal mb-4 mx-4">Email</label>
                                            <input type="email" name="email" id="email"
                                                   placeholder="eg. tryon@tryonlabs.ai"
                                                   value={username}
                                                   onChange={(e) => {
                                                       setUsername(e.target.value)
                                                   }}
                                                   className="w-full h-10 rounded-[118px] border border-stone-400 focus:border-rose-600 mt-2 bg-opac-input px-4"
                                                   required/>
                                        </div>
                                        <div className="my-4">
                                            <label htmlFor="password"
                                                   className="text-neutral-800 text-sm leading-normal mb-4 mx-4">Password</label>
                                            <div className="relative flex justify-between items-center">
                                                <input type={ passwordVisible ? "text" : "password"} name="password" id="password"
                                                       placeholder="*********"
                                                       value={password}
                                                       onChange={(e) => {
                                                           setPassword(e.target.value)
                                                       }}
                                                       className="w-full h-10 rounded-[118px] border border-stone-400 focus:border-rose-600 mt-2 bg-opac-input px-4"
                                                       required/>
                                                <img onClick={togglePasswordVisibility} src={passwordVisible ? showIcon : HideIcon} alt="hide icon" className="absolute top-5 right-4 cursor-pointer"/>
                                            </div>
                                        </div>

                                        <div className="flex items-start mt-4 mb-2 mx-2">
                                            <div className="flex items-center h-5">
                                                <input id="remember" type="checkbox" value=""
                                                       className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                                                       required/>
                                            </div>
                                            <label htmlFor="remember"
                                                   className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Remember
                                                me</label>
                                        </div>

                                        <button type="submit" onClick={handleSignin}
                                            className="w-full h-12 px-[113px] cursor-pointer py-4 bg-gradient-to-r from-[#F05941] to-[#BE3144] rounded-[50px] border border-red-200 hover:border-red-600 backdrop-blur-[100px] justify-center items-center gap-2.5 inline-flex mb-6 mt-4">
                                            {spinner ?
                                                <Spinner />
                                                :
                                                <div
                                                    className="text-[#F9E0E0] text-base font-semibold leading-normal">Sign in
                                                </div>
                                            }
                                        </button>

                                        <div
                                            className="text-[#424242] text-sm leading-normal cursor-pointer text-center">Forgot
                                            Password?
                                        </div>

                                        <div className="my-4 text-center"><span
                                            className="text-[#424242] text-sm leading-normal">Don’t have an account? </span>
                                            <a href="#"
                                               onClick={() => {
                                                   navigate("/signup")
                                               }}
                                               className="text-[#BE3144] text-sm underline leading-normal cursor-pointer">Sign
                                                up</a>
                                        </div>

                                        {showError ? (
                                            <p className="mt-4 text-sm text-red-600 dark:text-red-400 text-center">
                                                {errorMessage}
                                                {showVerify && (
                                                    <>
                                                        {" "}
                                                        <div
                                                            onClick= {(e) => {
                                                                e.preventDefault()
                                                                navigateVerify()
                                                            }}
                                                            // target="_blank"
                                                            // rel="noopener noreferrer"
                                                            className="text-blue-600 underline cursor-pointer"
                                                        >
                                                            Click here to verify.
                                                        </div>
                                                    </>
                                                )}
                                            </p>
                                        ) : (
                                            <div></div>
                                        )}


                                    </form>
                                </div>
                            </div>
                        </div>)
                    :
                    (<div className="flex h-screen items-center justify-center">
                        <Spinner/>
                    </div>)
                }
            </div>
        </div>
    )
}
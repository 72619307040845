import React, { useState, useEffect } from 'react';
import { HiAdjustments } from "react-icons/hi";
import { PiImagesLight, PiVideoLight, PiCubeLight } from "react-icons/pi";
import { FaDice, FaRegImages } from "react-icons/fa";
import {TRYON_SERVER_URL} from '../config';
import { useSelector } from 'react-redux';
import { selectAccessToken, selectAuthenticated } from "../selectors";
import ToastErrorMessage from "../components/ToastErrorMessage";
import Spinner from '../components/Spinner';
import { fetchExperiments } from '../Utils/ExperimentUtils';
import { handleDownload } from '../Utils/DownloadUtils';
import errorIcon from "../assets/errorIcon.svg";
import GradientFont from "../components/GradientFont";
import { useNavigate } from 'react-router-dom';
import AuthenticationRequired from '../components/AuthenticationRequired';  
import Joyride, { STATUS } from "react-joyride";

export default function Generate({selectedPrompt, setShowPromptGalleryModal}) {
    const [activeTab, setActiveTab] = useState('Images');
    const [showAdvanced, setShowAdvanced] = useState(false);
    const defaultImageSettings = {
        model: '',
        prompt: selectedPrompt ? selectedPrompt : '',
        width: 768,
        height: 1024,
        guidance: 3,   
        steps: 28,
        seed: 42,
        prompt_upsampling: false,
        safety_tolerance: 2,
        interval: 2,
        aspect_ratio: "9:16",
        raw: false
    }
    const navigate = useNavigate();

    const defaultModelSettings = {
        "flux-dev": {
            width: {type: "integer", min: 256, max: 1024, step: 32, default: 1024},
            height: {type: "integer", min: 256, max: 1024, step: 32, default: 768},
            guidance: {type: "float", min: 1.5, max: 5, step: 0.1, default: 3},
            steps: {type: "integer", min: 1, max: 50, step: 1, default: 28},
            seed: {type: "integer", min: 0, max: 1000000, step: 1, default: 42},
            prompt_upsampling: {type: "boolean", default: false},
            safety_tolerance: {type: "integer", min: 0, max: 6, step: 1, default: 2}
        },
        "flux-pro": {
            width: {type: "integer", min: 256, max: 1440, step: 32, default: 1024},
            height: {type: "integer", min: 256, max: 1440, step: 32, default: 768},
            steps: {type: "integer", min: 1, max: 50, step: 1, default: 40},
            seed: {type: "integer", min: 0, max: 1000000, step: 1, default: 42},
            prompt_upsampling: {type: "boolean", default: false},
            guidance: {type: "float", min: 1.5, max: 5, step: 0.1, default: 2.5},
            safety_tolerance: {type: "integer", min: 0, max: 6, step: 1, default: 2},
            interval: {type: "integer", min: 1, max: 4, step: 1, default: 2}
        },
        "flux-pro-1.1": {
            width: {type: "integer", min: 256, max: 1440, step: 32, default: 1024},
            height: {type: "integer", min: 256, max: 1440, step: 32, default: 768},
            seed: {type: "integer", min: 0, max: 1000000, step: 1, default: 42},
            prompt_upsampling: {type: "boolean", default: false},
            safety_tolerance: {type: "integer", min: 0, max: 6, step: 1, default: 2}
        },
        "flux-pro-1.1-ultra": {
            seed: {type: "integer", min: 0, max: 1000000, step: 1, default: 42},
            aspect_ratio: {type: "string", default: "21:9", options: ["9:16", "1:1", "21:9", "16:9", "9:21", "3:4", "4:3"]},
            prompt_upsampling: {type: "boolean", default: false},
            safety_tolerance: {type: "integer", min: 0, max: 6, step: 1, default: 2},
            raw: {type: "boolean", default: false}
        }
    }

    const [imageSettings, setImageSettings] = useState(defaultImageSettings);
    const accessToken = useSelector(selectAccessToken) || null;
    const authenticated = useSelector(selectAuthenticated);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorStatus, setErrorStatus] = useState('');
    const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [isGenerating, setIsGenerating] = useState(false);
    const [experimentsFetched, setExperimentsFetched] = useState(false);
    const [experiments, setExperiments] = useState([]);
    const [fetchingExperiments, setFetchingExperiments] = useState({});
    const [pendingOrRunningExperimentIds, setPendingOrRunningExperimentIds] = useState([]);
    const [fetchingExperimentsFromServer, setFetchingExperimentsFromServer] = useState(false);
    const [selectedExperiment, setSelectedExperiment] = useState(null);
    const flux_models_mapping = {
        "flux-dev": "FLUX.1-dev",
        "flux-pro": "FLUX.1-pro",
        "flux-pro-1.1": "FLUX.1.1-pro",
        "flux-pro-1.1-ultra": "FLUX.1.1-pro-ultra"
    }
    const costSettings = {
        "flux-dev": 3,
        "flux-pro": 6,
        "flux-pro-1.1": 5,
        "flux-pro-1.1-ultra": 7
    }

    useEffect(() => {
        console.log("accessToken:", accessToken)
        console.log("authenticated:", authenticated)

        if (!experimentsFetched) {
            console.log("fetching experiments");
            if(accessToken && authenticated){
                fetchExperiments1().then(() => {
                    console.log("experiments fetched");
                })
            }
        }
    }, [experiments, accessToken, authenticated]);

    useEffect(() => {
        console.log("selectedPrompt:", selectedPrompt)
        if(selectedPrompt){
            handleSettingChange('prompt', selectedPrompt)
        }
    }, [selectedPrompt])

    const fetchExperiments1 = async () => {
        setFetchingExperimentsFromServer(true);
        const response = await fetchExperiments({aimodel: "generate_ai", access: "private"}, accessToken)
        if(response.status === "success"){
            setExperiments(response.data);
            setExperimentsFetched(true);
            setFetchingExperimentsFromServer(false);
            // add ids of pending or running experiments to the pendingOrRunningExperimentIds array
            let pendingOrRunningExperimentIds = response.data.filter((result) => result.status === "pending" || result.status === "running").map((result) => result.id); 
            console.log("pending or running experiment ids", pendingOrRunningExperimentIds);
            setPendingOrRunningExperimentIds(pendingOrRunningExperimentIds);
        }else{
            setFetchingExperimentsFromServer(false);
            console.error("Failed to fetch experiments");
        }
    }

    const handleSettingChange = (setting, value) => {
        // For width and height, ensure they're multiples of 32 and within bounds
        if (setting === 'width' || setting === 'height') {
            const numValue = parseInt(value);
            const modelConfig = defaultModelSettings[imageSettings.model] || {};
            const paramConfig = modelConfig[setting] || {};
            
            if (numValue < (paramConfig.min || 256)) value = paramConfig.min || 256;
            if (numValue > (paramConfig.max || 1024)) value = paramConfig.max || 1024;
            value = Math.round(numValue / (paramConfig.step || 32)) * (paramConfig.step || 32);
        }
        
        // If model is changed, update settings based on the selected model's defaults
        if (setting === 'model' && value !== imageSettings.model) {
            const modelConfig = defaultModelSettings[value] || {};
            const newSettings = { ...imageSettings, model: value };
            
            // Apply default values from the selected model
            Object.keys(modelConfig).forEach(param => {
                newSettings[param] = modelConfig[param].default;
            });
            
            setImageSettings(newSettings);
            return;
        }
        
        setImageSettings(prev => ({
            ...prev,
            [setting]: value
        }));
    };

    const generateRandomSeed = () => {
        handleSettingChange('seed', Math.floor(Math.random() * 1000000));
    };

    const handleGenerate = async () => {
        console.log("Image generation settings: ", imageSettings);

        setIsGenerating(true);
        setShowToastErrorMessage(false);
        setErrorMessage('');
        setErrorStatus('');

        if (!authenticated) {
            setErrorMessage('You must be logged in to generate images');
            setErrorStatus('Error');
            setShowToastErrorMessage(true);
            setIsGenerating(false);
            return;
        }

        if(imageSettings.prompt === '') {
            setErrorMessage('Please provide a prompt to generate an image');
            setErrorStatus('Error');
            setShowToastErrorMessage(true);
            setIsGenerating(false);
            return;
        }

        if(imageSettings.model === '') {
            setErrorMessage('Please select a model to use for generation');
            setErrorStatus('Error');
            setShowToastErrorMessage(true);
            setIsGenerating(false);
            return;
        }

        try{
            // select relevant values from imageSettings for this particular model
            const modelConfig = defaultModelSettings[imageSettings.model] || {};
            const relevantSettings = Object.keys(modelConfig).reduce((acc, key) => {
                // Include all parameters that exist in the model config, regardless of type
                if (imageSettings[key] !== undefined) {
                    acc[key] = imageSettings[key];
                }
                return acc;
            }, {});
            
            // Add required parameters that might not be in the model config
            relevantSettings.prompt = imageSettings.prompt;
            relevantSettings.model = imageSettings.model;
            
            console.log("Relevant settings: ", relevantSettings);
  
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/flux/generate/`, {
                method: 'POST',
                body: JSON.stringify(relevantSettings),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if(!response.ok) {
                setErrorMessage('Failed to generate image: ' + response.statusText);
                setErrorStatus('Error');
                setShowToastErrorMessage(true);
                setIsGenerating(false);
                return;
            }

            const data = await response.json();
            console.log("Image generation response: ", data);
            console.log("Experiment created successfully", data);
            let experiments1 = [...[data], ...experiments]
            setExperiments(experiments1)
            setSelectedExperiment(data);
            
            // add the experiment id to the pendingOrRunningExperimentIds array
            setPendingOrRunningExperimentIds((prevExperimentIds) => [...prevExperimentIds, data.id]);

            const imageUrl = `${TRYON_SERVER_URL}/${data.result.image_url}`;
            console.log("Image URL: ", imageUrl);

            // reset image settings to default
            setImageSettings(defaultImageSettings);

            setImageUrl(imageUrl);
            setIsGenerating(false);
        }catch(error){
            console.error("Error generating image: ", error);
            setErrorMessage('Failed to generate image: ' + error.message);
            setErrorStatus('Error');
            setShowToastErrorMessage(true);
            setIsGenerating(false);
        }
    };

    const handleReimagine = (experimentId) => {
        console.log("Reimagining image with experiment id: ", experimentId);

        // open /reimagine with a get parameter for the result image id
        window.location.href = `/reimagineai?id=${experimentId}`;
    }

    const steps = [
        {
            target: ".select_type",
            content: "Select the type of data you want to generate. eg. image, video, 3D model, etc.",
            disableBeacon: true,
        },
        {
            target: ".enter_prompt",
            content: "Enter a prompt to generate an image/video/3D model. Please describe what you want to see in detail to get the best results.",
            disableBeacon: true,
        },
        {
            target: ".browse_prompt_gallery",
            content: "Click here to browse the prompt gallery",
            disableBeacon: true,
        },
        {
            target: ".select_model",
            content: "Select the model you want to use for generation.",
            disableBeacon: true,
        },
        {
            target: ".estimated_cost",
            content: "See the estimated cost of the generation. The cost is based on the model you selected.",
            disableBeacon: true,
        }, 
        {
            target: ".advanced_settings",
            content: "Toggle advanced settings to customize the generation. Select the model first to see the available settings.",
            disableBeacon: true,
        },
        {
            target: ".generate_button",
            content: "Click the generate button to generate an image/video/3D model. The generation will start in a few seconds.",
            disableBeacon: true,
        }, 
        {
            target: ".generated_images",
            content: "See the generated images/videos/3D models. You can click on the image/video/3D model to see the full size.",
            disableBeacon: true,
        },
        {
            target: ".generated_preview",
            content: "See the generated preview image/video/3D model. This section shows the generated image/video/3D model in a preview size with the parameters used for generation.",
            disableBeacon: true,
        }
    ]

    const handleJoyrideCallback = (data) => {
        const { step, status } = data;
        console.log(step, status)

        if ([STATUS.FINISHED].includes(status)) {
            localStorage.setItem("GENERATE_JOYRIDE_STATUS", "finished")
        }
    };

    const tabs = [
        {
            name: 'Images',
            icon: <PiImagesLight className="w-5 h-5" />,
            content: (
                <div className="space-y-5 py-2">
                    {/* Prompt Input */}
                    <div className="space-y-2 enter_prompt">
                        <label className="text-sm font-medium">Prompt</label>
                        <textarea 
                            placeholder="Enter your prompt here! You can generate a garment, model, or anything else you can imagine. Please describe what you want to see in detail to get the best results."
                            value={imageSettings.prompt}
                            onChange={(e) => handleSettingChange('prompt', e.target.value)}
                            className="w-full px-4 py-3 bg-white rounded-lg border border-gray-200 text-base text-gray-900 font-medium focus:ring-2 focus:ring-rose-500/20 focus:border-rose-500 outline-none resize-none h-24 shadow-sm placeholder-gray-400"
                        />

                        <button 
                            onClick={() => setShowPromptGalleryModal(true)}
                            className="browse_prompt_gallery inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-primary-600 bg-primary-50 hover:bg-primary-100 border border-primary-200 rounded-lg shadow-sm hover:shadow-md transition-all duration-200"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
                            </svg>
                            Browse Prompt Gallery
                        </button>
                    </div>

                    {/* Model Selection */}
                    <div className="flex flex-col gap-2 select_model">
                        <select 
                            value={imageSettings.model}
                            onChange={(e) => handleSettingChange('model', e.target.value)}
                            className="flex-1 px-4 py-2.5 rounded-lg border border-gray-200 bg-white text-sm focus:ring-2 focus:ring-rose-500/20 focus:border-rose-500 outline-none shadow-sm"
                        >
                            <option value="">Select model to use for generation</option>
                            {Object.keys(flux_models_mapping).map((model) => (
                                <option key={model} value={model}>{flux_models_mapping[model]}</option>
                            ))}
                        </select>
                    </div>

                    {/* Estimated Cost */}
                    {imageSettings.model ? (
                        <div className="estimated_cost flex items-center justify-between p-3 bg-gradient-to-r from-amber-50 to-rose-50 border border-amber-100 rounded-lg shadow-sm">
                            <div className="flex items-center gap-2">
                                <svg className="w-5 h-5 text-amber-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span className="text-sm font-medium text-gray-700">Estimated Cost</span>
                            </div>
                            <div className="flex items-center">
                                <span className="text-lg font-bold text-amber-600">{costSettings[imageSettings.model]}</span>
                                <span className="ml-1 text-sm font-medium text-gray-600">Credits</span>
                            </div>
                        </div>
                    ) : (
                        <div className="estimated_cost flex items-center justify-between p-3 bg-gray-50 border border-gray-100 rounded-lg">
                            <div className="flex items-center gap-2">
                                <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span className="text-sm font-medium text-gray-500">Estimated Cost</span>
                            </div>
                            <span className="text-sm text-gray-400">Select a model</span>
                        </div>
                    )}

                    {/* Advanced Settings Toggle */}
                    <button
                        onClick={() => setShowAdvanced(!showAdvanced)}
                        className="advanced_settings flex items-center gap-2 text-sm font-medium text-gray-600 hover:text-rose-500 transition-colors duration-200 py-1.5"
                    >
                        <HiAdjustments className={`w-5 h-5 transition-transform duration-200 ${showAdvanced ? 'rotate-180' : ''}`} />
                        {showAdvanced ? 'Hide Advanced Settings' : 'Show Advanced Settings'}
                    </button>

                    {/* Advanced Settings Section */}
                    <div className={`space-y-5 overflow-hidden transition-all duration-300 ${showAdvanced ? 'max-h-[600px] opacity-100' : 'max-h-0 opacity-0'}`}>
                        {imageSettings.model ? (
                            <>
                                {/* Image Dimensions */}
                                {(defaultModelSettings[imageSettings.model]?.width && defaultModelSettings[imageSettings.model]?.height) && (
                                    <div className="grid grid-cols-2 gap-4">
                                        <div className="space-y-2">
                                            <label className="text-sm font-medium text-gray-700 flex justify-between">
                                                Width
                                                <span className="text-gray-500 font-normal">{imageSettings.width}px</span>
                                            </label>
                                            <input 
                                                type="number" 
                                                min={defaultModelSettings[imageSettings.model]?.width?.min || 256}
                                                max={defaultModelSettings[imageSettings.model]?.width?.max || 1024}
                                                step={defaultModelSettings[imageSettings.model]?.width?.step || 32}
                                                value={imageSettings.width}
                                                onChange={(e) => handleSettingChange('width', e.target.value)}
                                                className="w-full px-4 py-2 rounded-lg border border-gray-200 bg-white text-sm focus:ring-2 focus:ring-rose-500/20 focus:border-rose-500 outline-none shadow-sm"
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <label className="text-sm font-medium text-gray-700 flex justify-between">
                                                Height
                                                <span className="text-gray-500 font-normal">{imageSettings.height}px</span>
                                            </label>
                                            <input 
                                                type="number" 
                                                min={defaultModelSettings[imageSettings.model]?.height?.min || 256}
                                                max={defaultModelSettings[imageSettings.model]?.height?.max || 1024}
                                                step={defaultModelSettings[imageSettings.model]?.height?.step || 32}
                                                value={imageSettings.height}
                                                onChange={(e) => handleSettingChange('height', e.target.value)}
                                                className="w-full px-4 py-2 rounded-lg border border-gray-200 bg-white text-sm focus:ring-2 focus:ring-rose-500/20 focus:border-rose-500 outline-none shadow-sm"
                                            />
                                        </div>
                                    </div>
                                )}

                                {/* Aspect Ratio (for models that support it) */}
                                {defaultModelSettings[imageSettings.model]?.aspect_ratio && (
                                    <div className="space-y-2">
                                        <label className="text-sm font-medium text-gray-700">Aspect Ratio</label>
                                        <select
                                            value={imageSettings.aspect_ratio}
                                            onChange={(e) => handleSettingChange('aspect_ratio', e.target.value)}
                                            className="w-full px-4 py-2 rounded-lg border border-gray-200 bg-white text-sm focus:ring-2 focus:ring-rose-500/20 focus:border-rose-500 outline-none shadow-sm"
                                        >
                                            {defaultModelSettings[imageSettings.model].aspect_ratio.options.map(option => (
                                                <option key={option} value={option}>{option}</option>
                                            ))}
                                        </select>
                                    </div>
                                )}

                                {/* Sliders Section */}
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    {/* Guidance Scale */}
                                    {defaultModelSettings[imageSettings.model]?.guidance && (
                                        <div className="space-y-2">
                                            <label className="text-sm font-medium text-gray-700 flex justify-between">
                                                Guidance Scale
                                                <span className="text-gray-500 font-normal">{imageSettings.guidance}</span>
                                            </label>
                                            <input 
                                                type="range" 
                                                min={defaultModelSettings[imageSettings.model]?.guidance?.min || 1}
                                                max={defaultModelSettings[imageSettings.model]?.guidance?.max || 20}
                                                step={defaultModelSettings[imageSettings.model]?.guidance?.step || 0.1}
                                                value={imageSettings.guidance}
                                                onChange={(e) => handleSettingChange('guidance', parseFloat(e.target.value))}
                                                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-rose-500"
                                            />
                                        </div>
                                    )}

                                    {/* Safety Tolerance */}
                                    {defaultModelSettings[imageSettings.model]?.safety_tolerance && (
                                        <div className="space-y-2">
                                            <label className="text-sm font-medium text-gray-700 flex justify-between">
                                                Safety Tolerance
                                                <span className="text-gray-500 font-normal">{imageSettings.safety_tolerance}</span>
                                            </label>
                                            <input 
                                                type="range" 
                                                min={defaultModelSettings[imageSettings.model]?.safety_tolerance?.min || 0}
                                                max={defaultModelSettings[imageSettings.model]?.safety_tolerance?.max || 5}
                                                step={defaultModelSettings[imageSettings.model]?.safety_tolerance?.step || 1}
                                                value={imageSettings.safety_tolerance}
                                                onChange={(e) => handleSettingChange('safety_tolerance', parseInt(e.target.value))}
                                                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-rose-500"
                                            />
                                        </div>
                                    )}

                                    {/* Interval (for models that support it) */}
                                    {defaultModelSettings[imageSettings.model]?.interval && (
                                        <div className="space-y-2">
                                            <label className="text-sm font-medium text-gray-700 flex justify-between">
                                                Interval
                                                <span className="text-gray-500 font-normal">{imageSettings.interval}</span>
                                            </label>
                                            <input 
                                                type="range" 
                                                min={defaultModelSettings[imageSettings.model]?.interval?.min || 1}
                                                max={defaultModelSettings[imageSettings.model]?.interval?.max || 4}
                                                step={defaultModelSettings[imageSettings.model]?.interval?.step || 1}
                                                value={imageSettings.interval}
                                                onChange={(e) => handleSettingChange('interval', parseInt(e.target.value))}
                                                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-rose-500"
                                            />
                                        </div>
                                    )}
                                </div>

                                {/* Steps and Seed */}
                                <div className="flex gap-4 items-end">
                                    {/* Steps (for models that support it) */}
                                    {defaultModelSettings[imageSettings.model]?.steps && (
                                        <div className="flex-1 space-y-2">
                                            <label className="text-sm font-medium text-gray-700 flex justify-between">
                                                Steps
                                                <span className="text-gray-500 font-normal">{imageSettings.steps}</span>
                                            </label>
                                            <input 
                                                type="number" 
                                                min={defaultModelSettings[imageSettings.model]?.steps?.min || 1}
                                                max={defaultModelSettings[imageSettings.model]?.steps?.max || 150}
                                                value={imageSettings.steps}
                                                onChange={(e) => handleSettingChange('steps', parseInt(e.target.value))}
                                                className="w-full px-4 py-2 rounded-lg border border-gray-200 bg-white text-sm focus:ring-2 focus:ring-rose-500/20 focus:border-rose-500 outline-none shadow-sm"
                                            />
                                        </div>
                                    )}

                                    {/* Seed */}
                                    {defaultModelSettings[imageSettings.model]?.seed && (
                                        <div className="flex-1 space-y-2">
                                            <label className="text-sm font-medium text-gray-700">Seed</label>
                                            <div className="flex gap-2">
                                                <input 
                                                    type="number"
                                                    min={defaultModelSettings[imageSettings.model]?.seed?.min || 0}
                                                    max={defaultModelSettings[imageSettings.model]?.seed?.max || 1000000}
                                                    value={imageSettings.seed}
                                                    onChange={(e) => handleSettingChange('seed', parseInt(e.target.value))}
                                                    className="w-full px-4 py-2 rounded-lg border border-gray-200 bg-white text-sm focus:ring-2 focus:ring-rose-500/20 focus:border-rose-500 outline-none shadow-sm"
                                                />
                                                <button 
                                                    onClick={generateRandomSeed}
                                                    className="px-3 py-2 rounded-lg border border-gray-200 hover:bg-rose-50 hover:border-rose-200 transition-colors duration-200"
                                                    title="Generate random seed"
                                                >
                                                    <FaDice className="w-4 h-4 text-gray-500" />
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* Checkboxes */}
                                <div className="space-y-3">
                                    {/* Prompt Upsampling */}
                                    {defaultModelSettings[imageSettings.model]?.prompt_upsampling && (
                                        <div className="flex items-center gap-3 py-1">
                                            <input
                                                type="checkbox"
                                                id="prompt_upsampling"
                                                checked={imageSettings.prompt_upsampling}
                                                onChange={(e) => handleSettingChange('prompt_upsampling', e.target.checked)}
                                                className="w-4 h-4 text-rose-500 rounded border-gray-300 focus:ring-rose-500/20"
                                            />
                                            <label htmlFor="prompt_upsampling" className="text-sm font-medium text-gray-700">
                                                Enable prompt upsampling
                                            </label>
                                        </div>
                                    )}

                                    {/* Raw (for models that support it) */}
                                    {defaultModelSettings[imageSettings.model]?.raw && (
                                        <div className="flex items-center gap-3 py-1">
                                            <input
                                                type="checkbox"
                                                id="raw"
                                                checked={imageSettings.raw}
                                                onChange={(e) => handleSettingChange('raw', e.target.checked)}
                                                className="w-4 h-4 text-rose-500 rounded border-gray-300 focus:ring-rose-500/20"
                                            />
                                            <label htmlFor="raw" className="text-sm font-medium text-gray-700">
                                                Enable raw output
                                            </label>
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : (
                            <div className="text-center py-4">
                                <p className="text-gray-500">Please select a model to see available settings</p>
                            </div>
                        )}
                    </div>

                    {/* Generate Button */}
                    <button 
                        className="generate_button w-full py-3 px-4 mt-2 bg-gradient-to-r from-rose-500 to-rose-400 hover:from-rose-600 hover:to-rose-500 text-white rounded-lg font-medium shadow-md shadow-rose-500/20 transition-all duration-200" 
                        onClick={handleGenerate}
                    >
                        {isGenerating ? 
                            <div className="flex items-center justify-center"> 
                                <span className="mr-2">Generating...</span> 
                                <Spinner width="4" height="4" fill="fill-white" /> 
                            </div> : 
                            'Generate'
                        }
                    </button>
                </div>
            )
        },
        {
            name: 'Videos',
            icon: <PiVideoLight className="w-5 h-5" />,
            content: (
                <div className="flex flex-col items-center justify-center p-8 rounded-xl bg-gradient-to-br from-rose-50 to-white border border-rose-100 shadow-inner">
                    <div className="w-20 h-20 mb-4 text-rose-400 opacity-80">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-full h-full">
                            <path strokeLinecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                        </svg>
                    </div>
                    <h3 className="text-xl font-semibold text-rose-600 mb-2">Video Generation Coming Soon</h3>
                    <p className="text-gray-600 text-center max-w-md mb-4">
                        We're working on bringing you powerful AI video generation capabilities. 
                        Stay tuned for this exciting feature!
                    </p>
                    <div className="inline-flex items-center gap-2 text-sm text-rose-500 bg-white px-4 py-2 rounded-full shadow-sm border border-rose-100">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                        </svg>
                        Get notified when it launches
                    </div>
                </div>
            )
        },
        {
            name: '3D Models',
            icon: <PiCubeLight className="w-5 h-5" />,
            content: (
                <div className="flex flex-col items-center justify-center p-8 rounded-xl bg-gradient-to-br from-blue-50 to-white border border-blue-100 shadow-inner">
                    <div className="w-20 h-20 mb-4 text-blue-400 opacity-80">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-full h-full">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
                        </svg>
                    </div>
                    <h3 className="text-xl font-semibold text-blue-600 mb-2">3D Model Generation Coming Soon</h3>
                    <p className="text-gray-600 text-center max-w-md mb-4">
                        Our team is developing cutting-edge 3D model generation capabilities.
                        Create amazing 3D assets with just a text prompt!
                    </p>
                    <div className="inline-flex items-center gap-2 text-sm text-blue-500 bg-white px-4 py-2 rounded-full shadow-sm border border-blue-100">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                        </svg>
                        Get notified when it launches
                    </div>
                </div>
            )
        }
    ];

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleErrorClose = () => {
        setShowToastErrorMessage(false);
        setErrorMessage('');
        setErrorStatus('');
    };  

  return (
        <>
        {localStorage.getItem("GENERATE_JOYRIDE_STATUS") !== "finished" ? (
                <Joyride callback={handleJoyrideCallback} steps={steps} showProgress={true} showSkipButton={true}
                    continuous={true} />) : (<></>)}
            <div className="flex flex-col lg:flex-row w-full h-[calc(100vh-5rem)] p-4 gap-4 overflow-y-auto lg:overflow-hidden">
                {/* Left Column */}
                <div className="w-full lg:w-4/12 flex-shrink-0 rounded-xl shadow-lg backdrop-blur-[30px] bg-white/80 lg:bg-white/90 border border-rose-100/50 lg:border-0 flex flex-col mb-4 lg:mb-0 max-h-[calc(100vh-6rem)] lg:max-h-none">
                    <div className="flex flex-col h-full overflow-hidden">
                        <div className="flex-shrink-0 p-4 pb-2 select_type">
                            <ul className="flex flex-wrap gap-2 text-sm font-medium text-gray-500 dark:text-gray-400 w-full">
                                {tabs.map((tab) => (
                                    <li 
                                        key={tab.name}
                                        className={`
                                            flex-shrink-0 transition-all duration-200 ease-in-out
                                            ${activeTab === tab.name 
                                                ? 'bg-gradient-to-r from-rose-500 to-rose-400 shadow-md shadow-rose-500/20 ring-1 ring-rose-500/50' 
                                                : 'bg-white hover:bg-rose-50 border border-gray-200 hover:ring-rose-200'
                                            } 
                                            cursor-pointer rounded-lg`}
                                        onClick={() => handleTabClick(tab.name)}
                                    >
                                        <a 
                                            href="#" 
                                            className={`
                                                inline-flex items-center gap-2 px-2 lg:px-3 py-1 lg:py-2
                                                ${activeTab === tab.name 
                                                    ? 'text-white' 
                                                    : 'text-gray-600 hover:text-rose-600'
                                                }
                                                font-medium tracking-wide
                                            `}
                                        >
                                            {tab.icon}
                                            <span className="whitespace-nowrap">{tab.name}</span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="flex-1 overflow-y-auto p-4 pt-0">
                            <div className="text-medium text-gray-500 dark:text-gray-400 rounded-lg">
                                {tabs.find(tab => tab.name === activeTab)?.content}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Middle Column */}
                <div className="generated_preview w-full lg:w-5/12 flex-shrink-0 rounded-xl shadow-lg backdrop-blur-[30px] bg-white/80 lg:bg-white/90 border border-rose-100/50 lg:border-0 flex flex-col mb-4 lg:mb-0 max-h-[calc(100vh-6rem)] lg:max-h-none">
                    <div className="flex flex-col h-full overflow-hidden">
                        {/* Main content area */}
                        <div className="flex-1 overflow-y-auto p-5 flex items-center justify-center">
                            {selectedExperiment ? (
                                selectedExperiment.status === "completed" ? (
                                    <div className="w-full h-full flex items-center justify-center relative">
                                        <img 
                                            src={`${TRYON_SERVER_URL}/${selectedExperiment.result.image_url}`} 
                                            alt="Generated Image" 
                                            className="max-w-full max-h-[calc(100vh-20rem)] object-contain rounded-xl shadow-lg" 
                                        />
                                        <div className="absolute top-2 right-2 flex gap-2">
                                            <button 
                                                className="bg-white hover:bg-gray-50 rounded-full p-2 shadow-md border border-gray-200 hover:shadow-lg transition-all duration-300"
                                                onClick={() => handleDownload(`${TRYON_SERVER_URL}/${selectedExperiment.result.image_url}`)}
                                                title="Download image"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4 text-blue-600">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5V21h18v-4.5M12 3v12m0 0l-3-3m3 3l3-3" />
                                                </svg>
                                            </button>
                                        </div>

                                        {/* Reimagine button with improved styling */}
                                        <button 
                                            className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-gradient-to-r from-rose-500 to-rose-400 hover:from-rose-600 hover:to-rose-500 text-white rounded-full px-6 py-2.5 shadow-lg hover:shadow-xl border border-rose-300 transition-all duration-300 flex items-center gap-2 font-medium"
                                            onClick={() => handleReimagine(selectedExperiment.result.id)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                                            </svg>
                                            Reimagine
                                        </button>
                                    </div>
                                ) : selectedExperiment.status === "failed" ? (
                                    <div className="text-center space-y-3">
                                        <div className="w-16 h-16 mx-auto text-red-500">
                                            <svg className="w-full h-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                            </svg>
                                        </div>
                                        <p className="text-red-600 font-medium">Generation Failed</p>
                                        <p className="text-gray-500 text-sm">Please try again with different settings</p>
                                    </div>
                                ) : (
                                    <div className="text-center space-y-4">
                                        <div className="relative">
                                            <div className="w-16 h-16 border-4 border-rose-100 border-t-rose-500 rounded-full animate-spin"></div>
                                            <div className="absolute inset-0 flex items-center justify-center">
                                                <div className="w-12 h-12 bg-white rounded-full"></div>
                                            </div>
                                            <div className="absolute inset-0 flex items-center justify-center">
                                                <div className="w-8 h-8 border-4 border-rose-50 border-t-rose-300 rounded-full animate-spin animate-delay-150"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <p className="text-gray-700 font-medium">Generating Image</p>
                                            <p className="text-gray-500 text-sm mt-1">This might take a few moments...</p>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div className="text-center space-y-3">
                                    <div className="w-16 h-16 mx-auto text-gray-300">
                                        <svg className="w-full h-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                    </div>
                                    <p className="text-gray-600 font-medium">No Image Selected</p>
                                    <p className="text-gray-400 text-sm">Selected generated image will appear here</p>
                                </div>
                            )}
                        </div>

                        {/* Image info and parameters at bottom */}
                        {selectedExperiment && (
                            <div className="flex-shrink-0 px-5 py-4 border-t border-gray-100 bg-white/50 backdrop-blur-sm rounded-b-xl">
                                <div className="flex items-center justify-between mb-3">
                                    <div className="flex items-center gap-3">
                                        <span className="text-sm font-medium text-gray-700">Status</span>
                                        <span className={`px-2 py-0.5 text-xs rounded-full ${
                                            selectedExperiment.status === "completed" 
                                                ? "bg-green-50 text-green-600" 
                                                : selectedExperiment.status === "failed"
                                                ? "bg-red-50 text-red-600"
                                                : "bg-yellow-50 text-yellow-600"
                                        }`}>
                                            {selectedExperiment.status.charAt(0).toUpperCase() + selectedExperiment.status.slice(1)}
                                        </span>
                                    </div>
                                    <span className="text-xs text-gray-400">
                                        {new Date(selectedExperiment.created_on).toLocaleString()}
                                    </span>
                                </div>

                                {/* Parameters Grid */}
                                <div className="space-y-3">
                                    {/* Prompt */}
                                    <div className="space-y-1">
                                        <label className="text-xs font-medium text-gray-500">Prompt</label>
                                        <p className="text-sm text-gray-700 line-clamp-2">
                                            {JSON.parse(selectedExperiment.params).prompt}
                                        </p>
                                    </div>

                                    {/* Parameters Grid */}
                                    <div className="grid grid-cols-2 md:grid-cols-4 gap-3 text-xs">
                                        {/* Model - Always show */}
    <div>
                                            <span className="text-gray-500">Model</span>
                                            <p className="font-medium text-gray-700 mt-0.5">
                                                {flux_models_mapping[JSON.parse(selectedExperiment.params).model] || JSON.parse(selectedExperiment.params).model}
                                            </p>
                                        </div>
                                        
                                        {/* Dynamically show parameters based on what's in the experiment params */}
                                        {Object.entries(JSON.parse(selectedExperiment.params)).map(([key, value]) => {
                                            // Skip model and prompt as they're handled separately
                                            if (key === 'model' || key === 'prompt') return null;
                                            
                                            // Format the display based on parameter type
                                            let displayValue = value;
                                            let displayName = key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
                                            
                                            // Special formatting for certain parameter types
                                            if (key === 'width' && JSON.parse(selectedExperiment.params).height) {
                                                return (
                                                    <div key={key}>
                                                        <span className="text-gray-500">Dimensions</span>
                                                        <p className="font-medium text-gray-700 mt-0.5">
                                                            {value} × {JSON.parse(selectedExperiment.params).height}
                                                        </p>
    </div>
                                                );
                                            } else if (key === 'height') {
                                                // Skip height as it's handled with width
                                                return null;
                                            } else if (typeof value === 'boolean') {
                                                displayValue = value ? 'Enabled' : 'Disabled';
                                            }
                                            
                                            return (
                                                <div key={key} className={key.length > 12 ? "col-span-2 md:col-span-1" : ""}>
                                                    <span className="text-gray-500">{displayName}</span>
                                                    <p className="font-medium text-gray-700 mt-0.5">{displayValue}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Right Column */}
                <div className="generated_images w-full lg:w-3/12 flex-shrink-0 rounded-xl shadow-lg backdrop-blur-[30px] bg-white/80 lg:bg-white/90 border border-rose-100/50 lg:border-0 flex flex-col max-h-[calc(100vh-6rem)] lg:max-h-none">
                    <div className="flex-shrink-0 px-5 pt-5">
                        <div className="flex items-center justify-between mb-4">
                            <h1 className="text-lg font-semibold bg-gradient-to-r from-rose-600 to-rose-400 bg-clip-text text-transparent">
                                Generated Images
                            </h1>
                            <span className="text-xs text-gray-400 font-medium">
                                {experiments.filter(exp => exp.status === "completed").length} results
                            </span>
                        </div>
                    </div>
                    
                    <div className="flex-1 px-5 pb-5 overflow-hidden">
                    {authenticated === null ?
                            <div className="flex flex-wrap gap-2 rounded-lg w-full">
                                <div className="w-full shadow rounded-lg bg-white animate-pulse">
                                    <div className="bg-slate-200 rounded-lg h-72 col-span-2 mb-4"></div>
                                    <div className="bg-slate-200 rounded-lg h-72 col-span-2 mb-4"></div>
                                    <div className="bg-slate-200 rounded-lg h-72 col-span-2 mb-4"></div>
                                </div>
                            </div>
                            :
                            authenticated ?
                            <div className="h-full overflow-y-auto pr-2 custom-scrollbar">
                                {experimentsFetched && experiments.length > 0 ? (
                                    <div className="grid grid-cols-2 gap-3">
                                        {experiments.map((experiment) => (
                                            experiment.status === "completed" && (
                                            <div
                                                key={experiment.id}
                                                onClick={() => setSelectedExperiment(experiment)}
                                                className={`relative group transition-all duration-200 cursor-pointer ${
                                                    selectedExperiment?.id === experiment.id 
                                                    ? 'border-2 border-rose-500 shadow-lg shadow-rose-100' 
                                                    : 'border border-gray-100 hover:border-rose-200 hover:shadow-md'
                                                } rounded-lg overflow-hidden`}
                                            >
                                                <div className="relative aspect-square w-full overflow-hidden">
                                                    {experiment.result && (
                                                        <>
                                                            <img 
                                                            src={`${TRYON_SERVER_URL}/${experiment.result.image_url}`} 
                                                            alt="Generated Image" 
                                                            className="w-full h-full object-cover object-top transition-transform duration-200 group-hover:scale-105" 
                                                        />
                                                        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                                                            <div className="absolute bottom-0 left-0 right-0 p-2 bg-gradient-to-t from-black/80 to-transparent">
                                                                <p className="text-white text-xs truncate px-1">
                                                                    {JSON.parse(experiment.params).prompt || "No prompt available"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            )
                                        ))}
                                    </div>
                                ) :
                                fetchingExperimentsFromServer ?
                                    (<div className="flex flex-wrap gap-2 rounded-lg w-full">
                                        <div className="w-full shadow rounded-lg bg-white animate-pulse">
                                            <div className="bg-slate-200 rounded-lg h-72 col-span-2 mb-4"></div>
                                            <div className="bg-slate-200 rounded-lg h-72 col-span-2 mb-4"></div>
                                            <div className="bg-slate-200 rounded-lg h-72 col-span-2 mb-4"></div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex flex-col items-center justify-center h-full text-center">
                                        <div className="w-12 h-12 text-gray-300 mb-2">
                                            <svg className="w-full h-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                            </svg>
                                        </div>
                                        <p className="text-gray-500 text-sm">No generated images yet</p>
                                        <p className="text-gray-400 text-xs mt-1">Generate your first image to see it here</p>
                                    </div>
                                )}
                            </div>
                            :
                            <AuthenticationRequired handleLoginRedirect={()=>{navigate('/signin')}} message={"Please Sign in/Sign up to see your generated images"} />
                            }
                    </div>

                    <style jsx>{`
                        .custom-scrollbar::-webkit-scrollbar {
                            width: 5px;
                        }
                        .custom-scrollbar::-webkit-scrollbar-track {
                            background: transparent;
                        }
                        .custom-scrollbar::-webkit-scrollbar-thumb {
                            background: #f43f5e40;
                            border-radius: 20px;
                        }
                        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
                            background: #f43f5e80;
                        }
                    `}</style>
                </div>

                {showToastErrorMessage && (
                    <ToastErrorMessage errorMessage={errorMessage} errorStatus={errorStatus} handleErrorClose={handleErrorClose} />
                )}
            </div>
        </>
  );
}

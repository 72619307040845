import axios from "axios";
import { TRYON_SERVER_URL, ADMIN_SHOP_URL } from "../config";


const fetchImages = async (url, accessToken=null) => {
    try{
        console.log("Fetching images...")
        let headers = {
            'Content-Type': 'application/json',
        }

        if(accessToken !== null){
            headers.Authorization = `Bearer ${accessToken}`
        }

        let res = await axios.get(url, {
            headers: headers
        })
        
        if(res.status === 200){
            console.log("Images fetched:", res.data)
            return {data: res.data.results, status: "success", statusCode: res.status, message: "Images fetched successfully"}
        }else{
            console.log("Failed to fetch images")
            return {data: null, status: "error", statusCode: res.status, message: "Failed to fetch images"}
        }
    }catch(error){
        console.error("Error fetching images:", error)
        return {data: null, status: "error", statusCode: 400, message: "Error fetching images: " + error}
    }
}

/**
 * Fetch all images from the server
 * @param {string} baseUrl - The base URL of the server
 * @param {string} accessToken - The access token for the user
 * @param {boolean} fetchUserImages - Whether to fetch user images
 * @param {boolean} fetchPublicImages - Whether to fetch public images
 * @param {Object} filters - The filters to apply to the images
 * @returns {Promise<Object>} - A promise that resolves to the response from the server
 */
const fetchAllImages = async (baseUrl, accessToken=null, fetchUserImages=false, fetchPublicImages=true, params={}) => {
    try{
        console.log("Fetching all images...")
        let allImages = [];
        let errorFetchingUserImages = false;
        let errorFetchingPublicImages = false;
        let message = "";

        if(params.type !== "all" && params.type !== undefined){
            baseUrl += `type=${params.type}&`;
        }

        if(params.gender !== "all" && params.gender !== undefined){
            baseUrl += `gender=${params.gender}&`;
        }

        if(params.limit !== undefined){
            baseUrl += `limit=${params.limit}&`;
        }

        if(params.offset !== undefined){
            baseUrl += `offset=${params.offset}&`;
        }

        if(fetchUserImages){
            console.log("Fetching user images...")
            let privateUrl = `${baseUrl}&access=private`
            console.log("Private URL:", privateUrl)
            let response = await fetchImages(privateUrl, accessToken)
            
            if(response.status === "success"){
                console.log("User images fetched:", response.data)
                allImages = [...allImages, ...response.data]
            }else{
                errorFetchingUserImages = true;
                message = response.message;
            }
        }

        if (fetchPublicImages){
            console.log("Fetching public images...")
            let publicUrl = `${baseUrl}&access=public&shop=${ADMIN_SHOP_URL}`
            console.log("Public URL:", publicUrl)
            let response = await fetchImages(publicUrl, null)
            
            if(response.status === "success"){
                console.log("Public images fetched:", response.data)
                allImages = [...allImages, ...response.data]
            }else{
                errorFetchingPublicImages = true;
                message = response.message;
            }
        }

        if(!errorFetchingUserImages && !errorFetchingPublicImages && fetchUserImages && fetchPublicImages){
            console.log("All images:", allImages)
            return {data: allImages, status: "success", statusCode: 200, message: "Images fetched successfully"}
        }

        if(!errorFetchingUserImages && fetchUserImages){
            console.log("User images fetched successfully")
            return {data: allImages, status: "success", statusCode: 200, message: "Images fetched successfully"}
        }

        if(!errorFetchingPublicImages && fetchPublicImages){
            console.log("Public images fetched successfully")
            return {data: allImages, status: "success", statusCode: 200, message: "Images fetched successfully"}
        }

        if(errorFetchingUserImages && errorFetchingPublicImages){
            console.log("Error fetching images:", message)
            return {data: null, status: "error", statusCode: 400, message: message}
        }
    }catch(error){
        console.error("Error fetching images:", error)
        return {data: null, status: "error", statusCode: 400, message: "Error fetching images: " + error}
    }
}

/**
 * Delete an image
 * @param {string} imageId - The ID of the image to delete
 * @param {string} accessToken - The access token for the user
 * @returns {Promise<Object>} - A promise that resolves to the response from the server
 */
const deleteExperimentImage = async (imageId, accessToken) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        };
        let baseUrl = `${TRYON_SERVER_URL}/api/v1/experiment_image/`;
    
        const deleteImageUrl = `${baseUrl}${imageId}/`;
        console.log("delete image URL", deleteImageUrl);
    
        const response = await axios.delete(deleteImageUrl, {
            headers: headers
        });

        if (response.status === 204) {
            return {status: "success", statusCode: 204, message: "Image deleted successfully"}  
        } else {
            return {status: "error", statusCode: response.status, message: "Failed to delete image"}
        }
    } catch (error) {
        console.error("Error deleting image:", error.message);
        return {status: "error", statusCode: 400, message: "Error deleting image: " + error}
    }
}

export {fetchAllImages, deleteExperimentImage};
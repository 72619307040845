import {Route, Routes, useLocation, useNavigate, Navigate} from "react-router-dom";
import Signin from "./pages/Signin"
import Signup from "./pages/Signup"
import Playground from "./pages/Playground"
import Preview from "./pages/Preview";
import ReimagineAI from "./pages/ReimagineAI";
import Navbar2 from "./components/Navbar2";
import React, {useEffect, useRef, useState} from "react";
import Sidebar from "./components/Sidebar";
import TryonAI from "./pages/TryonAI";
import Credits from "./pages/Credits";
import Profile from "./pages/Profile";
import OutfitAI from "./pages/OutfitAI";
import HumanModelGeneration from "./pages/HumanModelGeneration"
import Verification from "./pages/Verification";
import { useMediaQuery } from "react-responsive";
import OutfitCaptionAI from "./pages/OutfitCaptionAI";
import UserOnboardingDetails from "./pages/admin/UserOnboardingDetails";
import Dashboard from "./pages/admin/Dashboard";
import Users from "./pages/admin/Users";    
import Experiments from "./pages/admin/Experiments";
import { useSelector } from "react-redux";
import { selectUserData, selectAuthenticated, selectAccessToken } from "./selectors";
import MyImages from "./pages/MyImages";
import Docs from "./pages/Docs";
import { boot, update } from "@intercom/messenger-js-sdk";
import Intercom from '@intercom/messenger-js-sdk';
import { PiHandsClappingLight } from "react-icons/pi";
import { updateUser, fetchUser } from "./Utils/UserUtils";
import {setUserData, setUserID} from "./store/AuthActions";
import { useDispatch } from "react-redux";
import Generate from "./pages/Generate";
import { MINIMUM_CREDITS_TO_SHOW_BUY_CREDITS_POPUP } from "./config";
import PromptGallery from "./components/PromptGallery";

const App = () => {
    const location = useLocation();
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [currentOption, setCurrentOption] = useState("/");
    const [options, setOptions] = React.useState({
        "/myimages": false,
        "/tryonai": false,
        "/reimagineai": false,
        "/generate": false,
        "/outfitai": false,
        "/outfitcaptionai": false,
        "/credits": false,
        "/profile": false,
        "/docs": false,
        "/admin/dashboard": false,
        "/admin/useronboardingdetails": false,
        "/admin/users": false,
        "/admin/experiments": false,
    });
    const path_title_mapping = {
        "/": {title: "Playground", description: "Generate, Reimagine, and Try On"},
        "/myimages": {title: "My Images", description: "View your images"},
        "/tryonai": {title: "Virtual Try-On", description: "Try on clothes virtually"},
        "/reimagineai": {title: "Reimagine", description: "Reimagine your images with AI"},
        "/generate": {title: "Generate", description: "Generate fashion images with AI"},
        "/outfitai": {title: "Outfit", description: "Generate outfits with AI"},
        "/outfitcaptionai": {title: "Outfit Caption", description: "Generate captions for your outfits"},
        "/credits": {title: "Credits", description: "Purchase credits to use our AI features"},
        "/profile": {title: "Profile", description: "View and edit your profile information"},
        "/docs": {title: "Docs", description: "Documentation for our AI features"}
    }

    const user = useSelector(selectUserData);
    const authenticated = useSelector(selectAuthenticated);
    const sidebarRef = useRef(null);
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` })
    const userData = JSON.parse(useSelector(selectUserData));
    const [showWelcomePopup, setShowWelcomePopup] = useState(false);
    const accessToken = useSelector(selectAccessToken)
    const dispatch = useDispatch()
    const [fetchingUserData, setFetchingUserData] = useState(false);
    const [userDataFetched, setUserDataFetched] = useState(false);
    const minimumCreditsToShowBuyCreditsPopup = MINIMUM_CREDITS_TO_SHOW_BUY_CREDITS_POPUP
    const [showBuyCreditsPopup, setShowBuyCreditsPopup] = useState(false);
    const [showPromptGalleryModal, setShowPromptGalleryModal] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState(null);

    const getShowBuyCreditsPopup = (userCredits) => {
        let showBuyCreditsPopup1 = localStorage.getItem("SHOW_BUY_CREDITS_POPUP") === null ? true : localStorage.getItem("SHOW_BUY_CREDITS_POPUP") === "true"
        if(showBuyCreditsPopup1 && userCredits <= MINIMUM_CREDITS_TO_SHOW_BUY_CREDITS_POPUP){
            return true
        }else{
            return false
        }
    }

    useEffect(() => {
        if(userData?.show_welcome_popup){
            setShowWelcomePopup(true)
        }
    }, [userData])

    useEffect(() => {
        console.log("Apps useEffect")
        let option = location.pathname;

        console.log("Pathname:", option)
        setCurrentOption(option)

        // Create a new options object instead of mutating the existing one
        const updatedOptions = Object.keys(options).reduce((acc, key) => {
            acc[key] = key === option;
            return acc;
        }, {});

        console.log("Updated options:", updatedOptions)
        setOptions(updatedOptions)
        setSelectedPrompt(null)
        setShowPromptGalleryModal(false)
        
        if(isMobile){
            closeSideBar()
        }else{
            openSideBar()
        }
    }, []);

    // Separate useEffect for Intercom management
    useEffect(() => {
        const authPages = ["/signup", "/signin", "/verify"];
        const isAuthPage = authPages.includes(location.pathname);
        const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;

        if (!intercomAppId) {
            console.error('Intercom App ID is not defined');
            return;
        }

        try {
            // Always boot first with app ID
            Intercom({app_id: intercomAppId});

            if (isAuthPage) {
                console.log("Auth page - hiding Intercom");
                window.Intercom('shutdown');
                return;
            }

            // Show Intercom on non-auth pages
            window.Intercom('boot', {
                app_id: intercomAppId,
            });

            if (authenticated && user) {
                try {
                    // Safely parse user data
                    const parsedUser = typeof user === 'string' ? JSON.parse(user) : user;
                    
                    console.log("Updating Intercom with user data:", {
                        id: parsedUser?.id,
                        name: parsedUser?.first_name,
                        email: parsedUser?.email
                    });

                    // Update with user data
                    update({
                        user_id: parsedUser?.id,
                        name: parsedUser?.first_name,
                        email: parsedUser?.email,
                        created_at: Date.now()
                    });
                } catch (parseError) {
                    console.error('Error parsing user data:', parseError);
                }
            }
        } catch (error) {
            console.error('Error in Intercom initialization:', error);
        }
    }, [location.pathname, authenticated, user]);

    // Add this useEffect near the top of the component with other useEffects
    useEffect(() => {
        // When modal is shown (showWelcomePopup is false), disable body scroll
        if (!showWelcomePopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        // Cleanup function to re-enable scrolling when component unmounts
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [showWelcomePopup]);

    const toggleSidebar = () => {
        setSidebarOpen(choice => !choice);
    };

    function handleOptionSelect(option, options) {
        console.log("Option selected:", option, options)
        setOptions(options)
        navigateToPage(option)
        if(isMobile){
            closeSideBar()
        }
    }

    const navigateToPage = (selectedOption) => {
        if (currentOption === selectedOption) {
            console.log("You clicked on the same option:", currentOption)
        } else {
            setCurrentOption(selectedOption)
            setSelectedPrompt(null)
            navigate(selectedOption)
        }
    }

    const closeSideBar = () => {
        setSidebarOpen(false);
    }

    const openSideBar = () => {
        setSidebarOpen(true);
    }

    const RouteWrapper = ({ element, path }) => {
        console.log("RouteWrapper", userData?.is_staff, path)
        const routeExists = userData?.is_staff;
        return routeExists ? element : <Navigate to="/" />;
    };

    const hideWelcomePopup = async () => {
        const response = await updateUser({show_welcome_popup: false}, accessToken)
        console.log("Hide welcome popup response", response)
        if(response.status === "success"){
            dispatch(setUserID(response.data.id))
            dispatch(setUserData(JSON.stringify(response.data)))
            setShowWelcomePopup(false)
        }else{
            console.log("Error hiding welcome popup", response)
            setShowWelcomePopup(false)
        }
    }

    useEffect(() => {
        if(userDataFetched){
            return
        }
        const fetchUserData = async () => {
            setFetchingUserData(true)
            const response = await fetchUser(accessToken)
            console.log("Fetch user data response", response)
            if(response.status === "success"){
                dispatch(setUserID(response.data.id))
                dispatch(setUserData(JSON.stringify(response.data)))
                setUserDataFetched(true)

                // check the number of credits the user has
                if(getShowBuyCreditsPopup(response.data.credits)){
                    // show buy more credits popup
                    console.log("Show buy credits popup")
                    setShowBuyCreditsPopup(true)
                    localStorage.setItem("SHOW_BUY_CREDITS_POPUP", "true")
                }
            }else{
                console.log("Error fetching user data", response)
                setUserDataFetched(true)
            }
            setFetchingUserData(false)
        }
        fetchUserData()
    }, [accessToken])

    const getTitleDescription = () => {
        console.log("getTitle", location.pathname)
        return path_title_mapping[location.pathname] || {title: "TryOn AI", description: "Generate, Reimagine, and Try On"}
    }

    const handleBuyCreditsOptionSelect = (numOfCredits) => {
        setShowBuyCreditsPopup(false)
        localStorage.setItem("SHOW_BUY_CREDITS_POPUP", "false")
        navigate(`/credits?buy_credits=true&num_of_credits=${numOfCredits}`)
    }

    const handlePromptSelect = (prompt) => {
        console.log("Prompt selected", prompt)
        setSelectedPrompt(prompt)
        setShowPromptGalleryModal(false)
    }

    return (
        <>
            {location.pathname === "/signin" || location.pathname === "/signup" || location.pathname === "/verify"? (<></>):(
            <Sidebar ref={sidebarRef} handleOptionSelect={handleOptionSelect} isSidebarOpen={isSidebarOpen}
                     closeSideBar={closeSideBar} options={options} isAdmin={location.pathname.includes("/admin")}/>)}

            <div className={location.pathname==="/signin" || location.pathname === "/signup" || location.pathname === "/verify" ?"md:ml-0":"md:ml-64"}>
            {location.pathname === "/signin" || location.pathname === "/signup" || location.pathname === "/verify"? (<></>):(
                <Navbar2 title_description={getTitleDescription()} toggleSidebar={toggleSidebar} handleOptionSelect={handleOptionSelect} options={options}/>)}

                <Routes>
                    <Route path="/signup" element={<Signup/>}/>
                    <Route path="/signin" element={<Signin/>}/>
                    <Route path="/" element={<Playground options={options} handleOptionSelect={handleOptionSelect}/>}/>
                    <Route path="/tryonai" element={<TryonAI/>}/>
                    <Route path="/aimodel" element={<HumanModelGeneration/>}/>
                    <Route path="/reimagineai" element={<ReimagineAI selectedPrompt={selectedPrompt} setShowPromptGalleryModal={setShowPromptGalleryModal}/>}/>
                    <Route path="/generate" element={<Generate selectedPrompt={selectedPrompt} setShowPromptGalleryModal={setShowPromptGalleryModal}/>}/>
                    <Route path="/credits" element={<Credits/>}/>
                    <Route path="/profile" element={<Profile/>}/>
                    <Route path="/docs" element={<Docs/>}/>
                    <Route path="/verify" element={<Verification/>}/>
                    <Route path="/preview/:garment_url/:model_url/:tryon_url" element={<Preview/>}/>
                    <Route path="/outfitai" element={<OutfitAI selectedPrompt={selectedPrompt} setShowPromptGalleryModal={setShowPromptGalleryModal}/>}/>
                    <Route path="/outfitcaptionai" element={<OutfitCaptionAI/>}/>
                    <Route path="/myimages" element={<MyImages/>}/>

                    <Route path="/admin/dashboard" element={<RouteWrapper element={<Dashboard />} path="/admin/dashboard" />} />
                    <Route path="/admin/useronboardingdetails" element={<RouteWrapper element={<UserOnboardingDetails />} path="/admin/useronboardingdetails" />} />
                    {/* <Route path="/admin/users" element={<RouteWrapper element={<Users />} path="/admin/users" />} />
                    <Route path="/admin/experiments" element={<RouteWrapper element={<Experiments />} path="/admin/experiments" />} /> */}
                </Routes>
            </div>

            {/* show a welcome to TryOn AI Playground popup for new users */}
            {showWelcomePopup && (
                <div className="fixed inset-0 z-50 bg-black bg-opacity-60 flex items-center justify-center p-4 overflow-hidden">
                    <div className="bg-white rounded-lg shadow-2xl max-w-md w-full transform transition-all overflow-y-auto max-h-[90vh]">
                        {/* Header Section */}
                        <div className="p-6 text-center border-b border-gray-100">
                            <div className="flex items-center justify-center mb-4">
                                <PiHandsClappingLight className="text-4xl text-primary-600" />
                            </div>
                            <h2 className="text-2xl font-bold text-gray-900 mb-2">Welcome to TryOn AI! 🎉</h2>
                            <p className="text-gray-600">AI For Fashion: Generate, Reimagine, and Try On</p>
                        </div>

                        {/* Show 5 free credits to the user */}
                        <div className="pt-4 text-center">
                            <div className="inline-flex items-center bg-gradient-to-r from-amber-50 to-rose-50 px-2 py-1 rounded-lg border border-amber-200 shadow-sm mb-1">
                                <span className="text-base font-bold text-amber-600 mr-1">5</span>
                                <span className="font-semibold text-rose-600">free credits</span>
                            </div>
                        </div>

                        {/* Content Section */}
                        <div className="p-4 space-y-4">
                            <div className="space-y-3">
                                <div className="flex items-start space-x-3">
                                    <div className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-primary-100 text-primary-600">
                                        ✨
                                    </div>
                                    <p className="text-gray-700">Experience the <span className="font-semibold text-primary-600">Future of Fashion</span> with our <span className="font-semibold text-primary-600">State-of-the-Art AI Technology</span></p>
                                </div>
                                <div className="flex items-start space-x-3">
                                    <div className="flex-shrink-0 w-7 h-7 flex items-center justify-center rounded-full bg-amber-100 text-amber-600 shadow-sm">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" />
                                        </svg>
                                    </div>
                                    <div className="flex-1">
                                        <p className="text-gray-700">We've added <span className="font-semibold text-primary-600">5 free credits</span> to your account to get you started</p>
                                    </div>
                                </div>
                                <div className="flex items-start space-x-3">
                                    <div className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-primary-100 text-primary-600">
                                        💡
                                    </div>
                                    <p className="text-gray-700">Try our <span className="font-semibold text-primary-600">virtual try-on, reimagine, outfit generation, and image enhancement</span> features</p>
                                </div>
                            </div>
                        </div>

                        {/* Join our Discord support channel if you face any issues while using the platform */}
                        <div className="p-6">
                            <div className="bg-gradient-to-r from-indigo-50 to-purple-50 rounded-lg p-4 border border-indigo-100 shadow-sm">
                                <div className="flex items-center space-x-3">
                                    <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-md bg-indigo-100 text-indigo-600">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36" className="w-5 h-5" fill="currentColor">
                                            <path d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <h3 className="font-medium text-gray-800">Need help?</h3>
                                        <p className="text-gray-700">Join our <a className="font-semibold text-indigo-600 hover:text-indigo-800 underline transition-colors" href="https://discord.gg/SuM5Xr9nn9" target="_blank" rel="noopener noreferrer">Discord support channel</a> for assistance with the platform</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Footer Section */}
                        <div className="p-6 pt-0">
                            <button 
                                onClick={hideWelcomePopup}
                                className="w-full px-4 py-2 text-base font-semibold text-white transition-all duration-200 bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700 border border-transparent rounded-lg hover:shadow-lg disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                Start Exploring
                            </button>
                            <p className="text-center text-sm text-gray-500 mt-4">
                                Need help? <a href="mailto:contact@tryonlab.ai" className="text-primary-600 hover:text-primary-700">Contact our support team</a>
                            </p>
                        </div>
                    </div>
                </div>
            )}

            {(showBuyCreditsPopup || localStorage.getItem("SHOW_BUY_CREDITS_POPUP", "true") === "true") && (
                <div className="fixed inset-0 z-50 bg-black bg-opacity-60 flex items-center justify-center p-4 overflow-hidden">
                    <div className="bg-white rounded-xl shadow-2xl max-w-lg w-full transition-all overflow-hidden">
                        {/* Header */}
                        <div className="bg-gradient-to-r from-primary-50 to-primary-100 p-5 border-b border-primary-200">
                            <div className="flex items-center justify-between mb-2">
                                <h2 className="text-2xl font-bold text-gray-900 flex items-center">
                                    <svg className="w-7 h-7 mr-2 text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                                        <path fillRule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clipRule="evenodd" />
                                    </svg>
                                    Running Low on Credits
                                </h2>
                                <div className="flex items-center justify-center bg-white/90 rounded-full py-1 px-3 shadow-sm border border-primary-100">
                                    <div className="text-gray-700 font-medium flex items-center gap-1">
                                        <span className="font-bold text-primary-600">{userData.credits}</span> 
                                        <span className="text-sm text-gray-500">left</span>
                                    </div>
                                </div>
                            </div>
                            <p className="text-sm text-gray-700">
                                Get more credits to continue creating amazing AI fashion designs. More credits = more possibilities!
                            </p>
                        </div>
                        
                        {/* Credit packages */}
                        <div className="p-6 bg-opac-gallery">
                            {/* Most popular option highlighted */}
                            <div onClick={() => handleBuyCreditsOptionSelect(1000)} className="group relative bg-gradient-to-r from-primary-50 to-primary-100 hover:from-primary-100 hover:to-primary-200 rounded-xl p-5 shadow-md border border-primary-200 hover:border-primary-300 transition-all duration-300 cursor-pointer mb-5">
                                <div className="flex items-center justify-between">
                                    <div className="pl-1">
                                        <div className="text-4xl font-bold text-primary-600 group-hover:translate-y-[-2px] transition-transform">1000</div>
                                        <div className="text-sm font-medium text-gray-700 mt-1.5 font-Mulish">Credits</div>
                                    </div>
                                    <div className="flex flex-col items-end">
                                        <div className="text-sm font-semibold text-white bg-primary-600 rounded-full py-1.5 px-4 shadow-sm font-Mulish">$10</div>
                                        <div className="flex items-center mt-2.5 text-xs font-medium text-primary-800 bg-primary-100 rounded-full px-3 py-1.5 border border-primary-200">
                                            <svg className="w-3.5 h-3.5 mr-1.5" fill="currentColor" viewBox="0 0 20 20">
                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                            </svg>
                                            Best Value
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute -top-2.5 -left-2.5">
                                    <span className="bg-selected text-white text-xs font-bold py-1.5 px-4 rounded-full shadow-md border border-primary-300">
                                        Popular
                                    </span>
                                </div>
                            </div>
                            
                            {/* Basic packages */}
                            <div className="grid grid-cols-2 gap-5 mb-5">
                                <div onClick={() => handleBuyCreditsOptionSelect(200)} className="group bg-white hover:bg-primary-50 border border-gray-200 hover:border-primary-200 rounded-xl p-5 shadow hover:shadow-md transition-all duration-300 cursor-pointer">
                                    <div className="flex items-center justify-between">
                                        <div className="pl-1">
                                            <div className="text-3xl font-bold text-primary-500 group-hover:translate-y-[-2px] transition-transform">200</div>
                                            <div className="text-sm font-medium text-gray-600 group-hover:text-gray-700 transition-colors mt-1 font-Mulish">Credits</div>
                                        </div>
                                        <div className="text-xs font-semibold text-white bg-gray-600 group-hover:bg-primary-500 rounded-full py-1.5 px-3.5 shadow-sm transition-colors font-Mulish">$2</div>
                                    </div>
                                </div>
                                
                                <div onClick={() => handleBuyCreditsOptionSelect(500)} className="group bg-white hover:bg-primary-50 border border-gray-200 hover:border-primary-200 rounded-xl p-5 shadow hover:shadow-md transition-all duration-300 cursor-pointer">
                                    <div className="flex items-center justify-between">
                                        <div className="pl-1">
                                            <div className="text-3xl font-bold text-primary-500 group-hover:translate-y-[-2px] transition-transform">500</div>
                                            <div className="text-sm font-medium text-gray-600 group-hover:text-gray-700 transition-colors mt-1 font-Mulish">Credits</div>
                                        </div>
                                        <div className="text-xs font-semibold text-white bg-gray-600 group-hover:bg-primary-500 rounded-full py-1.5 px-3.5 shadow-sm transition-colors font-Mulish">$5</div>
                                    </div>
                                </div>
                            </div>
                            
                            {/* Premium package */}
                            <div onClick={() => handleBuyCreditsOptionSelect(10000)} className="group bg-gradient-to-r from-pale-red to-primary-50 hover:from-primary-100 hover:to-primary-200 border border-lite-red hover:border-primary-300 rounded-xl p-5 shadow hover:shadow-md transition-all duration-300 cursor-pointer">
                                <div className="flex items-center justify-between">
                                    <div className="pl-1">
                                        <div className="text-4xl font-bold text-primary-700 group-hover:translate-y-[-2px] transition-transform">10000</div>
                                        <div className="text-sm font-medium text-gray-700 mt-1.5 font-Mulish">Credits</div>
                                    </div>
                                    <div className="text-xs font-semibold text-white bg-selected rounded-full py-1.5 px-3.5 shadow-sm font-Mulish">$100</div>
                                </div>
                            </div>
                            
                            {/* Action buttons */}
                            <div className="flex items-center justify-between gap-3 mt-6">
                                <button 
                                    onClick={() => handleBuyCreditsOptionSelect(1000)}
                                    className="flex-1 py-2.5 px-4 font-medium text-white bg-primary-600 hover:bg-primary-700 rounded-lg shadow-sm transition-colors font-Mulish"
                                >
                                    Buy Now
                                </button>
                                <button 
                                    onClick={() => {setShowBuyCreditsPopup(false); localStorage.setItem("SHOW_BUY_CREDITS_POPUP", "false")}}
                                    className="px-4 py-2.5 text-sm font-medium text-primary-600 hover:text-primary-700 transition-colors rounded-lg hover:bg-primary-50 border border-primary-100 font-Mulish"
                                >
                                    Dismiss
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showPromptGalleryModal && <PromptGallery setShowPromptGalleryModal={setShowPromptGalleryModal} onSelectPrompt={handlePromptSelect} />}
        </>
    );
};

export default App;

import React from "react";
import landingbg2 from "../assets/bgGradient2.svg";
import TryonLabsLogo from "../assets/TryonLabsLogo.png";
import { useParams } from 'react-router-dom';
import {TRYON_SERVER_URL} from "../config";
import DownloadIcon from "../assets/download.svg";

export default function Preview() {
    const { garment_url,model_url,tryon_url } = useParams();
    console.log("garment url",garment_url,model_url,tryon_url)
    const handleDownload = async (imageUrl) => {
        let headers = {};
        // if (accessToken) {
        //     headers = {
        //         'Authorization': `Bearer ${accessToken}`
        //     };
        // }
        const response = await fetch(imageUrl, {
            headers: headers
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'Result.png'; // You can dynamically set the filename based on image data
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.remove();
        window.URL.revokeObjectURL(url);
    };
    return (
        <div className="flex flex-col w-full justify-between h-screen bg-black backdrop-blur-md" style={{ backgroundImage: `url(${landingbg2})`, backgroundSize: 'cover' }}>
            {/* <div className="absolute top-0 z-10"> */}
                <nav className="fixed top-0 z-10 flex items-center p-4 w-full h-fit min-h-[5vh] max-h-[7vh]">
                    <div className="flex flex-row items-center mr-6 ">
                        <div onClick={()=>{
                                window.open("https://tryonlabs.ai/", '_blank');
                            }} className="w-full h-full items-center flex justify-center">
                            {/* <img className="flex md:hidden w-8 h-8 object-contain cursor-pointer mr-4 " src={menu}></img> */}
                            <img 
                                className="flex w-auto h-8 object-cover object-top" src={TryonLabsLogo} alt="tryon labs logo" /><span className="block text-white ml-4 text-lg md:text-2xl font-bold font-Readex_pro">Tryonlabs.ai</span></div>
                        {/* <span className="tracking-tight "><a href="/" className=" font-bold text-[32px] leading-6 font-baloo text-black">Tryon Labs</a></span> */}
                        {/* bg-gradient-to-r from-[#F05941] to-[#872341] bg-clip-text text-transparent  */}
                    </div>
                </nav>
            {/* </div> */}
            <div className="relative flex h-[94vh] w-full items-center justify-center mt-[6vh]" >

                <div className="flex items-center justify-center w-full h-full max-w-7xl flex-col ">
                    <div
                        className='flex flex-col relative items-center justify-center w-[90%] h-[90%] border border-solid border-[#BE3144] bg-opac-preview rounded-2xl overflow-hidden'>
                        <div
                            className='flex flex-col items-center justify-center p-4 md:p-2 rounded-lg h-full '>
                          

                            <img
                                src={`${TRYON_SERVER_URL}/${tryon_url}`}
                                    
                                alt="preview-result"
                                className={`object-contain bg-inherit object-center max-w-full h-auto max-h-full `}
                            />
                            <div
                                className='absolute px-4 top-4 right-4 cursor-pointer'>
                                <img
                                    src={DownloadIcon}
                                    onClick={() => {
                                        handleDownload(`${TRYON_SERVER_URL}/${tryon_url}`)
                                    }}
                                    alt="DownloadIcon"
                                    className='object-contain w-6 h-full mb-4'></img>
                            </div>
                            <div
                                className='absolute flex-1 flex-col w-[20%] md:w-[10%] left-[1rem] bottom-[1rem] items-start justify-between max-h-[50%] -pb-2'>
                                <div
                                    className='flex items-center w-full h-[45%] rounded border-2 border-white mb-[10%]'>
                                    <img
                                        src={`${TRYON_SERVER_URL}/${garment_url}`}
                                        alt="preview-result"
                                        className='object-cover bg-white object-center w-auto max-h-full'
                                    />
                                </div>
                                <div
                                    className='flex items-center w-full h-[45%] rounded border-2 border-white'>
                                    <img
                                        src={`${TRYON_SERVER_URL}/${model_url}`}
                                        alt="preview-result"
                                        className='object-cover bg-white object-center w-auto max-h-full'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
import React from "react";
import errorIcon from "../assets/errorIcon.svg";
import GradientFont from "../components/GradientFont";

export default function AuthenticationRequired({handleLoginRedirect, message}){
    return (
        <div className='flex flex-col w-full items-center justify-center'>
            <div className='flex relative items-center justify-center left-0 right-0 bg-opacity-70 backdrop-blur-lg w-full h-full rounded-2xl bg-white/70 border-2 border-rose-100 shadow-lg'>
                <div className='relative flex flex-col items-center justify-center h-full w-full p-6'>
                    <div className="flex flex-col items-center justify-center mb-4 gap-4">
                        <img src={errorIcon} alt="error" className='object-contain h-10 w-10 mr-4' />
                        <div className="text-2xl font-semibold text-center font-OpenSans">
                            <GradientFont>{"Authentication Required"}</GradientFont>
                        </div>
                    </div>
                    <span className='text-neutral-800 text-center font-OpenSans text-sm font-normal leading-normal mb-4'>
                        {message}
                    </span>
                    <button 
                        onClick={handleLoginRedirect} 
                        className="flex flex-row items-center justify-center bg-gradient-to-r from-[#F05941] to-[#BE3144] rounded-full border border-[#EFCBCB] backdrop-blur-[50px] py-2 px-6 shadow-md hover:shadow-lg transition-shadow duration-200"
                    >
                        <span className="text-white text-sm font-semibold font-OpenSans leading-normal">Sign in</span>
                    </button>
                </div>
            </div>
        </div>
    )
}
import React from 'react';
import downloadIcon from "../assets/download.svg";

const Modal = ({ image, onClose }) => {
    const handleDownload = async (imageUrl) => {
        let headers = {};
        // if (accessToken) {
        //     headers = {
        //         'Authorization': `Bearer ${accessToken}`
        //     };
        // }
        const response = await fetch(imageUrl, {
            headers: headers
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'Result.png';
        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(url);
    };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75">
      <div className="relative bg-white rounded-lg overflow-hidden shadow-lg max-w-full max-h-full">
        <img
          src={image}
          alt="Preview"
          className="w-full h-auto max-h-[80vh] object-contain"
        />
        <button
          className="absolute top-4 right-4 p-1 bg-white rounded-full shadow-lg text-gray-500 hover:text-gray-700"
          onClick={onClose}
          aria-label="Close Modal"
        >
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <button
          className="absolute top-4 left-4 p-1 bg-white rounded-full shadow-lg text-gray-500 hover:text-gray-700"
          onClick={(e)=>{e.stopPropagation();
            handleDownload(image);}}
          aria-label="Download Modal"
        >
         <img className='w-6 h-6 ' src={downloadIcon}></img>
        </button>
      </div>
    </div>
  );
};

const ImageViewer = ({ image, onClose }) => {
  return (
    <div
      onClick={onClose}
      className="fixed inset-0 z-50 cursor-pointer"
      role="presentation"
    >
      <Modal image={image} onClose={onClose} />
    </div>
  );
};

export default ImageViewer;

import React, { useEffect, useState } from "react";
import DeleteIcon from "../assets/deleteIcon.svg";
import { useSelector } from "react-redux";
import { selectAccessToken, selectAuthenticated } from "../selectors";
import errorIcon from "../assets/errorIcon.svg";
import ExpandIcon from "../assets/expandicon.svg"
import GradientFont from "../components/GradientFont";
import { useNavigate } from "react-router-dom";
import { TRYON_SERVER_URL } from "../config";
import CaptionOutputCard from "../components/OutfitCaptionCard";
import {resizeUploadedFile, dataURLToBlob, urlToFile} from '../CommonUtils'
import ImageViewer from '../components/ImageViewer';
import NoModels from "../assets/NoModels.svg";

export default function OutfitCaptionAI() {
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedOption, setSelectedOption] = useState('json');
    const authenticated = useSelector(selectAuthenticated);
    const navigate = useNavigate();
    const [experiments, setExperiments] = useState([]);
    const [experimentsFetched, setExperimentsFetched] = useState(false);
    const accessToken = useSelector(selectAccessToken) || null;
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorStatus, setErrorStatus] = useState('');
    const [errorCode, setErrorCode] = useState(null);
    const [fetchingExperiments, setFetchingExperiments] = useState({});
    const [fetchingAllExperiments, setFetchingAllExperiments] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState(null);
    const [creatingExperiment, setCreatingExperiment] = useState(false)

    const handleFileSelected = async (event) => {
        const files = event.target.files;
        console.log("file types in fm", files)

        if (files.length > 0) {
            console.log(files[0])

            for(let i=0; i<files.length; i++){
                setSelectedImages((prevImages) => {
                    return [...prevImages, URL.createObjectURL(files[i])]
                })
            }
        }
    };

    const handleLoginRedirect = () => {
        navigate("/signin");
    }

    useEffect(() => {
        if(!experimentsFetched){
            console.log("fetching experiments");
            fetchExperiments().then(() => {
            });
        }

        experiments.map((experiment) => {
            if (experiment.status === "pending" || experiment.status === "running") {
                if (!fetchingExperiments[experiment.id]) {
                    console.log("setTimeout", experiment.id)
                    setTimeout(fetchExperiment, 5000, experiment);
                }
            }
        })
    }, [experiments]);

    const fetchExperiments = async () => {
        if (authenticated || accessToken) {
            setFetchingAllExperiments(true)
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment/?aimodel=outfit_ai&action=caption_outfit&access=private`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                let res = await response.json();
                console.log("Fetched experiments:", res);
                setExperiments(res);
                setExperimentsFetched(true);
                setFetchingAllExperiments(false)
            } else {
                console.error('Failed to fetch model swap experiments');
                setFetchingAllExperiments(false)
                setExperimentsFetched(true)
            }
        }
    };

    const handleErrorClose = () => {
        setError(false);
        setErrorCode(null);
        setErrorMessage("");
        setErrorStatus("");
    }

    const uploadExperimentImageAndCreateExperiment= async (file) => {
        if (!authenticated){
            setErrorStatus("Login Required");
            setErrorMessage("User not logged in. Please Log in and try Again");
            setErrorCode(401);
            setError(true);
            setCreatingExperiment(false)
            return null;
        }

        try {
            const resizedImageUrl = await resizeUploadedFile(file);
            const resizedFile = dataURLToBlob(resizedImageUrl);
            console.log("after resize", file?.name, resizedFile);

            const formData = new FormData();
            formData.append('type', 'garment');
            formData.append('access', 'private');
            formData.append('image', resizedFile, file?.name);
            formData.append('preprocess', false);

            let headers = {};
            if (accessToken) {
                headers = {
                    'Authorization': `Bearer ${accessToken}`
                };
            }
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment_image/`, {
                method: 'POST',
                headers: headers,
                body: formData,
            });

            if (!response.ok) {
                if (response.status === 401) {
                    throw new Error("User not logged in. Please Log in and try Again");
                } else {
                    throw new Error(JSON.stringify(await response.json()));
                }
            }

            const data = await response.json();
            console.log("uploaded image", data);

            // create an experiment
            const experimentData = new FormData();
            experimentData.append("garment_id", data.id);
            experimentData.append("action", "caption_outfit");
            experimentData.append("params", JSON.stringify({
                output_format: selectedOption
                }));

            console.log("Experiment data:", experimentData);

            const response2 = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment/`, {
                method: 'POST',
                headers: headers,
                body: experimentData,
            });

            if (!response2.ok) {
                if (response2.status === 401) {
                    throw new Error("User not logged in. Please Log in and try Again");
                } else {
                    throw new Error(JSON.stringify(await response.json()));
                }
            }

            const data2 = await response2.json();
            console.log("Experiment created successfully", data2.experiment);

            //let experiments1 = [...[data2.experiment], ...experiments]
            //setExperiments(prevExperiments=>({...prevExperiments, experiments1}));
            return data2.experiment
        } catch (error) {
            console.error("Error during try on:", error.message);
            if (isJSON(error?.message)) {
                let errorData = JSON.parse(error?.message);
                if (errorData?.image && Array.isArray(errorData?.image) && errorData?.image?.length > 0) {
                    setErrorMessage(errorData.image[0]);
                } else if (errorData?.message && Array.isArray(errorData?.message) && errorData?.message?.length > 0) {
                    setErrorMessage(errorData?.message[0]);
                } else {
                    setErrorMessage('Something went wrong! Please try again later');
                }
                // setErrorMessage(errorData[0]);
                setErrorStatus(errorData?.status);
                setError(true);
                return null;
            } else {
                setErrorStatus("Failure!")
                setErrorMessage(error?.message);
                setError(true);
                return null;
            }
            setCreatingExperiment(false)
        }
    }

    const createExperiment = async () => {
        setCreatingExperiment(true)
        if (!authenticated){
            setErrorStatus("Login Required");
            setErrorMessage("The user is not logged in. Please log in and try again!");
            setErrorCode(401);
            setError(true);
            setCreatingExperiment(false)
            return;
        }

        if(selectedImages.length === 0){
            setErrorStatus("Select images");
            setErrorMessage("Select images to create captions!!");
            setErrorCode(401);
            setError(true);
            setCreatingExperiment(false)
            return;
        }

        if(selectedOption === ""){
            setErrorStatus("Select output format");
            setErrorMessage("You must select an output format!");
            setErrorCode(401);
            setError(true);
            setCreatingExperiment(false)
            return;
        }
        
        let createdExperiments = []
        let imagesProcessed = 0
        for(let i=0;i< selectedImages.length;i++){
            let experiment1 =  await uploadExperimentImageAndCreateExperiment(await urlToFile(selectedImages[i], `garment_{i}.jpg`))
            imagesProcessed = imagesProcessed + 1
            if(experiment1 !== null){
                setSelectedImages([])
                console.log("returned:", experiment1)
                createdExperiments.push(experiment1)

                if(createdExperiments.length === imagesProcessed){
                    createdExperiments.reverse()
                    let experiments1 = [...createdExperiments, ...experiments]
                    setExperiments(experiments1);
                    setCreatingExperiment(false)
                }
            }
        }

        // console.log("created:", createdExperiments, experiments)
        // let experiments1 = [...createdExperiments, ...experiments]
        // console.log("experiments:", experiments)
        // setExperiments(experiments1);
        // console.log("experiments:", experiments)
    }

    function isJSON(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    const fetchExperiment = async (experiment) => {
        if (!fetchingExperiments[experiment.id]) {
            let fetchingExperiments1 = { ...fetchingExperiments }; // Clone the state object to avoid direct mutation
            fetchingExperiments1[experiment.id] = true;
            setFetchingExperiments(fetchingExperiments1);

            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment/${experiment.id}/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                let res = await response.json();
                let fetchedExperiment = res.experiment;

                console.log("Fetched experiment:", fetchedExperiment);

                if (fetchedExperiment.status === 'completed' || fetchedExperiment.status === 'failed') {
                    console.log("Final status reached:", fetchedExperiment.status);

                    setExperiments((prevExperiments) =>
                        prevExperiments.map((experiment1) =>
                            experiment1.id === fetchedExperiment.id ? { ...experiment1, ...fetchedExperiment } : experiment1
                        )
                    );
                } else if (fetchedExperiment.status === 'pending' || fetchedExperiment.status === 'running') {
                    console.log("Experiment is still running, polling again...");
                    setTimeout(() => fetchExperiment(experiment), 5000);
                }
            } else {
                console.error('Failed to fetch model swap experiment');
            }

            fetchingExperiments1[experiment.id] = false;
            setFetchingExperiments(fetchingExperiments1);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalImage(null);
    };

    const handleOpenModal = (imageUrl) => {
        setShowModal(true);
        setModalImage(imageUrl);
    };

    return (
        <div>
            <div className="max-w-7xl lg:h-[89vh] lg:grid lg:grid-cols-4 place-content-stretch gap-4 m-4">
                <div className="flex-col flex h-full col-span-1">
                    <div className="bg-white/30 rounded-lg p-4">
                        {/* outfit caption title */}
                        <div className="flex flex-col space-y-1 mb-6">
                            <h1 className="text-lg md:text-xl font-semibold text-gray-800">
                                Outfit Caption AI
                            </h1>
                            <p className="text-sm text-gray-500">
                                Generate captions for your outfit images
                            </p>
                        </div>

                        {selectedImages.length > 0 ? (
                            <div>
                            <div className="relative flex justify-center w-full mx-auto border border-solid border-stone-400 items-center bg-opac-preview rounded-lg">
                                <div className={`grid ${selectedImages.length > 1 ? 'grid-cols-2 m-2 h-72 overflow-y-scroll' : 'grid-cols-1 m-2'} gap-4`}>
                                    {selectedImages.map((image, index) => (
                                        <div key={index} className={`relative w-full ${selectedImages.length > 1 ? 'h-auto' : 'h-72'}`}>
                                            <img 
                                                src={image} 
                                                className="w-full h-full object-cover rounded-lg" 
                                                alt="garment image" 
                                            />
                                            <img src={DeleteIcon} alt="close icon" onClick={() => {
                                                setSelectedImages((prevImages) => {
                                                    return prevImages.filter((img, i) => i !== index)
                                                })
                                            }}
                                                className="absolute top-2 right-2 w-6 h-auto cursor-pointer bg-red-500 rounded-full p-1 shadow" />
                                                
                                            <img src={ExpandIcon} alt="expand icon"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleOpenModal(image);
                                                }}
                                                className="absolute top-2 left-2 w-6 h-auto cursor-pointer bg-white rounded-full p-1 shadow" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            </div>
                        ) : (
                            <div className="flex items-center justify-center w-full">
                                <label htmlFor="dropzone-file"
                                    className="flex flex-col h-[306px] items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                        </svg>
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span
                                            className="font-semibold">Click to upload</span> or drag and drop</p>
                                        <p className="text-xs text-gray-500 dark:text-gray-400">PNG, JPG, GIF, SVG(MAX.
                                            4000x4000px)</p>
                                    </div>
                                    <input id="dropzone-file" type="file" multiple  className="hidden"
                                        onChange={handleFileSelected} />
                                </label>
                            </div>
                        )}
                        <div className="flex justify-between items-center">
                                <p className="mt-2 text-sm">Total images: {selectedImages.length}</p>
                                <button className={`py-1 px-2 mt-2 text-sm ${selectedImages.length > 0? 'bg-gradient-to-r from-red-500 to-rose-600 text-white':'shadow-sm bg-gray-200 text-gray-600'} rounded-lg
                     cursor-pointer`} onClick={()=>{setSelectedImages([])}}>Clear</button>
                        </div>

                        <div className="mt-4 text-sm">
                            Select output format
                        </div>
                        <div className="my-2 border-b border-gray-200 dark:border-gray-700">
                            <ul className="flex w-full text-sm font-medium text-center" id="option-tab" data-tabs-toggle="#option-tab-content" role="tablist">
                                <li className="w-1/2" role="presentation">
                                    <button
                                        className={`inline-block w-full px-4 py-2 rounded-l-lg shadow-sm ${selectedOption === 'json' ? 'bg-gradient-to-r from-red-500 to-rose-600 text-white' : 'bg-white text-gray-800'}`}
                                        id="json-tab"
                                        data-tabs-target="#json"
                                        type="button"
                                        role="tab"
                                        aria-controls="json"
                                        aria-selected={selectedOption === 'json'}
                                        onClick={() => setSelectedOption('json')}
                                    >
                                        JSON
                                    </button>
                                </li>
                                <li className="w-1/2" role="presentation">
                                    <button
                                        className={`inline-block w-full px-4 py-2 rounded-r-lg shadow-sm ${selectedOption === 'text' ? 'bg-gradient-to-r from-red-600 to-rose-500 text-white' : 'bg-white text-gray-800'}`}
                                        id="text2-tab"
                                        data-tabs-target="#text"
                                        type="button"
                                        role="tab"
                                        aria-controls="text"
                                        aria-selected={selectedOption === 'text'}
                                        onClick={() => setSelectedOption('text')}
                                    >
                                        Text
                                    </button>
                                </li>
                            </ul>
                        </div>

                        <button
                            className={`py-2 px-4 w-full mt-4 mx-auto text-bg bg-gradient-to-r from-red-500 to-rose-600 rounded-lg
                            text-white cursor-pointer ${creatingExperiment ? 'animate-pulse' : ''}`} 
                            onClick={createExperiment}
                        >
                            {creatingExperiment ? 'Generating' : 'Generate'}
                        </button>
                    </div>
                </div>
                <div className="bg-white/30 rounded-lg col-span-3 h-full overflow-auto relative p-4">
                
                {authenticated === null ?
                        <div className="flex flex-wrap gap-2 rounded-lg w-full">
                            <div className="w-full shadow rounded-lg bg-white animate-pulse">
                                <div className="bg-slate-200 rounded-lg h-72 col-span-2 m-4"></div>
                                <div className="bg-slate-200 rounded-lg h-72 col-span-2 m-4"></div>
                                <div className="bg-slate-200 rounded-lg h-72 col-span-2 m-4"></div>
                            </div>
                        </div>
                        : authenticated ? (
                    <div className="grid grid-cols-1 gap-4">
                        {experimentsFetched && experiments.length > 0 ? (
                            experiments.map((experiment) => {
                                return <CaptionOutputCard 
                                key={experiment?.id} experiment={experiment}  />
                            })
                            ):experimentsFetched && experiments.length === 0?(<div className='flex flex-col items-center justify-center h-full w-full p-4 mb-2 bg-opacity-70 backdrop-blur-lg rounded-lg bg-white/60 border-2 border-rose-100'>
                                <div className="flex flex-col w-full items-center justify-center ">
                                    <img src={NoModels} alt="error" className='object-contain h-16 object-center w-16 mb-4' />
                                    <div className="!text-xl font-semibold text-center font-OpenSans ">
                                        <GradientFont>{"No Outfits Captions Generated!"}</GradientFont>
                                    </div>
                                </div>
                                <span className='ml-4 my-4 !text-neutral-800 text-center font-OpenSans text-sm font-normal leading-normal'>Generated outfits captions will be displayed here!</span>
                            </div>):(<div className="flex flex-wrap gap-2 rounded-lg w-full">
                            <div className="w-full shadow rounded-lg bg-white animate-pulse">
                                <div className="bg-slate-200 rounded-lg h-72 col-span-2 m-4"></div>
                                <div className="bg-slate-200 rounded-lg h-72 col-span-2 m-4"></div>
                                <div className="bg-slate-200 rounded-lg h-72 col-span-2 m-4"></div>
                            </div>
                        </div>)}
                    </div>
                    ):(<div className='flex flex-col w-full h-full items-center justify-center px-4 pt-4 pb-4'>
                        <div className='flex relative items-center justify-center left-0 right-0 bg-opacity-70 backdrop-blur-lg w-full h-full rounded-2xl bg-white/70 border-2 border-rose-100'>

                            <div className='relative flex flex-row items-center justify-center h-full w-fit'>

                                <div className='flex flex-col items-center justify-center h-full w-full p-4 mb-2'>

                                    <div className="flex flex-row w-full items-center justify-center">
                                        <img src={errorIcon} alt="error" className='object-contain h-8 object-center w-8 mr-4' />
                                        <div className="!text-xl font-semibold text-center font-OpenSans ">
                                            <GradientFont>{"Authentication Required"}</GradientFont>
                                        </div>
                                    </div>
                                    <span className='ml-4 my-2 !text-neutral-800 text-center font-OpenSans text-sm font-normal leading-normal'>Please sign in or sign up to start the outfit generation.</span>

                                    <div onClick={handleLoginRedirect} className='flex flex-row mt-2 cursor-pointer items-center justify-center bg-gradient-to-r from-[#F05941] to-[#BE3144] rounded-[50px] border-1 border-solid border-[#EFCBCB] backdrop-blur-[50px] py-3 px-8'>
                                        <div className={` flex flex-row items-center justify-center w-fit h-full `}>
                                            <span className={`!text-white text-sm font-semibold font-OpenSans leading-normal`}>Sign in</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}

                    {showModal && (
                        <ImageViewer
                            image={modalImage}
                            onClose={handleCloseModal}
                        />
                    )}

                    {error && (
                        <div id="toast-default" class="fixed bottom-5 left-1/2 transform -translate-x-1/2 flex items-center w-full max-w-lg p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
                            <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
                                </svg>
                                <span class="sr-only">Error icon</span>
                            </div>
                            <div class="flex flex-col">
                            <div class="ms-3 text-base font-normal text-red-500">{errorStatus}</div>
                            <div class="ms-3 text-sm font-normal text-red-400">{errorMessage}</div>
                            </div>
                            <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-default" aria-label="Close" onClick={handleErrorClose}>
                                <span class="sr-only">Close</span>
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                            </button>
                        </div>
                    )}
                </div>


            </div>
        </div>
    );
}

import { useState } from "react";
import DeleteIcon from "../assets/deleteIcon.svg";
import { resizeUploadedFile, dataURLToBlob, urlToFile } from "../Utils";
import { TRYON_SERVER_URL } from "../config";
import { useSelector } from "react-redux";
import { selectAccessToken } from "../selectors";
import Spinner from "./Spinner";
import CloseIcon from "../assets/close-icon.svg";

export default function UploadImage({closeModal, imageType, allImages, setAllImages}) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedType, setSelectedType] = useState("");
    const [selectedGender, setSelectedGender] = useState("");
    const accessToken = useSelector(selectAccessToken) || null;
    const [uploading, setUploading] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadErrorMessage, setUploadErrorMessage] = useState("");

    const handleFileSelected = async (event) => {
        const files = event.target.files;
        console.log("file types in fm", files)
        setSelectedImage(URL.createObjectURL(files[0]))
        setUploadErrorMessage("");
        setUploadError(false);
    };

    const handleImageUpload = async () => {
        console.log("selectedImage", selectedImage)
        console.log("selectedType", selectedType)
        console.log("selectedGender", selectedGender)

        setUploading(true);
        setUploadError(false);
        setUploadSuccess(false);
        setUploadErrorMessage("");

        // Upload image to server
        const formData = new FormData();

        if (selectedType !== "") {
            formData.append("type", selectedType);
        }else if(imageType === "garment"){
            formData.append("type", "garment");
        }

        if (selectedGender !== "") {
            formData.append("gender", selectedGender);
        }

        if(selectedImage === null) {
            setUploadErrorMessage("Please select an image to upload");
            setUploading(false);
            setUploadError(true);
            setUploadSuccess(false);
            return;
        }

        formData.append("access", "private");
        formData.append("preprocess", "false");

        try{
            // convert imageUrl to file
            const imageFile = await urlToFile(selectedImage, "image.png");
            const resizedImageUrl = await resizeUploadedFile(imageFile, 1024);
            const resizedFile = dataURLToBlob(resizedImageUrl);
            formData.append("image", resizedFile, "image.png");

            let headers = {};
            if (accessToken) {
                headers = {
                    'Authorization': `Bearer ${accessToken}`
                };
            }
            console.log("formData", formData)
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment_image/`, {
                method: 'POST',
                headers: headers,
                body: formData,
            });

            if (!response.ok) {
                setUploadError(true);
                setUploading(false);
                setUploadSuccess(false);
                setUploadErrorMessage("Error uploading image");
            }

            const data = await response.json();
            console.log("data", data)

            if (response.ok) {
                console.log("Image uploaded successfully");
                setUploadSuccess(true);
                setUploading(false);
                setUploadError(false);

                setSelectedImage(null);
                setSelectedType("");
                setSelectedGender("");

                // put the data at the first position
                allImages.unshift(data);
                setAllImages(allImages);

                // fetch all latest images
                // setFetchingAllImages(true);
                // setAllImagesFetched(false);
                // const images = await fetchAllImages(imageType, accessToken);
                // if (images.statusCode === 200) {
                //     console.log("images", images.data);
                //     setAllImages(images.data);
                //     setOriginalImages(images.data);
                //     setAllImagesFetched(true);
                //     setFetchingAllImages(false);
                // }else{
                //     console.error("Error fetching images:", images.message);
                //     setAllImagesFetched(true);
                //     setFetchingAllImages(false);
                // }

                setTimeout(() => {
                    closeModal();
                }, 1000);
            }
        } catch (error) {
            console.log("error", error)
            setUploadError(true);
            setUploading(false);
            setUploadSuccess(false);
            setUploadErrorMessage("Error uploading image");
        }
    }

    return (
        <div className="fixed inset-0 flex  items-center justify-center bg-black bg-opacity-50 z-50" onClick={closeModal}>
                <div className="bg-white rounded-lg max-h-[calc(100vh-6rem)] lg:h-auto overflow-y-auto p-6 mx-4 lg:mx-0 w-full lg:w-1/4" onClick={(e) => e.stopPropagation()}>
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg capitalize leading-normal font-semibold">Upload {imageType === "all" ? "Image" : imageType}</h2>

                        <button className="border border-stone-300 bg-white rounded-full shadow-lg text-gray-500 hover:text-gray-700 cursor-pointer" aria-label="Close Modal" onClick={closeModal}>
                            <img src={CloseIcon} alt="close icon" className="w-6 h-6" />
                        </button>
                    </div>

                    {selectedImage !== null ? (
                            <div>
                                <div className="relative flex justify-center w-full mx-auto border border-dashed border-stone-300 items-center bg-rose-50/50 hover:bg-rose-50/70 rounded-lg">
                                    <div className="m-2 h-72 overflow-y-scroll">
                                    
                                        <div className="relative w-full h-72">
                                            <img 
                                                src={selectedImage} 
                                                className="w-full h-full object-cover rounded-lg" 
                                                alt="garment image" 
                                            />
                                            <img src={DeleteIcon} alt="close icon" onClick={() => {
                                                setSelectedImage(null)
                                            }}
                                                className="absolute top-2 right-2 w-6 h-auto cursor-pointer bg-red-500 rounded-full p-1 shadow" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                        <div className="flex flex-col w-full">
                            <label htmlFor="dropzone-file"
                                className="flex flex-col h-[306px] items-center justify-center w-full border border-stone-300 border-dashed rounded-lg cursor-pointer bg-rose-50/50 dark:hover:bg-rose-50/70 dark:bg-rose-50/50 hover:bg-rose-50/70 dark:border-rose-500 dark:hover:border-rose-500 dark:hover:bg-rose-600">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg className="w-8 h-8 mb-4 text-stone-500 dark:text-stone-400" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                    </svg>
                                    <p className="mb-2 text-sm text-stone-500 dark:text-stone-400"><span
                                        className="font-semibold">Click to upload</span> or drag and drop</p>
                                    <p className="text-xs text-stone-500 dark:text-stone-400">PNG, JPG, GIF, SVG(MAX.
                                        4000x4000px)</p>
                                </div>
                                <input id="dropzone-file" type="file" multiple  className="hidden"
                                    onChange={handleFileSelected} />
                            </label>

                            <span className="text-xs text-stone-500 dark:text-stone-400 mt-2">
                                Please choose an image to upload. You can use it later for virtual try-on, model swapping, caption generation, and more.
                            </span>
                        </div>  
                    )}

                    {/* Take image type: model or garment */}
                    {imageType === "all" && (
                        <div className="flex flex-col w-full mt-4">
                            <p className="text-sm mb-2">Select Type(Optional):</p>

                            <select className="px-4 py-2 rounded border border-stone-300" onChange={(e) => setSelectedType(e.target.value)}>
                                <option value="">Select Type</option>
                                <option value="model">Model</option>
                                <option value="garment">Garment</option>
                            </select>

                            <span className="text-xs text-stone-500 dark:text-stone-400 mt-2">
                                Please choose a type tag for the image, such as "model" or "garment."
                            </span>
                        </div>
                    )}

                    {/* Take gender: male or female  */}
                    <div className="flex flex-col w-full mt-4">
                        <p className="text-sm mb-2">Select Gender(Optional):</p>

                        <select className="px-4 py-2 rounded border border-stone-300" onChange={(e) => setSelectedGender(e.target.value)}>
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>

                        <span className="text-xs text-stone-500 dark:text-stone-400 mt-2">
                            Please choose a gender tag for the image, such as "male" or "female."
                        </span>
                    </div>

                    <div className="flex items-center justify-center w-full mt-4">
                        <button className="bg-gradient-to-r from-rose-500 to-rose-600 hover:from-rose-600 hover:to-rose-700 text-white px-4 py-2 rounded" onClick={handleImageUpload}>
                            {uploading ? <span className="flex items-center"><Spinner /> Uploading...</span> : "Upload"}
                        </button>
                    </div>

                    {uploadError && <p className="text-sm text-red-500 mt-4 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4 inline-block mr-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                        {uploadErrorMessage}
                    </p>}
                    {uploadSuccess && (
                        <p className="text-sm text-green-500 mt-4 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4 inline-block mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                            </svg>
                            Image uploaded successfully
                        </p>
                    )}
            </div>
        </div>
    )
}
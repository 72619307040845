import React from "react";
import OptionGroup from './OptionGroup'; 

const FashionModelCustomizer = ({
  gender,
  setGender,
  ethnicity,
  setEthnicity,
  age,
  setAge,
  size,
  setSize,
  height,
  setHeight,
  disable
}) => {
  return (
   
      <div className="flex flex-col w-full h-fit bg-inherit border backdrop-blur-[50px] border-selected rounded-2xl p-3">
        <OptionGroup
          title="Select Gender"
          options={["female", "male"]}
          selected={gender}
          onSelect={setGender}
          disable={disable}
        />

        <OptionGroup
          title="Model Ethnicity"
          options={["caucasian", "asian", "hispanic"]}
          selected={ethnicity}
          onSelect={setEthnicity}
          disable={disable}
        />

        <OptionGroup
          title="Age Group"
          options={[30, 40, 50]}
          selected={age}
          onSelect={setAge}
          disable={disable}
        />

        <OptionGroup
          title="Size"
          options={["S", "M", "L", "XL", "XXL"]}
          selected={size}
          onSelect={setSize}
          disable={disable}
        />

        <OptionGroup
          className="-mb-0"
          title="Height"
          options={[152, 160, 165, 168, 172, 180]}
          selected={height}
          onSelect={setHeight}
          disable={disable}
        />
      </div>

  );
};

export default FashionModelCustomizer;

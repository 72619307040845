export const handleDownload = async (imageUrl) => {
    let headers = {};
    const response = await fetch(imageUrl, {
        headers: headers
    });
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'image.png';
    document.body.appendChild(link);
    link.click();

    link.remove();
    window.URL.revokeObjectURL(url);
};

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectAccessToken } from '../../selectors';
import { TRYON_SERVER_URL } from "../../config";
import Spinner from "../../components/Spinner";

export default function Users() {

    const [users, setUsers] = useState([]);
    const [usersFetched, setUsersFetched] = useState(false);
    const accessToken = useSelector(selectAccessToken);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);
    const [count, setCount] = useState(0);
    const [isNextDisabled, setIsNextDisabled] = useState(false);
    const [isPrevDisabled, setIsPrevDisabled] = useState(false);

    const fetchUserData = async () => {
        setUsersFetched(false);
        let headers = {
            'Content-Type': 'application/json',
        }
        if (accessToken) {
            headers = {
                ...headers,
                Authorization: `Bearer ${accessToken}`
            }
        }
        try {
            const usersResponse = await fetch(`${TRYON_SERVER_URL}/api/v1/admin/users/?is_admin=True&offset=${offset}&limit=${limit}`, {
                method: 'GET',
                headers: headers,
            });
            if (usersResponse.ok) {
                const userData = await usersResponse.json();
                setUsers(userData.results);
                setCount(userData.count);
                setIsNextDisabled(userData.next ? false : true);
                setIsPrevDisabled(userData.previous ? false : true);
                console.log("experiments is fetched", userData);
            } else {
                console.error('Failed to fetch public experiments');
            }
            setUsersFetched(true);
        } catch (error) {
            console.error('Error fetching results:', error);
            setUsersFetched(true);
        }

    }

    useEffect(()=>{
        console.log("users trigger", users)
    },[users])

    useEffect(() => {
        if (!usersFetched) {
            fetchUserData().then(() => {
                console.log("useys here",users,count)
            })
        }
    }, [usersFetched, limit, offset])

    const handleNext = () => {
        if (offset >= count) {
            return;
        }
        setOffset(offset + limit);
        setUsersFetched(false);
    }

    const handlePrev = () => {
        if (offset <= 0) {
            return;
        }
        setOffset(offset - limit);
        setUsersFetched(false);
    }
    return (
        <div className='!flex flex-col items-center justify-start h-[92vh] w-full font-OpenSans py-8'>
            {usersFetched ?

                <div className="relative w-[90%] overflow-x-auto shadow-md sm:rounded-lg">
                    <div className="flex items-center justify-between flex-column flex-wrap  p-4 md:flex-row space-y-4 md:space-y-0 pb-4 bg-white dark:bg-gray-900">
                        <div>
                            <button id="dropdownActionButton" data-dropdown-toggle="dropdownAction" className="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" type="button">
                                <span className="sr-only">Action button</span>
                                Action
                                <svg className="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                                </svg>
                            </button>
                            {/* <div id="dropdownAction" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                                <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownActionButton">
                                    <li>
                                        <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Reward</a>
                                    </li>
                                    <li>
                                        <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Promote</a>
                                    </li>
                                    <li>
                                        <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Activate account</a>
                                    </li>
                                </ul>
                                <div className="py-1">
                                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Delete User</a>
                                </div>
                            </div> */}
                        </div>
                        <label for="table-search" className="sr-only">Search</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="text" id="table-search-users" className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for users"/>
                        </div>
                    </div>
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="p-4">
                                    <div className="flex items-center">
                                        <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    id
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    credits
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, index) => (
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td className="w-4 p-4">
                                        <div className="flex items-center">
                                            <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                                        </div>
                                    </td>
                                    <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                                        {/* Uncomment this to show user profile picture <img src={user.profile_url} className="w-10 h-10 rounded-full" alt="" /> */}

                                        <div className="ps-3">
                                            <div className="text-base font-semibold">{user.username}</div>
                                            <div className="font-normal text-gray-500">{user.email}</div>
                                        </div>
                                    </th>
                                    <td className="px-6 py-4">
                                        {user.id}
                                    </td>
                                    <td className="px-6 py-4">
                                        <div className="flex items-center">
                                            {/* <div className="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div> */}
                                             {user.credits}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4">
                                        <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit user</a>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                    <div className="flex items-center justify-center">
                        <nav aria-label="Page navigation example" className='m-4 p-4'>
                            <ul className="inline-flex -space-x-px text-sm">
                                <li >
                                    <button disabled={isPrevDisabled} onClick={handlePrev} className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 disabled:opacity-35 disabled:bg-gray:400">Previous</button>
                                </li>
                                {/* {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((page) => (
                                                <li key={page}>
                                                    <a href="#" className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 ${currentPage === page ? 'bg-blue-50 text-blue-600' : 'hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}`}>
                                                        {page}
                                                    </a>
                                                </li>
                                            ))} */}
                                <li>
                                    <button disabled={isNextDisabled} onClick={handleNext} className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 disabled:opacity-35 disabled:bg-gray:400">Next</button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                
                :
                <div className="flex h-full w-full items-center justify-center"><Spinner /></div>

            }
        </div>
    )
}
import React from "react";


const Button = ({className, name, selected, onClick, disable}) => {
    return <button
        className={`mr-2 my-1 capitalize ${selected ? ' border-lite-red text-selected' : ''} ${disable ? 'pointer-events-none' : 'cursor-pointer'} rounded-full border-2 px-2 py-1 font-OpenSans gap-2.5 text-sm font-normal leading-6 not-italic bg-inherit ${className}`}
        onClick={onClick}> {name} </button>;
}

const OptionGroup = ({title, options, selected, onSelect, className, disable}) => (
    <div
        className={`flex flex-col flex-wrap w-full h-full bg-[#FFFFFFCC] border-1 mb-2 flex-shrink p-2 border-lite-red rounded-lg shadow-min-shadow ${className}`}>
        <span
            className="items-start justify-start font-OpenSans gap-2.5 text-sm font-normal leading-6 not-italic">{title}</span>
        <div className="flex flex-row flex-wrap w-full items-start cursor-pointer">
            {options.map((option) => (
                <Button
                    key={option}
                    selected={selected === option}
                    name={option}
                    onClick={() => {
                        onSelect(option);
                        console.log("Option selected:", option)}}
                    disable={disable}
                />
            ))}
        </div>
    </div>
);


export default OptionGroup;
import React, { Suspense, forwardRef , useState} from 'react';
import check from "../assets/check.svg";
import close from "../assets/close.svg";
import {TRYON_SERVER_URL} from "../config";


const GenerateModelModal = (({
  filter,
  toggleFilter,
  models,

}) => {




  return (
    <div className="bg-white shadow-navbarShadow w-full h-full">
    <div className="flex flex-col justify-start items-start h-[calc(100vh-8rem)] mx-auto font-OpenSans w-11/12">
      <div className="flex flex-row items-start justify-between w-full">
        <span className="mt-6 text-4xl font-semibold leading-6">Generate Model</span>
        {/* <img onClick={toggleModal} className="w-6 h-6 mt-6 cursor-pointer" src={close} alt="close" /> */}
      </div>
      <span className="font-normal text-base normal-case mt-5">Choose generated model which is suitable for your product.</span>
      <div className="flex flex-col mt-5 bg-white h-full mx-auto rounded-lg">
        <div className="flex justify-between items-center w-10/12 h-min">
          <div className="flex flex-row justify-start font-OpenSans">
            <button
              className={filter === "female" ? "border-2 border-selected text-selected bg-[#FCEFEF66] bg-opacity-40 rounded-full normal-case font-normal text-sm px-4 py-2 m-1" : 'rounded-full border-2 border-lite-red bg-[#FCEFEF66] bg-opacity-40 normal-case font-normal text-sm px-4 py-2 m-1'}
              onClick={() => toggleFilter("female")}>
              Female
            </button>
            <button
              className={filter === "male" ? "border-2 border-selected text-selected bg-[#FCEFEF66] bg-opacity-40 rounded-full normal-case font-normal text-sm px-4 py-2 m-1" : 'rounded-full bg-inherit border-2 border-lite-red normal-case font-normal text-sm px-4 py-2 m-1'}
              onClick={() => toggleFilter("male")}>
              Male
            </button>
          </div>
        </div>
        <div className="flex mt-5 mx-auto items-start justify-center flex-shrink">
          <div className="grid grid-cols-4 gap-6 min-h-[25rem] h-[calc(100vh-16rem)] overflow-y-auto pb-12 px-4 flex-shrink pr-6">
            {models.map((img) => (
              <div
                key={img.id}
                className={`p-2 w-[8rem] h-[16rem] cursor-auto relative rounded-2xl border-2 border-lite-red`}
               >
                <Suspense fallback={<div>Loading...</div>}>
                  <img
                    // src={`${TRYON_SERVER_URL}/${img?.result?.image_url}`}
                    src = {img.src}
                    className={`w-full h-full aspect-auto object-cover object-top rounded-lg`}
                    alt={`Image ${img.id}`}
                  />
                </Suspense>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
  );
});

export default GenerateModelModal;

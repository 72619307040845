import React, { useState, useEffect } from "react";
import { fetchPrompts } from "../Utils/PromptUtils";
import CloseIcon from "../assets/close.svg";
import { useSelector } from "react-redux";
import { selectAccessToken, selectAuthenticated } from "../selectors";
import { Toast } from 'flowbite-react';

const PromptGallery = ({ setShowPromptGalleryModal, onSelectPrompt }) => {
  const [prompts, setPrompts] = useState([]);
  const [promptsFetched, setPromptsFetched] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedType, setSelectedType] = useState("all");
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const accessToken = useSelector(selectAccessToken);
  const authenticated = useSelector(selectAuthenticated);

  useEffect(() => {
    const getPrompts = async () => {
      const response = await fetchPrompts(accessToken);
      if (response.status === "success") {
        setPrompts(response.data);
        // Extract unique categories and types
        const uniqueCategories = [...new Set(response.data.map(prompt => prompt.category))];
        const uniqueTypes = [...new Set(response.data.map(prompt => prompt.type))];
        setCategories(uniqueCategories);
        setTypes(uniqueTypes);
        setPromptsFetched(true);
      }else{
        console.log("Failed to fetch prompts", response.message)
      }
    };
    getPrompts();
  }, []);

  const filteredPrompts = prompts.filter(prompt => {
    let stringifiedPrompt = JSON.stringify(prompt);
    const matchesSearch = stringifiedPrompt.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = selectedCategory === "all" || prompt.category === selectedCategory;
    const matchesType = selectedType === "all" || prompt.type === selectedType;
    return matchesSearch && matchesCategory && matchesType;
  });

  const handlePromptSelect = (prompt) => {
    setSelectedPrompt(prompt);
    if (onSelectPrompt) {
      onSelectPrompt(prompt);
    }
    setShowPromptGalleryModal(false);
  };

  const handleCopyPrompt = (prompt) => {
    navigator.clipboard.writeText(prompt);
    setToastMessage("Prompt copied to clipboard!");
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-60 flex items-center justify-center p-4 overflow-hidden">
      {showToast && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-[60]">
          <Toast>
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-primary-100 text-primary-500">
              <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
              </svg>
            </div>
            <div className="ml-3 text-sm font-normal text-gray-700">
              {toastMessage}
            </div>
            <Toast.Toggle onDismiss={() => setShowToast(false)} />
          </Toast>
        </div>
      )}
      
      <div className="bg-white rounded-lg shadow-2xl max-w-4xl w-full max-h-[90vh] flex flex-col">
        {/* Header */}
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-2xl font-bold text-gray-900">Prompt Gallery</h2>
            <button
              onClick={() => setShowPromptGalleryModal(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          
          {/* Search and Filters */}
          <div className="space-y-4">
            {/* Search Bar */}
            <div className="relative group">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg
                  className="h-5 w-5 text-gray-400 group-focus-within:text-primary-500 transition-colors"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              <input
                type="text"
                placeholder="Search prompts by text, category, or type..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl bg-white focus:bg-white focus:ring-1 focus:ring-primary-500 focus:border-primary-500 transition-all duration-200 placeholder-gray-400 text-gray-900 font-medium text-base"
              />
            </div>

            {/* Filters */}
            <div className="flex flex-col sm:flex-row gap-4">
              {/* Category Filter */}
              <div className="flex-1">
                <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                  Category
                </label>
                <div className="relative">
                  <select
                    id="category"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="w-full pl-4 pr-10 py-3 border border-gray-200 rounded-xl bg-gray-50/50 focus:bg-white focus:ring-1 focus:ring-primary-500 focus:border-primary-500 transition-all duration-200 appearance-none cursor-pointer text-gray-700"
                  >
                    <option value="all">All Categories</option>
                    {categories.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                    <svg className="h-4 w-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </div>
                </div>
              </div>

              {/* Type Filter */}
              <div className="flex-1">
                <label htmlFor="type" className="block text-sm font-medium text-gray-700 mb-1">
                  Type
                </label>
                <div className="relative">
                  <select
                    id="type"
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                    className="w-full pl-4 pr-10 py-3 border border-gray-200 rounded-xl bg-gray-50/50 focus:bg-white focus:ring-1 focus:ring-primary-500 focus:border-primary-500 transition-all duration-200 appearance-none cursor-pointer text-gray-700"
                  >
                    <option value="all">All Types</option>
                    {types.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                    <svg className="h-4 w-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Prompts Grid */}
        <div className="flex-1 overflow-y-auto p-4 h-[90vh] lg:min-h-[500px]">
          {promptsFetched ? (
            filteredPrompts.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {filteredPrompts.map((prompt) => (
                  <div
                    key={prompt.id}
                    className="bg-white border border-gray-200 rounded-xl p-4 hover:shadow-md transition-shadow"
                  >
                    <div className="flex items-start justify-between mb-3">
                      <div>
                        <h3 className="text-lg font-semibold text-gray-900 mb-1">
                          {prompt.category}
                        </h3>
                        <span className="inline-block px-2 py-1 text-xs font-medium text-primary-700 bg-primary-100 rounded-full">
                          {prompt.type}
                        </span>
                      </div>
                    </div>
                    <div className="space-y-2">
                      {Array.isArray(prompt.prompts) ? (
                        prompt.prompts.map((singlePrompt, index) => (
                          <div
                            key={index}
                            className="p-3 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer transition-colors"
                            onClick={() => handlePromptSelect(singlePrompt)}
                          >
                            <div className="flex items-center justify-between">
                              <p className="text-gray-700 text-sm leading-relaxed">{singlePrompt}</p>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCopyPrompt(singlePrompt);
                                }}
                                className="text-gray-400 hover:text-primary-600 transition-colors ml-2"
                              >
                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div
                          className="p-3 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer transition-colors"
                          onClick={() => handlePromptSelect(prompt.prompts)}
                        >
                          <div className="flex items-center justify-between">
                            <p className="text-gray-700 text-sm leading-relaxed">{prompt.prompts}</p>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCopyPrompt(prompt.prompts);
                              }}
                              className="text-gray-400 hover:text-primary-600 transition-colors ml-2"
                            >
                              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-full min-h-[400px] text-center">
                <div className="w-16 h-16 text-gray-300 mb-4">
                  <svg className="w-full h-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                </div>
                <p className="text-gray-500 text-lg font-medium">No prompts found</p>
                <p className="text-gray-400 text-sm mt-2">Try adjusting your search or filters</p>
              </div>
            )
          ) : (
            <div className="flex items-center justify-center h-full min-h-[400px]">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PromptGallery;

import { TRYON_SERVER_URL } from "../config";
import { AsyncImage } from "loadable-image";

export default function ResultGrid({results, handleResultSelect, selectedResult}){
    return (
        <div
            className="grid grid-cols-2 lg:flex lg:justify-start gap-3 w-full overflow-y-auto lg:overflow-x-auto h-[300px] lg:h-auto pr-2">
            {results
                .filter((result) => {
                    if (!result.result) {
                        return (result?.status === 'completed' || result?.status === 'running' || result?.status === "pending") && (result?.model?.preprocessing === "completed" && result?.garment?.preprocessing === "completed");
                    }
                    return true;
                })
                .map((result, index) => (
                    <div
                        onClick={() => {
                            handleResultSelect(result)
                        }}
                        key={result?.id}
                        className={`relative aspect-[3/4] h-auto lg:h-48 cursor-pointer items-center justify-center border-2 border-solid rounded-lg hover:border-primary-opac ${selectedResult?.id === result.id ? 'border-primary-opac' : 'border-[#F8EEEE]'} `}
                    >
                        <div
                            className={`absolute inset-0 rounded-lg backdrop-blur-sm w-full h-full ${!(result?.id && result?.result) ? 'flex items-center justify-center' : 'hidden'}`}>
                            <div
                                className={`absolute rounded-lg loader ${!(result?.id && result?.result) ? 'flex ' : 'hidden'} `}></div>
                        </div>
                        {result?.result ? (
                            <div className="w-full h-full">
                                <AsyncImage
                                    loader={<div
                                        className="w-full h-full bg-gray-200 border border-solid border-gray-200 rounded-lg animate-pulse" />}
                                    error={<div className="w-full h-full bg-[#222]" />}
                                    className="w-full h-full object-cover object-top rounded-lg"
                                    alt="garment image"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        objectFit: 'contain',
                                        objectPosition: 'top',
                                        borderRadius: '0.5rem',
                                    }}
                                    src={`${TRYON_SERVER_URL}/${result.result?.image_url}`}
                                />
                            </div>
                        ) : (
                            <img
                                key={result?.id}
                                src={`${TRYON_SERVER_URL}/${result.model?.image_url}`}
                                alt="loading screen"
                                className="w-full h-full object-cover object-top rounded-lg"
                            />
                        )}
                    </div>
                ))}
        </div>
    )
}
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../assets/deleteIcon.svg";
import errorIcon from "../assets/errorIcon.svg";
import ErrorMessage from "../components/ErrorMessage";
import GradientFont from "../components/GradientFont";
import PictureGrid from "../components/PictureGrid";
import NoModels from "../assets/NoModels.svg";
import { TRYON_SERVER_URL } from "../config";
import { selectAccessToken, selectAuthenticated } from "../selectors";
import { useMediaQuery } from "react-responsive";

export default function ModelSwapAI(props) {
    const [modelImage, setModelImage] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [outputImage, setOutputImage] = useState(null)
    const [prompt, setPrompt] = useState("")
    const accessToken = useSelector(selectAccessToken) || null;
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorStatus, setErrorStatus] = useState('');
    const [errorCode, setErrorCode] = useState(null);
    const authenticated = useSelector(selectAuthenticated);
    const [experiments, setExperiments] = useState([]);
    const [experimentsFetched, setExperimentsFetched] = useState(false);
    const [fetchingExperiments, setFetchingExperiments] = useState({});
    const [advancedOptions, setAdvancedOptions] = useState(false);
    const navigate = useNavigate();

    const [seed, setSeed] = useState(-1);
    const [guidanceScale, setGuidanceScale] = useState(7.5);
    const [strength, setStrength] = useState(0.99);
    const [inferenceSteps, setInferenceSteps] = useState(20);
    const [garmentType, setGarmentType] = useState('upper garment');
    const [numResults, setNumResults] = useState(2);

    const handleSeedChange = (e) => setSeed(Number(e.target.value));
    const handleGuidanceScaleChange = (e) => setGuidanceScale(Number(e.target.value));
    const handleStrengthChange = (e) => setStrength(Number(e.target.value));
    const handleInferenceStepsChange = (e) => setInferenceSteps(Number(e.target.value));
    const handleGarmentTypeChange = (e) => setGarmentType(e.target.value);
    const handleNumResultsChange = (e) => setNumResults(Number(e.target.value));
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` })

    useEffect(() => {
        if (!experimentsFetched) {
            console.log("fetching experiments");
            fetchExperiments().then(() => {
            })
        }

        experiments.map((experiment) => {
            if (experiment.status === "pending" || experiment.status === "running") {
                if (!fetchingExperiments[experiment.id]) {
                    console.log("setTimeout", experiment.id)
                    setTimeout(fetchExperiment, 2000, experiment);
                }
            }
        })
    }, [experiments]);

    const handleErrorClose = () => {
        setError(false);
        setErrorCode(null);
        setErrorMessage("");
        setErrorStatus("");
    }

    const handleFileSelected = async (event) => {
        const files = event.target.files;
        console.log("file types in fm", files)

        if (files.length > 0) {
            console.log(files[0])
            setModelImage(URL.createObjectURL(files[0]))
            setSelectedFile(files[0])
        }
    };

    useEffect(() => {
        console.log("Experiment updated:", experiments);
    }, [experiments]);

    const handleLoginRedirect = () => {
        navigate("/signin");
    }


    const uploadExperimentImage = async (file) => {
        if (!authenticated){
            setErrorStatus("Login Required");
            setErrorMessage("User not logged in. Please Log in and try Again");
            setErrorCode(401);
            setError(true);
            return;
        }
        try {
            const resizedImageUrl = await resizeUploadedFile(file);
            const resizedFile = dataURLToBlob(resizedImageUrl);
            console.log("after resize", file?.name, resizedFile);

            const formData = new FormData();
            formData.append('type', 'model');
            formData.append('access', 'private');
            formData.append('image', resizedFile, file?.name);
            formData.append('preprocess', false);

            let headers = {};
            if (accessToken) {
                headers = {
                    'Authorization': `Bearer ${accessToken}`
                };
            }
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment_image/`, {
                method: 'POST',
                headers: headers,
                body: formData,
            });

            if (!response.ok) {
                if (response.status === 401) {
                    throw new Error("User not logged in. Please Log in and try Again");
                } else {
                    throw new Error(JSON.stringify(await response.json()));
                }
            }

            const data = await response.json();
            console.log("uploaded image", data);

            console.log("updating params", guidanceScale, strength, inferenceSteps, garmentType, seed, numResults);

            // create an experiment
            const experimentData = new FormData();
            experimentData.append("model_id", data.id);
            experimentData.append("action", "model_swap");
            experimentData.append("params", JSON.stringify({
                prompt: prompt, guidance_scale: guidanceScale,
                strength: strength, num_inference_steps: inferenceSteps, num_results: numResults, seed: seed, garment_class: garmentType,
                negative_prompt: "(hands:1.15), disfigured, ugly, bad, immature, cartoon, anime, 3d, painting, b&w, (ugly), (pixelated), watermark, glossy, smooth, earrings, necklace"
            }));

            console.log("Experiment data:", experimentData);

            const response2 = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment/`, {
                method: 'POST',
                headers: headers,
                body: experimentData,
            });

            if (!response2.ok) {
                if (response2.status === 401) {
                    throw new Error("User not logged in. Please Log in and try Again");
                } else {
                    throw new Error(JSON.stringify(await response.json()));
                }
            }

            const data2 = await response2.json();
            console.log("Experiment created successfully", data2.experiment);

            let experiments1 = [...[data2.experiment], ...experiments]

            setExperiments(experiments1)

            setModelImage(null)
            setPrompt("")
        } catch (error) {
            console.error("Error during try on:", error.message);
            if (isJSON(error?.message)) {
                let errorData = JSON.parse(error?.message);
                if (errorData?.image && Array.isArray(errorData?.image) && errorData?.image?.length > 0) {
                    setErrorMessage(errorData.image[0]);
                } else if (errorData?.message && Array.isArray(errorData?.message) && errorData?.message?.length > 0) {
                    setErrorMessage(errorData?.message[0]);
                } else {
                    setErrorMessage('Something went wrong! Please try again later');
                }
                // setErrorMessage(errorData[0]);
                setErrorStatus(errorData?.status);
                setError(true);
            } else {
                setErrorStatus("Failure!")
                setErrorMessage(error?.message);
                setError(true);
            }
        }
    }

    const fetchExperiments = async () => {
        // Fetch user results if authenticated or accessToken is available
        if (authenticated || accessToken) {
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment/?aimodel=model_swap&access=private`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                let res = await response.json();
                console.log("Fetched experiments:", res);
                setExperiments(res);
                setExperimentsFetched(true);
            } else {
                console.error('Failed to fetch model swap experiments');
            }
        } else {
            // setErrorMessage("User not logged in. Please Log in and try Again");
            // setErrorStatus("Failure!");
            // setErrorCode(401);
            // setError(true);
            console.error("User not logged in. Please Log in and try Again");
        }
    }

    //Check if the response is JSON
    function isJSON(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    // Resizes the image and returns the resized image dataURL
    const resizeUploadedFile = (file) => {
        return new Promise((resolve, reject) => {
            if (file.type.match(/image.*/)) {
                // Load the image
                var reader = new FileReader();
                reader.onload = function (readerEvent) {
                    var image = new Image();
                    image.onload = function (imageEvent) {
                        // Resize the image
                        var canvas = document.createElement('canvas'),
                            max_size = 1024, // TODO: pull max size from a site config
                            width = image.width,
                            height = image.height;
                        if (width > height) {
                            if (width > max_size) {
                                height *= max_size / width;
                                width = max_size;
                            }
                        } else {
                            if (height > max_size) {
                                width *= max_size / height;
                                height = max_size;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                        var dataUrl = canvas.toDataURL('image/jpeg');
                        resolve(dataUrl); // Resolve with the resized image URL
                    };
                    image.src = readerEvent.target.result;
                };
                reader.readAsDataURL(file);
            } else {
                reject(new Error('Uploaded file is not an image.'));
            }
        });
    };

    // Convert the resized image dataURL back to a Blob
    function dataURLToBlob(dataURL) {
        var parts = dataURL.split(';base64,');
        var contentType = parts[0].split(':')[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;
        var uInt8Array = new Uint8Array(rawLength);

        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], { type: contentType });
    }

    async function handleGenerateClick(e) {
        console.log("Generate button clicked")
        if (selectedFile !== null && prompt !== "") {
            uploadExperimentImage(selectedFile).then(() => {
            })
        } else {
            setErrorMessage("Upload an image and provide a prompt");
            setErrorStatus("Failure!");
            setError(true);
            setErrorCode(400);
        }

        return false;
    }

    function handlePromptChange(e) {
        setPrompt(e.target.value)
    }

    const toggleAdvancedOptions = () => {
        setAdvancedOptions((prev) => !prev);
    }

    const fetchExperiment = async (experiment) => {
        if (!fetchingExperiments[experiment.id]) {
            let fetchingExperiments1 = { ...fetchingExperiments }; // Clone the state object to avoid direct mutation
            fetchingExperiments1[experiment.id] = true;
            setFetchingExperiments(fetchingExperiments1);

            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/experiment/${experiment.id}/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                let res = await response.json();
                let fetchedExperiment = res.experiment;

                console.log("Fetched experiment:", fetchedExperiment);

                if (fetchedExperiment.status === 'completed' || fetchedExperiment.status === 'failed') {
                    console.log("Final status reached:", fetchedExperiment.status);

                    setExperiments((prevExperiments) =>
                        prevExperiments.map((experiment1) =>
                            experiment1.id === fetchedExperiment.id ? { ...experiment1, ...fetchedExperiment } : experiment1
                        )
                    );
                } else if (fetchedExperiment.status === 'pending' || fetchedExperiment.status === 'running') {
                    console.log("Experiment is still running, polling again...");
                    setTimeout(() => fetchExperiment(experiment), 5000);
                }
            } else {
                console.error('Failed to fetch model swap experiment');
            }

            fetchingExperiments1[experiment.id] = false;
            setFetchingExperiments(fetchingExperiments1);
        }
    };


    return (<div>
        <div className="max-w-7xl lg:h-[89vh] lg:grid lg:grid-cols-4 place-content-stretch gap-4 m-4">
            <div className="flex-col flex h-full mb-4">
                {isMobile?(
                <p className="mb-2 text-center text-lg font-Mulish">Model Swap AI</p>):(<></>)}
                
                <div className="bg-white/30 rounded-lg p-4 col-span-1">
                    {modelImage ? (
                        <div
                            className="relative flex justify-center w-full mx-auto border border-solid border-stone-400 items-center bg-opac-preview rounded-lg">
                            <img src={modelImage} className="h-72 w-auto object-contain rounded-lg" alt="model image" />
                            <img src={DeleteIcon} alt="close icon" onClick={() => {
                                setModelImage(null)
                            }}
                                className="absolute top-2 right-2 w-6 h-auto cursor-pointer bg-red-500 rounded-full p-1 shadow" />
                        </div>
                    ) : (
                        <div className="flex items-center justify-center w-full">
                            <label htmlFor="dropzone-file"
                                className="flex flex-col h-72 items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                    </svg>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span
                                        className="font-semibold">Click to upload</span> or drag and drop</p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX.
                                        800x400px)</p>
                                </div>
                                <input id="dropzone-file" type="file" className="hidden"
                                    onChange={handleFileSelected} />
                            </label>
                        </div>)}

                    <textarea
                        name="prompt" rows="3"
                        className="rounded-lg bg-white w-full shadow border border-stone-400 focus:border-rose-600 mt-4
                    backdrop-blur-[30px] py-2 px-4"
                        placeholder="Enter prompt here!" value={prompt} onChange={handlePromptChange} />

                    <div className="flex flex-col mb-4">
                        <label htmlFor="numResults" className="text-sm">
                            Number of Results
                        </label>
                        <input
                            id="numResults"
                            name="numberResults"
                            type="text"
                            className="rounded-lg bg-white w-full shadow border border-stone-400 focus:border-rose-600 mt-2 px-3 py-2"
                            value={numResults}
                            onChange={handleNumResultsChange}
                            min={1}
                            max={5}
                        />
                    </div>



                    <div onClick={() => toggleAdvancedOptions()} className="flex my-4 mx-1 justify-between items-center">
                        <span className="text-sm">Advanced Options</span>
                        <div className={`transform transition-transform duration-300 ${advancedOptions ? 'rotate-180' : 'rotate-0'}`}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path fillRule="evenodd" d="M5.293 9.707a1 1 0 011.414 0L10 13l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </div>

                    </div>

                    {advancedOptions && (
                        <div className="flex flex-col">
                            <div className="flex flex-col mb-4">
                                <label htmlFor="seed" className="text-sm">
                                    Seed
                                </label>
                                <input
                                    name="seed"
                                    type="number"
                                    className="rounded-lg bg-white w-full shadow border border-stone-400 focus:border-rose-600 mt-2 px-3 py-2"
                                    value={seed}
                                    onChange={handleSeedChange}
                                />
                            </div>

                            <div className="flex flex-col mb-4">
                                <label htmlFor="guidanceScale" className="text-sm">
                                    Guidance Scale
                                </label>
                                <input
                                    name="guidanceScale"
                                    type="number"
                                    step="0.1"
                                    max="20.0"
                                    min="0.0"
                                    className="rounded-lg bg-white w-full shadow border border-stone-400 focus:border-rose-600 mt-2 px-3 py-2"
                                    value={guidanceScale}
                                    onChange={handleGuidanceScaleChange}
                                />
                            </div>

                            <div className="flex flex-col mb-4">
                                <label htmlFor="strength" className="text-sm mb-2">
                                    Strength (0.1 to 1.0)
                                </label>
                                <input
                                    name="strength"
                                    type="range"
                                    min="0.0"
                                    max="1.0"
                                    step="0.01"
                                    className="appearance-none h-2 w-full rounded-lg bg-gradient-to-r from-gray-200 to-black focus:outline-none"
                                    value={strength}
                                    onChange={handleStrengthChange}
                                />
                                <span className="text-sm mt-2">Strength: {strength}</span>
                            </div>

                            <div className="flex flex-col mb-4">
                                <label htmlFor="inferenceSteps" className="text-sm">
                                    Inference Steps
                                </label>
                                <input
                                    name="inferenceSteps"
                                    type="number"
                                    className="rounded-lg bg-white w-full shadow border border-stone-400 focus:border-rose-600 mt-2 px-3 py-2"
                                    value={inferenceSteps}
                                    onChange={handleInferenceStepsChange}
                                />
                            </div>

                            <div className="flex flex-col mb-4">
                                <label htmlFor="garmentType" className="text-sm">
                                    Garment Type
                                </label>
                                <select
                                    name="garmentType"
                                    className="rounded-lg bg-white w-full shadow border border-stone-400 focus:border-rose-600 mt-2 px-3 py-2"
                                    value={garmentType}
                                    onChange={handleGarmentTypeChange}
                                >
                                    <option value="upper garment">Upper Garment</option>
                                    <option value="lower garment">Lower Garment</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                        </div>
                    )}

                    <button
                        className="py-2 px-4 w-full mt-2 mx-auto text-bg bg-gradient-to-r from-red-500 to-rose-600 rounded-lg
                    text-white cursor-pointer" onClick={handleGenerateClick}>
                        Generate
                    </button>

                </div>
            </div>

            <div className="bg-white/30 rounded-lg col-span-3 h-full overflow-auto relative">
                <div className="flex h-full">
                    <div
                        className=" hidden relative flex justify-center w-full mx-auto items-center bg-opac-preview rounded-lg">
                        {modelImage ? (
                            <img src={modelImage} className="h-full w-auto object-fit rounded-lg"
                                alt="output image" />) : (
                            <div className="text-sm text-gray-600">Output Model Image</div>)}
                    </div>


                    {authenticated === null ?
                        <div className="flex flex-wrap gap-2 p-4 rounded-lg w-full">
                            <div className="w-full shadow rounded-lg bg-white m-4 animate-pulse">
                                <div className="bg-slate-200 rounded-lg h-72 col-span-2 m-4"></div>
                                <div className="bg-slate-200 rounded-lg h-72 col-span-2 m-4"></div>
                                <div className="bg-slate-200 rounded-lg h-72 col-span-2 m-4"></div>
                            </div>
                        </div>
                        :
                        authenticated ?
                            <div className="flex flex-wrap gap-2 p-4 rounded-lg w-full">
                                {
                                    experimentsFetched && (experiments.length > 0
                                        ?
                                        experiments.map((experiment) => (
                                            <PictureGrid experiment={experiment} />
                                        ))
                                        :
                                        <div className='flex flex-col items-center justify-center h-full w-full p-4 mb-2 bg-opacity-70 backdrop-blur-lg rounded-xl bg-white/60 border-2 border-rose-100'>
                                            <div className="flex flex-col w-full items-center justify-center ">
                                                <img src={NoModels} alt="error" className='object-contain h-16 object-center w-16 mb-4' />
                                                <div className="!text-xl font-semibold text-center font-OpenSans ">
                                                    <GradientFont>{"No Experiments Found!"}</GradientFont>
                                                </div>
                                            </div>
                                            <span className='ml-4 my-4 !text-neutral-800 text-center font-OpenSans text-sm font-normal leading-normal'> Generated Models will be displayed here</span>
                                        </div>
                                    )
                                }

                            </div>
                            :
                            <div className='flex flex-col w-full h-full items-center justify-center px-4 pt-4 pb-4'>
                                <div className='flex relative items-center justify-center left-0 right-0 bg-opacity-70 backdrop-blur-lg w-full h-full rounded-2xl bg-white/70 border-2 border-rose-100'>

                                    <div className='relative flex flex-row items-center justify-center h-full w-fit'>

                                        <div className='flex flex-col items-center justify-center h-full w-full p-4 mb-2'>

                                            <div className="flex flex-row w-full items-center justify-center">
                                                <img src={errorIcon} alt="error" className='object-contain h-8 object-center w-8 mr-4' />
                                                <div className="!text-xl font-semibold text-center font-OpenSans ">
                                                    <GradientFont>{"Authentication Required"}</GradientFont>
                                                </div>
                                            </div>
                                            <span className='ml-4 my-2 !text-neutral-800 text-center font-OpenSans text-sm font-normal leading-normal'> Please Sign in/Sign up to perform Model Swap</span>

                                            <div onClick={handleLoginRedirect} className='flex flex-row mt-2 cursor-pointer items-center justify-center bg-gradient-to-r from-[#F05941] to-[#BE3144] rounded-[50px] border-1 border-solid border-[#EFCBCB] backdrop-blur-[50px] py-3 px-8'>
                                                <div className={` flex flex-row items-center justify-center w-fit h-full `}>
                                                    <span className={`!text-white text-sm font-semibold font-OpenSans leading-normal`}>Sign in</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                    }

                    {error && (
                        <div className="absolute inset-0">
                            <ErrorMessage
                                errorStatus={errorStatus}
                                errorMessage={errorMessage}
                                onClose={handleErrorClose}
                            />
                        </div>
                    )}
                </div>
            </div>

        </div>
    </div>);
}

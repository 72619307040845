// AuthActions.js
import { createAsyncThunk } from "@reduxjs/toolkit";

export const setAccessToken = (accessToken) => {
    console.log('Access token updated:', accessToken);
    localStorage.setItem('accessToken', accessToken);
    return {
        type: 'SET_ACCESS_TOKEN',
        payload: accessToken,
    };
};

export const setRefreshToken = (refreshToken) => {
    console.log('Refresh token updated:', refreshToken);
    localStorage.setItem('refreshToken', refreshToken);
    return {
        type: 'SET_REFRESH_TOKEN',
        payload: refreshToken,
    };
};

export const setAuthenticated = (authenticated) => {
    console.log("authenticated is updated", authenticated)
    sessionStorage.setItem('authenticated', authenticated);
    return {
        type: 'SET_AUTHENTICATED',
        payload: authenticated,
    };
};

export const setUserID = (userid) => {
    localStorage.setItem('userid', userid);
    return {
        type: 'SET_USER_ID',
        payload: userid,
    };
};

export const setUserData = (user_data) => {
    localStorage.setItem('user_data', user_data); 
    return {
        type: 'SET_USER_DATA',
        payload: user_data, 
    };  
};

export const clearData = () => ({
    type: 'CLEAR_DATA'
});


export const logoutUser = createAsyncThunk('/signout', async (_, { dispatch }) => {
    try {
        localStorage.clear();
        sessionStorage.clear();

        dispatch(clearData());
        dispatch(setAuthenticated(false));

        return 'User logged out successfully';
    } catch (error) {
        console.error('Logout error:', error);
        throw error;
    }
});
  
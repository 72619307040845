
import React, { useState, useRef, useEffect } from 'react';
import HumanOutline from "../assets/HumanOutline.svg";
import camera from "../assets/camera.svg";
import switchCameraSvg from "../assets/switchCamera.svg";
import Spinner from './Spinner';

import backArrow from "../assets/backArrow.svg";
import closeSvg from "../assets/close.svg";

const WebcamOverlay = ({ onUpload, handleUserModelClick, onClose }) => {
    const videoRef = useRef();
    const canvasRef = useRef(null);
    const streamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);
    const [facingMode, setFacingMode] = useState("user");
    const [loading, setLoading] = useState(true);
    const [errormsg, setErrorMsg] = useState(null);
    const [timer, setTimer] = useState(0);
    const [isCapturing, setIsCapturing] = useState(false);
    const [cameraInput, setCameraInput] = useState(false);


    useEffect(() => {
        console.log("useEffect is triggered", cameraInput)
        if (cameraInput) {
            startCamera();
        }
        return () => {
            stopCamera(); // Cleanup function to stop camera stream on unmount
        };
    }, [cameraInput, facingMode,]);


    const startCamera = async () => {
        setLoading(true);
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: { facingMode: facingMode }
            });
            streamRef.current = stream; // Store the stream in streamRef
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                videoRef.current.onloadedmetadata = () => {
                    videoRef.current.play();
                };
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setErrorMsg('Error accessing the camera: ' + err);
        }
    };

    const stopCamera = () => {
        console.log("turnOffCamera is triggered", videoRef);

        if (streamRef.current) {
            const tracks = streamRef.current.getTracks();
            tracks.forEach((track) => {
                track.stop();
            });
            streamRef.current = null; // Clear the stored stream
            console.log("Camera stopped", tracks);
        }

        if (videoRef?.current) {
            videoRef.current.srcObject = null;
            console.log("Video ref srcObject set to null");
        }
        setIsCapturing(false)
    };


    useEffect(() => {
        console.log("videoRef changed:", videoRef.current);
        if (!videoRef.current || imgSrc) {
            stopCamera();
        }
    }, [videoRef.current, imgSrc]);

    const capture = () => {
        setIsCapturing(true);
        setTimer(5);

        const countdown = setInterval(() => {
            setTimer((prev) => {
                if (prev <= 1) {
                    clearInterval(countdown);
                    const video = videoRef.current;
                    const context = canvasRef.current.getContext('2d');
                    const parent = video.parentNode;

                    // Capture the full video frame
                    const tempCanvas = document.createElement('canvas');
                    tempCanvas.width = video.videoWidth;
                    tempCanvas.height = video.videoHeight;
                    const tempContext = tempCanvas.getContext('2d');
                    tempContext.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                    const fullImage = tempContext.getImageData(0, 0, video.videoWidth, video.videoHeight);

                    // Setting canvas dimensions to match the parent element dimensions
                    canvasRef.current.width = parent.clientWidth;
                    canvasRef.current.height = parent.clientHeight;

                    const aspectRatio = 9 / 16;
                    const targetWidth = canvasRef.current.width;
                    const targetHeight = canvasRef.current.height;

                    // Calculate cropping coordinates to get the center 9/16 portion
                    const sx = (video.videoWidth - (video.videoHeight * aspectRatio)) / 2;
                    const sy = 0;
                    const sWidth = video.videoHeight * aspectRatio;
                    const sHeight = video.videoHeight;

                    context.save();
                    if (facingMode === 'user') {
                        context.translate(canvasRef.current.width, 0);
                        context.scale(-1, 1);
                    }

                    // Draw the cropped and scaled image on the canvas
                    context.drawImage(tempCanvas, sx, sy, sWidth, sHeight, 0, 0, targetWidth, targetHeight);
                    context.restore();

                    setImgSrc(canvasRef.current.toDataURL('image/jpeg'));
                    setIsCapturing(false);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    };


    const switchCamera = () => {
        setFacingMode((prevFacingMode) => (prevFacingMode === "user" ? "environment" : "user"));
    };

    const handleTryAgain = () => {
        setImgSrc(null);
        // Call stopCamera here to ensure stream is stopped before restarting
        stopCamera();
        startCamera();
    };

    const handleUpload = async () => {

        if (imgSrc && onUpload) {
            const response = await fetch(imgSrc);
            const blob = await response.blob();
            const file = new File([blob], "captured_image.jpg", { type: "image/jpeg" });
            onUpload(file);
            setCameraInput(false);
            onClose()
        }

    };

    const getAspectRatio = () => (window.innerWidth > window.innerHeight ? '16/9' : '9/16');

    return (


        <div className="relative flex flex-row w-full min-h-[80%] h-fit max-h-full bg-white border border-dashed border-selected rounded-lg shadow-auth-box-shadow py-4">
            <div className="flex w-full items-start justify-between absolute top-2 px-4 ">
                {cameraInput ? (<button
                    onClick={() => setCameraInput(false)}
                // className="absolute top-6 left-6 w-8 h-8 flex items-center justify-center bg-transparent hover:bg-gray-200 rounded-full"
                >
                    <img className="w-4 h-4 -scale-x-100" src={backArrow} alt="Back" />
                </button>) : <></>}
                <button
                    type="button"
                    onClick={() => {
                        setCameraInput(false);
                        onClose();
                    }}
                    // className="absolute top-4 right-4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex justify-center items-center"
                    data-modal-hide="default-modal"
                >
                    <img className="w-4 h-4" src={closeSvg} alt="Close" />
                </button>
            </div>
            <div className="flex w-full ">
                {cameraInput ? (
                    <div className="flex items-center justify-center w-full h-full p-6">
                        <div className='flex flex-col w-full h-full'>
                            {!imgSrc && (
                                <div className='flex flex-col w-full h-full justify-center items-center relative'>
                                    <div className="relative flex min-w-[20%] w-auto max-w-full h-full justify-center items-center ">
                                        <video
                                            ref={videoRef}
                                            className={`rounded-lg shadow-md w-full h-full object-cover transform ${facingMode === "user" ? 'scale-x-[-1]' : ''}`}
                                            style={{ aspectRatio: '9 / 16' }}
                                            onCanPlay={() => setLoading(false)}
                                            muted
                                        />
                                        <div className='absolute inset-0 flex items-end justify-center w-full h-full'>
                                            <img src={HumanOutline} className='object-cover opacity-70 w-full h-full' alt="Human Outline" />
                                        </div>
                                        <canvas
                                            ref={canvasRef}
                                            className="hidden"
                                            width={640}
                                            height={360}
                                        ></canvas>
                                        {loading && (
                                            <div className='absolute inset-0 flex items-center justify-center'>
                                                <Spinner />
                                            </div>
                                        )}
                                        {errormsg && (
                                            <div className='absolute bottom-0 left-0 m-4 text-red-500'>
                                                {errormsg}
                                                <button
                                                    onClick={() => {
                                                        setErrorMsg(null);
                                                        // stopCamera();
                                                        onClose();
                                                    }}
                                                    className="underline text-[#ED5642] ml-2"
                                                >
                                                    Go back
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    <div className="relative w-full h-full mt-6 flex items-center justify-center space-x-4">
                                        <button
                                            onClick={capture}
                                            disabled={isCapturing}
                                            className="flex items-center px-4 py-2 text-[#ED5642] border-2 border-[#ED5642] border-solid rounded-full transition-all duration-300 hover:bg-[#ED5642] hover:text-white"
                                        >
                                            <img src={camera} alt="Capture" className="w-6 h-6 mr-2" />
                                            {isCapturing ? `Capturing in ${timer}s` : 'Capture photo'}
                                        </button>
                                        <button
                                            onClick={switchCamera}
                                            className="flex items-center px-4 py-2 text-[#ED5642] border-2 border-[#ED5642] border-solid rounded-full transition-all duration-300 hover:bg-[#ED5642] hover:text-white"
                                        >
                                            <img src={switchCameraSvg} alt="Switch camera" className="w-6 h-6 mr-2" />
                                            Switch camera
                                        </button>
                                        {/* <button onClick= {()=>{stopCamera()}}>click</button> */}

                                    </div>
                                </div>
                            )}
                            {imgSrc && (
                                <div className='flex flex-col w-full h-full justify-center items-center relative'>
                                    <div className="flex w-full h-full items-center justify-center relative">
                                        <img src={imgSrc} alt="Captured" className="mt-2 min-h-[60%] h-fit max-h-full rounded-lg shadow-md object-contain" />
                                    </div>
                                    <div className="mt-6 flex space-x-4">
                                        <button
                                            onClick={handleTryAgain}
                                            className="flex items-center px-4 py-2 text-[#ED5642] border-2 border-[#ED5642] border-solid rounded-full transition-all duration-300 hover:bg-[#ED5642] hover:text-white"
                                        >
                                            Try again
                                        </button>
                                        <button
                                            onClick={handleUpload}
                                            className="flex items-center px-4 py-2 text-[#28A745] border-2 border-[#28A745] border-solid rounded-full transition-all duration-300 hover:bg-[#28A745] hover:text-white"
                                        >
                                            Upload
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col w-full m-6 items-center bg-[#FAEBEB] backdrop-blur-md justify-center rounded-lg border border-solid border-selected p-4 md:p-0">
                        <div
                            onClick={() => {
                                handleUserModelClick();
                                onClose();
                            }}
                            className="cursor-pointer flex items-center mb-6 justify-center bg-gradient-to-r from-[#F05941] to-[#BE3144] rounded-full border border-solid border-[#EFCBCB] backdrop-blur-md py-3 px-6 w-full sm:w-auto mx-4 transition-transform transform hover:scale-105"
                        >
                            <div className="flex items-center justify-center w-fit h-full font-OpenSans">
                                <span className="text-base font-semibold text-[#F9E0E0] leading-normal">
                                    Upload From Gallery
                                </span>
                            </div>
                        </div>
                        <div
                            onClick={() => { setCameraInput(true) }}
                            className="cursor-pointer flex items-center justify-center bg-gradient-to-r from-[#3B3F6A] to-[#22092C] rounded-full border border-solid border-[#EFCBCB] backdrop-blur-md py-3 px-6 w-full sm:w-auto mx-4 transition-transform transform hover:scale-105"
                        >
                            <div className="font-OpenSans flex items-center justify-center w-fit h-full">
                                <span className="text-base font-semibold text-center text-[#F9E0E0] leading-normal">
                                    Capture From Camera
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>


    );
};

export default WebcamOverlay;

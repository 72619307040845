import axios from "axios"
import { TRYON_SERVER_URL } from "../config"

/**
 * Update user data
 * @param {Object} userData - User data to update
 * @param {string} accessToken - Access token
 * @returns {Object} - Response from server
 */
export const updateUser = async (userData, accessToken) => {
    console.log("Updating user", userData, accessToken)
    try {
        const response = await fetch(`${TRYON_SERVER_URL}/api/v1/user/update/`, {
                method: 'POST',
                headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                userData
            )
        });

        if (response.ok) {
            const data = await response.json();
            const dataWithoutPassword = {...data.data, password: undefined};
            console.log("User updated successfully", dataWithoutPassword)
            return {"status": "success", "message": "User updated successfully", "data": dataWithoutPassword}
        } else {
            const errorData = await response.json();
            console.log("Error updating user", errorData)
            return {"status": "error", "message": "Failed to update user"}
        }
    } catch (error) {
        console.log("Error updating user", error)
        return {"status": "error", "message": "Failed to update user"}
    }
}

/**
 * Fetch user data
 * @param {string} accessToken - Access token
 * @returns {Object} - Response from server
 */
export const fetchUser = async (accessToken) => {
    try {
        console.log("access in fetch data", accessToken)
        const response = await fetch(`${TRYON_SERVER_URL}/api/v1/user/get/`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const data = await response.json();
            const dataWithoutPassword = {...data, password: undefined};
            return {"status": "success", "message": "User fetched successfully", "data": dataWithoutPassword}
        } else {
            const errorData = await response.json();
            console.log("Error fetching user", errorData)
            return {"status": "error", "message": "Failed to fetch user"}
        }
    } catch (error) {
        console.log("Error fetching user", error)
        return {"status": "error", "message": "Failed to fetch user"}
    }
}

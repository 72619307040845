import React, {useState} from 'react';
import { AsyncImage } from "loadable-image";
import downloadIcon from "../assets/download.svg";
import enalargeIcon from "../assets/expandicon.svg";
import ImageViewer from './ImageViewer';


const PictureCard = ({ experiment, imageUrl, label, index, TRYON_SERVER_URL,loading }) => {

    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState(null);

    const handleCloseModal = () => {
        setShowModal(false);
        setModalImage(null);
    };

    const handleOpenModal = (imageUrl) => {
        setShowModal(true);
        setModalImage(imageUrl);
    };

    const handleDownload = async (imageUrl) => {
        let headers = {};
        // if (accessToken) {
        //     headers = {
        //         'Authorization': `Bearer ${accessToken}`
        //     };
        // }
        const response = await fetch(imageUrl, {
            headers: headers
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'Result.png'; 
        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(url);
    };

    return (
        <div
            key={experiment.id}
            className={`group cursor-pointer relative flex items-center justify-center border-2 border-solid rounded-lg hover:border-[#BE3144] aspect-auto overflow-hidden border-[#F8EEEE] ${loading ? "backdrop-blur-sm animate-pulse w-full h-full" : ""}`}
        >
            <div className="relative flex w-full min-h-[30vh] h-full bg-gray-100 items-center justify-center cursor-pointer">
                <AsyncImage
                    loader={<div className="flex h-full bg-gray-200 items-center justify-center animate-pulse" />}
                    error={<div style={{ background: '#222' }} />}
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        objectFit: 'contain',
                        objectPosition: 'top',
                        position: 'relative',
                    }}
                    alt={`garment image ${index + 1}`}
                    src={`${TRYON_SERVER_URL}/${imageUrl}`} // Use either result or model image_url
                />
            </div>

            <div className="absolute top-1 w-full text-black hidden group-hover:flex justify-between items-center px-2">
                <div className='flex h-full p-1 items-center justify-center bg-white rounded-full shadow-lg'><img
                    src={downloadIcon}
                    onClick={() => {
                        handleDownload(`${TRYON_SERVER_URL}/${imageUrl}`)
                    }}
                    alt="DownloadIcon"
                    className='object-contain w-4 h-4 '></img></div>

                <div className='flex h-full p-2 items-center justify-center bg-white rounded-full shadow-lg'><img
                    src={enalargeIcon}
                    onClick={() => handleOpenModal(`${TRYON_SERVER_URL}/${imageUrl}`)}
                    alt="enlargeIcon"
                    className='object-contain w-[12px] h-[12px]'></img></div>
            </div>

            <div className="absolute bottom-2 right-2 text-black bg-white/60 backdrop-blur-md p-1 flex items-center justify-center rounded-md border">
                <span>{label}</span>
            </div>

            {showModal && (
                <ImageViewer
                    image={modalImage}
                    onClose={handleCloseModal}
                />
            )}

            { loading && (
                <div className="absolute inset-0 backdrop-blur-sm w-full h-full p-1">
                </div>
            )}
        </div>
    );
};

export default PictureCard;

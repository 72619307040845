import { TRYON_SERVER_URL, ADMIN_SHOP_URL } from "../config";
import axios from "axios";

const fetchAllResults = async (accessToken=null) => {
    console.log("fetching all results");

    let allResults = [];
    let errorFetchingUserResults = false;
    let errorFetchingPublicResults = false;
    let message = "";
    let baseUrl = `${TRYON_SERVER_URL}/api/v1/experiment/`;

    try {
        // fetching user results
        if(accessToken !== null){
            console.log("fetching user results");
            const response = await fetchResults(`${baseUrl}?aimodel=virtual_try_on`, accessToken);
            console.log("user results:", response);

            if(response.status === "success"){
                allResults = [...allResults, ...response.data];
            }else{
                errorFetchingUserResults = true;
                message = response.message;
            }
        }

        // fetching public results
        console.log("fetching public results");
        const response = await fetchResults(`${baseUrl}?shop=${ADMIN_SHOP_URL}&access=public`);

        if(response.status === "success"){
            allResults = [...allResults, ...response.data];
        }else{
            errorFetchingPublicResults = true;
            message = response.message;
        }

        if (errorFetchingUserResults && errorFetchingPublicResults) {
            console.error('Error fetching results');
            return {data: null, status: "error", statusCode: 400, message: message};
        }else {
            console.log("fetched all results:", allResults);
            return {data: allResults, status: "success", statusCode: 200, message: "Results fetched successfully"};
        }
    } catch (error) {
        console.error('Error fetching results:', error);
        return {data: null, status: "error", statusCode: 400, message: "Error fetching results: " + error};
    }
}

const fetchResults = async (url, accessToken=null) => {
    try {
        console.log("Fetching results...");
        let headers = {
            'Content-Type': 'application/json',
        };
        if (accessToken !== null) {
            headers.Authorization = `Bearer ${accessToken}`;
        }

        const response = await axios.get(url, {
            headers: headers,
        });

        if (response.status === 200) {
            const results = response.data;
            console.log("Fetched results:", response.data);
            return {data: results, status: "success", statusCode: response.status, message: "Results fetched successfully"};
        } else {
            console.error('Failed to fetch results');
            return {data: null, status: "error", statusCode: response.status, message: "Failed to fetch results"};
        }
    } catch (error) {
        console.error('Error fetching results:', error);
        return {data: null, status: "error", statusCode: 400, message: "Error fetching results: " + error};
    }
}

/**
 * Get the number of valid results
 * Valid results are the ones that are completed, running or pending
 * @param {Array} results - The array of results
 * @returns {number} - The number of valid results
 */
const getValidResultsCount = (results) => {
    console.log("getValidResultsCount:results:", results);
    return results.filter((result) => result.status === "completed" || result.status === "running" || result.status === "pending").length;
}

/**
 * Get the results except the failed ones
 * @param {Array} results - The array of results
 * @returns {Array} - The array of results except the failed ones
 */
const getResultsExceptFailed = (results) => {
    console.log("getResultsExceptFailed:results:", results);
    return results.filter((result) => result.status !== "failed");
}

/**
 * Fetch an experiment
 * @param {string} experimentId - The id of the experiment
 * @param {string} accessToken - The access token
 * @returns {Object} - The experiment
 */
const fetchExperiment = async (experimentId, accessToken) => {
    try{
        console.log("Fetching experiment...")
        let baseUrl = `${TRYON_SERVER_URL}/api/v1/experiment/${experimentId}/`;
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }

        let res = await axios.get(baseUrl, {
            headers: headers
        })

        if(res.status === 200){
            console.log("Experiment fetched:", res.data)
            return {data: res.data, status: "success", statusCode: res.status, message: "Experiment fetched successfully"}
        }else{
            console.log("Failed to fetch experiment")
            return {data: null, status: "error", statusCode: res.status, message: "Failed to fetch experiment"}
        }
    }catch(error){
        console.error("Error fetching experiment:", error)
        return {data: null, status: "error", statusCode: 400, message: "Error fetching experiment: " + error}
    }
}

/**
 * Get the status of an experiment
 * @param {string} experimentId - The id of the experiment
 * @param {string} accessToken - The access token
 * @returns {string} - The status of the experiment
 */
const getExperimentStatus = async (experimentId, accessToken) => {
    try{
        // fetch experiment status
        let experiment = await fetchExperiment(experimentId, accessToken)
        if(experiment.status === "success"){
            return experiment.data.status
        }else{
            return null
        }
    }catch(error){
        console.error("Error fetching experiment status:", error)
        return null
    }
}

/**
 * Update the all results with the new experiment
 * @param {Object} experiment - The experiment to update
 * @param {Array} allResults - The array of all results
 * @returns {Array} - The array of all results with the new experiment
 */
const updateAllResults = (experiment, allResults) => {
    console.log("Updating all results...")
    console.log("Experiment:", experiment)

    // relpace an experiment with the same id with the new experiment
    const updatedAllResults = allResults.map((result) => {
        if(result.id === experiment.id){
            return experiment
        }
        return result
    })

    return updatedAllResults
}

/**
 * Search for an experiment in the results
 * @param {Array} results - The array of results
 * @param {Object} selectedGarment - The selected garment
 * @param {Object} selectedModel - The selected model
 * @returns {Object} - The found result
 */
const searchExperiment = (results, selectedGarment, selectedModel) => {
    let foundResult = null;
    results.forEach(result => {
        if(result.garment.id === selectedGarment.id && result.model.id === selectedModel.id){
            foundResult = result;
        }
    })
    return foundResult;
}

export { fetchAllResults, fetchResults, getValidResultsCount, getResultsExceptFailed, getExperimentStatus, fetchExperiment, updateAllResults, searchExperiment };

import { AsyncImage } from "loadable-image";
import { TRYON_SERVER_URL } from "../config";
import ExpandIcon from "../assets/expandicon.svg";

export default function ImageGrid({type, images, handleImageSelect, setShowImagePreview, setPreviewImage, setSelectedType, selectedImage}) {
    return (
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 overflow-y-auto my-2 pr-2 grow">
            {images.map((image) => (
                <div className={`relative border-2 h-64 border-solid border-[#F8EEEE] cursor-pointer hover:border-primary-opac rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 ${image.id === selectedImage?.id ? "border-primary-opac" : ""}`}>
                    <div
                        onClick={(e) => {
                        e.stopPropagation();
                            handleImageSelect(image)
                        }} onTouchStart={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleImageSelect(image)
                        }}
                        key={image.id} className="rounded-lg h-full">
                        <AsyncImage
                                loader={<div
                                    className="flex bg-gray-200 border border-solid border-gray-200 rounded-lg items-center justify-center animate-pulse" />}
                                error={<div style={{ background: '#222' }} />}
                                className={`transition-border duration-300 cursor-pointer z-10`}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    objectFit: 'cover',
                                    objectPosition: 'top',
                                    position: 'relative',
                                    borderRadius: '0.5rem',
                                }}
                                alt="garment image"
                                src={`${TRYON_SERVER_URL}/${image?.image_url}`}
                            />
                    </div>

                    {(
                        (image.gender !== null && image.gender !== "Unknown") ||
                        (image.garment_brand !== null && image.garment_brand !== "Unknown")
                    ) && (
                    <div className="z-10 absolute bottom-0 left-0 right-0 flex items-center rounded-b-md bg-black/50 justify-between px-2 py-1 border-t border-gray-200/50">
                        {image.gender !== null && image.gender !== "Unknown" && (
                            <span className="text-xs text-white drop-shadow-xl">
                                {image.gender?.charAt(0).toUpperCase() + image.gender?.slice(1)}
                            </span>
                        )}
                        {image.garment_brand !== null && image.garment_brand !== "Unknown" && (
                            <span className="text-xs text-white drop-shadow-xl">
                                {image.garment_brand?.charAt(0).toUpperCase() + image.garment_brand?.slice(1)}
                            </span>
                        )}
                    </div>
                    )}

                    <button onClick={(e) => {
                        e.stopPropagation();
                        setShowImagePreview(true)
                        setPreviewImage(image)
                        setSelectedType(type)
                    }}
                        className="absolute cursor-pointer right-1 top-1 h-fit bg-white/90 rounded-full p-2 border border-solid border-primary-opac-2 z-20">
                        <img className="w-2 h-2" src={ExpandIcon} alt="Selected" />
                    </button>
                </div>
            ))}
        </div>
    )
}
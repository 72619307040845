export default function FileUpload({handleFileSelected, key1, buttonText, handleClick}) {

    return (
        <div className="flex flex-col h-80 items-center justify-center w-full border-2 border-gray-200/50 bg-white rounded-lg shadow-lg p-6">
            <label htmlFor={`dropzone-file-${key1}`}
                className="flex flex-col items-center justify-center w-full border-2 border-dashed border-gray-300 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 transition-colors duration-200 ease-in-out">
                <div className="flex flex-col items-center justify-center py-4 px-4 w-full">
                    <svg className="w-10 h-10 mb-4 text-gray-600" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2"
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p className="mb-2 text-sm text-gray-700 font-semibold text-center"><span
                        className="font-bold">Click to upload</span> or drag and drop</p>
                    <p className="text-xs text-gray-500 text-center">PNG, JPG, JPEG, WEBP (MAX.
                        1024x1024px)</p>
                </div>
                <input id={`dropzone-file-${key1}`} type="file" className="hidden" onChange={handleFileSelected} />
            </label>

            <p className="text-sm text-gray-600 my-4">
                Or
            </p>

            <div className="flex items-center justify-center w-full">
                <button className="text-sm bg-gradient-to-r w-full from-red-500 to-rose-600 hover:from-red-600 hover:to-rose-500 text-white px-4 py-2 rounded-md shadow-md" onClick={handleClick}>{buttonText}</button>
            </div>
        </div>
    )
}
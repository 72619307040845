import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { clearData, setAuthenticated, setUserData, setUserID } from "../store/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken, selectAuthenticated, selectUserData } from "../selectors";
import { TRYON_SERVER_URL } from "../config";
import ArrowUp from "../assets/arrow_up.png"
import ArrowDown from "../assets/arrow_down.png"
import LogoutIcon from "../assets/logout_icon.svg"
import UserAccountIcon from "../assets/user_account.svg"
import { useAuthentication } from "../Authentication";
import { logoutUser } from "../store/AuthActions";
import { useMediaQuery } from "react-responsive";

export default function Navbar2(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showDropdown, setShowDropdown] = useState(false);
    const [authenticated,setAuthenticated] = useState(false);
    const userData = JSON.parse(useSelector(selectUserData));
    const dropdownRef = useRef(null);
    const { isAuthenticated } = useAuthentication();
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` })
    
    useEffect(() => {
        const checkAuth = async () => {
            const authStatus = await isAuthenticated();
            setAuthenticated(authStatus);
            console.log("from auth Status", authStatus)
        };

        checkAuth();

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
              setShowDropdown(false);
            }
          };

          document.addEventListener('mousedown', handleClickOutside);

          return () => {
            document.removeEventListener('mousedown', handleClickOutside);
          };
    }, [isAuthenticated, navigate]);
    
    const toggleDropdown = () => setShowDropdown(!showDropdown);

    const logout = () => {
        dispatch(logoutUser())
          .then(() => {
            // Redirect or perform any other actions after successful logout
            console.log("logged out succesfully!")
            // navigate('/signin');
            window.location.reload();
          })
          .catch((error) => {
            // Handle any errors that occur during logout
            console.error('Logout error:', error);
          });
      };
    function handleSelectOption(option) {
        let options1 = props.options;
        Object.keys(options1).forEach((key) => {
            if (key === option) {
                options1[key] = true;
            } else {
                options1[key] = false;
            }
        })
        console.log(options1)
        props.handleOptionSelect(option, options1);
    }

    return (
        <div className="relative z-10" ref={dropdownRef}>
            <div className="flex justify-between md:justify-end items-center bg-white backdrop-blur-[30px] border-solid
                border-b border-stone-300 p-4 h-fit min-h-[5vh] max-h-[7vh]">
                <button onClick={props.toggleSidebar} data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar"
                    aria-controls="sidebar-multi-level-sidebar" type="button"
                    className="inline-flex items-center p-2 mt-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 ">
                    <span className="sr-only">Open sidebar</span>
                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path clipRule="evenodd" fillRule="evenodd"
                            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                    </svg>
                </button>
                <div className="flex justify-between items-center w-full">
                    <div className="flex items-center justify-start gap-4">
                        {!isMobile ? (
                            <div className="flex justify-start items-center gap-2">
                                <div className="text-xl font-semibold">
                                    {props.title_description.title}
                                </div>
                                <div className="text-sm text-gray-500">
                                    {props.title_description.description}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div>
                        { (authenticated) ? (
                                <div className="flex items-center justify-start gap-4">

                                    {userData && userData?.is_staff && (
                                        <button className="py-1 px-2 lg:px-4 lg:py-2 text-xs lg:text-base bg-gradient-to-r from-red-500 to-rose-600 rounded-lg text-white cursor-pointer"
                                            onClick={() => handleSelectOption("admin/dashboard")}>
                                            Dashboard
                                        </button>
                                    )}

                                    <button
                                        className="py-1 px-2 text-xs lg:text-base bg-gradient-to-r from-red-500 to-rose-600 rounded-lg text-white cursor-pointer"
                                        onClick={() => handleSelectOption("credits")}>
                                        Credits: {userData?.credits}
                                    </button>

                                    <div className="relative">
                                        <button type="button"
                                                onClick={toggleDropdown}
                                                className="flex justify-end items-center gap-2 cursor-pointer border py-1 px-4 rounded-lg"
                                                data-dropdown-toggle="dropdown">
                                            <div className="text-gray-900 text-base font-semibold">
                                                {userData?.username}
                                            </div>
                                            {showDropdown?(<img src={ArrowDown} alt="arrow down"/>):(<img src={ArrowUp} alt="arrow up"/>)}
                                            
                                        </button>

                                        {showDropdown && (
                                            <div
                                                className="absolute right-0 z-10 mt-2 origin-top-right w-36 rounded-bl-2xl rounded-br-2xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-6 px-4" role="none">
                                                    <div
                                                        className="cursor-pointer text-gray-700 text-sm flex justify-start items-center gap-2 mb-4"
                                                        onClick={() => {
                                                            handleSelectOption("profile")
                                                        }}><img src={UserAccountIcon} alt="Logout"/>
                                                        <div
                                                            className="text-neutral-800 text-base">Profile
                                                        </div>
                                                    </div>

                                                    <div onClick={logout}
                                                        className="cursor-pointer text-gray-700 w-full text-left text-sm flex justify-start items-center gap-2">
                                                        <img src={LogoutIcon} alt="Logout"/>
                                                        <div
                                                            className="text-neutral-800 text-base">Sign out
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            )
                            :
                            (
                                <div>
                                    <button
                                        onClick={() => {
                                            navigate("/signin")
                                        }} 
                                        className="flex flex-row items-center justify-center bg-gradient-to-r from-[#F05941] to-[#BE3144] rounded-full border border-[#EFCBCB] backdrop-blur-[50px] py-2 px-4 shadow-md hover:shadow-lg transition-shadow duration-200"
                                    >
                                        <span className="text-white text-xs font-semibold font-OpenSans leading-normal">Sign in</span>
                                    </button>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
}
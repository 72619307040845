import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from "../components/Spinner";
import TryonLabsLogo from "../assets/TryonLabsLogo.png";
import HideIcon from "../assets/HideIcon.svg";
import showIcon from "../assets/showIcon.svg";
import landingbg1 from "../assets/bgGradient.svg";
import { TRYON_SERVER_URL } from "../config";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setAccessToken, setRefreshToken, setUserData, setUserID } from "../store/AuthActions";
import { useAuthentication } from "../Authentication";
import { selectAuthenticated } from "../selectors";

export default function Verification() {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isAuthenticated } = useAuthentication();
    const authenticated = useSelector(selectAuthenticated)


    const {
        username = "",
        first_name = "",
        last_name = "",
        email = "",
        password = "",
    } = location.state || {};

    console.log("email", email)

    // Access the username from state
    const [otp, setOtp] = useState("");
    const [showError, setShowError] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState("This is an error");
    const [otpRegenerated, setOtpRegenerated] = useState(false);
    const [spinner, setSpinner] = useState(false);

    const checkAuthentication = useCallback(async () => {
        try {
            const userAuthenticated = await isAuthenticated();
            if (userAuthenticated) {
                if (location.state !== null) {
                    navigate(location.state.path)
                } else {
                    navigate("/");
                }
            }
        } catch (error) {
            console.error('Error checking authentication:', error);

            if (error.message && error.message.toLowerCase().includes('network')) {
                console.error('Network error, redirecting to login page');
                navigate("/login");
            }
        }
    }, [dispatch, isAuthenticated, navigate]);

    useEffect(() => {
        console.log("Authenticated:", authenticated)
        if (!authenticated) {
            checkAuthentication();
        }
    }, []);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const submitForm = async () => {
        setErrorMessage("");
        setShowError(false);

        if (email === "" || otp === "") {
            setErrorMessage("Enter valid OTP");
            setOtpRegenerated(false);
            setShowError(true);
            setSpinner(false);
            return;
        }

        try {
            const formData = new FormData();
            formData.append('email', email);
            formData.append('otp', otp);

            await verifyUser(formData);
        } catch (error) {
            setErrorMessage(error?.response?.data?.message || "Invalid email or otp")
            setOtpRegenerated(false);
            setShowError(true)
            setSpinner(false)
        }
    };

    const verifyUser = async (formData) => {
        try {
            const response = await axios.post(`${TRYON_SERVER_URL}/api/v1/user/verify/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const loginFormData1 = new FormData();
            loginFormData1.append('email', email);
            loginFormData1.append('password', password);
            console.log("login user", loginFormData1)

            await loginUser(loginFormData1);
            setSpinner(false)
            navigate("/signin");

        } catch (error) {
            throw error;
        }
    };


    const loginUser = async (formData) => {
        try {
            const response = await axios.post(`${TRYON_SERVER_URL}/api/v1/user/token/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log("status:", response)
            const { access, refresh } = response.data;
            console.log('Access token received:', access);
            localStorage.clear();
            sessionStorage.clear();
            dispatch(setAccessToken(access));
            dispatch(setRefreshToken(refresh));
            isAuthenticated().then(r => {
            });
            fetchUserData(access).then(r => {
            })
            // navigate to playground
            navigate("/");
        } catch (error) {
            // setErrorMessage("Invalid username or password")
            // setShowError(true)
            throw error;
        }
    };

    const regenerateOtp = async () => {
        try {
            // Simulating an API call for OTP regeneration
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/user/otp/regenerate`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: email }),
            });

            if (!response.ok) {
                throw new Error("Failed to regenerate OTP. Please try again.");
            }

            setOtpRegenerated(true);
            setErrorMessage(""); // Clear any previous errors
        } catch (error) {
            setShowError(true);
            setErrorMessage(error.message);
            setOtpRegenerated(false);
            setOtpRegenerated(false);
        }
    };

    const fetchUserData = async (accessToken) => {
        try {
            console.log("access in fetch data", accessToken)
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/user/get/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                const userid = data?.id
                console.log("userid", userid)
                dispatch(setUserID(userid));
                const dataWithoutPassword = { ...data, password: undefined }; // Omit the password field
                dispatch(setUserData(JSON.stringify(dataWithoutPassword)));
            } else {
                console.error('Failed to fetch user data');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);

        }
    };

    const handleVerify = (e) => {
        e.preventDefault();
        setSpinner(true);
        submitForm();
    };

    return (
        <div>
            <div
                className="flex w-full h-screen items-center justify-center overflow-hidden"
                style={{ backgroundImage: `url(${landingbg1})`, backgroundSize: "cover" }}
            >
                <div className="flex m-4 w-full items-center justify-center">
                    <div className="max-w-lg bg-inherit rounded-2xl shadow-auth-box-shadow backdrop-blur-[15px]">
                        <div className="w-full rounded-tl-2xl rounded-tr-2xl bg-opac-auth shadow-navbarShadow backdrop-blur-[15px]">
                            <div className="flex justify-center gap-4 items-center py-10 px-24">
                                <img src={TryonLabsLogo} alt="tryonlabs logo" className="w-12 h-auto" />
                                <div className="text-gray-900 text-xl font-medium font-Readex_pro">
                                    Tryon Labs
                                </div>
                            </div>
                        </div>

                        <div className="p-6">
                            <form action="#">
                                <div className="my-4">
                                    <label
                                        htmlFor="otp"
                                        className="text-neutral-800 text-sm leading-normal mb-4 mx-4"
                                    >
                                        Enter OTP here
                                    </label>
                                    <div className="relative flex justify-between items-center">
                                        <input
                                            type={passwordVisible ? "text" : "password"}
                                            name="otp"
                                            id="otp"
                                            placeholder="******"
                                            value={otp}
                                            onChange={(e) => {
                                                setErrorMessage("");
                                                setShowError(false);
                                                setOtp(e.target.value);
                                            }}
                                            className="w-full h-10 rounded-[118px] border border-stone-400 text-center focus:border-rose-600 mt-2 bg-opac-input px-4"
                                            required
                                        />
                                        <img
                                            onClick={togglePasswordVisibility}
                                            src={passwordVisible ? showIcon : HideIcon}
                                            alt="hide icon"
                                            className="absolute top-5 right-4 cursor-pointer"
                                        />
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    onClick={handleVerify}
                                    className="w-full h-12 px-[113px] cursor-pointer py-4 bg-gradient-to-r from-[#F05941] to-[#BE3144] rounded-[50px] border border-red-200 hover:border-red-600 backdrop-blur-[100px] justify-center items-center gap-2.5 inline-flex mb-6 mt-4"
                                >
                                    {spinner ?
                                        <Spinner />
                                        :
                                        <div className="text-[#F9E0E0] text-base font-semibold leading-normal">
                                            Verify
                                        </div>
                                    }
                                </button>

                                {showError && (
                                    <p className="mt-4 text-sm text-red-600 dark:text-red-400 text-center">
                                        {errorMessage}
                                    </p>
                                )}

                                {otpRegenerated && (
                                    <p className="mt-4 text-sm text-green-600 text-center">
                                        OTP has been regenerated successfully.
                                    </p>
                                )}

                                <div className="mt-4 text-center">
                                    <span className="text-sm text-gray-600">
                                        Didn't receive the OTP?{" "}
                                    </span>
                                    <span
                                        onClick={regenerateOtp}
                                        className="text-blue-600 underline cursor-pointer"
                                    >
                                        Regenerate OTP
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
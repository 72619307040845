import React from "react";
import GradientFont from "./GradientFont";

export default function StatusModal({ payload, onCancel, onDelete }) {
    const { title, message, detail } = payload;

    return (
        <div className='!z-50 fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-900 backdrop-blur-lg'>
            <div id="alert-additional-content-2" className="p-4 mb-4 rounded-lg font-OpenSans bg-white shadow-modalShadow " role="alert">
                <div className="flex items-center justify-center">
                    <span className="sr-only">{title}</span>
                    <h3 className="!text-lg font-OpenSans font-medium">{message}</h3>
                </div>
                <div className="!mt-2 mb-4 font-OpenSans text-sm">
                    {detail}
                </div>
                <div className="!flex w-full items-center font-OpenSans justify-center">
                    <div onClick={onCancel} className="cursor-pointer text-black focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-md px-3 py-1.5 me-2 text-center inline-flex items-center">
                        Cancel
                    </div>
                    <div onClick={async (e) => {
                        await onDelete(e);
                    }} className="cursor-pointer bg-transparent border border-red-800 hover:text-white focus:ring-4 focus:outline-none  font-medium rounded-lg text-md px-3 py-1.5 text-center" data-dismiss-target="#alert-additional-content-2" aria-label="Close">
                        <GradientFont>Delete</GradientFont>
                    </div>
                </div>
            </div>
        </div>
    );
}

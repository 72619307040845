export default function ToastSuccessMessage({ message, handleClose }) {

    return (
        <div 
            className="fixed bottom-5 left-1/2 transform -translate-x-1/2 flex items-center w-full max-w-lg p-4 bg-white/95 backdrop-blur-sm rounded-xl shadow-lg border border-emerald-100 animate-slide-up mx-4" 
            role="alert"
        >
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-emerald-500 bg-emerald-100 rounded-lg">
                <svg 
                    className="w-5 h-5" 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 24 24" 
                    fill="currentColor"
                >
                    <path 
                        fillRule="evenodd" 
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" 
                        clipRule="evenodd" 
                    />
                </svg>
            </div>
            <div className="flex flex-col ml-3">
                <div className="text-base font-medium text-emerald-600">Success</div>
                <div className="text-sm text-emerald-500">{message}</div>
            </div>
            <button 
                type="button" 
                className="ml-auto -mr-1 flex items-center justify-center w-8 h-8 text-gray-400 hover:text-gray-900 rounded-lg p-1.5 hover:bg-gray-100 transition-all duration-200" 
                onClick={handleClose}
            >
                <span className="sr-only">Close</span>
                <svg 
                    className="w-3.5 h-3.5" 
                    xmlns="http://www.w3.org/2000/svg" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    strokeWidth={2} 
                    stroke="currentColor"
                >
                    <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        d="M6 18L18 6M6 6l12 12" 
                    />
                </svg>
            </button>
        </div>
    )
}

import React, { useState } from "react";
import { AsyncImage } from "loadable-image";
import { TRYON_SERVER_URL } from "../config";
import PictureCard from "./PictureCard";
import errorIcon from "../assets/errorIcon.svg";
import GradientFont from "./GradientFont";



export default function PictureGrid(props) {
    const experiment = props.experiment;

    // appendResult(result);

    return (
        <div className={`flex flex-col w-full items-center justify-center rounded-2xl border-2 ${experiment.status === "failed" ? 'border-selected' : 'border-lite-red'}`}>

            <div className="relative w-full h-full grid grid-cols-4 gap-4 p-4">
                <PictureCard
                    key={experiment.id}
                    experiment={experiment}
                    imageUrl={experiment.model?.image_url}
                    label={"Original"}
                    index={0}
                    TRYON_SERVER_URL={TRYON_SERVER_URL}
                    loading={false}
                />
                {experiment.status === "completed" && (
                    <>
                        <PictureCard
                            key={experiment.result?.id || experiment.model?.id} 
                            experiment={experiment.result || experiment.model} 
                            imageUrl={experiment.result?.image_url || experiment.model?.image_url} 
                            label="Generation 1"
                            index={1} 
                            TRYON_SERVER_URL={TRYON_SERVER_URL}
                            loading={false}
                        />

                      
                        {Array.isArray(experiment.results) && experiment.results.length > 0 &&
                            experiment.results.map((image, index) => {
                                const imageUrl = image?.image_url || experiment?.model?.image_url;
                                const label = `Generation ${index + 2}`;

                                return (
                                    <PictureCard
                                        key={image.id}
                                        experiment={image}
                                        imageUrl={imageUrl}
                                        label={label}
                                        index={index + 2} 
                                        TRYON_SERVER_URL={TRYON_SERVER_URL}
                                        loading={false}
                                    />
                                );
                            })
                        }
                    </>
                )}


                {(experiment.status === "pending" || experiment.status === "running") &&
                    <>
                        <PictureCard
                            key={experiment.id}
                            experiment={experiment}
                            imageUrl={experiment.model?.image_url}
                            label={"Original"}
                            index={0}
                            TRYON_SERVER_URL={TRYON_SERVER_URL}
                            loading={true}
                        />

                        <PictureCard
                            key={experiment.id +1}
                            experiment={experiment}
                            imageUrl={experiment.model?.image_url}
                            label={"Original"}
                            index={0}
                            TRYON_SERVER_URL={TRYON_SERVER_URL}
                            loading={true}
                        />

                        <PictureCard
                            key={experiment.id + 2}
                            experiment={experiment}
                            imageUrl={experiment.model?.image_url}
                            label={"Original"}
                            index={0}
                            TRYON_SERVER_URL={TRYON_SERVER_URL}
                            loading={true}
                        />

                    </>

                }


                {experiment.status === "failed" && <div className="absolute inset-0 backdrop-blur-sm rounded-2xl w-full h-full p-1 flex items-center justify-center ">
                    <div className="flex w-full flex-col items-center justify-center">
                        <div className="flex flex-row w-full items-center justify-center">
                            <img src={errorIcon} alt="error" className='object-contain h-8 object-center w-8 mr-4' />
                            <GradientFont>FAILED</GradientFont>
                        </div>
                        <p> Generation failed. Contact us at <a href="mailto:contact@tryonlabs.ai" className="underline">contact@tryonlabs.ai</a></p>
                    </div>
                </div>}
            </div>

            <div className=" p-4 bg-gray-100 items-center justify-center rounded-b-2xl text-left w-full">
                <p className="text-gray-700 text-base">
                    {experiment?.params &&
                        Object.entries(JSON.parse(experiment.params))
                        .slice(0, -1)
                        .map(([key, value]) => (
                            <span key={key}>
                                <strong>{key}</strong>: {value.toString()}{key === "prompt" ? <br /> : <>,&nbsp;</>} 
                            </span>
                        ))
                    }
                </p>

            </div>
        </div>
    )
}   
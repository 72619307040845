import React, { useEffect, useCallback, useState } from "react";
import { useAuthentication } from "../../Authentication";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAccessToken, selectAuthenticated, selectUserData } from "../../selectors";
import { TRYON_SERVER_URL } from "../../config";
import ThreeDotSpinner from "../../components/ThreeDotSpinner";

export default function UserOnboardingDetails(props){
    const {isAuthenticated} = useAuthentication();  
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const authenticated = useSelector(selectAuthenticated)
    const accessToken = useSelector(selectAccessToken)
    const [userOnboardingDetails, setUserOnboardingDetails] = useState([])
    const [fetchingUserOnboardingDetails, setFetchingUserOnboardingDetails] = useState(false)
    const [userOnboardingDetailsFetched, setUserOnboardingDetailsFetched] = useState(false)
    const userData = JSON.parse(useSelector(selectUserData));
    const [userDetails, setUserDetails] = useState(null)

    let pageNumber = 1
    let limit = 10

    const checkAuthentication = useCallback(async () => {
        try {
            const userAuthenticated = await isAuthenticated();
            if (!userAuthenticated) {
                navigate("/signin");
            }
        } catch (error) {
            console.error('Error checking authentication:', error);

            if (error.message && error.message.toLowerCase().includes('network')) {
                console.error('Network error, redirecting to login page');
                navigate("/signin");
            }
        }
    }, [dispatch, isAuthenticated, navigate]);

    useEffect(() => {
        if (!authenticated) {
            checkAuthentication().then(() => {
            });
        }
    }, []);

    const fetchUserOnboardingDetails = async () => {
        try {
            setFetchingUserOnboardingDetails(true)
            const response = await fetch(`${TRYON_SERVER_URL}/api/v1/user/onboarding/list/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            })

            if(response.ok){
                const data = await response.json()
                console.log("user onboarding details:", data)
                setUserOnboardingDetails(data)
                // for(const userId in data){
                //     fetchUserDetails(userId).then((userDetail)=>{
                //         let userDetails2 = userDetails === null ? {} : userDetails
                //         userDetails2[userId] = userDetail
                //         setUserDetails(userDetails2)
                //         console.log("user details:", userDetails)
                //     })
                // }
                setUserOnboardingDetailsFetched(true)
                setFetchingUserOnboardingDetails(false)
            }else{
                console.error('Failed to fetch user onboarding details');
                setUserOnboardingDetailsFetched(true)
                setFetchingUserOnboardingDetails(false)
            }
        } catch (error) {
            console.error('Error fetching user onboarding details:', error);
            setUserOnboardingDetailsFetched(true)
            setFetchingUserOnboardingDetails(false)
        }
    }

    useEffect(() => {
        if(authenticated && !userOnboardingDetailsFetched){
            fetchUserOnboardingDetails()
        }
    }, [authenticated, userOnboardingDetailsFetched])

    const fetchUserDetails = async (userId) => {
        try {
            console.log("fetching user details for user id:", userId)
            const response = await fetch(`${TRYON_SERVER_URL}/api/v2/admin/users/${userId}/`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
            })

            if(response.ok){
                const data = await response.json()
                console.log("user details:", data)
                return data
            }else{
                console.error('Failed to fetch user details');
                return null
            }

        } catch (error) {
            console.error('Error fetching user details:', error);
            return null
        }
    }

    const onboardingDetails = (details, userId) => {
        return details.map((onboardingDetail, i) => (
            <div key={i} className="flex items-center justify-center lg:justify-start bg-gradient-to-r from-gray-50 to-gray-100 
                            shadow-sm border border-red-200 rounded-lg lg:rounded-full px-4 py-1.5 hover:shadow-md 
                            transition-all duration-200 ease-in-out">
                <p>{onboardingDetail.onboarding_step.split('_').map(word => 
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                ).join(' ')}:&nbsp;
                {onboardingDetail.onboarding_value}</p>
            </div>
        ));
    };

    return (<div>
        {authenticated ? 
        fetchingUserOnboardingDetails ? <div className="text-center text-xl h-72 bg-gray-100 m-4 rounded-lg animate-pulse flex items-center justify-center"><ThreeDotSpinner/></div> :
        userData.is_staff ? <div className="m-4">
            <h1 className="text-xl mb-4">User Onboarding Details</h1>

            <div className="h-screen overflow-y-auto flex flex-col gap-4">
            {userOnboardingDetails.map((userOnboardingDetail) => (
                <div key={userOnboardingDetail.user_id} className="p-6 bg-white shadow-md rounded-lg w-full mx-auto">

                        <div className="flex flex-col lg:flex-row lg:justify-start lg:items-center gap-4 mb-4">
                            <div className="bg-gray-50 px-4 py-2 rounded-lg shadow-md border border-gray-50">
                                <p className="text-lg font-medium">User ID:</p>
                                <p className="text-gray-700"><a href={`${TRYON_SERVER_URL}/admin/apis/customuser/${userOnboardingDetail.user_id}/`} 
                           target="_blank" 
                           rel="noopener noreferrer" 
                           className="text-blue-500 hover:underline">
                            {userOnboardingDetail.user_details?.id}
                        </a></p>
                            </div>
                            <div className="bg-gray-50 px-4 py-2 rounded-lg shadow-md border border-gray-50">
                                <p className="text-lg font-medium">Username:</p>
                                <p className="text-gray-700">{userOnboardingDetail.user_details?.username}</p>
                            </div>
                            <div className="bg-gray-50 px-4 py-2 rounded-lg shadow-md border border-gray-50">
                                <p className="text-lg font-medium">Name:</p>
                                <p className="text-gray-700">
                                    {userOnboardingDetail.user_details?.first_name} 
                                    {userOnboardingDetail.user_details?.last_name ? ` ${userOnboardingDetail.user_details?.last_name}` : ''}
                                </p>
                            </div>
                            <div className="bg-gray-50 px-4 py-2 rounded-lg shadow-md border border-gray-50">
                                <p className="text-lg font-medium">Email:</p>
                                <p className="text-gray-700">{userOnboardingDetail.user_details?.email}</p>
                            </div>
                        </div>

                    <div className="flex flex-start lg:grid lg:grid-cols-2 flex-wrap gap-2 mt-4">
                    {Object.keys(userOnboardingDetail.onboarding_details).map((onboardingStep)=>{
                        return (
                            <div key={onboardingStep} className="flex items-center justify-center lg:justify-start bg-gradient-to-r from-gray-50 to-gray-100 
                            shadow-sm border border-red-200 rounded-lg px-4 py-1.5 hover:shadow-md 
                            transition-all duration-200 ease-in-out">
                                <p className="text-sm">{onboardingStep.split('_').map(word => 
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                ).join(' ')}:&nbsp;
                                {userOnboardingDetail.onboarding_details[onboardingStep]}</p>
                            </div>
                        )
                    })}
                    </div>

                    {/* {userDetails && Object.keys(userDetails).length > 0 ?
                        <div className="flex flex-col lg:flex-row lg:justify-start lg:items-center gap-4">
                            <div className="bg-gray-50 px-4 py-2 rounded-lg shadow-md">
                                <p className="text-lg font-medium">Username:</p>
                                <p className="text-gray-700">{userDetails[userId]?.username}</p>
                            </div>
                            <div className="bg-gray-50 px-4 py-2 rounded-lg shadow-md">
                                <p className="text-lg font-medium">Name:</p>
                                <p className="text-gray-700">
                                    {userDetails[userId]?.first_name} 
                                    {userDetails[userId]?.last_name ? ` ${userDetails[userId]?.last_name}` : ''}
                                </p>
                            </div>
                            <div className="bg-gray-50 px-4 py-2 rounded-lg shadow-md">
                                <p className="text-lg font-medium">Email:</p>
                                <p className="text-gray-700">{userDetails[userId]?.email}</p>
                            </div>
                        </div>
                    :
                    <div className="text-center text-xl h-24 bg-gray-100 animate-pulse mt-4 flex items-center justify-center"><ThreeDotSpinner/></div>
                    } */}
                    {/* <div className="flex flex-start flex-wrap gap-2 mt-4">
                        {onboardingDetails(userOnboardingDetails[userId], userId)}
                    </div> */}
                </div>
            ))}
            </div>

            <div className="flex justify-center items-center mt-4 hidden">
                <button className="py-1 px-2 lg:px-4 lg:py-2 text-sm rounded-lg text-black-300 bg-white border-2 border-black-300 cursor-pointer">
                    Show More
                </button>
            </div>

            </div>
            : 
        <div className="text-center text-xl mt-4">You are not authorized to access this page</div>
        :
        <div></div>
        }
    </div>)
}
